import React, { useCallback, useEffect } from "react";
import { VStack } from "@chakra-ui/react";
import { setFormData, setMode, storyState } from "../../stores/StoryStore";
import { useSnapshot } from "valtio";
import { cloneDeep } from "lodash";
import { Models } from "specs";

import { DynamicForm } from "../components/inputs/DynamicForm";

const StoryForm = ({ handleSubmit, story }: any) => {
  const {
    formData,
    modes,
    properties,
    mode,
    activeIndexes,
    exp,
    modelEditing,
  } = useSnapshot(storyState);

  useEffect(() => {
    const initValues: any = {};
    properties &&
      properties.map(([key, value]: any, index: number) => {
        switch (value.type) {
          case "string":
            if (value.title === "Audio Url") {
              initValues[key] = value.examples[0];
              return null;
            }
            if (value.title === "Prompt") {
              if (story) {
                let defaultValue = PromptValue();
                if (Models.dialog.includes(modelEditing)) {
                  defaultValue = DialogueValue();
                }
                initValues[key] = defaultValue;
              } else {
                initValues[key] = value.description;
              }
              return null;
            }
            if (value.title === "Text") {
              initValues[key] = DialogueValue();
              return null;
            }
            if (value.enum) {
              initValues[key] = value.enum[0];
              return null;
            }
            initValues[key] = value.default;
            return null;

          case "integer":
            initValues[key] = value.default;
            return null;
          case "number":
            initValues[key] = formData[key] || 0;
            return null;
          case "boolean":
            initValues[key] = false;
            return null;
          case "array":
            initValues[key] = value.default;
            return null;
          default:
            if (value.anyOf) {
              const enums = value.anyOf[1].enum;
              initValues[key] = enums[0];
              return null;
            }
            console.log(value.type, "is unhandled", value);
        }
      });
    setFormData({ ...cloneDeep(formData), ...initValues });
  }, []);

  const PromptValue = useCallback(
    () => getPromptFromExp(exp, activeIndexes),
    [activeIndexes, exp]
  );
  const DialogueValue = useCallback(
    () => getDiagFromExp(exp, activeIndexes),
    [activeIndexes, exp]
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setFormData({ ...cloneDeep(formData), [key]: e.target.value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <div role="tablist" className="tabs tabs-bordered">
          {modes.map((m: string) => (
            <button
              role="tab"
              className={`tab ${mode === m ? "tab-active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setMode(m);
              }}
              key={m}
            >
              {m}
            </button>
          ))}
        </div>
        <DynamicForm
          handleChange={handleChange}
          formData={formData}
          properties={properties}
          setFormData={setFormData}
        />
      </VStack>
    </form>
  );
};

export function getPromptFromExp(exp: any, activeIndexes: any) {
  const [sceneIndex] = activeIndexes.split(",");
  const scene = exp?.out?.content?.scenes[sceneIndex];
  if (!scene) {
    return "";
  }
  const prompt = `Scene Description: ${scene.scene_description}, Visual details: ${scene.visual_details}`;
  return prompt;
}

export function getDiagFromExp(exp: any, activeIndexes: any) {
  const [sceneIndex, diagIndex] = activeIndexes.split(",");
  const scene = exp?.out?.content?.scenes[sceneIndex];
  const diag = scene?.dialogues[diagIndex]?.line;
  return diag;
}

export default StoryForm;
