import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Text,
  VStack,
  Progress,
  useToast,
  Button,
  SimpleGrid,
  Image,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FaCloudUploadAlt, FaCheckCircle, FaEllipsisV } from 'react-icons/fa';
import { SendAxiosFormData } from "../../utils/main";

interface UploadComponentProps {
  videoId: string;
  type: string;
//   onUploadSuccess: (asset: any) => void;
  onAction: (action: string, asset: any) => void;
}

interface FileWithPreview extends File {
  preview: string;
  path?: string;
  uploadStatus: 'pending' | 'success' | 'error';
  asset?: any;
}

const UploadComponent: React.FC<UploadComponentProps> = ({ videoId, type, onAction }) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<FileWithPreview[]>([]);
  const toast = useToast();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFiles(prevFiles => [
      ...prevFiles,
      ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        uploadStatus: 'pending' as const
      }))
    ]);
  }, []);

  let accept: Record<string, any> = {
    //   'image/*': [],
      'video/*': []
    }
    if (type === "Image") {
      accept = {
          'image/*': [],
        }
    }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept,
    maxSize: 500 * 1024 * 1024 // 500 MB
  });

  const uploadFile = async (fileWithPreview: FileWithPreview) => {
    setUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    const originalFile = fileWithPreview as File;
    formData.append('file', originalFile, originalFile.name);
    formData.append('videoId', videoId);
    formData.append('type', type);

    try {
        const response = await SendAxiosFormData('/api/v2/brand/add/file ', formData, "POST", {
            'Content-Type': 'multipart/form-data'
          },{ onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
          setUploadProgress(percentCompleted);
      }}
        );

      if (response.data.asset) {
        fileWithPreview.uploadStatus = 'success';
        fileWithPreview.asset = response.data.asset;
        // onUploadSuccess(response.data.asset);
        toast({
          title: 'Upload successful',
          description: `${fileWithPreview.path} has been uploaded.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      fileWithPreview.uploadStatus = 'error';
      toast({
        title: 'Upload failed',
        description: `Failed to upload ${fileWithPreview.path}.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
      setSelectedFiles([...selectedFiles]); // Trigger re-render
    }
  };

  const handleUploadClick = async () => {
    for (const file of selectedFiles.filter(f => f.uploadStatus === 'pending')) {
      await uploadFile(file);
    }
  };

  const handleAction = (action: string, file: FileWithPreview) => {
    if (file.asset) {
      onAction(action, file.asset);
    }
  };

  return (
    <VStack spacing={4}>
      <Box
        {...getRootProps()}
        border="2px dashed"
        borderColor={isDragActive ? "blue.500" : "gray.300"}
        borderRadius="md"
        p={10}
        textAlign="center"
        cursor="pointer"
      >
        <input {...getInputProps()} />
        <FaCloudUploadAlt size={40} color="gray.400" />
        <Text mt={2}>
          {isDragActive
            ? "Drop the file here..."
            : "Click to upload or drag and drop"}
        </Text>
        <Text fontSize="sm" color="gray.500">
          Images or videos up to 500 MB
        </Text>
      </Box>
      {selectedFiles.length > 0 && (
        <>
          <SimpleGrid columns={4} spacing={4}>
            {selectedFiles.map((file, index) => {
                console.log({file})
              return <Box key={index} position="relative">
                {/* {file.type && file.type.startsWith('video') ? ( */}
                {type === "Image" && <Image src={file.preview} alt={file.name} />}
                {type === "Video" && <video src={file.preview} controls style={{ width: '100%', height: '300px' }} />}
                  
                {/* ) : (
                  
                )} */}
                <Text fontSize="sm">{file.path}</Text>
                {file.uploadStatus === 'success' && (
                  <>
                    <Icon
                      as={FaCheckCircle}
                      color="green.500"
                      position="absolute"
                      top={2}
                      right={2}
                    />
                    <Menu>
                      <MenuButton
                        as={Button}
                        size="sm"
                        position="absolute"
                        bottom={2}
                        left={2}
                        onClick={() => handleAction('update', file)}
                      >
                        Update
                      </MenuButton>
                    </Menu>
                  </>
                )}
              </Box>
})}
          </SimpleGrid>
          <Button 
            onClick={handleUploadClick} 
            isLoading={uploading}
            isDisabled={uploading || !selectedFiles.some(f => f.uploadStatus === 'pending')}
          >
            Upload Files
          </Button>
        </>
      )}
      {uploading && (
        <Progress value={uploadProgress} width="100%" />
      )}
    </VStack>
  );
};

export default UploadComponent;