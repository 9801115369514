import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  DrawerFooter,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { cloneDeep } from 'lodash'
import StoryForm from "./StoryForm";
import { useSnapshot } from "valtio";
import { storyState } from "../../stores/StoryStore";

const FormHeader = (props: any) => {
  function showPipelineSettings(arg0: number) {
    if (arg0 == 0) {
      return "Video Model Settings";
    }
    if (arg0 == 1) {
      return "Dialogue Model Settings";
    }
    if (arg0 == 2) {
      return "Background Music Settings";
    }
  }
  const [sceneIndex, diagIndex ,modelIndex] =  props.activeIndexes.split(',');
  return (
    <span>
      Edit Pipeline Settings for Scene: {Number(sceneIndex)+ 1}{" "}
      {Number(diagIndex) > -1
        ? `, Dialogue: ${Number(diagIndex) + 1}`
        : ""}{" "}
      {Number(modelIndex) > -1
        ? ` : ${showPipelineSettings(modelIndex)}`
        : ""}
    </span>
  );
};

const EditPipeline = (props: any) => {
  const { formData,  mode  } = useSnapshot(storyState);  

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      props.saveExpPipeline({
        formData : cloneDeep(formData),
        indexes: props.activeIndexes,
        model: props.modelEditing,
        mode
      });
      props.editPipelineOnClose();
      props.handleOpen();
      // Handle success (e.g., display a message or the response data)
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., display an error message)
    }
  };
  return (
    <>
      <Drawer
        isOpen={props.editPipelineIsOpen}
        placement="right"
        size={"xl"}
        //   initialFocusRef={firstField}
        onClose={props.editPipelineOnClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <FormHeader {...props} />
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              <Box>
                <StoryForm
                  // schema={imageToImageSchema("fast-svd")}
                  handleSubmit={props.handleSubmit}
                  story={true}
                />
                {/* <Button colorScheme='blue' variant='outline'  size={"sm"}>Edit Background Pipeline</Button> */}
              </Box>
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              variant="outline"
              mr={3}
              onClick={props.editPipelineOnClose}
            >
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleSubmit}>
              Update Pipeline
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default EditPipeline;
