import { getAvailableFonts } from "@remotion/google-fonts";
import React, { useCallback, useState } from "react";
 
export const FontPicker: React.FC<any> = ({
  indexInside,
  editorProps,
  selectedComponent,
  prop,
  getValue,
  setValue,
  defaults,
  valOpts={}
}: any) => {
const [selectedFont, setSelectedFont] = useState<string>(() => {
  const val = getValue(selectedComponent, prop, editorProps);
  setValue(`${selectedComponent}_${prop}`, val);
  return val;
});
  const newFonts = getAvailableFonts();
 
  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const fonts = newFonts[e.target.selectedIndex];
 
      // Load font information
      const loaded = await fonts.load();
 
      // Load the font itself
      const { fontFamily, ...otherInfo } = loaded.loadFont();
 
      // Or get metadata about the font
      setSelectedFont(fontFamily);
      setValue(
        `${selectedComponent}_${prop}`,
        fontFamily
      );
      
      const info = loaded.getInfo();
      setValue(
        `${selectedComponent}_fontImportName`,
        info.importName
      );
      // const styles = Object.keys(info.fonts);
      // console.log("Font", info, " Styles");
      // for (const style of styles) {
      //   const weightObject = info.fonts[style as keyof typeof info.fonts];
      //   const weights = Object.keys(weightObject);
      //   console.log("- Style", style, "supports weights", weights);
      //   for (const weight of weights) {
      //     const scripts = Object.keys(weightObject[weight]);
      //     console.log("-- Weight", weight, "supports scripts", scripts);
      //   }
      // }
    },
    [newFonts],
  );
 
  return (
    <div key={indexInside}> 
      <select onChange={onChange} defaultValue={selectedFont}>
        {newFonts.map((f) => {
          return (
            <option key={f.importName} value={f.importName}>
              {f.fontFamily}
            </option>
          );
        })}
      </select>
      <div
        style={{
          fontFamily: selectedFont,
          fontSize: "24px",
          marginTop: "20px",
        }}
      >
        "This is how the {selectedFont} font looks"
      </div>
    </div>
  );
};