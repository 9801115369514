import {
  InputGroup,
  InputLeftAddon,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Icon,
  Image,
  VStack,
  Center,
  Switch,
  FormControl,
  FormLabel,
  Select,
  Box,
  StackDivider,
} from "@chakra-ui/react";
import {
  alignItemsOptions,
  backendurl,
  displayOptions,
  getSubString,
  justifyContentOptions,
  positionOptions,
} from "./Misc";
import { handleShowAlert } from "../../pages/Signup";
import {
  MdAdd,
  MdControlPointDuplicate,
  MdOutlineDelete,
} from "react-icons/md";
import {
  editorState,
  handleScreenChange,
  setSelectedComponent,
  setShowLoader,
} from "../../stores/EditorStore";
import { useSnapshot } from "valtio";
import CustomInput from "../components/props/CustomInput";
import { ColorPicker } from "@lanyue/react-color-picker";
import "@lanyue/react-color-picker/dist/style.css";
import { RepeatIcon } from "@chakra-ui/icons";
import { FontPicker } from "../components/props/FontPicker";
import { Track } from "../../modules/editor/Timeline";
import ImageFilterEditor from "../components/ImageFilterEditor";
// import OpacityInput from "../components/props/OpacityInput";

const presets = [
  "#f44337",
  "#e91e63",
  "#9c27b0",
  "#683ab8",
  "#3f52b5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "rgba(0, 0, 0, 0)",
  "rgba(255, 255, 255, 0)",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#9E9E9E",
];

export function RenderScreens({
  updateComponent,
  send,
}: {
  updateComponent: Function;
  send: Function;
}): React.ReactNode {
  const { screens, selectedScreen, videoId } = useSnapshot(editorState);
  if (!screens) {
    return;
  }
  const ScreenNames = Object.keys(screens);
  return ScreenNames?.map((s: any, index: number) => {
    const screenKey = screens[s].name;
    const key = screens[s].id;
    return (
      <Card
        maxW="sm"
        key={index}
        onClick={() => {
          handleScreenChange(s, updateComponent, key, screenKey);
        }}
        border={s === selectedScreen ? "8px solid black;" : "8px solid #4A5568"}
      >
        <CardBody p={0} key={index}>
          {/* <Image
            src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
            alt="Green double couch with wooden legs"
            borderRadius="lg"
          /> */}
          <Center bg="gray.400" h="60px" color="white">
            {s}
          </Center>
          {/* <Text >{s}</Text> */}
        </CardBody>
        <CardFooter pt={0} p={0} bg="gray.500">
          <ButtonGroup spacing="5">
            <Button
              variant="ghost"
              colorScheme="blue"
              onClick={() => send("addScreen", null, videoId)}
            >
              <Icon as={MdAdd} w={6} h={6} />
            </Button>
            <Button variant="ghost" colorScheme="blue" onClick={() => send("duplicateScreen", { screenName: screenKey }, videoId)}>
              <Icon as={MdControlPointDuplicate} w={6} h={6} />
            </Button>
            <Button variant="ghost" colorScheme="blue" onClick={() => send("deleteScreen", { screenName: screenKey }, videoId)}>
              <Icon as={MdOutlineDelete} w={6} h={6} />
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    );
  });
}

// export function handleImport(type: number, setCurrentImporting: Function, importOpen: Function) {
//   setCurrentImporting(type);
//   importOpen();
// }

export const modelsList = [
  {
    name: "Stable Video Diffusion",
    slug: "stable-video-diffusion",
    description: "",
    type: "text2vid",
    url: "https://huggingface.co/stabilityai/stable-video-diffusion-img2vid-xt",
    info: "generate 4s vid from a single image at (25 frames at 6 fps)",
  },
];

export const AvtModelsList = [
  {
    name: "Did",
    slug: "did",
    description: "",
    type: "text2vid",
    url: "https://www.d-id.com",
    info: "generate avatar video from text",
  },
];

export function createHandleImport(
  importType: number,
  setCurrentImporting: Function,
  importOpen: Function,
  setCurrentGenerating: Function,
  genOpen: Function
) {
  return function (event: React.MouseEvent<HTMLButtonElement>) {
    if (importType < 10) {
      //ADD
      setCurrentImporting(importType);
      importOpen();
    }
    if (importType < 20 && importType >= 10) {
      // Generate
      setCurrentGenerating(importType);
      genOpen();
      console.log("Generate");
    }
  };
}

export function createHandleGenerate(
  importType: number,
  setCurrentImporting: Function,
  importOpen: Function,
  setCurrentGenerating: Function,
  genOpen: Function
) {
  return function (event: React.MouseEvent<HTMLButtonElement>) {
    if (importType < 10) {
      //ADD
      setCurrentImporting(importType);
      importOpen();
    }
    if (importType < 20 && importType >= 10) {
      // Generate
      setCurrentGenerating(importType);
      genOpen();
    }
  };
}

export function handleGenerate(
  type: number,
  setCurrentGenerating: Function,
  genOpen: Function
) {
  setCurrentGenerating(type);
  genOpen();
}
// export function createHandle(type: number, setCurrentCreating: Function, videoOpen: Function) {
//   setCurrentCreating(type);
//   videoOpen();
// }

export function createHandle(
  type: number,
  setCurrentCreating: Function,
  videoOpen: Function
) {
  return function (event: React.MouseEvent<HTMLButtonElement>) {
    setCurrentCreating(type);
    videoOpen();
  };
}

export function importAsset(
  importing: number,
  importProps: any,
  send: Function,
  videoId: string | undefined,
  selectedScreen: string,
  importonClose: Function
): void {
  if (!videoId) {
    return handleShowAlert("error", "Error", "Video id missing");
  }
  switch (importing) {
    case 0:
      addImage(importProps, send, videoId, selectedScreen);
      break;
    case 1:
      addAudio(importProps, send, videoId, selectedScreen);
      break;
    case 2:
      addVideo(importProps, send, videoId, selectedScreen);
      break;
    case 3:
      addText(importProps, send, videoId, selectedScreen);
      break;

    default:
      break;
  }
  importonClose();
}

export function createNewVideo(
  creating: any,
  video: any,
  auth: any,
  videoOnClose: Function,
  generateScenes?: Function,
  generateOpen?: Function
) {
  setShowLoader(true);
  switch (creating) {
    case 0:
      console.log("VIDEO", video);
      createVideo(video, auth, setShowLoader, generateScenes, generateOpen);
      break;
    case 1:
      console.log("VIDEO", video);
      createVideo(video, auth, setShowLoader);
      break;
    default:
      console.log("Unhandled VIDEO", video, creating);

      break;
  }
  videoOnClose();
}


export function RenderProps(props: any) {
  // setSelectedComponentName={setSelectedComponentName}
  // setSelectedComponent={setSelectedComponent}
  const components = props.screenprops[props.selectedScreen];
  const propToName = (id: string) => props.video?.meta.props[`${id}_name`];
  return (
    <Box>
      <Card>
        <Box>
          <Breadcrumb p={2}>
            <BreadcrumbItem>
              <BreadcrumbLink href="#">
                {getSubString(props.video?.title, 20)}
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#">{props.selectedScreen}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Card>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={1}
        pt={2}
        align="stretch"
      >
        {components &&
          Object.keys(components).map((c, index) => {
            // const component = components[c];

            let label = propToName(c);
            // if (component?.type && component?.type?.value) {
            //   label = component?.type?.value;
            // }
            return (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedComponent(c, label, props.videoId);
                  // props.setSelectedComponentName(label);
                  props.propOpen();
                }}
                key={index}
                bg={props.propselected === c ? "green.200" : "gray.200"}
              >
                <label>{label} </label>
              </Button>
            );
          })}
      </VStack>
    </Box>
  );
}

export const updateProps = (props: any, video: string, send: Function) => {
  const payload = {
    action: "updateProps",
    video,
    props,
  };
  send(payload);
};

export const updateStringProps = (props: any, video: string, send: Function) => {
  const payload = {
    action: "updateProps",
    video,
    stringProps: props,
  };
  send(payload);
};
export const addImage = (
  props: any,
  send: Function,
  videoId: string | undefined,
  selectedScreen: string
) => {
  if (!videoId) {
    return handleShowAlert("error", "Error", "Video ID Missing");
  }
  const payload = {
    action: "addImage",
    id: videoId,
    screen: selectedScreen,
    component: "Img",
    props,
  };
  send(payload);
};

export const addVideo = (
  props: any,
  send: Function,
  videoId: string | undefined,
  selectedScreen: string
) => {
  if (!videoId) {
    return handleShowAlert("error", "Error", "Video ID Missing");
  }
  const payload = {
    action: "addVideo",
    id: videoId,
    data: {
      screen: selectedScreen,
      component: "Video",
      props,
    },
  };
  send(payload);
};

export const addAudio = (
  props: any,
  send: Function,
  videoId: string | undefined,
  selectedScreen: string
) => {
  if (!videoId) {
    return handleShowAlert("error", "Error", "Video ID Missing");
  }

  const payload = {
    action: "addAudio",
    id: videoId,
    data: {
      screen: selectedScreen,
      component: "Audio",
      props,
    },
  };
  send(payload);
};

export const exportVideo = (send: Function, videoId: string | undefined) => {
  return function (event: React.MouseEvent<HTMLButtonElement>) {
    if (!videoId) {
      return handleShowAlert("error", "Error", "Video ID Missing");
    }
    const payload = {
      action: "exportVideo",
      id: videoId,
    };
    send(payload);
    handleShowAlert(
      "success",
      "Export in Progress",
      "Exported Video will be sent to your email, once the export is complete."
    );
  };
};


export const addFont = (
  props: any,
  send: Function,
  videoId: string | undefined,
  selectedScreen: string
) => {
  if (!videoId) {
    return handleShowAlert("error", "Error", "Video ID Missing");
  }
  const payload = {
    action: "addFont",
    id: videoId,
    data: {
      screen: selectedScreen,
      component: "Font",
      props,
    },
  };
  send(payload);
};

export const getTimeLinePayload = (
  screenprops: any,
  selectedScreen: any,
  video: any,
  childrenRef: any
) => {
  const screenProps: any = {};
  selectedScreen = selectedScreen || Object.keys(video.meta.screens)[0];

  if (selectedScreen) {
    screenProps[selectedScreen] = screenProps[selectedScreen] || {};
  }
  const timelineDetails: any = {};
  const props = video.meta.props;
  const childRef = video.meta.screens[selectedScreen].kids;
  // const componentKey = message.video.meta.screens[screen].id;

  screenProps[selectedScreen] = screenProps[selectedScreen] || {};
  // timelineDetails[screen] = timelineDetails[screen] || {};

  childRef.map((curr: any) => {
    const compKey = curr.id;
    screenProps[selectedScreen][compKey] = { ...curr.props, name: curr.name };
    timelineDetails[compKey] = timelineDetails[compKey] || {};
    timelineDetails[compKey].key = compKey;
    timelineDetails[compKey].name = video.meta.props[`${compKey}_name`];
    timelineDetails[compKey].type = curr.tag;
  });

  // Object.keys(message.video.meta.screens).map((screen) => {
  //   const childRef = message.video.meta.screens[screen].children;
  //   // const componentKey = message.video.meta.screens[screen].id;

  //   screenProps[screen] = screenProps[screen] || {};
  //   // timelineDetails[screen] = timelineDetails[screen] || {};
  //   console.log(childRef, "childref", message.video.meta.screens[screen]);

  //   childRef.map((curr: any) => {
  //     const compKey = curr.id;
  //     screenProps[screen][compKey] = { ...curr.props, name: curr.name };
  //     timelineDetails[compKey] = timelineDetails[compKey] || {};
  //     timelineDetails[compKey].key = compKey;
  //     timelineDetails[compKey].name =
  //       message.video.meta.props[`${compKey}_name`];
  //     console.log(childrenRef, curr, "childrenRef[curr]?.props");
  //   });
  // });

  const timelinePayload = Object.keys(timelineDetails).map((screen) => {
    const key = timelineDetails[screen].key;
    const type = timelineDetails[screen].type;
    // const name = timelineDetails[screen].name;
    const from = props[`${key}_from`];
    const durationInFrames = props[`${key}_durationInFrames`];
    const convertToSeconds = (from: number) => (from ? from * 1000 : 0);
    return {
      title: key,
      type,
      keyframes: [
        {
          val: convertToSeconds(from),
        },
        {
          val: convertToSeconds(from) + convertToSeconds(durationInFrames),
        },
      ],
    };
  });

  return { timelinePayload, screenProps };
};

export const getTimeLinePayload2 = (
  screenprops: any,
  selectedScreen: any,
  video: any,
  childrenRef: any
) => {
  const screenProps: any = {};
  selectedScreen = selectedScreen || Object.keys(video.meta.screens)[0];

  if (selectedScreen) {
    screenProps[selectedScreen] = screenProps[selectedScreen] || {};
  }
  const props = video.meta.props;
  const childRef = video.meta.screens[selectedScreen].kids;

  const timelinePayload: any = [];
  const tracks = video.meta.screens[selectedScreen].tracks || [];
  // const componentKey = message.video.meta.screens[screen].id;

  screenProps[selectedScreen] = screenProps[selectedScreen] || {};
  // timelineDetails[screen] = timelineDetails[screen] || {};

  childRef.map((curr: any) => {
    const compKey = curr.id;
    screenProps[selectedScreen][compKey] = { ...curr.props, name: curr.name };
    const timeline = {
      id: compKey,
      type: curr.tag,
      start: props[`${compKey}_from`],
      duration: props[`${compKey}_durationInFrames`],
      track: tracks.findIndex((track: Track) => track.name === props[`${compKey}_track`]),
      content: props[`${compKey}_name`]
    }
    timelinePayload.push(timeline);
  });


  return { timelinePayload, screenProps, tracks };
};

export const addText = (
  props: any,
  send: Function,
  videoId: string | undefined,
  selectedScreen: string
) => {
  if (!videoId) {
    return handleShowAlert("error", "Error", "Video ID Missing");
  }
  const payload = {
    action: "addText",
    id: videoId,
    data: {
      screen: selectedScreen,
      component: "Text",
      props,
    },
  };
  send(payload);
};

export const addScreen = (send: Function, videoId: string) => {
  const payload = {
    action: "addScreen",
    id: videoId,
  };
  send(payload);
};


const exportFile = async (type: string, token: string) => {
  const input = document.getElementById("avtBase") as HTMLInputElement;
  if (!input || !input.files) {
    ///TODO - show error here
    return;
  }
  const formData = new FormData();
  formData.append("file", input.files[0]);
  formData.append("type", type);
  formData.append("alt", type);
  const resp = await fetch(`${backendurl}/api/media`, {
    method: "POST",
    headers: {
      Authorization: `JWT ${token}`,
    },
    // credentials: "include",
    body: formData,
  });
  return resp.json();
};

export const genAssets = (props: any, type: string, auth: any) => {
  exportFile("avtBase", auth.token).then((avtBase) => {
    exportFile("avtAudio", auth.token).then((avtAudio) => {
      fetch(`${backendurl}/api/generate/avatar`, {
        method: "POST",
        headers: {
          Authorization: `JWT ${auth.token}`,
          "Content-Type": "application/json",
        },
        // credentials: "include",
        body: JSON.stringify({
          base: avtBase.doc.id,
          audio: avtAudio.doc.id,
          props,
        }),
      })
        .then((resp) => {
          handleShowAlert(
            "success",
            "Processing",
            "AI creation underway, generated video will be added automatically"
          );
          console.log(resp);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  });
};

export function loadVideo(videoId: string | undefined, send: Function) {
  if (!videoId) {
    return handleShowAlert("error", "Error", "Video ID Missing");
  }
  const payload = {
    action: "getVideo",
    id: videoId,
  };
  send(payload);

  // const modelpayload = {
  //   action: "getModels",
  // };
  // send(modelpayload);
}


export function saveProps(
  videoId: string,
  send: any,
  editorProps: any,
  selectedScreen: string
) {
  const payload = {
    action: "saveVideo",
    id: videoId,
    data: { editorProps, selectedScreen, updateMeta: true, refresh: true },
  };
  send(payload);
}

export function saveGlobals(videoId: string, send: Function, global: any) {
  const payload = {
    action: "saveGlobals",
    id: videoId,
    data: { global, updateMeta: false, refresh: true },
  };
  send(payload);
}

export const createVideo = (
  video: any,
  auth: any,
  setShowLoader: Function,
  generateScenes?: Function,
  generateOpen?: Function
) => {
  const payload = {
    meta: {
      props: {
        width: video.meta?.global?.width || 1280,
        height: video.meta?.global?.height || 720,
        fps: video.meta?.global?.fps || 30,
      },
    },
    title: video.title,
    description: video.description,
    url: video.url,
  };
  _createVideo(payload, auth, generateScenes, generateOpen, setShowLoader)
    .then(() => {
      // setShowLoader(false);
    })
    .catch((e) => {
      setShowLoader(false);
      console.log(e);
    });
};

export const sendMessage = (obj: any, wsRef: any) => {
  if (wsRef.current && wsRef.current.readyState === 1) {
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    wsRef.current.send(JSON.stringify({ ...obj, auth }));
    return;
  }
  if (wsRef.current && wsRef.current.readyState === 0) {
    console.log("connecting to server");
    return;
  }
  handleShowAlert("error", "Error", "Issue connecting to the Server");
};

export function createHandleOpen(
  loadVideo: Function,
  videoId: string | undefined,
  send: Function
) {
  return function (this: WebSocket, ev: Event) {
    if (!videoId) {
      return;
    }
    loadVideo(videoId, send);
  };
}

export function getValue(
  componentId: string,
  propId: string,
  editorProps: any
): string | number | readonly string[] | undefined {
  const elementKey = `${componentId}_${propId}`;
  return editorProps[elementKey];
}

export function setValue(
  videoId: string | undefined,
  screenId: string,
  componentId: any,
  propId: string,
  editorProps: any,
  setEditorProps: Function,
  value: string
): void {
  if (!videoId) {
    return;
  }
  const elementKey = `${componentId}-${propId}`;

  setEditorProps(Object.assign({}, editorProps, { [elementKey]: value }));
}

export const ComponentProps = ({
  type,
  register,
  screenprops,
  selectedComponent,
  selectedScreen,
  videoId,
  editorProps,
  setValue,
  openAsset
}: any) => {
  const components = screenprops[selectedScreen];

  const component = components[selectedComponent];

  if (!component) return <span></span>

  const componentProps = Object.keys(component);

  const positionProps = [
    "position",
    "left",
    "top",
    "right",
    "bottom",
    "height",
    "width",
    "zIndex",
    "translateX",
    "translateY",
    "rotate",
    "skew",
    "scale",
  ];
  const metaProps = [
    "fontSize",
    "text",
    "color",
    "background",
    "opacity",
    "url",
    "playbackRate",
    "volume",
    "disable",
    "fontFamily",
    "filter"
  ];
  const timingProps = ["from", "durationInFrames"];

  const filteredProps = componentProps.filter((prop: any) => {
    if (type === "position" && positionProps.includes(prop)) {
      return true;
    }
    if (type === "meta" && metaProps.includes(prop)) {
      return true;
    }
    if (type === "timing" && timingProps.includes(prop)) {
      return true;
    }
  });

  const props = filteredProps.map((prop: any, indexInside) => {
    if (prop === "fontFamily") {
      return (<Box key={`${prop}${indexInside}`} p={2} border="1px solid black">
        <FontPicker
          indexInside={indexInside}
          editorProps={editorProps}
          selectedComponent={selectedComponent}
          prop={prop}
          getValue={getValue}
          setValue={setValue} />
      </Box>)
    }

    if (prop === "filter") {
      return (<Box key={`${prop}${indexInside}`} p={2} border="1px solid black">
        <ImageFilterEditor
          editorProps={editorProps}
          indexInside={indexInside}
          selectedComponent={selectedComponent}
          prop={prop}
          getValue={getValue}
          setValue={setValue}
          defaults={{}}
          valOpts={{}}
        />
      </Box>)
    }
    if (prop === "disable") {
      return (
        <Box key={`${prop}${indexInside}`} p={2} border="1px solid black">
          <FormControl
            display="flex"
            alignItems="center"
            paddingTop={2}
            paddingLeft={1}
          >
            <FormLabel htmlFor="disable-component" mb="0">
              Disable
            </FormLabel>
            <Switch
              id="disable-component"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultChecked={
                getValue(selectedComponent, prop, editorProps) || false
              }
            />
          </FormControl>
        </Box>
      );
    }
    if (prop === "fontSize") {
      return (
        <CustomInput
          register={register}
          indexInside={indexInside}
          editorProps={editorProps}
          selectedComponent={selectedComponent}
          prop={prop}
          getValue={getValue}
          key={`${selectedComponent}_${prop}`}
          setValue={setValue}
        />
      );
    }
    if (prop === "url") {
      const type = component?.type?.default || "Image";
      return (
        <Box key={`${prop}${indexInside}`}>
          <Box borderWidth="1px" borderStyle="dashed" borderRadius="md" p={2}>

            {type === "Image" && <Image src={getValue(selectedComponent, prop, editorProps) as string} alt="image" />}
            {type === "Video" && <video src={getValue(selectedComponent, prop, editorProps) as string} controls={true} />}
            {type === "Audio" && <audio src={getValue(selectedComponent, prop, editorProps) as string} />}
          </Box>
          {type === "Video" && <Button
            leftIcon={<RepeatIcon />}
            width="100%"
            mt={2}
            onClick={() => openAsset()}
          >
            Change {type}
          </Button>}
          {type === "Image" && <Button
            leftIcon={<RepeatIcon />}
            width="100%"
            mt={2}
            onClick={() => openAsset()}
          >
            Change {type}
          </Button>}

        </Box>
      );
    }
    if (prop === "text") {
      return (
        <Box key={`${prop}${indexInside}`}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="text"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }
    if (prop === "color" || prop === "background") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <ColorPicker
              value={
                (getValue(selectedComponent, prop, editorProps) as string) ||
                "black"
              }
              presets={presets}
              onChange={(value) => {
                setValue(`${selectedComponent}_${prop}`, value);
              }}
            />
          </InputGroup>
        </Box>
      );
    }
    if (prop === "scale") {
      return (
        <CustomInput
          register={register}
          indexInside={indexInside}
          editorProps={editorProps}
          selectedComponent={selectedComponent}
          prop={prop}
          getValue={getValue}
          key={`${selectedComponent}_${prop}`}
          setValue={setValue}
          valOpts={{ step: 0.01 }}
        />
      );
    }
    if (prop === "opacity") {
      return (
        <CustomInput
          register={register}
          indexInside={indexInside}
          editorProps={editorProps}
          selectedComponent={selectedComponent}
          prop={prop}
          getValue={getValue}
          key={`${selectedComponent}_${prop}`}
          setValue={setValue}
          valOpts={{ min: 0, max: 1, step: 0.01 }}
        ></CustomInput>
      );
    }
    if (prop === "skew") {
      return (
        <CustomInput
          register={register}
          indexInside={indexInside}
          editorProps={editorProps}
          selectedComponent={selectedComponent}
          prop={prop}
          getValue={getValue}
          key={`${selectedComponent}_${prop}`}
          setValue={setValue}
        />
      );
    }
    if (prop === "width") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="string"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }
    if (["top", "right", "left", "bottom"].includes(prop)) {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="string"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }

    if (prop === "height") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="string"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }
    if (prop === "zIndex") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="number"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }

    if (prop === "display") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2} zIndex={3}>
            <InputLeftAddon children={prop} />
            <Select
              placeholder="Select option"
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            >
              {displayOptions.map((o) => (
                <option value={o.value}>{o.label}</option>
              ))}
            </Select>
            {/* <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <CreatableSelect
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      ":hover": { cursor: "pointer" },
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      backgroundColor: "darkgrey",
                      color: "black",
                    }),
                  }}
                  isClearable
                  options={displayOptions}
                  {...register(`${selectedComponent}_${prop}`)}
                  value={value}
                  onChange={onChange} // send value to hook form
                  onBlur={onBlur} // notify when input is touched
                  inputRef={ref} // wire up the input ref
                />
              )}
              name={`${selectedComponent}_${prop}`}
              rules={{ required: true }}
            /> */}
          </InputGroup>
        </Box>
      );
    }
    if (prop === "transform") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="number"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }
    if (["translateX", "translateY", "rotate"].includes(prop)) {
      return (
        <CustomInput
          register={register}
          indexInside={indexInside}
          editorProps={editorProps}
          selectedComponent={selectedComponent}
          prop={prop}
          getValue={getValue}
          key={`${selectedComponent}_${prop}`}
          setValue={setValue}
        />
      );
    }
    if (prop === "alignItems") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2} zIndex={2}>
            <InputLeftAddon children={prop} />
            <Select
              placeholder="Select option"
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            >
              {alignItemsOptions.map((o) => (
                <option value={o.value}>{o.label}</option>
              ))}
            </Select>
            {/* <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <CreatableSelect
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      ":hover": { cursor: "pointer" },
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      backgroundColor: "darkgrey",
                      color: "black",
                    }),
                  }}
                  isClearable
                  options={alignItemsOptions}
                  {...register(`${selectedComponent}_${prop}`)}
                  value={value}
                  onChange={onChange} // send value to hook form
                  onBlur={onBlur} // notify when input is touched
                  inputRef={ref} // wire up the input ref
                />
              )}
              name={`${selectedComponent}_${prop}`}
              rules={{ required: true }}
            /> */}
          </InputGroup>
        </Box>
      );
    }

    if (prop === "justifyContent") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2} zIndex={1}>
            <InputLeftAddon children={prop} />
            <Select
              placeholder="Select option"
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            >
              {justifyContentOptions.map((o) => (
                <option value={o.value}>{o.label}</option>
              ))}
            </Select>
          </InputGroup>
        </Box>
      );
    }

    if (prop === "position") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2} zIndex={1}>
            <InputLeftAddon children={prop} />
            <Select
              placeholder="Select option"
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            >
              {positionOptions.map((o) => (
                <option value={o.value}>{o.label}</option>
              ))}
            </Select>
          </InputGroup>
        </Box>
      );
    }
    if (prop === "from") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="number"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }
    if (prop === "durationInFrames") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="number"
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }
    if (prop === "playbackRate") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="number"
              min={0.0625}
              step={0.0005}
              max={16}
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }
    if (prop === "volume") {
      return (
        <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
          <InputGroup mt={2}>
            <InputLeftAddon children={prop} />
            <Input
              type="number"
              min={0}
              step={0.0005}
              max={1}
              name={`${selectedComponent}_${prop}`}
              {...register(`${selectedComponent}_${prop}`)}
              defaultValue={getValue(selectedComponent, prop, editorProps)}
            />
          </InputGroup>
        </Box>
      );
    }
    return (
      <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
        <label>
          {prop}
          <input
            type={prop}
            name={`${selectedComponent}_${prop}`}
            {...register(`${selectedComponent}_${prop}`)}
            defaultValue={getValue(selectedComponent, prop, editorProps)}
          />
        </label>
      </Box>
    );
  });
  return <>{props}</>;
};

export const _createVideo = async (
  data: any,
  auth: any,
  generateScenes?: Function,
  generateOpen?: Function,
  setShowLoader?: Function
) => {
  try {
    const response = await fetch(`${backendurl}/api/videos`, {
      method: "POST",
      headers: {
        Authorization: `JWT ${auth.token}`,
        "Content-Type": "application/json",
      },
      // credentials: "include",
      body: JSON.stringify(data),
    });

    const result = await response.json();
    if (response.status === 201) {
      console.log("Video created successfully:", result);
      if (generateScenes) {
        handleShowAlert(
          "success",
          "Success",
          "Video created. Creating scenes using ai."
        );
        if (generateOpen) {
          generateOpen();
        }
        generateScenes({ data, video: result });
      } else {
        handleShowAlert(
          "success",
          "Success",
          "Video created successfully.Navigating"
        );
        window.location.href = `/video/${result.doc.id}`;
        if (setShowLoader) {
          setShowLoader(false);
        }
      }
    } else {
      handleShowAlert(
        "error",
        "Error",
        `Error creating video:${result.message}`
      );
      console.error("Error signing up:", result.message);
    }
  } catch (error) {
    if (setShowLoader) {
      setShowLoader(false);
    }
    handleShowAlert("error", "Error", `Network or server error:${error}`);
    console.error("Network or server error:", error);
  }
};
