
  import { useMemo } from "react";
import { AbsoluteFill, Sequence } from "remotion";
import { Audio } from "remotion";
import ResizableVideo from "../../../lib/ResizableVideo";
import { makeTransform, rotate, translate, skew, scale } from "@remotion/animation-utils";
  

const MyComposition: React.FC<any> = (props: any) => {
     const videoProps = useMemo(
          () => (props),
          [props]
        );
const mT_holder1_holder1_transform = makeTransform([rotate(0), translate(0, 0), skew(0), scale(1)]);
const mT_holder2_holder2_transform = makeTransform([rotate(0), translate(0, 0), skew(0), scale(1)]);

  return (
    <AbsoluteFill>
    <Sequence durationInFrames={178} >
<Audio src='https://cdn.aexy.io/templates/ball-off-door/2024-08-19/audio.mp3' playbackRate={1} style={{}} volume={1} className="target" />
</Sequence>
<Sequence durationInFrames={33} >
<ResizableVideo src='https://cdn.aexy.io/templates/ball-off-door/2024-08-19/scene_1.mp4' playbackRate={1} style={{zIndex: 1,opacity: 1,transform: mT_holder1_holder1_transform,height: '100%',width: '100%'}} componentId='holder1' volume={0} className="target" />
</Sequence>
<Sequence durationInFrames={145} from={33}>
<ResizableVideo src='https://cdn.aexy.io/templates/ball-off-door/2024-08-19/scene_2.mp4' playbackRate={1} style={{zIndex: 1,opacity: 1,transform: mT_holder2_holder2_transform,height: '100%',width: '100%'}} componentId='holder2' volume={0} className="target" />
</Sequence>
    </AbsoluteFill>
  );
};

export default MyComposition;
