import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Input,
  ModalFooter,
  Button,
  Select,
  Accordion,
  AccordionPanel,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  Box,
  Textarea,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { createNewVideo } from "../utils/EditorUtil";
import { editorState, setNewVideo } from "../../stores/EditorStore";
import { cloneDeep } from "lodash";
import { useSnapshot } from "valtio";
import { Resolution } from "./DisplaySwicherModal";

function updateScreenSize(width: number, height: number, video: any, setNewVideo: Function) {
  const videoPayload = {
    meta: { global: {} },
    ...cloneDeep(video),
  };
  videoPayload.meta.global.width = width;
  videoPayload.meta.global.height = height;
  setNewVideo(videoPayload);
}

export const NewVideoModal: FC<{
  videoIsOpen: boolean;
  videoOnClose: () => void;
  setCurrentCreating: (importIndex: number) => void;
  creating: number;
  newVideo: any;
  auth: any;
  generateScenes: any;
  generateOpen: any;
}> = ({
  videoIsOpen,
  videoOnClose,
  setCurrentCreating,
  creating,
  newVideo,
  auth,
  generateScenes,
  generateOpen,
}) => {
    // const { resolution, isVertical } = useSnapshot(editorState);

    const [customWidth, setCustomWidth] = useState<string>("");
    const [customHeight, setCustomHeight] = useState<string>("");
    const [isVertical, setIsVertical] = useState<boolean>(
      newVideo?.meta?.global.height > newVideo?.meta?.global.width
    );

    const defaultResolutions: Resolution[] = [
      {
        width: 640,
        height: 360,
        title: "SD",
        description: "360p resolution",
        tags: ["standard", "medium"],
      },
      {
        width: 854,
        height: 480,
        title: "SD",
        description: "480p resolution",
        tags: ["standard", "medium"],
      },
      {
        width: 1280,
        height: 720,
        title: "HD",
        description: "720p resolution",
        tags: ["high", "large"],
      },
      {
        width: 1920,
        height: 1080,
        title: "Full HD",
        description: "1080p resolution",
        tags: ["full", "large"],
      },
      {
        width: 2560,
        height: 1440,
        title: "QHD",
        description: "1440p resolution",
        tags: ["quad", "extra-large"],
      },
      {
        width: 3840,
        height: 2160,
        title: "4K",
        description: "2160p resolution",
        tags: ["ultra", "extra-large"],
      },
    ];

    const handleResolutionChange = (res: Resolution) => {
      // setResolution(res);
      console.log(`Resolution changed to: ${res.width}x${res.height}`);
      if (!isVertical) {
        updateScreenSize(res.width, res.height, newVideo, setNewVideo);
      } else {
        updateScreenSize(res.height, res.width, newVideo, setNewVideo);
      }
    };

    const handleAddResolution = () => {
      const width = parseInt(customWidth, 10);
      const height = parseInt(customHeight, 10);
      if (isVertical) {
        updateScreenSize(width, height, newVideo, setNewVideo);
      } else {
        updateScreenSize(height, width, newVideo, setNewVideo);
      }
    };

    const toggleOrientation = () => {
      // setIsHorizontal(!isHorizontal);
      setIsVertical(!isVertical)
      if (!isVertical) {
        updateScreenSize(newVideo.meta.global.width, newVideo.meta.global.height, newVideo, setNewVideo);
      } else {
        updateScreenSize(newVideo.meta.global.height, newVideo.meta.global.width, newVideo, setNewVideo);
      }
    };

    return (
      <Modal isOpen={videoIsOpen} onClose={videoOnClose} size={"xxl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs
              position="relative"
              variant="unstyled"
              defaultIndex={creating}
              onChange={(index) => setCurrentCreating(index)}
            >
              <TabList>
                <Tab>Using AI</Tab>
                <Tab>From Scratch (No AI)</Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <Input
                    placeholder="Add Video Title"
                    onChange={(event) => {
                      setNewVideo({
                        ...cloneDeep(newVideo),
                        ...{ title: event.target.value },
                      });
                    }}
                    mt={2}
                  />
                  <Textarea
                    placeholder="Add Video Description"
                    onChange={(event) => {
                      setNewVideo({
                        ...cloneDeep(newVideo),
                        ...{ description: event.target.value },
                      });
                    }}
                    height={"300px"}
                    mt={2}
                  />
                  <Textarea
                    placeholder="Add Additional details about the story- like genre, tone etc"
                    onChange={(event) => {
                      setNewVideo({
                        ...cloneDeep(newVideo),
                        ...{ details: event.target.value },
                      });
                    }}
                    mt={2}
                  />
                  <Accordion>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            Advanced Options
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <h2 className="text-xl font-bold mb-4">
                          Current Video Resolution :{" "}
                          {`${newVideo?.meta?.global.height}x${newVideo?.meta?.global.width}`}
                        </h2>
                        <div className="flex flex-wrap gap-4 mb-4">
                          {defaultResolutions.map((res) => {
                            let selected = false;
                            if (isVertical) {
                              selected =
                                newVideo?.meta?.global.width === res.height &&
                                newVideo?.meta?.global.height === res.width;
                            } else {
                              selected =
                                newVideo?.meta?.global.width === res.width &&
                                newVideo?.meta?.global.height === res.height;
                            }
                            return (
                              <div
                                key={`${res.width}x${res.height}`}
                                onClick={() => handleResolutionChange(res)}
                                className={`border rounded-md p-4 cursor-pointer shadow-md ${selected
                                    ? "bg-blue-500 text-white"
                                    : "bg-white text-gray-800 hover:bg-gray-100"
                                  }`}
                              >
                                <h3 className="text-lg font-bold mb-2">{res.title}</h3>
                                <p className="mb-2">{res.description}</p>
                                <p className="mb-2">
                                  {isVertical
                                    ? `${res.width}x${res.height}`
                                    : `${res.height}x${res.width}`}
                                </p>

                                <div className="flex flex-wrap gap-1 text-black">
                                  {res.tags.map((tag, index) => (
                                    <span
                                      key={index}
                                      className="px-2 py-1 bg-gray-200 rounded-md text-xs"
                                    >
                                      {tag}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="mb-4">
                          <input
                            type="number"
                            value={customWidth}
                            onChange={(e) => setCustomWidth(e.target.value)}
                            placeholder="Width"
                            className="px-4 py-2 border rounded-md mr-2"
                          />
                          <input
                            type="number"
                            value={customHeight}
                            onChange={(e) => setCustomHeight(e.target.value)}
                            placeholder="Height"
                            className="px-4 py-2 border rounded-md mr-2"
                          />
                          <button
                            onClick={handleAddResolution}
                            className="px-4 py-2 bg-green-500 text-white rounded-md"
                          >
                            Update
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={toggleOrientation}
                            className="px-4 py-2 bg-purple-500 text-white rounded-md"
                          >
                            Switch to {!isVertical ? "Vertical" : "Horizontal"}{" "}
                          </button>
                        </div>
                        <Select
                          placeholder="Select FPS"
                          onChange={(e) => {
                            const videoPayload = {
                              meta: { global: {} },
                              ...cloneDeep(newVideo),
                            };
                            videoPayload.meta.global.fps = Number(e.target.value);
                            setNewVideo(videoPayload);
                          }}
                          mt={2}
                        >
                          <option value="24">24 FPS</option>
                          <option value="30">30 FPS (Default)</option>
                          <option value="60">60 FPS </option>
                          <option value="12">12 FPS</option>
                        </Select>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
                <TabPanel>
                  <Input
                    placeholder="Add Video Title"
                    onChange={(event) => {
                      setNewVideo({
                        ...cloneDeep(newVideo),
                        ...{ title: event.target.value },
                      });
                    }}
                    mt={2}
                  />
                  <Textarea
                    placeholder="Add Video Description"
                    onChange={(event) => {
                      setNewVideo({
                        ...cloneDeep(newVideo),
                        ...{ description: event.target.value },
                      });
                    }}
                    height={"300px"}
                    mt={2}
                  />
                  <Accordion>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            Advanced Options
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <h2 className="text-xl font-bold mb-4">
                          Current Video Resolution :{" "}
                          {`${newVideo?.meta?.global.height}x${newVideo?.meta?.global.width}`}
                        </h2>
                        <div className="flex flex-wrap gap-4 mb-4">
                          {defaultResolutions.map((res) => {
                            let selected = false;
                            if (isVertical) {
                              selected =
                                newVideo?.meta?.global.width === res.height &&
                                newVideo?.meta?.global.height === res.width;
                            } else {
                              selected =
                                newVideo?.meta?.global.width === res.width &&
                                newVideo?.meta?.global.height === res.height;
                            }
                            return (
                              <div
                                key={`${res.width}x${res.height}`}
                                onClick={() => handleResolutionChange(res)}
                                className={`border rounded-md p-4 cursor-pointer shadow-md ${selected
                                    ? "bg-blue-500 text-white"
                                    : "bg-white text-gray-800 hover:bg-gray-100"
                                  }`}
                              >
                                <h3 className="text-lg font-bold mb-2">{res.title}</h3>
                                <p className="mb-2">{res.description}</p>
                                <p className="mb-2">
                                  {isVertical
                                    ? `${res.width}x${res.height}`
                                    : `${res.height}x${res.width}`}
                                </p>

                                <div className="flex flex-wrap gap-1 text-black">
                                  {res.tags.map((tag, index) => (
                                    <span
                                      key={index}
                                      className="px-2 py-1 bg-gray-200 rounded-md text-xs"
                                    >
                                      {tag}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="mb-4">
                          <input
                            type="number"
                            value={customWidth}
                            onChange={(e) => setCustomWidth(e.target.value)}
                            placeholder="Width"
                            className="px-4 py-2 border rounded-md mr-2"
                          />
                          <input
                            type="number"
                            value={customHeight}
                            onChange={(e) => setCustomHeight(e.target.value)}
                            placeholder="Height"
                            className="px-4 py-2 border rounded-md mr-2"
                          />
                          <button
                            onClick={handleAddResolution}
                            className="px-4 py-2 bg-green-500 text-white rounded-md"
                          >
                            Update
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={toggleOrientation}
                            className="px-4 py-2 bg-purple-500 text-white rounded-md"
                          >
                            Switch to {!isVertical ? "Vertical" : "Horizontal"}{" "}
                          </button>
                        </div>
                        <Select
                          placeholder="Select FPS"
                          onChange={(e) => {
                            const videoPayload = {
                              meta: { global: {} },
                              ...cloneDeep(newVideo),
                            };
                            videoPayload.meta.global.fps = Number(e.target.value);
                            setNewVideo(videoPayload);
                            // setNewVideo({
                            //   ...video,
                            //   ...{
                            //     meta: {
                            //       props: {
                            //         fps: Number(e.target.value),
                            //       },
                            //     },
                            //   },
                            // });
                          }}
                          mt={2}
                        >
                          <option value="24">24 FPS</option>
                          <option value="30">30 FPS (Default)</option>
                          <option value="60">60 FPS </option>
                          <option value="12">12 FPS</option>
                        </Select>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={(e) => {
                createNewVideo(
                  creating,
                  newVideo,
                  auth,
                  videoOnClose,
                  generateScenes,
                  generateOpen
                );
              }}
            >
              Create
            </Button>
            <Button variant="ghost" onClick={videoOnClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };


