import Schemas from "specs";


export function GetModesFromOPId(id: string) {
  if (!id) {
    return [];
  }
  return Object.keys(Schemas[id].modes);
}

export function GetInputsFromOPId(id: string, mode: string) {
  if (!id || !mode) {
    return [];
  }
  const input  = Schemas[id].modes[mode]?.input;
  return Schemas[id].specs.components.schemas[input];
}

export function GetPathsFromOPId(id: string, mode: string) {
  if (!id) {
    return "";
  }
  const { path } = Schemas[id].modes[mode];
  return path;
}
