import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  GridItem,
  Box,
  Grid,
  Icon,
  useDisclosure,
  Flex,
  useToast,
  CircularProgress,
  Button,
} from "@chakra-ui/react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { Player, PlayerRef } from "@remotion/player";
import { MdArrowDropDown } from "react-icons/md";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useForm } from "react-hook-form";
// import { useHookstate } from "@hookstate/core";

import { useParams } from "react-router-dom";

import { wssurl } from "../lib/utils/Misc";
import { useAuth } from "../components/lib/useAuth";
import {
  addScreen,
  createHandleOpen,
  createHandle,
  loadVideo,
  RenderScreens,
  updateProps,
  exportVideo,
  saveGlobals,
  saveProps,
  sendMessage,
} from "../lib/utils/EditorUtil";
import { NewVideoModal } from "../lib/modals/NewVideo";
import { PropertyModal } from "../lib/modals/Properties";
import { ImportModal } from "../lib/modals/Import";
import { VariationModal } from "../lib/modals/Variations";
import { GenerateModal } from "../lib/modals/GenerateMedia";
import { KeyboardModal } from "../lib/modals/Keyboard";
import { Timeline2 } from "../lib/components/Timeline";
import { setGeneration } from "../stores/GenerationStore";
import {
  editorState,
  setCredit,
  setCurrentCreating,
  setCurrentImporting,
  setRuns,
  setShowLoader,
  setVariation,
  setVideo,
  updateAPIs,
  updateRuns,
  updateSubtitles,
  updateTimeline,
} from "../stores/EditorStore";
import { useSnapshot } from "valtio";
import { RunsDialog } from "../lib/modals/Runs";
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { IoImageOutline } from "react-icons/io5";
import { MdOutlineAudiotrack } from "react-icons/md";
import { RxVideo } from "react-icons/rx";
import { BiExport } from "react-icons/bi";
import { useKeyState } from "use-key-state";
import { handleShowAlert } from "./Signup";
import { TfiText } from "react-icons/tfi";
import { FaFontAwesomeAlt, FaUserCircle } from "react-icons/fa";
import { GiRegeneration, GiResize } from "react-icons/gi";
import { SiStorybook } from "react-icons/si";
import { MdSwitchAccessShortcut } from "react-icons/md";
import { DisplaySwicherModal } from "../lib/modals/DisplaySwicherModal";
import AssetLibrary from "../lib/modals/ReplacAssetLibrary";
import { useWebSocket } from "../lib/components/WebSocketContext";

export interface renderPropsInput {
  SelectedComponent: string;
}

const Editor: React.FC = () => {
  const playerRef = useRef<PlayerRef>(null);
  const [Composition, setComposition] = useState<any>();
  const { send, addEventListener, removeEventListener } = useWebSocket();

  const {
    editorProps,
    variation,
    runInstance,
    apis,
    video,
    newVideo,
    screens,
    selectedScreen,
    selectedComponent,
    selectedComponentName,
    creating,
    showLoader,
    durationInFrames,
    credits
  } = useSnapshot(editorState);

  const navigate = useNavigate();
  const {
    isOpen: importIsOpen,
    onOpen: importOpen,
    onClose: importonClose,
  } = useDisclosure();
  // this is for
  const {
    isOpen: genIsOpen,
    onOpen: genOpen,
    onClose: genOnClose,
  } = useDisclosure();
  // this is for generate story-
  const {
    isOpen: generateStoryIsOpen,
    onOpen: generateStoryOpen,
    onClose: generateStoryOnClose,
  } = useDisclosure();

  const {
    isOpen: propIsOpen,
    onOpen: propOpen,
    onClose: proponClose,
  } = useDisclosure();
  const {
    isOpen: videoIsOpen,
    onOpen: videoOpen,
    onClose: videoOnClose,
  } = useDisclosure();

  const {
    isOpen: variationIsOpen,
    onOpen: variationOpen,
    onClose: variationOnClose,
  } = useDisclosure();

  const {
    isOpen: runIsOpen,
    onOpen: runOpen,
    onClose: runOnClose,
  } = useDisclosure();

  const {
    isOpen: shortcutIsOpen,
    onOpen: shortcutOpen,
    onClose: shortcutOnClose,
  } = useDisclosure();

  const {
    isOpen: displaySwitchIsOpen,
    onOpen: displaySwitchOpen,
    onClose: displaySwitchOnClose,
  } = useDisclosure();

  const shortcuts = useKeyState({
    newVideoCut: "ctrl+shift+n",
    addImageCut: "ctrl+shift+i",
    addAudioCut: "ctrl+shift+a",
    addVideoCut: "ctrl+shift+v",
    genImageCut: "ctrl+shift+g",
    genAudioCut: "ctrl+shift+m",
    genVideoCut: "ctrl+shift+j",
    addTextCut: "ctrl+shift+t",
    exportCut: "ctrl+shift+e",
    storyboardCut: "ctrl+shift+s",
    viewGenerationCut: "ctrl+shift+x",
  });

  let { videoId = "" } = useParams();
  const methods = useForm();
  const [auth, error, loading] = useAuth();
  const toast = useToast();
  const [assetIsOpen, setAssetIsOpen] = useState(false);
  const [assetImporting, setAssetImporting] = useState("Image");
  const openAsset = (type: string) => { setAssetIsOpen(true); setAssetImporting(type); };
  const assetOnClose = () => setAssetIsOpen(false);
  let stoastId: any = null;

  // const handleReconnection = () => {
  //   if (reconnecting || retryCount >= maxRetries) return;

  //   reconnecting = true;

  //   if (!etoastId) {
  //     etoastId = toast({
  //       title: `Reconnecting to server`,
  //       position: "top-right",
  //       variant: "top-accent",
  //       isClosable: true,
  //       onCloseComplete: () => {
  //         etoastId = null;
  //       }, // Reset the toastId when toast is closed
  //     });
  //   }

  //   setRetryCount(retryCount + 1);

  //   setTimeout(() => {
  //     reconnecting = false;
  //     connectWebSocket();
  //   }, retryInterval);
  // };
  // Listen for messages from the WebSocket server
  const handleMessage = async (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status || message.status === "error") {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "success":
        console.log("Updated video:", message.video);
        break;
      case "credits":
        const { balance, validUpto, plan } = message.data;
        setCredit(balance, validUpto, plan);
        break;
      case "error":
        console.log("Error video:", message.message, event);
        break;
      case "subtitles":
        console.log("Error video:", message.data.meta.result.chunks, event);
        if (message.data?.meta?.result?.chunks) {
          updateSubtitles(message.data.meta.result.chunks);
        }
        break;
      // case "models":
      //   console.log(" Models:", message.models, event);
      //   setModels(message.models);
      //   break;

      case "video":
        if (message.new) {
          return navigate("/video/" + message.video.id);
        }
        if (message.refresh) {
          return window.location.reload();
        }
        setVideo(message.video, videoId, setComposition);
        break;
      case "sceneFull":
        navigate(`/storyboard/${message.experimentObj._id}`);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "variations":
        setVariation(message.data);
        variationOpen();
        getRun(message.data);
        break;
      case "getRun":
        const { run, apis } = message.data;
        setRuns(run, apis);
        break;
      case "runs":
      case "myruns":
        setShowLoader(false);
        updateRuns(message.runs);
        // setApis(apis);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "gens":
        setShowLoader(false);
        updateAPIs(message.gens);
        // setApis(apis);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "getAPIsForRun":
        updateAPIs(message.apis);
        // setApis(apis);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      default:
        break;
    }
  };

  const connectWebSocket = () => {
    addEventListener("open", handleOpen);
    addEventListener("message", handleMessage);
    addEventListener("error", handleError);
    addEventListener("close", () => {
      console.log("WebSocket closed");
      handleError("WebSocket closed");
    });    
    if (!video || video.id !== videoId) {
      loadVideo(videoId, send);
    }
  };

  const handleOpen = () => {
    if (!stoastId) {
      stoastId = toast({
        title: `Connected`,
        position: "top-right",
        variant: "solid",
        isClosable: true,
        onCloseComplete: () => {
          stoastId = null;
        },
      });
    }
    loadVideo(videoId, send);
    send({ action: "credits" });
  }
  const handleError = (error: any) => {
    console.log("WebSocket error: ", error);
  };

  useEffect(() => {
    document.title = "Crackviral | Edit Video";    
    connectWebSocket();
    setShowLoader(false);
    return () => {
      removeEventListener(
        "open",
        handleOpen
      );
      removeEventListener("message", handleMessage);
    };
  }, []);

  const updateCompo = async () => {
    if (!selectedScreen || !selectedComponent) {
      return;
    }

    const componentModule = await import(
      `../components/${videoId}/${selectedScreen}/Composition`
    );
    setComposition(() => componentModule.default);
  };

  const saveVideoUpdates = (updatedProps: any) =>
    saveProps(videoId || "noid", send, updatedProps, selectedScreen);

  useEffect(() => {
    updateCompo().then(() => {
      updateTimeline();
    });
  }, [selectedScreen, videoId]);

  useEffect(() => {
    if (shortcuts.addVideoCut.down) {
      setCurrentCreating(0);
      videoOpen();
      // createHandle(0, setCurrentCreating, videoOpen);
    }
    if (shortcuts.addImageCut.down) {
      setCurrentImporting(0);
      importOpen();
    }
    if (shortcuts.addAudioCut.down) {
      setCurrentImporting(1);
      importOpen();
    }
    if (shortcuts.addVideoCut.down) {
      setCurrentImporting(2);
      importOpen();
    }
    if (shortcuts.addTextCut.down) {
      setCurrentImporting(3);
      importOpen();
    }
    if (shortcuts.genAudioCut.down) {
      setGeneration(11);
      genOpen();
    }
    if (shortcuts.genImageCut.down) {
      setGeneration(10);
      genOpen();
    }
    if (shortcuts.genVideoCut.down) {
      setGeneration(12);
      genOpen();
    }
    if (shortcuts.exportCut.down) {
      exportVideo(send, videoId);
    }
    if (shortcuts.storyboardCut.down) {
      if (video.exp) {
        navigate(`/storyboard/${video.exp}`);
      } else {
        handleShowAlert(
          "Success",
          "No storyboard connected. ",
          "Need to use to create AI story mode for storyboard."
        );
      }
    }
    if (shortcuts.viewGenerationCut.down) {
      runOpen();
    }
  }, [shortcuts]);
  if (!videoId) {
    return <div>No Video Id passed</div>;
  }

  if (!auth || loading || error) {
    return <div>Not logged in redirecting</div>;
  }

  function generateScenes(story: any) {
    const payload = {
      action: "storyToScenes",
      data: story,
    };
    send(payload);
  }

  function getRun(data: any) {
    const payload = {
      action: "getRun",
      data,
    };
    send(payload);
  }

  function sendData(action: string, data: any, id?: string) {
    const payloadData: any = {
      action,
    };
    if (data) {
      payloadData.data = data;
    }
    if (id) {
      payloadData.id = id;
    }
    send(payloadData);
  }

  function replaceComponent(data: any) {
    const payload = {
      action: "replaceComponent",
      data,
    };
    send(payload);
  }

  function saveGenPipeline(data: any) {
    const payload = {
      action: "saveGenPipeline",
      data,
    };
    send(payload);
  }

  function getRunsForVideo(data: any) {
    const payload = {
      action: "getRunsForVideo",
      data,
    };
    send(payload);
  }

  const updateComponent = async (screenName?: string) => {
    const componentUpdatedName = screenName || selectedScreen;

    const componentModule = await import(
      `../components/${videoId}/${screens[componentUpdatedName].name}/Composition`
    );

    setComposition(() => componentModule.default);
  };

  const updateScreenSize = (height: number, width: number) => {
    const videoRef = cloneDeep(video);

    videoRef.meta.global.height = height;
    videoRef.meta.global.width = width;
    setVideo(videoRef, videoId, setComposition);
    saveGlobals(videoId, send, videoRef.meta.global);
  };

  const MenuGridItem = () => {
    return (
      <GridItem bg="gray.600" area={"header"} pt={2}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box p="1">
            <Menu>
              <MenuButton
                px={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="0"
                marginRight={"1"}
                color={"whiteAlpha.900"}
              >
                File
                <Icon as={MdArrowDropDown} w={6} h={6} color="white" />
              </MenuButton>
              <MenuList mt={1}>
                <MenuItem
                  mb={2}
                  onClick={() => navigate("/video/create")}
                  icon={<AddIcon />}
                >
                  New Video
                </MenuItem>
                <MenuItem
                  mb={2}
                  onClick={() => displaySwitchOpen()}
                  icon={<GiResize />}
                >
                  Change Video Resolution
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  icon={<ArrowBackIcon />}
                >
                  Return to Dashboard
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                px={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="0"
                marginRight={"1"}
                color={"whiteAlpha.900"}
              >
                Add
                <Icon as={MdArrowDropDown} w={6} h={6} color="white" />
              </MenuButton>
              <MenuList mt={1}>
                <MenuItem
                  onClick={() => {
                    setCurrentImporting(0);
                    importOpen();
                  }}
                  icon={<IoImageOutline />}
                >
                  Image
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCurrentImporting(1);
                    importOpen();
                  }}
                  icon={<MdOutlineAudiotrack />}
                >
                  Audio
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCurrentImporting(2);
                    importOpen();
                  }}
                  icon={<RxVideo />}
                >
                  Video
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCurrentImporting(3);
                    importOpen();
                  }}
                  icon={<TfiText />}
                >
                  Text
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                px={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="0"
                marginRight={"1"}
                color={"whiteAlpha.900"}
              >
                Generate
                <Icon as={MdArrowDropDown} w={6} h={6} color="white" />
              </MenuButton>
              <MenuList mt={1}>
                <MenuItem
                  onClick={() => {
                    toast({
                      title: `Coming Soon`,
                      position: "top-right",
                      variant: "solid",
                      isClosable: true,
                    });
                    // createHandleGenerate(
                    //   13,
                    //   setCurrentImporting,
                    //   importOpen,
                    //   setCurrentGenerating,
                    //   genOpen
                    // )
                  }}
                  icon={<AddIcon />}
                >
                  Avatar
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setGeneration(10);
                    genOpen();
                    //   createHandleGenerate(
                    //   10,
                    //   setCurrentImporting,
                    //   importOpen,
                    //   setCurrentGenerating,
                    //   genOpen
                    // )
                  }}
                  icon={<IoImageOutline />}
                >
                  Image
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setGeneration(11);
                    genOpen();
                  }}
                  icon={<MdOutlineAudiotrack />}
                >
                  Audio
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setGeneration(12);
                    genOpen();
                  }}
                  icon={<RxVideo />}
                >
                  Video
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton
                px={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="0"
                marginRight={"1"}
                color={"whiteAlpha.900"}
              >
                Export
                <Icon as={MdArrowDropDown} w={6} h={6} color="white" />
              </MenuButton>
              <MenuList mt={1}>
                <MenuItem
                  // onClick={()=> toast({
                  //   title: `Temporarily disabled. Fix incoming soon.`,
                  //   position: "top-right",
                  //   isClosable: true,
                  // })}
                  onClick={() => exportVideo(send, videoId)}
                  icon={<BiExport />}
                >
                  Export
                </MenuItem>
              </MenuList>
            </Menu>

            <Menu>
              <MenuButton
                px={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="0"
                marginRight={"1"}
                color={"whiteAlpha.900"}
              >
                View
                <Icon as={MdArrowDropDown} w={6} h={6} color="white" />
              </MenuButton>
              <MenuList mt={1}>
                <MenuItem
                  onClick={() => {
                    getRunsForVideo({ id: videoId });
                    runOpen();
                  }}
                  icon={<GiRegeneration />}
                >
                  Generations
                </MenuItem>
                {video?.exp && (
                  <MenuItem
                    onClick={() => navigate(`/storyboard/${video.exp}`)}
                    icon={<SiStorybook />}
                  >
                    Storyboard
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => shortcutOpen()}
                  icon={<MdSwitchAccessShortcut />}
                >
                  Shortcuts
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
          <Box p="1">
            {credits.balance >= 50 && <Button colorScheme="green" onClick={() => navigate("/profile")} size="sm">Balance: {credits.balance}</Button>}
            {credits.balance < 50 && <Button colorScheme="red" onClick={() => navigate("/profile")} size="sm">Balance: {credits.balance}</Button>}
            {credits.plan == "free" && <Button colorScheme="red" onClick={() => navigate("/payments")} ml="4" size="sm"> Upgrade Now</Button>}
            <Menu>
              <MenuButton
                px={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="0"
                color={"whiteAlpha.900"}
              >
                <Icon as={FaUserCircle} w={6} h={6} color="white" />
              </MenuButton>
              <MenuList mt={1}>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/user/ledger');
                  }}
                >
                  Credit History
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    navigate('logout');
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </GridItem>
    );
  };
  const handleTimelineChange = (id: string, keyframes: any[]) => {
    // setData(e);
    const propPayload: any = {};
    // e.map((val: { id: any; actions: any }) => {
    //   const { id, actions } = val;
    const msToSeconds = (val: number) => (val ? val / 1000 : 0);
    propPayload[`${id}_from`] = msToSeconds(keyframes[0].val);
    propPayload[`${id}_durationInFrames`] =
      msToSeconds(keyframes[1].val) - msToSeconds(keyframes[0].val);
    // });
    updateProps(propPayload, videoId, send);
    return true;
  };

  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <Grid display={{
          base: "grid",
          md: "none",
        }}> <Button
          onClick={() => {
            navigate("/dashboard");
          }}
        ><ArrowBackIcon />
            Return to Dashboard
          </Button>Sorry- the editor isn't optimised for mobile yet.Please switch to a desktop or a tablet. </Grid>
        <Grid
          templateAreas={`"header header header"
                  "nav main main"
                  "footer footer footer"`}
          gridTemplateRows={"50px 1fr 200px"}
          gridTemplateColumns={"200px 1fr 200px"}
          h="200px"
          gap="0"
          color="blackAlpha.700"
          fontWeight="bold"
          display={{
            base: "none",
            md: "grid",
          }}
        >
          <MenuGridItem />
          <GridItem h="100%" bg="gray.600" area={"nav"}>
            <div>
              <RenderScreens
                updateComponent={updateComponent}
                send={send}
              />
            </div>
          </GridItem>
          <GridItem area={"main"}>
            <div className="preview">
              {Composition && (
                <Suspense fallback={<div>Loading...</div>}>
                  <Player
                    ref={playerRef}
                    inputProps={editorProps}
                    compositionWidth={video?.meta?.global?.width || 1280}
                    compositionHeight={video?.meta?.global?.height || 720}
                    durationInFrames={durationInFrames}
                    fps={video?.meta?.global?.fps || 30}
                    component={Composition}
                    alwaysShowControls={true}
                    controls={true}
                    // style={{
                    //   width: 1280,
                    //   height: 720,
                    // }}
                    clickToPlay={false}
                  // style={{ background: "white" }}
                  />
                </Suspense>
              )}
            </div>
          </GridItem>
          <GridItem h="100%" bg={"black"} area={"footer"}>
            <Timeline2
              handleTimelineChange={handleTimelineChange}
              videoId={videoId}
              propOpen={propOpen}
              playerRef={playerRef}
              openAsset={openAsset}
            />
          </GridItem>
        </Grid>
        <>
          <ImportModal
            importIsOpen={importIsOpen}
            importonClose={importonClose}
            send={send}
            videoId={videoId}
          />
          <GenerateModal
            generateIsOpen={genIsOpen}
            generateOnClose={genOnClose}
            sendData={sendData}
            runOpen={runOpen}
          />
          {/* isOpen: shortcutIsOpen,
    onOpen: shortcutOpen,
    onClose: , */}
          <KeyboardModal
            shortcutIsOpen={shortcutIsOpen}
            shortcutOnClose={shortcutOnClose}
          />

          <DisplaySwicherModal
            shortcutIsOpen={displaySwitchIsOpen}
            shortcutOnClose={displaySwitchOnClose}
            updateScreenSize={updateScreenSize}
            video={video}
          />
          <RunsDialog
            runIsOpen={runIsOpen}
            runOnClose={runOnClose}
            videoId={videoId}
            sendData={sendData}
          />
          <NewVideoModal
            generateScenes={generateScenes}
            videoIsOpen={videoIsOpen}
            videoOnClose={videoOnClose}
            setCurrentCreating={setCurrentCreating}
            creating={creating}
            newVideo={newVideo}
            auth={auth}
            generateOpen={generateStoryOpen}
          />
          <PropertyModal
            propIsOpen={propIsOpen}
            proponClose={proponClose}
            methods={methods}
            videoId={videoId}
            sendData={sendData}
            saveVideoUpdates={saveVideoUpdates}
            openAsset={openAsset}
          />
          <VariationModal
            propIsOpen={variationIsOpen}
            proponClose={variationOnClose}
            variation={variation}
            selectedScreen={selectedScreen}
            selectedComponent={selectedComponent}
            videoId={videoId}
            selectedComponentName={selectedComponentName}
            runInstance={runInstance}
            syncRun={getRun}
            apis={apis}
            replaceComponent={replaceComponent}
          />

          <AssetLibrary isOpen={assetIsOpen} onClose={assetOnClose} assetImporting={assetImporting} />
          {showLoader && (
            <CircularProgress
              isIndeterminate
              color="green.300"
              size={"xl"}
              style={{ left: "30%", position: "absolute", top: "30%" }}
            />
          )}
        </>
      </div>
    </ChakraProvider>
  );
};

export default Editor;
