import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Heading,
  Text,
  VStack,
  HStack,
  Progress,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import DashboardWrapper from '../../lib/components/Wrapper';
import { SendRequest } from '../../utils/main';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

interface OnboardingFormData {
  user: {
    name: string;
    industry: string;
    purpose: string;
    social: string;
    found: string;
  };
  organization: {
    name: string;
    website: string;
  };
  team: {
    name: string;
    description: string;
  };
}

const OnboardingForm: React.FC = () => {
  const [step, setStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<OnboardingFormData>(() => {
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    const initVal = {
      user: {
        name: '',
        industry: '',
        purpose: '',
        social: '',
        found: '',
      },
      organization: {
        name: '',
        website: '',
      },
      team: {
        name: '',
        description: '',
      },
    }
    if (auth.user && auth.user.name) {
      initVal.user.name =  auth.user.name;
    }
    return initVal;
  });

  const toast = useToast();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    document.title = "Crackviral | Onboarding";
  }, []);

  const updateFormData = (section: 'user' | 'organization' | 'team', field: string, value: string) => {
    setFormData(prevData => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleNext = () => {
    if (step === 0 ) {
      if (!formData.user.name) {
        toast({
          title: "Name is required",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (!formData.user.industry) {
        toast({
          title: "Industry is required",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (!formData.user.purpose) {
        toast({
          title: "Purpose is required. We use it so that we can make this tool more useful for you.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (!formData.user.found) {
        toast({
          title: "Please fill how you found us. It is super useful to know this.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      
    }
    if (step === 1 && !formData.organization.name) {
      toast({
        title: "Organization name is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setStep(prevStep => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep(prevStep => prevStep - 1);
  };

  const onSubmit = async () => {
    if (!formData.team.name) {
      toast({
        title: "Team name is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await SendRequest(
        `/api/v2/onboarding/new`,
        formData,
        "POST"
      );
      if (response.data) {
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        auth.user = response.data.user;
        localStorage.setItem("auth", JSON.stringify(auth));
        toast({
          title: "Onboarding complete",
          description: "Your profile, organization, and team have been created.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/dashboard");
      } else {
        toast({
          title: "Onboarding Error",
          description: `Error saving onboarding details: ${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Onboarding Error",
        description: `Network or server error: ${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardWrapper>
      <Box maxW="lg" mx="auto" mt="10">
        
        <Progress value={(step + 1) * 33.33} mb="6" />
        <VStack spacing={4} as="form" onSubmit={handleSubmit(onSubmit)}>
          {step === 0 && (
            <>
              <Heading as='h3' size='lg' mb="6">
                Welcome to Crackviral Onboarding
              </Heading>
              <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  {...register("name", { required: "Name is required" })}
                  value={formData.user.name}
                  onChange={(e) => updateFormData('user', 'name', e.target.value)}
                />
                {errors.name && <Text color="red.500">{errors.name.message  as string}</Text>}
              </FormControl>
              <FormControl id="industry" isRequired>
                <FormLabel>Industry</FormLabel>
                <Select
                  {...register("industry", { required: "Industry is required" })}
                  value={formData.user.industry}
                  onChange={(e) => updateFormData('user', 'industry', e.target.value)}
                >
                  <option value="">Choose your intended target industry</option>
                  <option value="film_tv">Film and Television</option>
                  <option value="advertising_marketing">Advertising and Marketing</option>
                  <option value="corporate">Corporate (Internal Communications)</option>
                  <option value="education">Education</option>
                  <option value="news_journalism">News and Journalism</option>
                  <option value="event_management">Event Management</option>
                  <option value="real_estate">Real Estate</option>
                  <option value="healthcare">Healthcare</option>
                  <option value="government">Government</option>
                  <option value="non_profit">Non-Profit</option>
                  <option value="fashion">Fashion</option>
                  <option value="music">Music Industry</option>
                  <option value="sports">Sports</option>
                  <option value="content_creation">Independent Content Creation</option>
                  <option value="gaming_streaming">Gaming and Streaming</option>
                  <option value="travel_tourism">Travel and Tourism</option>
                  <option value="social_media">Social Media Influencers</option>
                  <option value="other">Other</option>
                </Select>
                {errors.industry && <Text color="red.500">{errors.industry.message  as string}</Text>}
              </FormControl>
              <FormControl id="purpose" isRequired>
                <FormLabel>Purpose of Using This Tool</FormLabel>
                <Textarea
                  {...register("purpose", { required: "Purpose is required" })}
                  value={formData.user.purpose}
                  onChange={(e) => updateFormData('user', 'purpose', e.target.value)}
                  placeholder="Describe your purpose"
                />
                {errors.purpose && <Text color="red.500">{errors.purpose.message  as string}</Text>}
              </FormControl>
              <FormControl id="social" isRequired>
                <FormLabel>Social Media Links</FormLabel>
                <Textarea
                  {...register("social", { required: "Social Links are required" })}
                  value={formData.user.social}
                  onChange={(e) => updateFormData('user', 'social', e.target.value)}
                  placeholder="Ex: twitter/instagram link"
                />
                {errors.social && <Text color="red.500">{errors.social.message  as string}</Text>}
              </FormControl>
              <FormControl id="found" isRequired>
                <FormLabel>How did you find our platform?</FormLabel>
                <Select
                  {...register("found", { required: "This field is required" })}
                  value={formData.user.found}
                  onChange={(e) => updateFormData('user', 'found', e.target.value)}
                >
                  <option value="">Choose one</option>
                  <option value="search_engine">Search Engine</option>
                  <option value="social_media">Social Media</option>
                  <option value="friend">Friend / Word of Mouth</option>
                  <option value="advertisement">Advertisement</option>
                  <option value="staff">You met someone from Crackviral</option>
                  <option value="other">Other</option>
                </Select>
                {errors.found && <Text color="red.500">{errors.found.message  as string}</Text>}
              </FormControl>
            </>
          )}
          {step === 1 && (
            <>
              <Heading as='h3' size='lg' mb="6">
                Let's create your organization
              </Heading>
              <FormControl id="orgName" isRequired>
                <FormLabel>Organization Name</FormLabel>
                <Input
                  value={formData.organization.name}
                  onChange={(e) => updateFormData('organization', 'name', e.target.value)}
                />
              </FormControl>
              <FormControl id="website">
                <FormLabel>Website</FormLabel>
                <Input
                  type="url"
                  value={formData.organization.website}
                  onChange={(e) => updateFormData('organization', 'website', e.target.value)}
                />
              </FormControl>
            </>
          )}
          {step === 2 && (
            <>
              <Heading as='h3' size='lg' mb="6">
                Let's create your team
              </Heading>
              <FormControl id="teamName" isRequired>
                <FormLabel>Team Name</FormLabel>
                <Input
                  value={formData.team.name}
                  onChange={(e) => updateFormData('team', 'name', e.target.value)}
                />
              </FormControl>
              <FormControl id="teamDescription">
                <FormLabel>Team Description</FormLabel>
                <Input
                  value={formData.team.description}
                  onChange={(e) => updateFormData('team', 'description', e.target.value)}
                />
                <Text as='i' fontSize='xs'>Briefly describe the team's purpose and responsibilities.</Text>
              </FormControl>
            </>
          )}

          <HStack spacing={4} alignSelf="flex-start">
            {step > 0 && (
              <Button onClick={handlePrevious}>
                Previous
              </Button>
            )}
            {step < 2 ? (
              <Button colorScheme="blue" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <Button 
                colorScheme="green" 
                type="submit"
                isDisabled={isSubmitting}
                leftIcon={isSubmitting ? <Spinner size="sm" /> : undefined}
              >
                {isSubmitting ? 'Submitting...' : 'Complete Onboarding'}
              </Button>
            )}
          </HStack>
        </VStack>
      </Box>
    </DashboardWrapper>
  );
};

export default OnboardingForm;