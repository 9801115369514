import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Type, Image, Layers, Music, Film, Settings, SettingsIcon, Replace, ReplaceIcon } from 'lucide-react';
import CaptionSettingsModal from '../modules/editor/CaptionSettingsModal';
import Timeline, { TimelineItem, Track } from '../modules/editor/Timeline';
import { Player, PlayerRef } from '@remotion/player';
import { useSnapshot } from 'valtio';
import { useWebSocket } from '../lib/components/WebSocketContext';
import { editorState, setCredit, setCurrentCreating, setCurrentImporting, setRuns, setShowLoader, setTimelineRows, setVariation, setVideo, updateAPIs, updateRuns, updateSubtitles, updateTimeline } from '../stores/EditorStore';
import { useDisclosure, useToast, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeyState } from 'use-key-state';
import { useAuth } from '../components/lib/useAuth';
import { loadVideo, saveProps, exportVideo, RenderScreens, saveGlobals, addScreen } from '../lib/utils/EditorUtil';
import { setGeneration } from '../stores/GenerationStore';
import { handleShowAlert } from './Signup';
import { DisplaySwicherModal } from '../lib/modals/DisplaySwicherModal';
import { GenerateModal } from '../lib/modals/GenerateMedia';
import { ImportModal } from '../lib/modals/Import';
import { KeyboardModal } from '../lib/modals/Keyboard';
import { PropertyModal } from '../lib/modals/Properties';
import { RunsDialog } from '../lib/modals/Runs';
import Menu from '../modules/editor/Menu/Main';
import { cloneDeep } from "lodash";
import AssetLibrary from "../lib/modals/ReplacAssetLibrary";

const VideoEditorUI = () => {
  const [selectedTool, setSelectedTool] = useState("");

  const [duration, setDuration] = useState(60);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);



  const playerRef = useRef<PlayerRef | null>(null);
  const [Composition, setComposition] = useState<any>();
  const { send, addEventListener, removeEventListener } = useWebSocket();

  const {
    editorProps,
    video,
    screens,
    selectedScreen,
    selectedComponent,
    durationInFrames,
    timelineRows,
    tracks,
    showLoader,
    assetSelected
  } = useSnapshot(editorState);

  const [isTimelineExpanded, setIsTimelineExpanded] = useState(false);

  useEffect(() => {
    if (timelineRows && timelineRows.length > 0) {
      setIsTimelineExpanded(true);
    }
  }, [timelineRows])


  const handleFrameChange = useCallback(() => {
    const player = playerRef.current;
    if (player) {
      const currentFrame = player.getCurrentFrame();
      const newTime = currentFrame / (video?.meta?.global?.fps || 30);
      setCurrentTime(newTime);
    }
  }, [video?.meta?.global?.fps]);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      player.addEventListener('timeupdate', handleFrameChange);
    }

    return () => {
      if (player) {
        player.removeEventListener('timeupdate', handleFrameChange);
      }
    };
  }, [Composition]);

  useEffect(() => {
    if (playerRef.current) {
      const frameToSeek = Math.round(currentTime * (video?.meta?.global?.fps || 30));
      playerRef.current.seekTo(frameToSeek);
    }
  }, [currentTime, video?.meta?.global?.fps]);

  const tools = [
    { icon: Layers, name: 'Scenes' },
    { icon: Type, name: 'Text' },
    { icon: Image, name: 'Image' },
    { icon: Music, name: 'Audio' },
    { icon: Film, name: 'Video' },
    { icon: SettingsIcon, name: 'Settings' }
  ];

  const navigate = useNavigate();
  const {
    isOpen: importIsOpen,
    onOpen: importOpen,
    onClose: importonClose,
  } = useDisclosure();
  // this is for
  const {
    isOpen: genIsOpen,
    onOpen: genOpen,
    onClose: genOnClose,
  } = useDisclosure();


  const {
    isOpen: propIsOpen,
    onOpen: propOpen,
    onClose: proponClose,
  } = useDisclosure();
  const {
    isOpen: videoIsOpen,
    onOpen: videoOpen,
    onClose: videoOnClose,
  } = useDisclosure();

  const {
    isOpen: variationIsOpen,
    onOpen: variationOpen,
    onClose: variationOnClose,
  } = useDisclosure();

  const {
    isOpen: runIsOpen,
    onOpen: runOpen,
    onClose: runOnClose,
  } = useDisclosure();

  const {
    isOpen: shortcutIsOpen,
    onOpen: shortcutOpen,
    onClose: shortcutOnClose,
  } = useDisclosure();

  const {
    isOpen: displaySwitchIsOpen,
    onOpen: displaySwitchOpen,
    onClose: displaySwitchOnClose,
  } = useDisclosure();

  const shortcuts = useKeyState({
    newVideoCut: "ctrl+shift+n",
    addImageCut: "ctrl+shift+i",
    addAudioCut: "ctrl+shift+a",
    addVideoCut: "ctrl+shift+v",
    genImageCut: "ctrl+shift+g",
    genAudioCut: "ctrl+shift+m",
    genVideoCut: "ctrl+shift+j",
    addTextCut: "ctrl+shift+t",
    exportCut: "ctrl+shift+e",
    storyboardCut: "ctrl+shift+s",
    viewGenerationCut: "ctrl+shift+x",
  });

  let { videoId = "" } = useParams();
  const methods = useForm();
  const [auth, error, loading] = useAuth();
  const toast = useToast();
  const [assetIsOpen, setAssetIsOpen] = useState(false);
  // const [assetImporting, setAssetImporting] = useState("Image");
  const openAsset = () => { setAssetIsOpen(true); };
  const assetOnClose = () => setAssetIsOpen(false);

  let stoastId: any = null;

  const handleMessage = async (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status || message.status === "error") {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "success":
        console.log("Updated video:", message.video);
        break;
      case "credits":
        const { balance, validUpto, plan } = message.data;
        setCredit(balance, validUpto, plan);
        break;
      case "error":
        console.log("Error video:", message.message, event);
        break;
      case "subtitles":
        console.log("Error video:", message.data.meta.result.chunks, event);
        if (message.data?.meta?.result?.chunks) {
          updateSubtitles(message.data.meta.result.chunks);
        }
        break;
      // case "models":
      //   console.log(" Models:", message.models, event);
      //   setModels(message.models);
      //   break;

      case "video":
        if (message.new) {
          return navigate("/video/" + message.video.id);
        }
        if (message.refresh) {
          setShowLoader(true);
          updateCompo().then(() => setShowLoader(false)).catch((e) => {
            console.log(e);

            console.log("updating");

          });
          break;
          // return window.location.reload();
        }
        setVideo(message.video, videoId, setComposition);
        break;
      case "sceneFull":
        navigate(`/storyboard/${message.experimentObj._id}`);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "variations":
        setVariation(message.data);
        variationOpen();
        sendData("getRun", message.data);
        break;
      case "getRun":
        const { run, apis } = message.data;
        setRuns(run, apis);
        break;
      case "runs":
      case "myruns":
        setShowLoader(false);
        updateRuns(message.runs);
        // setApis(apis);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "gens":
        setShowLoader(false);
        updateAPIs(message.gens);
        // setApis(apis);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "getAPIsForRun":
        updateAPIs(message.apis);
        // setApis(apis);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      default:
        break;
    }
  };

  const connectWebSocket = () => {
    addEventListener("open", handleOpen);
    addEventListener("message", handleMessage);
    addEventListener("error", handleError);
    addEventListener("close", () => {
      console.log("WebSocket closed");
      handleError("WebSocket closed");
    });
    if (!video || video.id !== videoId) {
      loadVideo(videoId, send);
    }
  };

  const handleOpen = () => {
    if (!stoastId) {
      stoastId = toast({
        title: `Connected`,
        position: "top-right",
        variant: "solid",
        isClosable: true,
        onCloseComplete: () => {
          stoastId = null;
        },
      });
    }
    loadVideo(videoId, send);
    send({ action: "credits" });
  }
  const handleError = (error: any) => {
    console.log("WebSocket error: ", error);
  };

  useEffect(() => {
    document.title = "Crackviral | Edit Video";
    connectWebSocket();
    setShowLoader(false);
    return () => {
      removeEventListener(
        "open",
        handleOpen
      );
      removeEventListener("message", handleMessage);
    };
  }, []);

  const updateCompo = async () => {
    if (!selectedScreen || !selectedComponent) {
      return;
    }
    const componentModule = await import(
      /* webpackMode: "eager" */
      `../components/${videoId}/${(video._v || 0)}/${selectedScreen}/Composition.tsx`
    );

    setComposition(() => componentModule.default);
  };

  const saveVideoUpdates = (updatedProps: any) =>
    saveProps(videoId || "noid", send, updatedProps, selectedScreen);

  useEffect(() => {
    updateCompo().then(() => {
      updateTimeline();
    });
  }, [selectedScreen, videoId]);

  useEffect(() => {
    if (shortcuts.addVideoCut.down) {
      setCurrentCreating(0);
      videoOpen();
      // createHandle(0, setCurrentCreating, videoOpen);
    }
    if (shortcuts.addImageCut.down) {
      setCurrentImporting(0);
      importOpen();
    }
    if (shortcuts.addAudioCut.down) {
      setCurrentImporting(1);
      importOpen();
    }
    if (shortcuts.addVideoCut.down) {
      setCurrentImporting(2);
      importOpen();
    }
    if (shortcuts.addTextCut.down) {
      setCurrentImporting(3);
      importOpen();
    }
    if (shortcuts.genAudioCut.down) {
      setGeneration(11);
      genOpen();
    }
    if (shortcuts.genImageCut.down) {
      setGeneration(10);
      genOpen();
    }
    if (shortcuts.genVideoCut.down) {
      setGeneration(12);
      genOpen();
    }
    if (shortcuts.exportCut.down) {
      exportVideo(send, videoId);
    }
    if (shortcuts.storyboardCut.down) {
      if (video.exp) {
        navigate(`/storyboard/${video.exp}`);
      } else {
        handleShowAlert(
          "Success",
          "No storyboard connected. ",
          "Need to use to create AI story mode for storyboard."
        );
      }
    }
    if (shortcuts.viewGenerationCut.down) {
      runOpen();
    }
  }, [shortcuts]);

  function sendData(action: string, data: any, id?: string) {
    const payloadData: any = {
      action,
    };
    if (data) {
      payloadData.data = data;
    }
    if (id) {
      payloadData.id = id;
    }
    send(payloadData);
  }

  const onMoveItemEnd = (id: string, start: number, oldItem: TimelineItem) => {

    // setData(e);
    const propPayload: any = {};
    // const msToSeconds = (val: number) => (val ? val / 1000 : 0);
    propPayload[`${id}_from`] = start;
    propPayload[`${id}_track`] = tracks[oldItem.track].name;
    propPayload[`${id}_durationInFrames`] =
      start + oldItem.duration;
    // });
    const payload = {
      action: "updateProps",
      video,
      props: propPayload,
    };

    send(payload);
  };


  const handleItemMove = (id: string, start: number, track: number, oldItem: TimelineItem) => {

    let trackNumber = track;
    if (!tracks[track]) {
      trackNumber = oldItem.track;
    }
    const updatedTimeline = cloneDeep(timelineRows).map((item: any) =>
      item.id === id ? { ...item, start, track: trackNumber } : item
    )
    setTimelineRows(updatedTimeline);


  };

  const handleItemResize = (id: string, duration: number, start: number) => {

    const updatedTimeline = cloneDeep(timelineRows).map((item: any) =>
      item.id === id ? { ...item, duration, start } : item
    )

    setTimelineRows(updatedTimeline);
  };

  const onResizeEnd = (id: string, item: TimelineItem) => {
    const propPayload: any = {};
    propPayload[`${id}_from`] = item.start;
    propPayload[`${id}_durationInFrames`] = item.start + item.duration;
    const payload = {
      action: "updateProps",
      video,
      props: propPayload,
    };

    send(payload);
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    // Here you would typically start or pause your video playback
    // For this example, we'll just toggle the state
  };

  const handleSeek = (time: number) => {
    setCurrentTime(time);
  };

  const handleToggleTimelineExpand = (isExpanded: boolean) => {
    setIsTimelineExpanded(isExpanded);
  };
  const updateComponent = async (screenName?: string) => {
    const componentUpdatedName = screenName || selectedScreen;

    const componentModule = await import(

      `../components/${videoId}/${(video._v || 0)}/${screens[componentUpdatedName].name}/Composition`
    );
    console.log({ componentModule });

    setComposition(() => componentModule.default);
  };

  const updateScreenSize = (height: number, width: number) => {
    const videoRef: any = cloneDeep(video);

    videoRef.meta.global.height = height;
    videoRef.meta.global.width = width;
    setVideo(videoRef, videoId, setComposition);
    saveGlobals(videoId, send, videoRef.meta.global);
  };


  if (!videoId) {
    return <div>No Video Id passed</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-900 text-white">
      {/* Top Bar */}
      <div className="flex justify-between items-center p-4 border-b border-gray-700">
        <div className="flex "><img src="/logo.svg" style={{ filter: "invert(1)" }} />
          <Text
            fontSize="2xl"
            ml="2"
            mt="2"
            color="brand.500"
            _dark={{
              color: "white",
            }}
            fontWeight="semibold"
          >
            CrackViral Studio Beta
          </Text>
        </div>
        <div className="flex space-x-4">
          <button className="px-4 py-2 rounded bg-gray-700 hover:bg-gray-600" onClick={() => navigate(`/bulk/${videoId}`)}>Bulk Edit</button>
          <button className="px-4 py-2 rounded bg-gray-700 hover:bg-gray-600" onClick={() => displaySwitchOpen()}>Resize</button>
          <button className="px-4 py-2 rounded bg-gray-700 hover:bg-gray-600" onClick={() => exportVideo(send, videoId)}>Export</button>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex flex-1 overflow-hidden">
        {/* Narrow Sidebar */}
        <div className="w-16 bg-gray-800 flex flex-col items-center py-4">
          {tools.map((tool) => (
            <button
              key={tool.name}
              className={`p-2 mb-4 rounded-full hover:bg-gray-700 ${selectedTool === tool.name ? 'bg-gray-700' : ''}`}
              onClick={() => setSelectedTool(selectedTool === tool.name ? "" : tool.name)}
            >
              <tool.icon size={24} />
            </button>
          ))}
        </div>

        {/* Wider Menu (conditionally rendered) */}

        <Menu selectedTool={selectedTool} updateComponent={updateComponent} sendData={sendData} setSelectedTool={setSelectedTool} importOpen={importOpen} genOpen={genOpen} />

        {/* Main Editing Area */}
        <div className="flex-1 p-4 flex flex-col items-center justify-center relative">
          <div className="w-full bg-transparent rounded flex justify-center items-center">
            {Composition && (
              <Suspense fallback={<div>Loading...</div>}>
                <Player
                  ref={playerRef}
                  inputProps={editorProps}
                  compositionWidth={video?.meta?.global?.width || 1280}
                  compositionHeight={video?.meta?.global?.height || 720}
                  durationInFrames={durationInFrames || 1}
                  fps={video?.meta?.global?.fps || 30}
                  component={Composition}
                  alwaysShowControls={true}
                  controls={true}
                  style={{
                    width: ((video?.meta?.global?.width || 1280)),
                    height: ((video?.meta?.global?.height || 720)),

                  }}
                  clickToPlay={false}
                // style={{ background: "white" }}
                />
              </Suspense>
            )}
          </div>
          <p className="text-white mt-4 mr-28 pb-2">Clicking 'Render' will provide accurate lip-sync and higher clarity.</p>
        </div>
      </div>
      <div className="absolute top-20 right-4 flex flex-col items-center space-y-2">
        {selectedComponent && (
          <button
            className="p-2 bg-gray-800 hover:bg-gray-700 text-white rounded-full flex items-center space-x-1 transition-colors duration-200"
            onClick={() => propOpen()}
          >

            <span className="text-sm">Settings</span>
            <Settings size={25} />
          </button>
        )}
        {selectedComponent && ["Img", "Video"].includes(assetSelected) && (
          <button
            className="p-2 bg-gray-800 hover:bg-gray-700 text-white rounded-full flex items-center space-x-1 transition-colors duration-200"
            onClick={() => openAsset()}
          >
            <span className="text-sm">Replace </span>
            <ReplaceIcon size={25} />
          </button>
        )}
      </div>

      <CaptionSettingsModal />

      {/* Timeline */}
      <div className={`${isTimelineExpanded ? 'h-40' : 'h-10'} bg-gray-800 border-t border-gray-700`}>
        {/* <div className="flex items-center mb-2">
          <Play size={20} className="mr-2" />
          <span>00:00:00 | 00:00:00</span>
        </div>
        <div className="w-full h-2 bg-gray-700 rounded">
          <div className="w-1/3 h-full bg-blue-500 rounded"></div>
        </div> */}
        <div className={`${isTimelineExpanded ? 'h-30' : 'h-10'} transition-all duration-300 ease-in-out`}>
          <Timeline
            items={timelineRows}
            tracks={tracks}
            isPlaying={isPlaying}
            isExpanded={isTimelineExpanded}
            currentTime={currentTime}
            duration={duration}
            onItemMove={handleItemMove}
            onItemResize={handleItemResize}
            onPlayPause={handlePlayPause}
            onSeek={handleSeek}
            onToggleExpand={handleToggleTimelineExpand}
            onMoveItemEnd={onMoveItemEnd}
            onResizeEnd={onResizeEnd}
          />
        </div>
      </div>
      <ImportModal
        importIsOpen={importIsOpen}
        importonClose={importonClose}
        send={send}
        videoId={videoId}
      />
      <GenerateModal
        generateIsOpen={genIsOpen}
        generateOnClose={genOnClose}
        sendData={sendData}
        runOpen={runOpen}
      />
      <KeyboardModal
        shortcutIsOpen={shortcutIsOpen}
        shortcutOnClose={shortcutOnClose}
      />

      <DisplaySwicherModal
        shortcutIsOpen={displaySwitchIsOpen}
        shortcutOnClose={displaySwitchOnClose}
        updateScreenSize={updateScreenSize}
        video={video}
      />
      <RunsDialog
        runIsOpen={runIsOpen}
        runOnClose={runOnClose}
        videoId={videoId}
        sendData={sendData}
      />
      <PropertyModal
        propIsOpen={propIsOpen}
        proponClose={proponClose}
        methods={methods}
        videoId={videoId}
        sendData={sendData}
        saveVideoUpdates={saveVideoUpdates}
        openAsset={openAsset}
      />
      <AssetLibrary isOpen={assetIsOpen} onClose={assetOnClose} assetImporting={assetSelected} />
      {showLoader && <div className='flex space-x-2 justify-center items-center bg-white h-screen dark:invert'>
        <span className='sr-only'>Loading...</span>
        <div className='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
        <div className='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
        <div className='h-8 w-8 bg-black rounded-full animate-bounce'></div>
      </div>}
    </div>
  );
};

export default VideoEditorUI;


