import { proxy } from "valtio";
import { GetInputsFromOPId, GetModesFromOPId } from "../apis/sd/video";
import { getDiagFromExp, getPromptFromExp } from "../lib/modals/StoryForm";

import { uniq } from "lodash";

type Dialogue = {
  speaker: string;
  line: string;
};

type Scene = {
  scene_number: number;
  scene_description: string;
  visual_details: string;
  dialogues: Dialogue[];
  audio_details: string;
};

const initState: {
  showLoader: boolean;
  apis: any;
  scenes: any;
  activeIndexes: string;
  runs: any;
  modes: string[];
  APISelected: string[];
  sceneSelected: string[];
  sceneSettings: string[];
  searchQuery: string;
  modelEditing: string;
  mode: string;
  exp: any;
  properties: any;
  formData: any;
  editingScene: number;
} = {
  showLoader: false,
  activeIndexes: "-1, -1, -1",
  runs: [],
  apis: [],
  modes: [],
  scenes: [],
  APISelected: [],
  sceneSelected: [],
  sceneSettings: [],
  searchQuery: "",
  modelEditing: "",
  mode: "",
  exp: {},
  properties: {},
  formData: {},
  editingScene: 0,
};

export const storyState = proxy(initState);

export const setExp = (exp: any, scenes: any) => {
  storyState.exp = exp;
  storyState.scenes = scenes;
};

export const updateRuns = (runs: any, apis: any) => {
  console.log({ runs, apis });

  storyState.runs = runs;
  storyState.apis = apis;
};

export const setEditingScene = (editingScene: number) => {
  console.log("setting scene");

  storyState.editingScene = editingScene;
};

export const handleSceneEdit = (value: string, field: keyof Scene) => {
  storyState.scenes = storyState.scenes.map((scene: Scene, i: number) => {
    if (i === storyState.editingScene) {
      return { ...scene, [field]: value };
    }
    return scene;
  });
};

// Function to add a scene below the current editing scene
export const addScene = (
  newScene: Scene,
  indexRef: number,
  saveExpScenes: Function
) => {
  const index = indexRef + 1;
  storyState.scenes = [
    ...storyState.scenes.slice(0, index),
    newScene,
    ...storyState.scenes.slice(index),
  ];
  saveExpScenes(storyState.scenes);
};

// Function to delete a scene
export const deleteScene = (index: number, saveExpScenes: Function) => {
  storyState.scenes = storyState.scenes.filter(
    (_: any, i: number) => {
      console.log({i, index});
      
      return i !== index;
    }
  );
  console.log({screens: storyState.scenes});
  
  saveExpScenes(storyState.scenes);
};

// Update dialogues
export const handleDialogueChange = (
  index: number,
  field: keyof Dialogue,
  value: string
) => {
  storyState.scenes = storyState.scenes.map((scene: Scene, i: number) => {
    if (i === storyState.editingScene) {
      const newDialogues = scene.dialogues.map((dialogue, i) => {
        if (i === index) {
          return { ...dialogue, [field]: value };
        }
        return dialogue;
      });
      return { ...scene, dialogues: newDialogues };
    }
    return scene;
  });
};

// Add a new dialogue
export const addDialogue = () => {
  storyState.scenes = storyState.scenes.map((scene: Scene, i: number) => {
    if (i === storyState.editingScene) {
      scene.dialogues = scene.dialogues || [];
      // Assuming dialogues is always an initialized array
      return {
        ...scene,
        dialogues: [...scene.dialogues, { speaker: "", line: "" }],
      };
    }
    return scene;
  });
};

// Delete a dialogue
export const deleteDialogue = (index: number) => {
  storyState.scenes = storyState.scenes.map((scene: Scene, i: number) => {
    if (i === storyState.editingScene) {
      const newDialogues = scene.dialogues.filter((_, idx) => idx !== index);
      return { ...scene, dialogues: newDialogues };
    }
    return scene;
  });
};

export const setScenes = (scenes: any) => {
  storyState.scenes = scenes;
};

export const setActiveIndex = (activeIndexes: any) => {
  storyState.activeIndexes = activeIndexes;
};

export const setModelEditing = (modelEditing: string) => {
  storyState.modelEditing = modelEditing;
  const modes = GetModesFromOPId(modelEditing);
  storyState.modes = modes;
  storyState.mode = modes[0];
  const propsEntries = Object.entries(
    GetInputsFromOPId(modelEditing, storyState.mode)?.properties || {}
  );
  storyState.properties = propsEntries;
  let initValues: any = {};

  propsEntries &&
    propsEntries.map(([key, value]: any, index: number) => {
      initValues[key] = value.default;
      const modelIndex = storyState.activeIndexes.split(",")[2];
      if (key === "prompt" && storyState.activeIndexes && modelIndex === "0") {
        initValues["prompt"] = getPromptFromExp(
          storyState.exp,
          storyState.activeIndexes
        );
      }
      if (key === "text" && storyState.activeIndexes && modelIndex === "1") {
        initValues["text"] = getDiagFromExp(
          storyState.exp,
          storyState.activeIndexes
        );
      }
      // if (!initValues[key] && value.examples) {
      //   initValues[key] = value.examples[0];
      // }
      if (!initValues[key] && value.default) {
        initValues[key] = value.default;
      }
      if (!initValues[key] && value.v) {
        initValues[key] = value.v;
      }
      if (!initValues[key]) {
        console.log({ v: initValues[key], key, value }, "not set");
      }
    });
  storyState.formData = initValues;
};

export const setSearchQuery = (searchQuery: string) => {
  storyState.searchQuery = searchQuery;
};

export const setSceneSelected = (sceneSelected: any) => {
  storyState.sceneSelected = sceneSelected;
};

export const setAPISelected = (APISelected: any) => {
  storyState.APISelected = uniq(APISelected);
};

export const setSceneSettings = (sceneSettings: any) => {
  storyState.sceneSettings = sceneSettings;
};

export const setMode = (mode: string) => {
  storyState.mode = mode;
  const propsEntries = Object.entries(
    GetInputsFromOPId(storyState.modelEditing, storyState.mode)?.properties ||
      {}
  );
  storyState.properties = propsEntries;
  let initValues: any = {};

  propsEntries &&
    propsEntries.map(([key, value]: any, index: number) => {
      initValues[key] = value.default;
      const modelIndex = storyState.activeIndexes.split(",")[2];
      if (key === "prompt" && storyState.activeIndexes && modelIndex === "0") {
        initValues["prompt"] = getPromptFromExp(
          storyState.exp,
          storyState.activeIndexes
        );
      }
      if (key === "text" && storyState.activeIndexes && modelIndex === "1") {
        initValues["text"] = getDiagFromExp(
          storyState.exp,
          storyState.activeIndexes
        );
      }
      // if (!initValues[key] && value.examples) {
      //   initValues[key] = value.examples[0];
      // }
      if (!initValues[key] && value.default) {
        initValues[key] = value.default;
      }
      if (!initValues[key] && value.v) {
        initValues[key] = value.v;
      }
      if (!initValues[key]) {
        console.log({ v: initValues[key], key, value }, "not set");
      }
    });
  storyState.formData = initValues;
};

export const setFormData = (formData: any) => {
  storyState.formData = formData;
};

export const setShowLoader = (state: boolean) => {
  storyState.showLoader = state;
};