import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { backendurl } from "../lib/utils/Misc";
import { handleShowAlert } from "./Signup";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

function hash(str: string) {
  const utf8 = new TextEncoder().encode(str);
  return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
}

export default function ForgotPassword() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data: any) => {
    try {
      const response = await fetch(`${backendurl}/api/users/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.status === 201 || response.status === 200) {
        handleShowAlert("success", "Success", "Check your email for reset password link.");
        // navigate("/dashboard");
      } else {
        handleShowAlert(
          "error",
          "Error",
          `Error Resetting password:${result.message}`
        );
        console.error("Error logggin in:", result.message);
      }
    } catch (error) {
      handleShowAlert("error", "Error", `Network or server error:${error}`);
      console.error("Network or server error:", error);
    }
  };
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className="relative py-16 overflow-hidden h-screen">
        <img
          className="absolute top-0 left-0 w-full h-screen object-cover"
          src="homepage/bromo-assets/sign-in/background.png"
          alt=""
        />
        <div className="relative container mx-auto px-4">
          <div className="max-w-2xl px-4 mx-auto">
            <div className="relative py-12 px-10 text-center rounded-3xl overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full filter backdrop-blur backdrop-filter bg-black bg-opacity-70" />
              <div className="relative">
                <h2 className="font-heading text-4xl font-medium text-white tracking-tight mb-6">
                  Reset your password
                </h2>
                <div className="max-w-md mx-auto">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {errors.email && <span>Email is required</span>}
                    <div className="text-left">
                      <span className="block mb-2 text-sm font-medium text-white leading-snug">
                        Email
                      </span>
                      <input
                        className="block w-full py-2 px-3 h-11 mb-6 bg-gray-800 border border-gray-700 rounded-lg text-sm placeholder-gray-500 text-white border-none outline-none"
                        type="email"
                        placeholder="user@bromo.io"
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                      <div>
                        <button
                          className="flex w-full h-14 py-2 px-4 mb-5 items-center justify-center text-base font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full"
                          type="submit"
                        >
                          <span className="mr-2">Reset Password</span>
                          <svg
                            width={25}
                            height={24}
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.4199 6.62C18.3185 6.37565 18.1243 6.18147 17.8799 6.08C17.7597 6.02876 17.6306 6.00158 17.4999 6H7.49994C7.23472 6 6.98037 6.10536 6.79283 6.29289C6.6053 6.48043 6.49994 6.73478 6.49994 7C6.49994 7.26522 6.6053 7.51957 6.79283 7.70711C6.98037 7.89464 7.23472 8 7.49994 8H15.0899L6.78994 16.29C6.69621 16.383 6.62182 16.4936 6.57105 16.6154C6.52028 16.7373 6.49414 16.868 6.49414 17C6.49414 17.132 6.52028 17.2627 6.57105 17.3846C6.62182 17.5064 6.69621 17.617 6.78994 17.71C6.8829 17.8037 6.9935 17.8781 7.11536 17.9289C7.23722 17.9797 7.36793 18.0058 7.49994 18.0058C7.63195 18.0058 7.76266 17.9797 7.88452 17.9289C8.00638 17.8781 8.11698 17.8037 8.20994 17.71L16.4999 9.41V17C16.4999 17.2652 16.6053 17.5196 16.7928 17.7071C16.9804 17.8946 17.2347 18 17.4999 18C17.7652 18 18.0195 17.8946 18.207 17.7071C18.3946 17.5196 18.4999 17.2652 18.4999 17V7C18.4984 6.86932 18.4712 6.74022 18.4199 6.62Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                        <p className="text-sm font-medium text-white">
                          <span>Don’t have an account?</span>
                          <a
                            className="inline-block text-sky-900 hover:text-sky-600"
                            href="/signup"
                          >
                            Sign up
                          </a>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
