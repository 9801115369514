import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { backendurl } from "../lib/utils/Misc";
import { handleShowAlert } from "./Signup";

const meta = {
    title: "",
    meta: [],
    link: [],
    style: [],
    script: [],
};


export default function ResetPassword() {
    let { search } = useLocation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = async (data: any) => {
        if (data.password !== data.repeatpassword) {
            return handleShowAlert("error", "Error", `Passwords need to match`);
        }
        const query = new URLSearchParams(search);
        const token = query.get('token');
        if (!token) {
            return handleShowAlert("error", "Error", `Reset Password link is invalid. Kindly contact support in case you are stuck here.`);
        }
        data.token = token;
        try {
            const response = await fetch(`${backendurl}/api/users/reset-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (response.status === 201 || response.status === 200) {
                handleShowAlert("success", "Success", "Check your email for reset password link.");
                // navigate("/dashboard");
            } else {
                handleShowAlert(
                    "error",
                    "Error",
                    `Error Resetting password:${result.message}`
                );
                console.error("Error logggin in:", result.message);
            }
        } catch (error) {
            handleShowAlert("error", "Error", `Network or server error:${error}`);
            console.error("Network or server error:", error);
        }
    };
    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet {...meta}></Helmet>
            </HelmetProvider>
            <section className="relative py-16 overflow-hidden h-screen">
                <img
                    className="absolute top-0 left-0 w-full h-screen object-cover"
                    src="homepage/bromo-assets/sign-in/background.png"
                    alt=""
                />
                <div className="relative container mx-auto px-4">
                    <div className="max-w-2xl px-4 mx-auto">
                        <div className="relative py-12 px-10 text-center rounded-3xl overflow-hidden">
                            <div className="absolute top-0 left-0 w-full h-full filter backdrop-blur backdrop-filter bg-black bg-opacity-70" />
                            <div className="relative">
                                <h2 className="font-heading text-4xl font-medium text-white tracking-tight mb-6">
                                    Reset your password
                                </h2>
                                <div className="max-w-md mx-auto">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {errors.password && <span>This field is required</span>}
                                        <span className="block mb-2 text-sm font-medium text-white leading-snug">
                                            Password
                                        </span>
                                        <div className="flex py-2 px-3 h-11 mb-5 bg-gray-800 border border-gray-700 rounded-lg">
                                            <input
                                                className="w-full bg-transparent text-sm placeholder-gray-500 text-white border-none outline-none"
                                                type="password"
                                                {...register("password", {
                                                    required: "Password is required",
                                                })}
                                                placeholder="Enter your password"
                                            />
                                            <button className="text-gray-600 hover:text-gray-200">
                                                <svg
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M21.9199 11.6C19.8999 6.91 16.0999 4 11.9999 4C7.89994 4 4.09994 6.91 2.07994 11.6C2.02488 11.7262 1.99646 11.8623 1.99646 12C1.99646 12.1377 2.02488 12.2738 2.07994 12.4C4.09994 17.09 7.89994 20 11.9999 20C16.0999 20 19.8999 17.09 21.9199 12.4C21.975 12.2738 22.0034 12.1377 22.0034 12C22.0034 11.8623 21.975 11.7262 21.9199 11.6ZM11.9999 18C8.81994 18 5.82994 15.71 4.09994 12C5.82994 8.29 8.81994 6 11.9999 6C15.1799 6 18.1699 8.29 19.8999 12C18.1699 15.71 15.1799 18 11.9999 18ZM11.9999 8C11.2088 8 10.4355 8.2346 9.77766 8.67412C9.11987 9.11365 8.60718 9.73836 8.30443 10.4693C8.00168 11.2002 7.92246 12.0044 8.0768 12.7804C8.23114 13.5563 8.61211 14.269 9.17152 14.8284C9.73093 15.3878 10.4437 15.7688 11.2196 15.9231C11.9955 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8863 14.8801 15.3258 14.2223C15.7653 13.5645 15.9999 12.7911 15.9999 12C15.9999 10.9391 15.5785 9.92172 14.8284 9.17157C14.0782 8.42143 13.0608 8 11.9999 8ZM11.9999 14C11.6044 14 11.2177 13.8827 10.8888 13.6629C10.5599 13.4432 10.3036 13.1308 10.1522 12.7654C10.0008 12.3999 9.9612 11.9978 10.0384 11.6098C10.1155 11.2219 10.306 10.8655 10.5857 10.5858C10.8654 10.3061 11.2218 10.1156 11.6098 10.0384C11.9977 9.96126 12.3999 10.0009 12.7653 10.1522C13.1308 10.3036 13.4431 10.56 13.6629 10.8889C13.8826 11.2178 13.9999 11.6044 13.9999 12C13.9999 12.5304 13.7892 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 11.9999 14Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <span className="block mb-2 text-sm font-medium text-white leading-snug">
                                            Repeat password
                                        </span>
                                        {errors.repeatpassword && (
                                            <span>This field is required</span>
                                        )}

                                        <div className="flex py-2 px-3 h-11 mb-5 bg-gray-800 border border-gray-700 rounded-lg">
                                            <input
                                                className="w-full bg-transparent text-sm placeholder-gray-500 text-white border-none outline-none"
                                                type="password"
                                                {...register("repeatpassword", {
                                                    required: "Repeat is required",
                                                })}
                                                placeholder="Repeat password"
                                            />
                                            <button className="text-gray-600 hover:text-gray-200">
                                                <svg
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M21.9199 11.6C19.8999 6.91 16.0999 4 11.9999 4C7.89994 4 4.09994 6.91 2.07994 11.6C2.02488 11.7262 1.99646 11.8623 1.99646 12C1.99646 12.1377 2.02488 12.2738 2.07994 12.4C4.09994 17.09 7.89994 20 11.9999 20C16.0999 20 19.8999 17.09 21.9199 12.4C21.975 12.2738 22.0034 12.1377 22.0034 12C22.0034 11.8623 21.975 11.7262 21.9199 11.6ZM11.9999 18C8.81994 18 5.82994 15.71 4.09994 12C5.82994 8.29 8.81994 6 11.9999 6C15.1799 6 18.1699 8.29 19.8999 12C18.1699 15.71 15.1799 18 11.9999 18ZM11.9999 8C11.2088 8 10.4355 8.2346 9.77766 8.67412C9.11987 9.11365 8.60718 9.73836 8.30443 10.4693C8.00168 11.2002 7.92246 12.0044 8.0768 12.7804C8.23114 13.5563 8.61211 14.269 9.17152 14.8284C9.73093 15.3878 10.4437 15.7688 11.2196 15.9231C11.9955 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8863 14.8801 15.3258 14.2223C15.7653 13.5645 15.9999 12.7911 15.9999 12C15.9999 10.9391 15.5785 9.92172 14.8284 9.17157C14.0782 8.42143 13.0608 8 11.9999 8ZM11.9999 14C11.6044 14 11.2177 13.8827 10.8888 13.6629C10.5599 13.4432 10.3036 13.1308 10.1522 12.7654C10.0008 12.3999 9.9612 11.9978 10.0384 11.6098C10.1155 11.2219 10.306 10.8655 10.5857 10.5858C10.8654 10.3061 11.2218 10.1156 11.6098 10.0384C11.9977 9.96126 12.3999 10.0009 12.7653 10.1522C13.1308 10.3036 13.4431 10.56 13.6629 10.8889C13.8826 11.2178 13.9999 11.6044 13.9999 12C13.9999 12.5304 13.7892 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 11.9999 14Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
