export interface Options {
  readonly value: string;
  readonly label: string;
}
export const backendurl = "https://api.crackviral.com";

// export const backendurl = "http://localhost:4001";
// export const wssurl = "ws://localhost:4001";
export const wssurl = "wss://api.crackviral.com";

export const alignItemsOptions: Options[] = [
  { value: "flex-start", label: "Flex Start" },
  { value: "flex-end", label: "Flex End" },
  { value: "center", label: "Center" },
  { value: "baseline", label: "Baseline" },
  { value: "stretch", label: "Stretch" },
];

export const justifyContentOptions: Options[] = [
  { value: "normal", label: "Normal" },
  { value: "flex-start", label: "Flex Start" },
  { value: "flex-end", label: "Flex End" },
  { value: "center", label: "Center" },
  { value: "space-between", label: "Space Between" },
  { value: "space-around", label: "Space Around" },
  { value: "space-evenly", label: "Space Evenly" },
  { value: "stretch", label: "Stretch" },
];

export const displayOptions: Options[] = [
  { value: "block", label: "Block" },
  { value: "inline", label: "Inline" },
  { value: "inline-block", label: "Inline Block" },
  { value: "flex", label: "Flex" },
  { value: "grid", label: "Grid" },
  { value: "table", label: "Table" },
];

export const positionOptions: Options[] = [
  { value: "static", label: "Static" },
  { value: "relative", label: "Relative" },
  { value: "absolute", label: "Absolute" },
  { value: "fixed", label: "Fixed" },
  { value: "sticky", label: "Sticky" },
];

export function getSubString(str: string, limit: number): React.ReactNode {
  if (!str) {
    return "";
  }
  if (str.length < limit) return str;
  return `${str.substring(0, limit)}...`;
}