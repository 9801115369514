import React, { useState, useRef, useEffect, useMemo } from 'react';
import { ZoomIn, ZoomOut, ChevronUp, ChevronDown } from 'lucide-react';
import { setAssetSelected, setSelectedComponent } from '../../stores/EditorStore';
import { useParams } from 'react-router-dom';

export interface TimelineItem {
  id: string;
  type: 'video' | 'audio' | 'caption' | 'effect';
  start: number;
  duration: number;
  track: number;
  content: string;
}

export interface Track {
  id: number;
  name: string;
}


export interface ResizeOpt {
  id: string;
  direction: string;
}

interface ResizeState {
  item: TimelineItem;
  direction: 'left' | 'right';
  startPosition: number;
}

interface TimelineProps {
  items: TimelineItem[];
  tracks: Track[];
  duration: number;
  onItemMove: (id: string, start: number, track: number, oldItem: TimelineItem) => void;
  onItemResize: (id: string, duration: number, newStart: number) => void;
  onPlayPause: () => void;
  isPlaying: boolean;
  isExpanded: boolean;
  currentTime: number;
  onSeek: (time: number) => void;
  onToggleExpand: (isExpanded: boolean) => void;
  onMoveItemEnd: (id: string, start: number, item: TimelineItem) => void;
  onResizeEnd: (id: string, item: TimelineItem) => void;
}

const Timeline: React.FC<TimelineProps> = ({
  items,
  tracks,
  duration,
  onItemMove,
  onItemResize,
  onPlayPause,
  isPlaying,
  currentTime,
  onSeek,
  isExpanded,
  onToggleExpand,
  onMoveItemEnd,
  onResizeEnd,
}) => {
  const [zoom, setZoom] = useState(1);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const timelineRef = useRef<HTMLDivElement>(null);
  const labelsRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const resizeStateRef = useRef<ResizeState | null>(null);
  const dragStateRef = useRef<{ id: string; startX: number; startY: number } | null>(null);
  const lastMoveRef = useRef<{ id: string; start: number; track: number } | null>(null);
  const lastResizeRef = useRef<{ id: string; duration: number; start: number } | null>(null);


  let { videoId = "" } = useParams();
  const [hoverTime, setHoverTime] = useState<number | null>(null);
  const [isHoveringTimeline, setIsHoveringTimeline] = useState(false);

  const trackHeight = 40;
  const scaleHeight = 25;
  const labelWidth = 100;


  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Dynamic tick interval calculation based on zoom level
  const { majorTickInterval, minorTicksPerMajor } = useMemo(() => {
    if (zoom <= 0.5) return { majorTickInterval: 10, minorTicksPerMajor: 5 };
    if (zoom <= 1) return { majorTickInterval: 5, minorTicksPerMajor: 2 };
    if (zoom <= 2) return { majorTickInterval: 3, minorTicksPerMajor: 1 };
    if (zoom <= 4) return { majorTickInterval: 2, minorTicksPerMajor: 1 };
    if (zoom <= 8) return { majorTickInterval: 1, minorTicksPerMajor: 0.5 };
    return { majorTickInterval: 0.5, minorTicksPerMajor: 5 };
  }, [zoom]);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth - labelWidth);
      }
    };

    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  const pixelsPerSecond = useMemo(() => Math.max(1, (containerWidth / duration) * zoom), [containerWidth, duration, zoom]);
  const contentWidth = useMemo(() => {
    const maxEndTime = Math.max(
      duration,
      ...items.map(item => item.start + item.duration)
    );
    return Math.max(containerWidth, maxEndTime * pixelsPerSecond);
  }, [items, duration, containerWidth, pixelsPerSecond]);

  // const handleZoomIn = () => setZoom(prev => Math.min(10, prev * 1.2));
  // const handleZoomOut = () => setZoom(prev => Math.max(0.1, prev / 1.2));

  // const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
  //   const target = e.target as HTMLDivElement;
  //   setScrollLeft(target.scrollLeft);
  // };

  const handleTimelineMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const mouseX = e.clientX - rect.left + scrollLeft;
      const hoverTimeValue = mouseX / pixelsPerSecond;
      setHoverTime(hoverTimeValue);
    }
  };

  const handleTimelineMouseEnter = () => {
    setIsHoveringTimeline(true);
  };

  const handleTimelineMouseLeave = () => {
    setIsHoveringTimeline(false);
    setHoverTime(null);
  };

  const handleScaleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const clickX = e.clientX - rect.left + scrollLeft;
      const clickTime = clickX / pixelsPerSecond;
      onSeek(clickTime);
    }
  };

  const RenderTimelineScale = () => {
    const visibleDuration = contentWidth / pixelsPerSecond;
    const majorTicks = Math.ceil(visibleDuration / majorTickInterval);

    return (
      <div
        className="relative"
        style={{ height: `${scaleHeight}px`, width: `${contentWidth}px` }}
        onClick={handleScaleClick}
      >
        {Array.from({ length: majorTicks }, (_, i) => {
          const tickTime = i * majorTickInterval;
          const tickX = tickTime * pixelsPerSecond;

          return (
            <React.Fragment key={i}>
              {/* Major tick */}
              <div
                className="absolute top-0 h-full bg-white"
                style={{
                  left: `${tickX}px`,
                  width: '1px',
                  height: "20px"
                }}
              />
              <div
                className="absolute text-xs text-white-400"
                style={{
                  left: `${tickX + 4}px`,
                  top: '4px'
                }}
              >
                {formatTime(tickTime)}
              </div>

              {/* Minor ticks */}
              {Array.from({ length: minorTicksPerMajor - 1 }, (_, j) => {
                const minorTickTime = tickTime + ((j + 1) * majorTickInterval / minorTicksPerMajor);
                const minorTickX = minorTickTime * pixelsPerSecond;
                return (
                  <div
                    key={`minor-${i}-${j}`}
                    className="absolute top-0 h-full bg-white"
                    style={{
                      left: `${minorTickX}px`,
                      width: '1px',
                      height: "10px"
                    }}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
              {/* Hover time indicator */}
              {isHoveringTimeline && hoverTime !== null && (
          <div
            className="absolute top-0 bg-blue-500 text-white text-xs px-1 py-0.5 rounded"
            style={{
              left: `${hoverTime * pixelsPerSecond}px`,
              transform: 'translateX(-50%)',
              pointerEvents: 'none'
            }}
          >
            {formatTime(hoverTime)}
          </div>
        )}
      </div>
    );
  };


  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth - labelWidth);
      }
    };

    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);


  const handleZoomIn = () => setZoom(prev => Math.min(10, prev * 1.2));
  const handleZoomOut = () => setZoom(prev => Math.max(0.1, prev / 1.2));

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setScrollLeft(target.scrollLeft);
    setScrollTop(target.scrollTop);
    if (labelsRef.current) {
      labelsRef.current.scrollTop = target.scrollTop;
    }
  };


  const handleMouseMove = (e: MouseEvent) => {
    if (resizeStateRef.current) {
      const { item, direction, startPosition } = resizeStateRef.current;
      const deltaX = (e.clientX - startPosition) / pixelsPerSecond;

      let newStart = item.start;
      let newDuration = item.duration;

      if (direction === 'left') {
        newStart = Math.max(0, item.start + deltaX);
        newDuration = Math.max(0.1, item.duration - deltaX);
      } else { // right resize
        newDuration = Math.max(0.1, item.duration + deltaX);
        newDuration = Math.min(newDuration, duration - item.start);
      }

      onItemResize(item.id, newDuration, newStart);
      lastResizeRef.current = { id: item.id, duration: newDuration, start: newStart };
    } else if (dragStateRef.current) {
      const { id } = dragStateRef.current;
      const item = items.find(i => i.id === id);
      if (item && contentRef.current) {
        const contentRect = contentRef.current.getBoundingClientRect();
        const newStart = Math.max(0, (e.clientX - contentRect.left + scrollLeft) / pixelsPerSecond);
        const newTrack = Math.max(0, Math.floor((e.clientY - contentRect.top + scrollTop) / trackHeight));
        if (newStart !== item.start || newTrack !== item.track) {
          onItemMove(id, newStart, newTrack, item);
          lastMoveRef.current = { id, start: newStart, track: newTrack };
        }
        // onItemMove(id, newStart, newTrack, item);
        // lastMoveRef.current = { id, start: newStart, track: newTrack };
      }
    }
    
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const mouseX = e.clientX - rect.left + scrollLeft;
      const hoverTimeValue = mouseX / pixelsPerSecond;
      setHoverTime(hoverTimeValue);
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (dragStateRef.current && lastMoveRef.current) {
      const { id, start, track } = lastMoveRef.current;
      const item = items.find(i => i.id === id);
      if (item && (start !== item.start || track !== item.track)) {
        onMoveItemEnd(id, start, { ...item, start, track });
      }
    }
    if (resizeStateRef.current && lastResizeRef.current) {
      const { id, duration, start } = lastResizeRef.current;
      const item = items.find(i => i.id === id);
      if (item) {
        onResizeEnd(id, { ...item, duration, start });
      }
    }
    resizeStateRef.current = null;
    dragStateRef.current = null;
    lastMoveRef.current = null;
    lastResizeRef.current = null;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleItemMouseDown = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    const item = items.find(i => i.id === id);
    if (item) {
      console.log({item});
      setAssetSelected(item.type);
      setSelectedComponent(id, item.content, videoId);
      dragStateRef.current = {
        id,
        startX: e.clientX,
        startY: e.clientY,
      };
      lastMoveRef.current = { id, start: item.start, track: item.track };
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  const handleResizeMouseDown = (e: React.MouseEvent, id: string, direction: 'left' | 'right') => {
    e.preventDefault();
    e.stopPropagation();
    const item = items.find(i => i.id === id);
    if (item) {
      resizeStateRef.current = {
        item: { ...item },
        direction,
        startPosition: e.clientX,
      };
      lastResizeRef.current = { id, duration: item.duration, start: item.start };
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleSeek = (e: React.MouseEvent) => {
    if (contentRef.current) {
      const contentRect = contentRef.current.getBoundingClientRect();
      const clickPosition = e.clientX - contentRect.left + scrollLeft;
      const newTime = Math.max(0, clickPosition / pixelsPerSecond);
      onSeek(newTime);
    }
  };

  const toggleExpand = () => {
    const newExpandedState = !isExpanded;
    onToggleExpand(newExpandedState);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [items, onMoveItemEnd, onResizeEnd]);

  if (tracks.length === 0) {
    return <div className="bg-gray-900 text-white w-full flex flex-col h-full" ref={containerRef}>
      <div className="flex items-left p-1 bg-gray-800">
        <button onClick={toggleExpand}>
          {isExpanded ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
        </button>
        {isExpanded && (
          <>
            <button onClick={handleZoomOut} className="ml-2"><ZoomOut size={16} /></button>
            <button onClick={handleZoomIn} className="ml-2"><ZoomIn size={16} /></button>
          </>
        )}
      </div></div>
  }
  return (
    <div className="bg-gray-900 text-white w-full flex flex-col h-full" ref={containerRef}>
      <div className="flex items-left p-1 bg-gray-800">
        <button onClick={toggleExpand}>
          {isExpanded ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
        </button>
        {isExpanded && (
          <>
            <button onClick={handleZoomOut} className="ml-2"><ZoomOut size={16} /></button>
            <button onClick={handleZoomIn} className="ml-2"><ZoomIn size={16} /></button>
          </>
        )}
      </div>
      <div 
        className={`${isExpanded ? "" : "hidden"} flex flex-1 overflow-hidden`}
        onMouseMove={handleTimelineMouseMove}
        onMouseEnter={handleTimelineMouseEnter}
        onMouseLeave={handleTimelineMouseLeave}
      >
        {/* Track labels */}
        <div className="flex-shrink-0" style={{ width: `${labelWidth}px` }}>
          <div style={{ height: `${scaleHeight}px` }} className="px-2 bg-gray-700">Scale</div>
          {tracks.map((track) => (
            <div
              key={track.id}
              className="px-2 truncate bg-gray-800 flex items-center"
              style={{ height: `${trackHeight}px` }}
            >
              {track.name}
            </div>
          ))}
        </div>
        {/* Scrollable timeline area */}
        <div className="flex-grow overflow-hidden">
          <div 
            ref={timelineRef}
            className="overflow-x-auto"
            onScroll={handleScroll}
          >
            <div style={{ width: `${contentWidth}px` }}>
              <RenderTimelineScale />
              <div 
                ref={contentRef}
                style={{ height: `${tracks.length * trackHeight}px`, position: 'relative' }}
              >
                {items.map((item) => (
                  <div
                    key={item.id}
                    className="absolute rounded cursor-move group"
                    style={{
                      left: `${item.start * pixelsPerSecond}px`,
                      top: `${item.track * trackHeight}px`,
                      width: `${Math.min(item.duration * pixelsPerSecond, contentWidth - (item.start * pixelsPerSecond))}px`,
                      height: `${trackHeight - 2}px`,
                      backgroundColor: item.type === 'video' ? 'rgba(59, 130, 246, 0.5)' :
                        item.type === 'audio' ? 'rgba(16, 185, 129, 0.5)' :
                          item.type === 'caption' ? 'rgba(245, 158, 11, 0.5)' : 'rgba(139, 92, 246, 0.5)',
                    }}
                    onMouseDown={(e) => handleItemMouseDown(e, item.id)}
                  >
                    <div className="px-1 truncate text-xs">{item.content}</div>
                    <div
                      className="absolute left-0 top-0 bottom-0 bg-white opacity-50 hover:opacity-100 cursor-ew-resize"
                      onMouseDown={(e) => handleResizeMouseDown(e, item.id, 'left')}
                      style={{ width: "4px" }}
                    />
                    <div
                      className="absolute right-0 top-0 bottom-0 bg-white opacity-50 hover:opacity-100 cursor-ew-resize"
                      onMouseDown={(e) => handleResizeMouseDown(e, item.id, 'right')}
                      style={{ width: "4px" }}
                    />
                  </div>
                ))}
                {/* Playhead */}
                <div
                  className="absolute top-0 bg-white z-20 abc"
                  style={{ left: `${currentTime * pixelsPerSecond}px`, width: "2px", height:"40px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;