import { TableContainer, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Button } from "@chakra-ui/react";
import { useSnapshot } from "valtio";
import { storyState } from "../../stores/StoryStore";
import { ShowPreview } from "./ShowPreview";
import { ShowLogs } from "./Showlogs";
import { remove, clone } from "lodash";
import { ShowLogType } from "./ShowLogType";

export const ApiTable = (props: any) => {
    const { sceneIndex, diagIndex } = props;
    const indexesToCheck = [0, 1, 2].map(
      (key: number) => `${sceneIndex},${diagIndex},${key}`
    );
    let isRunning = true;
    const { runs, apis } = useSnapshot(storyState);
  
    const filteredApis = apis.filter((api: any) => {
      const runsExists = runs.filter((r: any) => String(r._id) === String(api.run) );
      if (runsExists.length === 0) {
        return false;
      }
      const filteredIndexes = Object.keys(runsExists[0].meta.configs).filter(
        (index: string) => indexesToCheck.includes(index)
      );
      return filteredIndexes.length > 0;
    });
    return (
      <TableContainer>
        <Table variant="simple">
          {filteredApis.length > 0 && <><TableCaption>Pipelines</TableCaption>
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Preview</Th>
              <Th>Status</Th>
              <Th>Logs</Th>
              <Th>Action</Th>
            </Tr>
          </Thead></>}
          
          <Tbody>
            {filteredApis.map((api: any, i: number) => {
              const selected = props.APISelected.includes(api._id);
              return (
                <Tr key={i}>
                  <Td>
                    <ShowLogType index={api.index} />
                  </Td>
                  <Td>
                    <ShowPreview api={api} />
                  </Td>
                  <Td>{api.status}</Td>
                  <Td>
                    <ShowLogs api={api} />
                  </Td>
                  <Td>
                    <Button
                      variant={"solid"}
                      colorScheme={selected ? "orange" : "gray"}
                      loadingText="Generating Scenes"
                      spinnerPlacement="start"
                      onClick={() => {
                        if (selected) {
                          const filteredAPIs = remove(
                            clone(props.APISelected),
                            (n) => n !== api._id
                          );
                          props.setAPISelected(filteredAPIs);
                        } else {
                          props.setAPISelected([...props.APISelected, api._id]);
                        }
                      }}
                    >
                      {selected ? "Selected" : "Select"}
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };