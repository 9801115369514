import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "Terms of Service Page",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Terms() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="relative py-12 md:py-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="relative max-w-lg lg:max-w-8xl mx-auto">
              <div className="absolute top-0 left-0 -ml-64 -mt-80 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
              <div className="absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
              <div className="relative">
                <div className="max-w-2xl mx-auto mb-18 text-center">
                  <span className="block text-lg text-gray-500 tracking-tight mb-6">
                    Updated 18-01-24
                  </span>
                  <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight">
                    Terms & Conditions of Crackviral.com
                  </h2>
                </div>
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full px-4 mb-16 lg:mb-0">
                    <a className="block" href="#">
                      <div className="w-full">
                        <span className="block text-base tracking-tight text-gray-200 opacity-70 mb-5">
                          Created - Jul 20, 2023
                        </span>
                        {/* <h3 className="text-3xl font-medium text-white mb-5">
                          Consectures Dummy Content Velit officia consequat duis
                          enim velit
                        </h3> */}
                        <p className="text-lg tracking-tight text-gray-100 opacity-60">
                          <p>
                            <strong>Terms of Service of Crackviral.com</strong>
                          </p>
                          <p>
                            <em>Effective Date: Jul 20, 2023</em>
                          </p>
                          <p>
                            <strong>1. Introduction</strong>
                          </p>
                          <p>
                            Welcome to Crackviral.com. These Terms of Service
                            (&quot;Terms&quot;) govern your access to and use of
                            Crackviral.com&#39;s website, services, and applications
                            (collectively, the &quot;Services&quot;). By
                            accessing or using the Services, you agree to be
                            bound by these Terms.
                          </p>
                          <p>
                            <strong>2. Use of Services</strong>
                          </p>
                          <p>
                            a. <strong>Eligibility</strong>: You must be at
                            least 18 years old to use our Services.
                          </p>
                          <p>
                            b. <strong>Account Registration</strong>: To access
                            certain features of the Services, you must create an
                            account providing accurate and complete information.
                          </p>
                          <p>
                            c. <strong>User Responsibilities</strong>: You are
                            responsible for your conduct and any data, text,
                            files, information, usernames,images, graphics,
                            photos, profiles, audio and video clips, sounds,
                            musical works, works of authorship, applications,
                            links and other content or materials (collectively,
                            &quot;User Content&quot;) that you submit, post or
                            display on or via our Services.
                          </p>
                          <p>
                            d. <strong>Compliance with Laws</strong>: You agree
                            to comply with all local, state, national, and
                            international laws and regulations applicable to
                            your use of the Services.
                          </p>
                          <p>
                            <strong>3. Intellectual Property Rights</strong>
                          </p>
                          <p>
                            a. <strong>Ownership</strong>: Crackviral.com and its
                            licensors retain all proprietary rights to the
                            Services, including copyrights, trademarks, patents,
                            and trade secrets.
                          </p>
                          <p>
                            b. <strong>License to Use</strong>: Crackviral.com grants
                            you a limited, non-exclusive, non-transferable, and
                            revocable license to access and use the Services
                            strictly in accordance with these Terms.
                          </p>
                          <p>
                            c. <strong>User Content</strong>: You retain all
                            rights in, and are solely responsible for, the User
                            Content you post to Crackviral.com.
                          </p>
                          <p>
                            <strong>4. User Conduct</strong>
                          </p>
                          <p>
                            a. <strong>Prohibited Activities</strong>: You may
                            not use the Services for any illegal or unauthorized
                            purpose. Harassment, hacking, impersonating another
                            person, spamming, and engaging in fraudulent or
                            deceptive practices are expressly prohibited.
                          </p>
                          <p>
                            b. <strong>AI Model Interaction</strong>: You must
                            not misuse AI models or manipulate outputs in a
                            manner that violates ethical guidelines or laws.
                          </p>
                          <p>
                            <strong>5. Disclaimer of Warranties</strong>
                          </p>
                          <p>
                            The Services are provided &quot;AS IS&quot; without
                            warranty of any kind, express or implied, including
                            but not limited to warranties of performance,
                            merchantability, fitness for a particular purpose,
                            accuracy, omissions, completeness, currentness, and
                            delays.
                          </p>
                          <p>
                            <strong>6. Limitation of Liability</strong>
                          </p>
                          <p>
                            In no event shall Crackviral.com, its affiliates,
                            directors, employees, agents, partners, or licensors
                            be liable for any indirect, incidental, special,
                            consequential or punitive damages, including without
                            limitation, loss of profits, data, use, goodwill, or
                            other intangible losses, resulting from your access
                            to or use of or inability to access or use the
                            Services.
                          </p>
                          <p>
                            <strong>7. Indemnification</strong>
                          </p>
                          <p>
                            You agree to indemnify and hold harmless Crackviral.com and
                            its officers, directors, employees, and agents from
                            any and all claims, damages, obligations, losses,
                            liabilities, costs, debt, and expenses arising from:
                            (a) your use of and access to the Services; (b) your
                            violation of any term of these Terms; (c) your
                            violation of any third-party right, including
                            without limitation any copyright, trademark,
                            privacy, or other personal or proprietary right; or
                            (d) any claim that your User Content caused damage
                            to a third party.
                          </p>
                          <p>
                            <strong>
                              8. Modifications to the Services and Terms
                            </strong>
                          </p>
                          <p>
                            Crackviral.com reserves the right, at its sole discretion,
                            to modify or discontinue the Services or to modify
                            these Terms at any time and without prior notice. If
                            we modify these Terms, we will post the modification
                            on the website or provide you with notice of the
                            modification. By continuing to access or use the
                            Services after we have posted a modification, you
                            are indicating that you agree to be bound by the
                            modified Terms.
                          </p>
                          <p>
                            <strong>9. Governing Law</strong>
                          </p>
                          <p>
                            These Terms shall be governed by and construed in
                            accordance with the laws of the State of California,
                            United States, without regard to its conflict of law
                            provisions.
                          </p>
                          <p>
                            <strong>10. Contact Information</strong>
                          </p>
                          <p>
                            If you have any questions about these Terms, please
                            contact us at [Insert Contact Information].
                          </p>
                          <hr />
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
