import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  useDisclosure,
  chakra,
  Button,
  GridItem,
  useToast,
  CircularProgress,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


import "../Tailwind.css";
import { getSubString } from "../lib/utils/Misc";
import { NewVideoModal } from "../lib/modals/NewVideo";
import { useAuth } from "../components/lib/useAuth";
import { useSnapshot } from "valtio";
import {
  editorState,
  setCurrentCreating,
  setShowLoader,
} from "../stores/EditorStore";
import { HeadContent } from "../lib/components/Headcontent";
import { useWebSocket } from "../lib/components/WebSocketContext";
import DashboardWrapper from "../lib/components/Wrapper";

const MyVideos: React.FC = () => {
  // const wsRef = useRef<WebSocket | null>(null);
  const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
  const [videos, setVideos] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVideos, setFilteredVideos] = useState(videos);
  const navigate = useNavigate();
  const toast = useToast();
  const [auth, error, loading] = useAuth();
  const {
    video,
    newVideo: videoNew,
    creating,
    showLoader,
  } = useSnapshot(editorState);

  useEffect(() => {
    addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);

  useEffect(() => {
    document.title = "Crackviral | Dashboard";
    setFilteredVideos(videos);
  }, [videos]);

  useEffect(() => {
    if (videos.length === 0) {
      loadVideos();
    }
    setShowLoader(false);
  }, []);

  const sidebar = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
  const {
    isOpen: videoIsOpen,
    onOpen: videoOpen,
    onClose: videoOnClose,
  } = useDisclosure();

  const {
    isOpen: generateIsOpen,
    onOpen: generateOpen,
    onClose: generateOnClose,
  } = useDisclosure();

  // Listen for messages from the WebSocket server
  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status) {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "videos":
        console.log("Updated video:", message.videos);
        setVideos(message.videos);
        break;
      case "video":
        console.log("Updated video:", message.video);
        loadVideos();
        break;
      case "sceneFull":
        console.log("experiment obj:", message.experimentObj);
        navigate(`/storyboard/${message.experimentObj._id}`);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "error":
        console.log("Error video:", message.message, event);
        toast({
          title: `Error video:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };
  const handleSearch = () => {
    if (searchQuery !== null && searchQuery !== undefined) {
      const query = searchQuery.toLowerCase();
      const filtered = videos.filter((vid) => {
        if (vid?.title && vid?.description) {
          return (
            vid.title.toLowerCase().includes(query) ||
            vid.description.toLowerCase().includes(query)
          );
        }
        if (vid?.title) {
          return vid.title.toLowerCase().includes(query);
        }
        if (vid?.description) {
          return vid.description.toLowerCase().includes(query);
        }
        if (!vid?.title && !vid?.description) {
          return;
        }
      });
      setFilteredVideos(filtered);
    }
  };

  function loadVideos() {
    const payload = {
      action: "getVideos",
    };
    send(payload);
  }

  function submitAuth() {
    const payload = {
      action: "auth",
      data: localStorage.getItem("auth"),
    };
    send(payload);
  }

  function generateScenes(meta: any) {
    const payload = {
      action: "storyToScenes",
      data: meta,
    };
    send(payload);
  }
  const createVideo = (obj: any) => {
    const payload = {
      action: "createVideo",
      video: {
        title: obj.title,
        url: obj.url,
        description: obj.description,
        meta: {
          ai: { details: obj.details },
          props: { height: obj.meta.props.height, width: obj.meta.props.width },
        },
      },
    };
    send(payload);
  };
  const handleOpen = () => {
    submitAuth();
    loadVideos();
  };

  return (
    <DashboardWrapper>
      <HeadContent
        sidebar={sidebar}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
      />
      <Box as="main" p="4">
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} gap={6}>
          {filteredVideos?.map((vid, index) => {
            return (
              <GridItem w="100%" key={index}>
                <Box
                  maxW="xs"
                  mx="auto"
                  bg="white"
                  _dark={{
                    bg: "gray.800",
                  }}
                  shadow="lg"
                  height="500px"
                  rounded="lg"
                >
                  <Box px={4} py={2}>

                    <chakra.h1
                      color="gray.800"
                      _dark={{
                        color: "white",
                      }}
                      fontWeight="bold"
                      fontSize="xl"
                      textTransform="uppercase"
                      noOfLines={1}
                    >
                      {getSubString(vid.title, 20)}
                    </chakra.h1>
                    {vid.preview?.thumb && <Image
                      h="400px"
                      w="full"
                      fit="cover"
                      mt={2}
                      src={vid.preview.thumb}
                      alt="Video preview"
                    />}
                    {!vid.preview?.thumb && <Image
                      h="400px"
                      w="full"
                      fit="cover"
                      mt={2}
                      src={"https://placehold.co/275x490"}
                      alt="Video preview"
                    />}
                    <chakra.p
                      mt={1}
                      fontSize="sm"
                      color="gray.600"
                      _dark={{
                        color: "gray.400",
                      }}
                      noOfLines={1}
                    >
                      {vid.description}
                    </chakra.p>
                  </Box>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    px={4}
                    py={2}
                    bg="gray.200"
                    roundedBottom="lg"
                  >
                    {/* <chakra.h1 color="white" fontWeight="bold" fontSize="lg">
                                 $129
                             </chakra.h1> */}
                    <chakra.button
                      px={2}
                      py={1}
                      bg="white"
                      fontSize="xs"
                      color="gray.900"
                      fontWeight="bold"
                      rounded="lg"
                      textTransform="uppercase"
                      _hover={{
                        bg: "gray.200",
                      }}
                      _focus={{
                        bg: "gray.400",
                      }}
                      onClick={() => navigate("/video/" + vid._id)}
                    >
                      Edit
                    </chakra.button>
                    {vid?.exp && (
                      <chakra.button
                        px={2}
                        py={1}
                        bg="white"
                        fontSize="xs"
                        color="gray.900"
                        fontWeight="bold"
                        rounded="lg"
                        textTransform="uppercase"
                        _hover={{
                          bg: "gray.200",
                        }}
                        _focus={{
                          bg: "gray.400",
                        }}
                        onClick={() => navigate("/storyboard/" + vid.exp)}
                      >
                        Storyboard
                      </chakra.button>
                    )}
                  </Flex>
                </Box>
              </GridItem>
            );
          })}
        </SimpleGrid>
        <NewVideoModal
          videoIsOpen={videoIsOpen}
          videoOnClose={videoOnClose}
          setCurrentCreating={setCurrentCreating}
          creating={creating}
          newVideo={videoNew}
          auth={auth}
          generateScenes={generateScenes}
          generateOpen={generateOpen}
        />

        {showLoader && (
          <CircularProgress
            isIndeterminate
            color="green.300"
            size={"xl"}
            style={{ left: "30%", position: "absolute", top: "30%" }}
          />
        )}
        <Button
          // isLoading
          style={{ position: "fixed", bottom: "10px", right: "10px" }}
          loadingText="Import Scenes"
          // colorScheme="teal"
          // variant="outline"
          spinnerPlacement="start"
          onClick={() => navigate("/video/create")}
        >
          Create Video
        </Button>
      </Box></DashboardWrapper>
  );
};

export default MyVideos;