import { useEffect, useState } from "react";
import { useRouteError } from "react-router-dom";
import { SendRequest } from "./utils/main";

import { useToast } from "@chakra-ui/react";

export default function ErrorPage() {
  const error = useRouteError();
  const [errorLog, setErrorLog] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (error) {
      const errorDetails = {
        statusText: error.statusText,
        message: error.message,
        stack: error.stack,
      };

      SendRequest("/api/v2/log/error", errorDetails)
        .then((data) => console.log("Error logged successfully:", data))
        .catch((err) => {
          toast({
            title: `Seems like your login has expired. Kindly report the issue on support if this happens post login again. `,
            position: "top-right",
            variant: "top-accent",
            isClosable: true,
          });
          setErrorLog(true);
        });
    }
  }, [error]);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      {errorLog && (
        <p>
          Sorry, an unexpected error has occurred. We had issue logging the
          error. Your token might have expired. Kindly login again by clicking{" "}
          <a href="/login"> here</a> We are still in Beta, though your
          experience is your priority. In case you still see this post login,
          Kindly share a full page screenshot with the error log on
          bhanu@aexy.io along with the rough time when the error took place.
        </p>
      )}
      {!errorLog && (
        <>
          <p>
            Sorry, an unexpected error has occurred. We have logged the error on
            our end. We are still in Beta, though your experience is your
            priority. We solve most reported bugs in 24 hours.Thanks for your
            support.
          </p>{" "}
          <p>
            Feel free to reach out to bhanu@aexy.io in case you are stuck on
            something for faster help.
          </p>
        </>
      )}

      <p>
        <i>{error.statusText || error.message}</i>
        {error.stack && <pre>{error.stack}</pre>}
      </p>
    </div>
  );
}
