import React, { useState } from 'react';
import { ArrowLeft, ArrowRight } from 'lucide-react';

const ScriptSelectionComponent = () => {
  const [selectedScript, setSelectedScript] = useState(0);

  const scripts = [
    {
      title: 'Response Bubble',
      content: "maintaining healthy joint fluid and cartilage. Just take three tablets daily with your meals, and you might start noticing a difference in your mobility and comfort levels pretty soon. Definitely give it a try if you're looking to keep those joints happy and healthy while you're on the move! Get yours now and start feeling the difference!",
    },
    {
      title: 'Motivational',
      content: "supplement, a blend of ingredients supporting joint mobility and comfort, strength, flexibility, and crucial cartilage support. Now, every step I take is pain-free. If you're seeking the same comfort and freedom in your movement, try Move Free. Experience the joy today and embrace a life where every move is a breeze.",
    },
    {
      title: 'Let Me Show You',
      content: "Hey everyone! You know that nagging joint discomfort that just won't let you be your active self? Well, I found something truly helpful. Move Free Advanced with Glucosamine and Chondroitin has been a game-changer for me! With ingredients that support joint mobility, comfort, and strength, I've noticed significant improvements in just",
    },
    {
      title: 'Problem-Solution',
      content: "Ever notice how some days your joints just don't want to cooperate, especially when you're trying to stay active? Yeah, I've been there. That's until I found Move Free Advanced with MSM. This powerhouse combines essential nutrients like Glucosamine and Chondroitin with Uniflex to support joint comfort in just two weeks! Plus, it's in smaller,",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-800 to-gray-900 min-h-screen flex flex-col items-center justify-center p-4 text-white">
      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-bold mb-8 text-center">Choose your favorite script</h1>
        
        <div className="mb-6">
          <div className="flex justify-center space-x-4 mb-6">
            <button className="bg-gray-700 hover:bg-gray-600 text-white font-medium py-2 px-6 rounded-lg transition duration-300">
              AI script
            </button>
            <button className="bg-gray-700 hover:bg-gray-600 text-white font-medium py-2 px-6 rounded-lg transition duration-300">
              Do It yourself
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {scripts.map((script, index) => (
            <div key={index} className="bg-gray-800 rounded-lg p-4 flex flex-col justify-between">
              <div>
                <h3 className="text-xl font-semibold mb-2">{script.title}</h3>
                <p className="text-gray-400 mb-4 h-40 overflow-y-auto">
                  {script.content}
                </p>
              </div>
              <button
                onClick={() => setSelectedScript(index)}
                className={`w-full py-2 px-4 rounded-lg transition duration-300 ${
                  selectedScript === index
                    ? 'bg-blue-600 hover:bg-blue-500'
                    : 'bg-gray-700 hover:bg-gray-600'
                }`}
              >
                Select
              </button>
            </div>
          ))}
        </div>

        <div className="mt-6 text-center">
          <button className="text-blue-400 hover:underline">
            Generate more scripts
          </button>
        </div>

        <div className="flex justify-between mt-8">
          <button className="bg-gray-700 hover:bg-gray-600 text-white font-medium py-2 px-6 rounded-lg flex items-center transition duration-300">
            <ArrowLeft size={20} className="mr-2" />
            Back
          </button>
          <button className="bg-blue-600 hover:bg-blue-500 text-white font-medium py-2 px-6 rounded-lg flex items-center transition duration-300">
            Next
            <ArrowRight size={20} className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScriptSelectionComponent;