import { proxy, subscribe } from "valtio";

import { getTimeLinePayload2 } from "../lib/utils/EditorUtil";
import { GetInputsFromOPId, GetModesFromOPId } from "../apis/sd/video";
import { importCompositionWithRetry } from "../utils/main";

export interface Subtitle {
  text: string;
  color: string;
  fontSize: number;
  from: number;
  durationInFrames: number;
}

interface Resolution {
  width: number;
  height: number;
}

// interface CompProperties {
//   meta?: {
//     prompt?: string;
//     text?: string;
//   };
// }

// interface EditorProps {
//   [key: string]: number;
// }

const initState: {
  importProps: any;
  genProps: any;
  durationInFrames: number;
  importing: number;
  creating: number;
  showLoader: boolean;
  model: string;
  selectedScreen: string;
  selectedComponent: string;
  selectedComponentName: string;
  screens: any;
  screenprops: any;
  video: any;
  newVideo: any;
  runs: any;
  apis: any;
  runInstance: any;
  variation: string;
  timelineRows: any;
  editorProps: any;
  formData: any;
  APISelected: string[];
  compProperties: any;
  varProperties: any[];
  componentRef: any;
  videoId: string;
  currentTime: number;
  subtitles: Subtitle[];
  resolution: Resolution;
  isVertical: Boolean;
  credits: { balance: number, validUpto: string, plan: string };
  tracks: any;
  assetSelected: string;
  matrixRows: any;
  matrixHeaders: any;
  variationMap: any;
} = {
  importProps: {},
  genProps: {},
  durationInFrames: 900,
  importing: 0,
  creating: 0,
  showLoader: false,
  model: "",
  selectedScreen: "",
  componentRef: null,
  selectedComponent: "",
  selectedComponentName: "",
  screens: [],
  screenprops: {},
  video: null,
  newVideo: null,
  apis: [],
  runs: [],
  runInstance: null,
  variation: "",
  timelineRows: [],
  formData: {},
  editorProps: {},
  APISelected: [],
  compProperties: {},
  varProperties: [],
  subtitles: [],
  videoId: "",
  currentTime: 0,
  resolution: {
    width: 1920,
    height: 1080,
  },
  isVertical: false,
  credits: {
    balance: -1,
    validUpto: "",
    plan: "free"
  },
  tracks: [],
  assetSelected: "Image",
  matrixRows: [],
  matrixHeaders: [],
  variationMap: {},
};

const localState = localStorage.getItem("aexy-storage");
const initialState = localState ? JSON.parse(localState) : initState;

export const editorState = proxy(initialState);

subscribe(editorState, () => {
  localStorage.setItem("aexy-storage", JSON.stringify(editorState));
});

export const setCurrentTime = (currentTime: any) => {
  editorState.currentTime = currentTime;
};

export const setCredit = (balance: number, validUpto: string, plan: string) => {
  editorState.credits = {
    balance,
    validUpto,
    plan
  };
};

export const setSubtitles = (subtitles: Subtitle[]) => {
  editorState.subtitles = subtitles;
};

export const setResolution = (resolution: Resolution) => {
  editorState.resolution = resolution;
};

export const updateSubtitles = (chunks: any[]) => {
  const subtitles = chunks.map((chunk) => {
    return {
      text: chunk.text,
      color: "black",
      fontSize: 20,
      from: chunk.timestamp[0],
      durationInFrames: chunk.timestamp[1] - chunk.timestamp[0],
    };
  });
  editorState.subtitles = subtitles;
};

export const setBulkProps = async (
  variations: any[],
  video: any
) => {
  editorState.video = video;
  const components: any[] = [];
  await Promise.all(
    Object.keys((video.meta as any)?.screens).map(
      async (screenName: any) => {
        // components.push(screen);
        const screen = (video.meta as any)?.screens[screenName];
        components.push(...screen.kids);
      }
    )
  );
  const headers: { type: string; text: string; }[] = [];

  components.forEach((component: any) => {
    const componentName = video.meta.props[`${component.id}_name`];
    Object.keys(component.props).forEach(propName => {
      headers.push({ type: propName, text: `${componentName} ${propName}`, });
      // cells.push({ header: `${componentName} ${propName}`, type: propName, text: String(video.meta.props[`${component.id}_${propName}`]), width: 150, id: component.id, name: String(video.meta.props[`${component.id}_name`]) });
    });
  });
  const matrixRows: any = {}
  const variationMap: any = {}

  variations.forEach((variation: any) => {
    variationMap[variation._id] = variation._v;
    const combinedProps = { ...video.meta.props, ...variation.meta.props };
    const cells: { type: string; text: any; width: number; id: string; name: string; header: string; _v: number }[] = [];
    components.forEach((component: any) => {
      const componentName = video.meta.props[`${component.id}_name`];
      Object.keys(component.props).forEach(propName => {
        // headers.push({ type: propName, text: `${componentName} ${propName}`, });
        cells.push({ header: `${componentName} ${propName}`, type: propName, text: String(combinedProps[`${component.id}_${propName}`]), width: 150, id: component.id, name: String(video.meta.props[`${component.id}_name`]), _v: variation._v });
      });
    });
    matrixRows[variation._id] = cells;

  });
  editorState.variationMap = variationMap;

  editorState.matrixRows = matrixRows;
  editorState.matrixHeaders = headers;
}

export const updateCell = async (variationId: string, propId: string, value: string) => {
  editorState.matrixRows[variationId].forEach((row: any) => {
    if (row.header === propId) {
      row.text = value;
    }
  })
}

export const setVideoOnly = async (
  video: any,
) => {
  editorState.video = video;
}

export const setBulkVideo = async (
  video: any,
  videoId: string,
) => {
  editorState.video = video;
  const components: any[] = [];
  await Promise.all(
    Object.keys((video.meta as any)?.screens).map(
      async (screenName: any) => {
        // components.push(screen);
        const screen = (video.meta as any)?.screens[screenName];
        components.push(...screen.kids);
      }
    )
  );
  const headers: { type: string; text: string; }[] = [];
  const cells: { type: string; text: any; width: number; id: string; name: string; header: string; }[] = [];

  components.forEach((component: { props: {}; id: string }) => {
    const componentName = video.meta.props[`${component.id}_name`];
    Object.keys(component.props).forEach(propName => {
      headers.push({ type: propName, text: `${componentName} ${propName}`, });
      cells.push({ header: `${componentName} ${propName}`, type: propName, text: String(video.meta.props[`${component.id}_${propName}`]), width: 150, id: component.id, name: String(video.meta.props[`${component.id}_name`]) });
    });
  });

  editorState.matrixRows = cells;
  editorState.matrixHeaders = headers;

}

export const setVideo = async (
  video: any,
  videoId: string,
  setComposition: Function
) => {
  editorState.video = video;
  editorState.screens = video.meta.screens;
  if (video.meta.props) {
    editorState.editorProps = video.meta.props;
  }
  let firstScreen = Object.keys(video.meta.screens)[0];

  if (editorState.videoId !== String(video._id)) {
    editorState.selectedScreen = firstScreen;
    editorState.selectedComponent = "";
  }

  if (!editorState.selectedScreen) {
    editorState.selectedScreen = firstScreen;
  }
  const key = video.meta.screens[editorState.selectedScreen].id;

  editorState.durationInFrames = secondsToFrames(video.meta.props[`${key}_durationInFrames`], video.meta.global.fps);


  const childrenRef = video.meta.screens[editorState.selectedScreen].kids;

  const { timelinePayload, screenProps, tracks } = getTimeLinePayload2(
    editorState.screenprops,
    editorState.selectedScreen,
    video,
    childrenRef
  );

  // const effects = timelinePayload.reduce((prev, curr) => {
  //   return { ...prev, [curr.id]: { id: curr.id, name: "curr.id" } };
  // }, {});
  // editorState.effects = effects;
  editorState.timelineRows = timelinePayload;
  editorState.tracks = tracks;
  editorState.screenprops = screenProps;
  editorState.videoId = video._id;
  if (editorState.selectedScreen) {
    await importCompositionWithRetry(videoId, video._v || 0, editorState.selectedScreen, setComposition)
    // const componentModule = await import(
    //   `../components/${videoId}/${video._v || 0}/${editorState.selectedScreen}/Composition`
    // );
    // setComposition(() => componentModule.default);
  }
  const res = {
    width: video.meta.global.width,
    height: video.meta.global.height,
  };
  setResolution(res);
  editorState.isVertical = res.height > res.width;
};

export const setVariation = (variation: any) => {
  editorState.variation = variation;
};

export const setTimelineRows = (timelineRows: any) => {
  editorState.timelineRows = timelineRows;
};

export const setRuns = (run: any, apis: any) => {
  editorState.runInstance = run;
  editorState.apis = apis;
};

export const updateRuns = (runs: any) => {
  editorState.runs = runs;
};
export const updateAPIs = (apis: any) => {
  editorState.apis = apis;
};

export const setCurrentImporting = (importing: number) => {
  editorState.importing = importing;
};

export const setImportProps = (importProps: any) => {
  editorState.importProps = importProps;
};

export const setNewVideo = (video: any) => {
  editorState.newVideo = video;
};

export const setShowLoader = (state: boolean) => {
  editorState.showLoader = state;
};

export const setEditorProps = (editorProps: any) => {
  editorState.editorProps = editorProps;
};
export const setAPISelected = (APISelected: string[]) => {
  editorState.APISelected = APISelected;
};

export const setCurrentCreating = (creating: number) => {
  editorState.creating = creating;
};

export const setSelectedComponentId = (componentId: string) => {
  editorState.selectedComponent = componentId;
  editorState.selectedComponentName = editorState.editorProps[`${componentId}_name`];
  editorState.compProperties =
    editorState.editorProps[`${componentId}_run`];
  editorState.componentRef = editorState.video.meta.screens[
    editorState.selectedScreen
  ].kids.filter(
    (kid: any) => kid.id === editorState.selectedComponent
  )[0];
  if (!editorState.compProperties?.meta) {
    return;
  }

  editorState.model = editorState.compProperties?.meta?.model_name;
  // setModel(modelName);
  // const modelName = getModelName(model_name);
  const modes = GetModesFromOPId(editorState.model);

  const mode = editorState.compProperties?.meta?.mode || modes[0];

  const props = Object.entries(
    GetInputsFromOPId(editorState.model, mode)?.properties || {}
  );
  editorState.varProperties = props;
  let initValues: any = {};

  props &&
    props.map(([key, value]: any, index: number) => {
      initValues[key] = value.default;
      // if (key === "prompt" && activeIndexes[2] == 0) {
      //   initValues["prompt"] = PromptValue();
      // }
      // if (key === "text" && activeIndexes[2] == 1) {
      //   initValues["text"] = DialogueValue();
      // }
      if (!initValues[key] && value.examples) {
        initValues[key] = value.examples[0];
      }
    });
  initValues["vos"] = 2;
  initValues["model_name"] = editorState.model;
  initValues["meta"] = {
    screen: editorState.selectedScreen,
    videoId: editorState.video._id,
    component: componentId,
    index: editorState.compProperties?.index,
  };
  setSubtitles([
    {
      text:
        editorState.compProperties?.meta?.prompt ||
        editorState.compProperties?.meta?.text ||
        "",
      color: "#000000",
      fontSize: 14,
      from: editorState.editorProps[`${componentId}_from`] || 0,
      durationInFrames:
        editorState.editorProps[`${componentId}_durationInFrames`] || 0,
    },
  ]);

  setFormData(initValues);
};
export const setAssetSelected = (assetSelected: string) => {
  editorState.assetSelected = assetSelected;
};


export const setSelectedComponent = (
  selectedComponent: string,
  selectedComponentName: string,
  videoId: string
) => {
  editorState.selectedComponent = selectedComponent;
  editorState.selectedComponentName = selectedComponentName;
  editorState.compProperties =
    editorState.editorProps[`${selectedComponent}_run`];
  editorState.componentRef = editorState.video.meta.screens[
    editorState.selectedScreen
  ].kids.filter(
    (kid: any) => kid.id === editorState.selectedComponent
  )[0];
  if (!editorState.compProperties?.meta) {
    return;
  }

  editorState.model = editorState.compProperties?.meta?.model_name;
  // setModel(modelName);
  // const modelName = getModelName(model_name);
  const modes = GetModesFromOPId(editorState.model);

  const mode = editorState.compProperties?.meta?.mode || modes[0];

  const props = Object.entries(
    GetInputsFromOPId(editorState.model, mode)?.properties || {}
  );
  editorState.varProperties = props;
  let initValues: any = {};

  props &&
    props.map(([key, value]: any, index: number) => {
      initValues[key] = value.default;
      // if (key === "prompt" && activeIndexes[2] == 0) {
      //   initValues["prompt"] = PromptValue();
      // }
      // if (key === "text" && activeIndexes[2] == 1) {
      //   initValues["text"] = DialogueValue();
      // }
      if (!initValues[key] && value.examples) {
        initValues[key] = value.examples[0];
      }
    });
  initValues["vos"] = 2;
  initValues["model_name"] = editorState.model;
  initValues["meta"] = {
    screen: editorState.selectedScreen,
    videoId,
    component: selectedComponent,
    index: editorState.compProperties?.index,
  };
  setSubtitles([
    {
      text:
        editorState.compProperties?.meta?.prompt ||
        editorState.compProperties?.meta?.text ||
        "",
      color: "#000000",
      fontSize: 14,
      from: editorState.editorProps[`${selectedComponent}_from`] || 0,
      durationInFrames:
        editorState.editorProps[`${selectedComponent}_durationInFrames`] || 0,
    },
  ]);

  setFormData(initValues);
};

export const handleScreenChange = (
  s: string,
  updateComponent: Function,
  key: string,
  screenKey: string
) => {
  editorState.selectedScreen = s;
  updateComponent(s);
  const childrenRef = editorState.screens[screenKey].kids;
  const props: any = { ...editorState.screenprops, [screenKey]: {} };
  childrenRef.map((kid: any) => {
    props[screenKey][kid.id] = kid.props;
  });
  editorState.screenprops = props;
  editorState.durationInFrames =
    parseInt(editorState.video.meta.props[`${key}_durationInFrames`]) *
    editorState.video.meta.global.fps;
};

export const updateTimeline = () => {
  if (!editorState.video) {
    return;
  }
  const selectedScreenTmp =
    editorState.selectedScreen ||
    Object.keys(editorState.video.meta.screens)[0];
  const childrenRef =
    editorState.video.meta.screens[selectedScreenTmp].kids;

  const selectedComponentIndex = Object.keys(childrenRef)[0];
  const selectedComponent = childrenRef[selectedComponentIndex].id;
  
  editorState.selectedComponent = selectedComponent;

  const { timelinePayload, tracks, screenProps } = getTimeLinePayload2(
    editorState.screenprops,
    editorState.selectedScreen,
    editorState.video,
    childrenRef
  );
  editorState.timelineRows = timelinePayload;
  editorState.tracks = tracks;
  editorState.screenprops = screenProps;
  editorState.videoId = editorState.video._id;

  // const effects = timelinePayload.reduce((prev, curr) => {
  //   return { ...prev, [curr.id]: { id: curr.id, name: "curr.id" } };
  // }, {});

  // editorState.effects = effects;
  editorState.timelineRows = timelinePayload;
};

export const setFormData = (formData: any) => {
  editorState.formData = formData;
};

function secondsToFrames(val: string, fps: number) {
  return Math.round((parseFloat(val) || 0) * fps);
}