const modes = {
  base: {
    path: "/v1/text-to-speech/{voice_id}",
    input: "Body_Text_to_speech_v1_text_to_speech__voice_id__post",
  },
  // timestamp: {
  //   path: "/v1/text-to-speech/{voice_id}//with-timestamps",
  //   input:
  //     "Body_Text_to_speech_streaming_with_timestamps_v1_text_to_speech__voice_id__stream_with_timestamps_post",
  // },
};

const voices =[
    {
      voice_id: "21m00Tcm4TlvDq8ikWAM",
      name: "Rachel",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "calm",
        age: "young",
        gender: "female",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "29vD33N1CtxCmqQRPOHJ",
      name: "Drew",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "well-rounded",
        age: "middle aged",
        gender: "male",
        "use case": "news",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/29vD33N1CtxCmqQRPOHJ/e8b52a3f-9732-440f-b78a-16d5e26407a1.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "2EiwWnXFnvU5JabPnv8n",
      name: "Clyde",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "war veteran",
        age: "middle aged",
        gender: "male",
        "use case": "video games",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/2EiwWnXFnvU5JabPnv8n/65d80f52-703f-4cae-a91d-75d4e200ed02.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "5Q0t7uMcjvnagumLfvZi",
      name: "Paul",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "ground reporter",
        age: "middle aged",
        gender: "male",
        "use case": "news",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/5Q0t7uMcjvnagumLfvZi/1094515a-b080-4282-aac7-b1b8a553a3a8.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "AZnzlk1XvdvUeBnXmlld",
      name: "Domi",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "strong",
        age: "young",
        gender: "female",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/AZnzlk1XvdvUeBnXmlld/508e12d0-a7f7-4d86-a0d3-f3884ff353ed.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "CYw3kZ02Hs0563khs1Fj",
      name: "Dave",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "british-essex",
        description: "conversational",
        age: "young",
        gender: "male",
        "use case": "video games",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/CYw3kZ02Hs0563khs1Fj/872cb056-45d3-419e-b5c6-de2b387a93a0.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "D38z5RcWu1voky8WS1ja",
      name: "Fin",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "irish",
        description: "sailor",
        age: "old",
        gender: "male",
        "use case": "video games",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/D38z5RcWu1voky8WS1ja/a470ba64-1e72-46d9-ba9d-030c4155e2d2.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "EXAVITQu4vr4xnSDxMaL",
      name: "Sarah",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "soft",
        age: "young",
        gender: "female",
        "use case": "news",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/6851ec91-9950-471f-8586-357c52539069.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "ErXwobaYiN019PkySvjV",
      name: "Antoni",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "well-rounded",
        age: "young",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/ErXwobaYiN019PkySvjV/ee9ac367-91ee-4a56-818a-2bd1a9dbe83a.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "GBv7mTt0atIp3Br8iCZE",
      name: "Thomas",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "calm",
        age: "young",
        gender: "male",
        "use case": "meditation",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/GBv7mTt0atIp3Br8iCZE/98542988-5267-4148-9a9e-baa8c4f14644.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "IKne3meq5aSn9XLyUdCD",
      name: "Charlie",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "australian",
        description: "casual",
        age: "middle aged",
        gender: "male",
        "use case": "conversational",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [
        "eleven_multilingual_v1",
        "eleven_turbo_v2",
      ],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "JBFqnCBsd6RMkjVDRZzb",
      name: "George",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "british",
        description: "warm",
        age: "middle-aged",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/JBFqnCBsd6RMkjVDRZzb/365e8ae8-5364-4b07-9a3b-1bfb4a390248.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_turbo_v2"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "LcfcDJNUP1GQjkzn1xUU",
      name: "Emily",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "calm",
        age: "young",
        gender: "female",
        "use case": "meditation",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/LcfcDJNUP1GQjkzn1xUU/e4b994b7-9713-4238-84f3-add8fccaaccd.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "MF3mGyEYCl7XYWbV9V6O",
      name: "Elli",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "emotional",
        age: "young",
        gender: "female",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/MF3mGyEYCl7XYWbV9V6O/d8ecadea-9e48-4e5d-868a-2ec3d7397861.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "N2lVS1w4EtoT3dr4eOWO",
      name: "Callum",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "intense",
        age: "middle-aged",
        gender: "male",
        "use case": "characters",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [
        "eleven_multilingual_v1",
        "eleven_turbo_v2",
      ],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "ODq5zmih8GrVes37Dizd",
      name: "Patrick",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "shouty",
        age: "middle aged",
        gender: "male",
        "use case": "video games",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/ODq5zmih8GrVes37Dizd/0ebec87a-2569-4976-9ea5-0170854411a9.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "SOYHLrjzK2X1ezoPC6cr",
      name: "Harry",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "anxious",
        age: "young",
        gender: "male",
        "use case": "video games",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/SOYHLrjzK2X1ezoPC6cr/86d178f6-f4b6-4e0e-85be-3de19f490794.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "TX3LPaxmHKxFdv7VOQHJ",
      name: "Liam",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "calm",
        age: "young",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [
        "eleven_multilingual_v1",
        "eleven_turbo_v2",
      ],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "ThT5KcBeYPX3keUQqHPh",
      name: "Dorothy",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "british",
        description: "pleasant",
        age: "young",
        gender: "female",
        "use case": "children's stories",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/ThT5KcBeYPX3keUQqHPh/981f0855-6598-48d2-9f8f-b6d92fbbe3fc.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "TxGEqnHWrfWFTfGW9XjX",
      name: "Josh",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "deep",
        age: "young",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "VR6AewLTigWG4xSOukaG",
      name: "Arnold",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "crisp",
        age: "middle aged",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/VR6AewLTigWG4xSOukaG/316050b7-c4e0-48de-acf9-a882bb7fc43b.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "XB0fDUnXU5powFXDhCwa",
      name: "Charlotte",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "british-swedish",
        description: "seductive",
        age: "young",
        gender: "female",
        "use case": "characters",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "Xb7hH8MSUJpSbSDYk0k2",
      name: "Alice",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "british",
        description: "confident",
        age: "middle-aged",
        gender: "female",
        "use case": "news",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/Xb7hH8MSUJpSbSDYk0k2/f5409e2f-d9c3-4ac9-9e7d-916a5dbd1ef1.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_turbo_v2"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "XrExE9yKIg1WjnnlVkGX",
      name: "Matilda",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "friendly",
        age: "middle-aged",
        gender: "female",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [
        "eleven_multilingual_v1",
        "eleven_turbo_v2",
      ],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "ZQe5CZNOzWyzPSCn5a3c",
      name: "James",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "australian",
        description: "calm ",
        age: "old",
        gender: "male",
        "use case": "news",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/ZQe5CZNOzWyzPSCn5a3c/35734112-7b72-48df-bc2f-64d5ab2f791b.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "Zlb1dXrM653N07WRdFW3",
      name: "Joseph",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "british",
        age: "middle aged",
        gender: "male",
        "use case": "news",
        "description ": "ground reporter ",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/Zlb1dXrM653N07WRdFW3/daa22039-8b09-4c65-b59f-c79c48646a72.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "bVMeCyTHy58xNoL34h3p",
      name: "Jeremy",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american-irish",
        description: "excited",
        age: "young",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/bVMeCyTHy58xNoL34h3p/66c47d58-26fd-4b30-8a06-07952116a72c.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "flq6f7yk4E4fJM5XTYuZ",
      name: "Michael",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        age: "old",
        gender: "male",
        "use case": "audiobook",
        "description ": "orotund",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/flq6f7yk4E4fJM5XTYuZ/c6431a82-f7d2-4905-b8a4-a631960633d6.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "g5CIjZEefAph4nQFvHAz",
      name: "Ethan",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        age: "young",
        gender: "male",
        "use case": "ASMR",
        "description ": "whisper",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/g5CIjZEefAph4nQFvHAz/26acfa99-fdec-43b8-b2ee-e49e75a3ac16.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "iP95p4xoKVk53GoZ742B",
      name: "Chris",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "casual",
        age: "middle-aged",
        gender: "male",
        "use case": "conversational",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/iP95p4xoKVk53GoZ742B/c1bda571-7123-418e-a796-a2b464b373b4.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_turbo_v2"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "jBpfuIE2acCO8z3wKNLl",
      name: "Gigi",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "childlish",
        age: "young",
        gender: "female",
        "use case": "animation",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/jBpfuIE2acCO8z3wKNLl/3a7e4339-78fa-404e-8d10-c3ef5587935b.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "jsCqWAovK2LkecY7zXl4",
      name: "Freya",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        age: "young",
        gender: "female",
        "description ": "overhyped",
        usecase: "video games",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/jsCqWAovK2LkecY7zXl4/8e1f5240-556e-4fd5-892c-25df9ea3b593.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "nPczCjzI2devNBz1zQrb",
      name: "Brian",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "deep",
        age: "middle-aged",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/nPczCjzI2devNBz1zQrb/f4dbda0c-aff0-45c0-93fa-f5d5ec95a2eb.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_turbo_v2"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "oWAxZDx7w5VEj9dCyTzz",
      name: "Grace",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american-southern",
        age: "young",
        gender: "female",
        "use case": "audiobook ",
        "description ": "gentle",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/oWAxZDx7w5VEj9dCyTzz/84a36d1c-e182-41a8-8c55-dbdd15cd6e72.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "onwK4e9ZLuTAKqWW03F9",
      name: "Daniel",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "british",
        description: "authoritative",
        age: "middle-aged",
        gender: "male",
        "use case": "news",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [
        "eleven_multilingual_v1",
        "eleven_turbo_v2",
      ],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "pFZP5JQG7iQjIQuC4Bku",
      name: "Lily",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "british",
        description: "raspy",
        age: "middle-aged",
        gender: "female",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/pFZP5JQG7iQjIQuC4Bku/0ab8bd74-fcd2-489d-b70a-3e1bcde8c999.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_turbo_v2"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "pMsXgVXv3BLzUgSXRplE",
      name: "Serena",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "pleasant",
        age: "middle aged",
        gender: "female",
        "use case": "interactive",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/pMsXgVXv3BLzUgSXRplE/d61f18ed-e5b0-4d0b-a33c-5c6e7e33b053.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "pNInz6obpgDQGcFmaJgB",
      name: "Adam",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "deep",
        age: "middle aged",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/38a69695-2ca9-4b9e-b9ec-f07ced494a58.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "piTKgcLEGmPE4e6mEKli",
      name: "Nicole",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "whisper",
        age: "young",
        gender: "female",
        "use case": "audiobook",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/piTKgcLEGmPE4e6mEKli/c269a54a-e2bc-44d0-bb46-4ed2666d6340.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "pqHfZKP75CvOlQylNhV4",
      name: "Bill",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: true,
        finetuning_state: "fine_tuned",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: "en",
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "strong",
        age: "old",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/pqHfZKP75CvOlQylNhV4/52f0842a-cf81-4715-8cf0-76cfbd77088e.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_turbo_v2"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "t0jbNlBVZ17f02VDIeMI",
      name: "Jessie",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "raspy ",
        age: "old",
        gender: "male",
        "use case": "video games",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/t0jbNlBVZ17f02VDIeMI/e26939e3-61a4-4872-a41d-33922cfbdcdc.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "yoZ06aMxZJJ28mfd3POQ",
      name: "Sam",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "raspy",
        age: "young",
        gender: "male",
        "use case": "narration",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/yoZ06aMxZJJ28mfd3POQ/ac9d1c91-92ce-4b20-8cc2-3187a7da49ec.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: [],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "z9fAnlkpzviPz146aGWa",
      name: "Glinda",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "american",
        description: "witch",
        age: "middle aged",
        gender: "female",
        "use case": "video games",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/z9fAnlkpzviPz146aGWa/cbc60443-7b61-4ebb-b8e1-5c03237ea01d.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "zcAOhNBS3c14rBihAFp1",
      name: "Giovanni",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "english-italian",
        description: "foreigner",
        age: "young",
        gender: "male",
        "use case": "audiobook",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/zcAOhNBS3c14rBihAFp1/e7410f8f-4913-4cb8-8907-784abee5aff8.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
    {
      voice_id: "zrHiDhphv9ZnVXBqCLjz",
      name: "Mimi",
      samples: null,
      category: "premade",
      fine_tuning: {
        is_allowed_to_fine_tune: false,
        finetuning_state: "not_started",
        verification_failures: [],
        verification_attempts_count: 0,
        manual_verification_requested: false,
        language: null,
        finetuning_progress: {},
        message: null,
        dataset_duration_seconds: null,
        verification_attempts: null,
        slice_ids: null,
        manual_verification: null,
      },
      labels: {
        accent: "english-swedish",
        description: "childish",
        age: "young",
        gender: "female",
        "use case": "animation",
      },
      description: null,
      preview_url:
        "https://storage.googleapis.com/eleven-public-prod/premade/voices/zrHiDhphv9ZnVXBqCLjz/decbf20b-0f57-4fac-985b-a4f0290ebfc4.mp3",
      available_for_tiers: [],
      settings: null,
      sharing: null,
      high_quality_base_model_ids: ["eleven_multilingual_v1"],
      safety_control: null,
      voice_verification: {
        requires_verification: false,
        is_verified: false,
        verification_failures: [],
        verification_attempts_count: 0,
        language: null,
        verification_attempts: null,
      },
      owner_id: null,
      permission_on_resource: null,
    },
  ];
const models = [
  {
    model_id: "eleven_multilingual_v2",
    name: "Eleven Multilingual v2",
    can_be_finetuned: true,
    can_do_text_to_speech: true,
    can_do_voice_conversion: false,
    can_use_style: true,
    can_use_speaker_boost: true,
    serves_pro_voices: false,
    token_cost_factor: 1.0,
    description:
      "Our state of the art multilingual speech synthesis model, able to generate life-like speech in 29 languages.",
    requires_alpha_access: false,
    max_characters_request_free_user: 10000,
    max_characters_request_subscribed_user: 10000,
    maximum_text_length_per_request: 10000,
    languages: [
      { language_id: "en", name: "English" },
      { language_id: "ja", name: "Japanese" },
      { language_id: "zh", name: "Chinese" },
      { language_id: "de", name: "German" },
      { language_id: "hi", name: "Hindi" },
      { language_id: "fr", name: "French" },
      { language_id: "ko", name: "Korean" },
      { language_id: "pt", name: "Portuguese" },
      { language_id: "it", name: "Italian" },
      { language_id: "es", name: "Spanish" },
      { language_id: "id", name: "Indonesian" },
      { language_id: "nl", name: "Dutch" },
      { language_id: "tr", name: "Turkish" },
      { language_id: "fil", name: "Filipino" },
      { language_id: "pl", name: "Polish" },
      { language_id: "sv", name: "Swedish" },
      { language_id: "bg", name: "Bulgarian" },
      { language_id: "ro", name: "Romanian" },
      { language_id: "ar", name: "Arabic" },
      { language_id: "cs", name: "Czech" },
      { language_id: "el", name: "Greek" },
      { language_id: "fi", name: "Finnish" },
      { language_id: "hr", name: "Croatian" },
      { language_id: "ms", name: "Malay" },
      { language_id: "sk", name: "Slovak" },
      { language_id: "da", name: "Danish" },
      { language_id: "ta", name: "Tamil" },
      { language_id: "uk", name: "Ukrainian" },
      { language_id: "ru", name: "Russian" },
    ],
  },
  {
    model_id: "eleven_multilingual_v1",
    name: "Eleven Multilingual v1",
    can_be_finetuned: true,
    can_do_text_to_speech: true,
    can_do_voice_conversion: false,
    can_use_style: false,
    can_use_speaker_boost: false,
    serves_pro_voices: false,
    token_cost_factor: 1.0,
    description:
      "Generate lifelike speech in multiple languages and create content that resonates with a broader audience.",
    requires_alpha_access: false,
    max_characters_request_free_user: 10000,
    max_characters_request_subscribed_user: 10000,
    maximum_text_length_per_request: 10000,
    languages: [
      { language_id: "en", name: "English" },
      { language_id: "de", name: "German" },
      { language_id: "pl", name: "Polish" },
      { language_id: "es", name: "Spanish" },
      { language_id: "it", name: "Italian" },
      { language_id: "fr", name: "French" },
      { language_id: "pt", name: "Portuguese" },
      { language_id: "hi", name: "Hindi" },
      { language_id: "ar", name: "Arabic" },
    ],
  },
  {
    model_id: "eleven_monolingual_v1",
    name: "Eleven English v1",
    can_be_finetuned: true,
    can_do_text_to_speech: true,
    can_do_voice_conversion: false,
    can_use_style: false,
    can_use_speaker_boost: false,
    serves_pro_voices: false,
    token_cost_factor: 1.0,
    description:
      "Use our standard English language model to generate speech in a variety of voices, styles and moods.",
    requires_alpha_access: false,
    max_characters_request_free_user: 10000,
    max_characters_request_subscribed_user: 10000,
    maximum_text_length_per_request: 10000,
    languages: [{ language_id: "en", name: "English" }],
  },
  {
    model_id: "eleven_multilingual_sts_v2",
    name: "Eleven Multilingual v2",
    can_be_finetuned: true,
    can_do_text_to_speech: false,
    can_do_voice_conversion: true,
    can_use_style: true,
    can_use_speaker_boost: true,
    serves_pro_voices: false,
    token_cost_factor: 1.0,
    description:
      "Our cutting-edge, multilingual speech-to-speech model is designed for situations that demand unparalleled control over both the content and the prosody of the generated speech across various languages.",
    requires_alpha_access: false,
    max_characters_request_free_user: 10000,
    max_characters_request_subscribed_user: 10000,
    maximum_text_length_per_request: 10000,
    languages: [
      { language_id: "en", name: "English" },
      { language_id: "ja", name: "Japanese" },
      { language_id: "zh", name: "Chinese" },
      { language_id: "de", name: "German" },
      { language_id: "hi", name: "Hindi" },
      { language_id: "fr", name: "French" },
      { language_id: "ko", name: "Korean" },
      { language_id: "pt", name: "Portuguese" },
      { language_id: "it", name: "Italian" },
      { language_id: "es", name: "Spanish" },
      { language_id: "ru", name: "Russian" },
      { language_id: "id", name: "Indonesian" },
      { language_id: "nl", name: "Dutch" },
      { language_id: "tr", name: "Turkish" },
      { language_id: "fil", name: "Filipino" },
      { language_id: "pl", name: "Polish" },
      { language_id: "sv", name: "Swedish" },
      { language_id: "bg", name: "Bulgarian" },
      { language_id: "ro", name: "Romanian" },
      { language_id: "ar", name: "Arabic" },
      { language_id: "cs", name: "Czech" },
      { language_id: "el", name: "Greek" },
      { language_id: "fi", name: "Finnish" },
      { language_id: "hr", name: "Croatian" },
      { language_id: "ms", name: "Malay" },
      { language_id: "sk", name: "Slovak" },
      { language_id: "da", name: "Danish" },
      { language_id: "ta", name: "Tamil" },
      { language_id: "uk", name: "Ukrainian" },
    ],
  },
  {
    model_id: "eleven_english_sts_v2",
    name: "Eleven English v2",
    can_be_finetuned: true,
    can_do_text_to_speech: false,
    can_do_voice_conversion: true,
    can_use_style: true,
    can_use_speaker_boost: true,
    serves_pro_voices: false,
    token_cost_factor: 1.0,
    description:
      "Our state-of-the-art speech to speech model suitable for scenarios where you need maximum control over the content and prosody of your generations.",
    requires_alpha_access: false,
    max_characters_request_free_user: 5000,
    max_characters_request_subscribed_user: 5000,
    maximum_text_length_per_request: 5000,
    languages: [{ language_id: "en", name: "English" }],
  },
  {
    model_id: "eleven_turbo_v2",
    name: "Eleven Turbo v2",
    can_be_finetuned: false,
    can_do_text_to_speech: true,
    can_do_voice_conversion: false,
    can_use_style: false,
    can_use_speaker_boost: false,
    serves_pro_voices: false,
    token_cost_factor: 1.0,
    description:
      "Our cutting-edge turbo model is ideally suited for tasks demanding extremely low latency.",
    requires_alpha_access: false,
    max_characters_request_free_user: 30000,
    max_characters_request_subscribed_user: 30000,
    maximum_text_length_per_request: 30000,
    languages: [{ language_id: "en", name: "English" }],
  },
];

const title = "Eleven Labs";
const specs = {
  openapi: "3.1.0",
  info: {
    title: "ElevenLabs API Documentation",
    description:
      "This is the documentation for the ElevenLabs API. You can use this API to use our service programmatically, this is done by using your xi-api-key. <br/> You can view your xi-api-key using the 'Profile' tab on https://elevenlabs.io. Our API is experimental so all endpoints are subject to change.",
    version: "1.0",
  },
  paths: {
    "/v1/history": {
      get: {
        tags: ["speech-history"],
        summary: "Get Generated Items",
        description: "Returns metadata about all your generated audio.",
        operationId: "Get_generated_items_v1_history_get",
        parameters: [
          {
            description:
              "How many history items to return at maximum. Can not exceed 1000, defaults to 100.",
            required: false,
            schema: {
              type: "integer",
              title: "Page Size",
              description:
                "How many history items to return at maximum. Can not exceed 1000, defaults to 100.",
              default: 100,
            },
            name: "page_size",
            in: "query",
          },
          {
            description:
              "After which ID to start fetching, use this parameter to paginate across a large collection of history items. In case this parameter is not provided history items will be fetched starting from the most recently created one ordered descending by their creation date.",
            required: false,
            schema: {
              type: "string",
              title: "Start After History Item Id",
              description:
                "After which ID to start fetching, use this parameter to paginate across a large collection of history items. In case this parameter is not provided history items will be fetched starting from the most recently created one ordered descending by their creation date.",
            },
            name: "start_after_history_item_id",
            in: "query",
          },
          {
            description:
              "Voice ID to be filtered for, you can use GET https://api.elevenlabs.io/v1/voices to receive a list of voices and their IDs.",
            required: false,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be filtered for, you can use GET https://api.elevenlabs.io/v1/voices to receive a list of voices and their IDs.",
            },
            name: "voice_id",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GetSpeechHistoryResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/history/{history_item_id}": {
      get: {
        tags: ["speech-history"],
        summary: "Get History Item By Id",
        description: "Returns information about an history item by its ID.",
        operationId: "Get_history_item_by_ID_v1_history__history_item_id__get",
        parameters: [
          {
            description:
              "History item ID to be used, you can use GET https://api.elevenlabs.io/v1/history to receive a list of history items and their IDs.",
            required: true,
            schema: {
              type: "string",
              title: "History Item Id",
              description:
                "History item ID to be used, you can use GET https://api.elevenlabs.io/v1/history to receive a list of history items and their IDs.",
            },
            example: "VW7YKqPnjY4h39yTbx2L",
            name: "history_item_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/SpeechHistoryItemResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
      delete: {
        tags: ["speech-history"],
        summary: "Delete History Item",
        description: "Delete a history item by its ID",
        operationId: "Delete_history_item_v1_history__history_item_id__delete",
        parameters: [
          {
            description:
              "History item ID to be used, you can use GET https://api.elevenlabs.io/v1/history to receive a list of history items and their IDs.",
            required: true,
            schema: {
              type: "string",
              title: "History Item Id",
              description:
                "History item ID to be used, you can use GET https://api.elevenlabs.io/v1/history to receive a list of history items and their IDs.",
            },
            example: "VW7YKqPnjY4h39yTbx2L",
            name: "history_item_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/history/{history_item_id}/audio": {
      get: {
        tags: ["speech-history"],
        summary: "Get Audio From History Item",
        description: "Returns the audio of an history item.",
        operationId:
          "Get_audio_from_history_item_v1_history__history_item_id__audio_get",
        parameters: [
          {
            description:
              "History item ID to be used, you can use GET https://api.elevenlabs.io/v1/history to receive a list of history items and their IDs.",
            required: true,
            schema: {
              type: "string",
              title: "History Item Id",
              description:
                "History item ID to be used, you can use GET https://api.elevenlabs.io/v1/history to receive a list of history items and their IDs.",
            },
            example: "VW7YKqPnjY4h39yTbx2L",
            name: "history_item_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "audio/mpeg": {} },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/history/download": {
      post: {
        tags: ["speech-history"],
        summary: "Download History Items",
        description:
          "Download one or more history items. If one history item ID is provided, we will return a single audio file. If more than one history item IDs are provided, we will provide the history items packed into a .zip file.",
        operationId: "Download_history_items_v1_history_download_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Download_history_items_v1_history_download_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": { description: "Successful Response" },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices/{voice_id}/samples/{sample_id}": {
      delete: {
        tags: ["samples"],
        summary: "Delete Sample",
        description: "Removes a sample by its ID.",
        operationId:
          "Delete_sample_v1_voices__voice_id__samples__sample_id__delete",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "Sample ID to be used, you can use GET https://api.elevenlabs.io/v1/voices/{voice_id} to list all the available samples for a voice.",
            required: true,
            schema: {
              type: "string",
              title: "Sample Id",
              description:
                "Sample ID to be used, you can use GET https://api.elevenlabs.io/v1/voices/{voice_id} to list all the available samples for a voice.",
            },
            example: "VW7YKqPnjY4h39yTbx2L",
            name: "sample_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices/{voice_id}/samples/{sample_id}/audio": {
      get: {
        tags: ["samples"],
        summary: "Get Audio From Sample",
        description:
          "Returns the audio corresponding to a sample attached to a voice.",
        operationId:
          "Get_audio_from_sample_v1_voices__voice_id__samples__sample_id__audio_get",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "Sample ID to be used, you can use GET https://api.elevenlabs.io/v1/voices/{voice_id} to list all the available samples for a voice.",
            required: true,
            schema: {
              type: "string",
              title: "Sample Id",
              description:
                "Sample ID to be used, you can use GET https://api.elevenlabs.io/v1/voices/{voice_id} to list all the available samples for a voice.",
            },
            example: "VW7YKqPnjY4h39yTbx2L",
            name: "sample_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "audio/*": {} },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/text-to-speech/{voice_id}": {
      post: {
        tags: ["text-to-speech"],
        summary: "Text To Speech",
        description:
          "Converts text into speech using a voice of your choice and returns audio.",
        operationId: "Text_to_speech_v1_text_to_speech__voice_id__post",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
            required: false,
            schema: {
              type: "boolean",
              title: "Enable request logging.",
              description:
                "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
              default: true,
            },
            name: "enable_logging",
            in: "query",
          },
          {
            description:
              "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
            required: false,
            schema: {
              type: "integer",
              title:
                "How much we should optimize streaming request latency (time to first audio byte).",
              description:
                "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
              default: 0,
            },
            name: "optimize_streaming_latency",
            in: "query",
          },
          {
            description:
              "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
            required: false,
            schema: {
              type: "string",
              title: "Output format of the generated audio.",
              description:
                "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
              default: "mp3_44100_128",
            },
            name: "output_format",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Text_to_speech_v1_text_to_speech__voice_id__post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "audio/mpeg": {} },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/text-to-speech/{voice_id}/with-timestamps": {
      post: {
        tags: ["text-to-speech"],
        summary: "Text To Speech With Timestamps",
        description:
          "Converts text into speech using a voice of your choice and returns JSON containing audio as a base64 encoded string together with information on when which character was spoken.",
        operationId:
          "Text_to_speech_with_timestamps_v1_text_to_speech__voice_id__with_timestamps_post",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
            required: false,
            schema: {
              type: "boolean",
              title: "Enable request logging.",
              description:
                "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
              default: true,
            },
            name: "enable_logging",
            in: "query",
          },
          {
            description:
              "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
            required: false,
            schema: {
              type: "integer",
              title:
                "How much we should optimize streaming request latency (time to first audio byte).",
              description:
                "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
              default: 0,
            },
            name: "optimize_streaming_latency",
            in: "query",
          },
          {
            description:
              "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
            required: false,
            schema: {
              type: "string",
              title: "Output format of the generated audio.",
              description:
                "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
              default: "mp3_44100_128",
            },
            name: "output_format",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Text_to_speech_with_timestamps_v1_text_to_speech__voice_id__with_timestamps_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": {} },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/text-to-speech/{voice_id}/stream": {
      post: {
        tags: ["text-to-speech"],
        summary: "Text To Speech Streaming",
        description:
          "Converts text into speech using a voice of your choice and returns audio as an audio stream.",
        operationId:
          "Text_to_speech_streaming_v1_text_to_speech__voice_id__stream_post",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
            required: false,
            schema: {
              type: "boolean",
              title: "Enable request logging.",
              description:
                "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
              default: true,
            },
            name: "enable_logging",
            in: "query",
          },
          {
            description:
              "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
            required: false,
            schema: {
              type: "integer",
              title:
                "How much we should optimize streaming request latency (time to first audio byte).",
              description:
                "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
              default: 0,
            },
            name: "optimize_streaming_latency",
            in: "query",
          },
          {
            description:
              "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
            required: false,
            schema: {
              type: "string",
              title: "Output format of the generated audio.",
              description:
                "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
              default: "mp3_44100_128",
            },
            name: "output_format",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Text_to_speech_streaming_v1_text_to_speech__voice_id__stream_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": { description: "Successful Response" },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/text-to-speech/{voice_id}/stream/with-timestamps": {
      post: {
        tags: ["text-to-speech"],
        summary: "Text To Speech Streaming With Timestamps",
        description:
          "Converts text into speech using a voice of your choice and returns a stream of JSONs containing audio as a base64 encoded string together with information on when which character was spoken.",
        operationId:
          "Text_to_speech_streaming_with_timestamps_v1_text_to_speech__voice_id__stream_with_timestamps_post",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
            required: false,
            schema: {
              type: "boolean",
              title: "Enable request logging.",
              description:
                "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
              default: true,
            },
            name: "enable_logging",
            in: "query",
          },
          {
            description:
              "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
            required: false,
            schema: {
              type: "integer",
              title:
                "How much we should optimize streaming request latency (time to first audio byte).",
              description:
                "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
              default: 0,
            },
            name: "optimize_streaming_latency",
            in: "query",
          },
          {
            description:
              "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
            required: false,
            schema: {
              type: "string",
              title: "Output format of the generated audio.",
              description:
                "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
              default: "mp3_44100_128",
            },
            name: "output_format",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Text_to_speech_streaming_with_timestamps_v1_text_to_speech__voice_id__stream_with_timestamps_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": { description: "Successful Response" },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/speech-to-speech/{voice_id}": {
      post: {
        tags: ["speech-to-speech"],
        summary: "Speech To Speech",
        description:
          "Create speech by combining the content and emotion of the uploaded audio with a voice of your choice.",
        operationId: "Speech_to_Speech_v1_speech_to_speech__voice_id__post",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
            required: false,
            schema: {
              type: "boolean",
              title: "Enable request logging.",
              description:
                "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
              default: true,
            },
            name: "enable_logging",
            in: "query",
          },
          {
            description:
              "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
            required: false,
            schema: {
              type: "integer",
              title:
                "How much we should optimize streaming request latency (time to first audio byte).",
              description:
                "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
              default: 0,
            },
            name: "optimize_streaming_latency",
            in: "query",
          },
          {
            description:
              "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
            required: false,
            schema: {
              type: "string",
              title: "Output format of the generated audio.",
              description:
                "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
              default: "mp3_44100_128",
            },
            name: "output_format",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/Body_Speech_to_Speech_v1_speech_to_speech__voice_id__post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "audio/mpeg": {} },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/speech-to-speech/{voice_id}/stream": {
      post: {
        tags: ["speech-to-speech"],
        summary: "Speech To Speech Streaming",
        description:
          "Create speech by combining the content and emotion of the uploaded audio with a voice of your choice and returns an audio stream.",
        operationId:
          "Speech_to_Speech_Streaming_v1_speech_to_speech__voice_id__stream_post",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
            required: false,
            schema: {
              type: "boolean",
              title: "Enable request logging.",
              description:
                "When enable_logging is set to false full privacy mode will be used for the request. This will mean history features are unavailable for this request, including request stitching. Full privacy mode may only be used by enterprise customers.",
              default: true,
            },
            name: "enable_logging",
            in: "query",
          },
          {
            description:
              "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
            required: false,
            schema: {
              type: "integer",
              title:
                "How much we should optimize streaming request latency (time to first audio byte).",
              description:
                "You can turn on latency optimizations at some cost of quality. The best possible final latency varies by model. Possible values:\n0 - default mode (no latency optimizations)\n1 - normal latency optimizations (about 50% of possible latency improvement of option 3)\n2 - strong latency optimizations (about 75% of possible latency improvement of option 3)\n3 - max latency optimizations\n4 - max latency optimizations, but also with text normalizer turned off for even more latency savings (best latency, but can mispronounce eg numbers and dates).\n\nDefaults to 0.\n",
              default: 0,
            },
            name: "optimize_streaming_latency",
            in: "query",
          },
          {
            description:
              "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
            required: false,
            schema: {
              type: "string",
              title: "Output format of the generated audio.",
              description:
                "Output format of the generated audio. Must be one of:\nmp3_22050_32 - output format, mp3 with 22.05kHz sample rate at 32kbps.\nmp3_44100_32 - output format, mp3 with 44.1kHz sample rate at 32kbps.\nmp3_44100_64 - output format, mp3 with 44.1kHz sample rate at 64kbps.\nmp3_44100_96 - output format, mp3 with 44.1kHz sample rate at 96kbps.\nmp3_44100_128 - default output format, mp3 with 44.1kHz sample rate at 128kbps.\nmp3_44100_192 - output format, mp3 with 44.1kHz sample rate at 192kbps. Requires you to be subscribed to Creator tier or above.\npcm_16000 - PCM format (S16LE) with 16kHz sample rate.\npcm_22050 - PCM format (S16LE) with 22.05kHz sample rate.\npcm_24000 - PCM format (S16LE) with 24kHz sample rate.\npcm_44100 - PCM format (S16LE) with 44.1kHz sample rate. Requires you to be subscribed to Pro tier or above.\nulaw_8000 - μ-law format (sometimes written mu-law, often approximated as u-law) with 8kHz sample rate. Note that this format is commonly used for Twilio audio inputs.\n",
              default: "mp3_44100_128",
            },
            name: "output_format",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/Body_Speech_to_Speech_Streaming_v1_speech_to_speech__voice_id__stream_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": { description: "Successful Response" },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voice-generation/generate-voice/parameters": {
      get: {
        tags: ["voice-generation"],
        summary: "Voice Generation Parameters",
        description:
          "Get possible parameters for the /v1/voice-generation/generate-voice endpoint.",
        operationId:
          "Voice_Generation_Parameters_v1_voice_generation_generate_voice_parameters_get",
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/VoiceGenerationParameterResponseModel",
                },
              },
            },
          },
        },
      },
    },
    "/v1/voice-generation/generate-voice": {
      post: {
        tags: ["voice-generation"],
        summary: "Generate A Random Voice",
        description:
          "Generate a random voice based on parameters. This method returns a generated_voice_id in the response header, and a sample of the voice in the body. If you like the generated voice call /v1/voice-generation/create-voice with the generated_voice_id to create the voice.",
        operationId:
          "Generate_a_random_voice_v1_voice_generation_generate_voice_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Generate_a_random_voice_v1_voice_generation_generate_voice_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "audio/mpeg": {} },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voice-generation/create-voice": {
      post: {
        tags: ["voice-generation"],
        summary: "Create A Previously Generated Voice",
        description:
          "Create a previously generated voice. This endpoint should be called after you fetched a generated_voice_id using /v1/voice-generation/generate-voice.",
        operationId:
          "Create_a_previously_generated_voice_v1_voice_generation_create_voice_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Create_a_previously_generated_voice_v1_voice_generation_create_voice_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/VoiceResponseModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/user/subscription": {
      get: {
        tags: ["user"],
        summary: "Get User Subscription Info",
        description: "Gets extended information about the users subscription",
        operationId: "Get_user_subscription_info_v1_user_subscription_get",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ExtendedSubscriptionResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/user": {
      get: {
        tags: ["user"],
        summary: "Get User Info",
        description: "Gets information about the user",
        operationId: "Get_user_info_v1_user_get",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/UserResponseModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices": {
      get: {
        tags: ["voices"],
        summary: "Get Voices",
        description: "Gets a list of all available voices for a user.",
        operationId: "Get_voices_v1_voices_get",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/GetVoicesResponseModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices/settings/default": {
      get: {
        tags: ["voices"],
        summary: "Get Default Voice Settings.",
        description:
          'Gets the default settings for voices. "similarity_boost" corresponds to"Clarity + Similarity Enhancement" in the web app and "stability" corresponds to "Stability" slider in the web app.',
        operationId:
          "Get_default_voice_settings__v1_voices_settings_default_get",
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/VoiceSettingsResponseModel",
                },
              },
            },
          },
        },
      },
    },
    "/v1/voices/{voice_id}/settings": {
      get: {
        tags: ["voices"],
        summary: "Get Voice Settings",
        description:
          'Returns the settings for a specific voice. "similarity_boost" corresponds to"Clarity + Similarity Enhancement" in the web app and "stability" corresponds to "Stability" slider in the web app.',
        operationId: "Get_voice_settings_v1_voices__voice_id__settings_get",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/VoiceSettingsResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices/{voice_id}": {
      get: {
        tags: ["voices"],
        summary: "Get Voice",
        description: "Returns metadata about a specific voice.",
        operationId: "Get_voice_v1_voices__voice_id__get",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "If set will return settings information corresponding to the voice, requires authorization.",
            required: false,
            schema: {
              type: "boolean",
              title: "With Settings",
              description:
                "If set will return settings information corresponding to the voice, requires authorization.",
              default: false,
            },
            name: "with_settings",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/VoiceResponseModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
      delete: {
        tags: ["voices"],
        summary: "Delete Voice",
        description: "Deletes a voice by its ID.",
        operationId: "Delete_voice_v1_voices__voice_id__delete",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices/{voice_id}/settings/edit": {
      post: {
        tags: ["voices"],
        summary: "Edit Voice Settings",
        description:
          'Edit your settings for a specific voice. "similarity_boost" corresponds to"Clarity + Similarity Enhancement" in the web app and "stability" corresponds to "Stability" slider in the web app.',
        operationId:
          "Edit_voice_settings_v1_voices__voice_id__settings_edit_post",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                allOf: [
                  { $ref: "#/components/schemas/VoiceSettingsResponseModel" },
                ],
                title: "Settings",
                description: "The settings for a specific voice.",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices/add": {
      post: {
        tags: ["voices"],
        summary: "Add Voice",
        description:
          "Add a new voice to your collection of voices in VoiceLab.",
        operationId: "Add_voice_v1_voices_add_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/Body_Add_voice_v1_voices_add_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/AddVoiceResponseModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices/{voice_id}/edit": {
      post: {
        tags: ["voices"],
        summary: "Edit Voice",
        description: "Edit a voice created by you.",
        operationId: "Edit_voice_v1_voices__voice_id__edit_post",
        parameters: [
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/Body_Edit_voice_v1_voices__voice_id__edit_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/voices/add/{public_user_id}/{voice_id}": {
      post: {
        tags: ["voices"],
        summary: "Add Sharing Voice",
        description:
          "Add a sharing voice to your collection of voices in VoiceLab.",
        operationId:
          "Add_sharing_voice_v1_voices_add__public_user_id___voice_id__post",
        parameters: [
          {
            description:
              "Public user ID used to publicly identify ElevenLabs users.",
            required: true,
            schema: {
              type: "string",
              title: "Public User Id",
              description:
                "Public user ID used to publicly identify ElevenLabs users.",
            },
            example:
              "63e06b7e7cafdc46be4d2e0b3f045940231ae058d508589653d74d1265a574ca",
            name: "public_user_id",
            in: "path",
          },
          {
            description:
              "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            required: true,
            schema: {
              type: "string",
              title: "Voice Id",
              description:
                "Voice ID to be used, you can use https://api.elevenlabs.io/v1/voices to list all the available voices.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "voice_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Add_sharing_voice_v1_voices_add__public_user_id___voice_id__post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/AddVoiceResponseModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects": {
      get: {
        tags: ["projects"],
        summary: "Get Projects",
        description:
          "Returns a list of your projects together and its metadata.",
        operationId: "Get_projects_v1_projects_get",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GetProjectsResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/add": {
      post: {
        tags: ["projects"],
        summary: "Add Project",
        description:
          "Creates a new project, it can be either initialized as blank, from a document or from a URL.",
        operationId: "Add_project_v1_projects_add_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/Body_Add_project_v1_projects_add_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/AddProjectResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}": {
      get: {
        tags: ["projects"],
        summary: "Get Project By Id",
        description:
          "Returns information about a specific project. This endpoint returns more detailed information about a project than GET api.elevenlabs.io/v1/projects.",
        operationId: "Get_project_by_ID_v1_projects__project_id__get",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ProjectExtendedResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
      post: {
        tags: ["projects"],
        summary: "Edit Basic Project Info",
        description: "Edits basic project info.",
        operationId: "Edit_basic_project_info_v1_projects__project_id__post",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Edit_basic_project_info_v1_projects__project_id__post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/EditProjectResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
      delete: {
        tags: ["projects"],
        summary: "Delete Project",
        description: "Delete a project by its project_id.",
        operationId: "Delete_project_v1_projects__project_id__delete",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/convert": {
      post: {
        tags: ["projects"],
        summary: "Convert Project",
        description: "Starts conversion of a project and all of its chapters.",
        operationId: "Convert_project_v1_projects__project_id__convert_post",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/snapshots": {
      get: {
        tags: ["projects"],
        summary: "Get Project Snapshots",
        description: "Gets the snapshots of a project.",
        operationId:
          "Get_project_snapshots_v1_projects__project_id__snapshots_get",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ProjectSnapshotsResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/snapshots/{project_snapshot_id}/stream": {
      post: {
        tags: ["projects"],
        summary: "Stream Project Audio",
        description: "Stream the audio from a project snapshot.",
        operationId:
          "Stream_project_audio_v1_projects__project_id__snapshots__project_snapshot_id__stream_post",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "The project_snapshot_id of the project snapshot. You can query GET /v1/projects/{project_id}/snapshots to list all available snapshots for a project.",
            required: true,
            schema: {
              type: "string",
              title: "Project Snapshot Id",
              description:
                "The project_snapshot_id of the project snapshot. You can query GET /v1/projects/{project_id}/snapshots to list all available snapshots for a project.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_snapshot_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Stream_project_audio_v1_projects__project_id__snapshots__project_snapshot_id__stream_post",
              },
            },
          },
        },
        responses: {
          "200": { description: "Successful Response" },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/snapshots/{project_snapshot_id}/archive": {
      post: {
        tags: ["projects"],
        summary: "Streams Archive With Project Audio",
        description: "Streams archive with project audio.",
        operationId:
          "Streams_archive_with_project_audio_v1_projects__project_id__snapshots__project_snapshot_id__archive_post",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "The project_snapshot_id of the project snapshot. You can query GET /v1/projects/{project_id}/snapshots to list all available snapshots for a project.",
            required: true,
            schema: {
              type: "string",
              title: "Project Snapshot Id",
              description:
                "The project_snapshot_id of the project snapshot. You can query GET /v1/projects/{project_id}/snapshots to list all available snapshots for a project.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_snapshot_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": { description: "Successful Response" },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/chapters": {
      get: {
        tags: ["projects"],
        summary: "Get Chapters",
        description:
          "Returns a list of your chapters for a project together and its metadata.",
        operationId: "Get_chapters_v1_projects__project_id__chapters_get",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GetChaptersResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/chapters/{chapter_id}": {
      get: {
        tags: ["projects"],
        summary: "Get Chapter By Id",
        description: "Returns information about a specific chapter.",
        operationId:
          "Get_chapter_by_ID_v1_projects__project_id__chapters__chapter_id__get",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
            required: true,
            schema: {
              type: "string",
              title: "Chapter Id",
              description:
                "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "chapter_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/ChapterResponseModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
      delete: {
        tags: ["projects"],
        summary: "Delete Chapter",
        description: "Delete a chapter by its chapter_id.",
        operationId:
          "Delete_chapter_v1_projects__project_id__chapters__chapter_id__delete",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
            required: true,
            schema: {
              type: "string",
              title: "Chapter Id",
              description:
                "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "chapter_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/chapters/{chapter_id}/convert": {
      post: {
        tags: ["projects"],
        summary: "Convert Chapter",
        description: "Starts conversion of a specific chapter.",
        operationId:
          "Convert_chapter_v1_projects__project_id__chapters__chapter_id__convert_post",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
            required: true,
            schema: {
              type: "string",
              title: "Chapter Id",
              description:
                "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "chapter_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/chapters/{chapter_id}/snapshots": {
      get: {
        tags: ["projects"],
        summary: "Get Chapter Snapshots",
        description:
          "Gets information about all the snapshots of a chapter, each snapshot corresponds can be downloaded as audio. Whenever a chapter is converted a snapshot will be automatically created.",
        operationId:
          "Get_chapter_snapshots_v1_projects__project_id__chapters__chapter_id__snapshots_get",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
            required: true,
            schema: {
              type: "string",
              title: "Chapter Id",
              description:
                "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "chapter_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ChapterSnapshotsResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/projects/{project_id}/chapters/{chapter_id}/snapshots/{chapter_snapshot_id}/stream":
      {
        post: {
          tags: ["projects"],
          summary: "Stream Chapter Audio",
          description:
            "Stream the audio from a chapter snapshot. Use `GET /v1/projects/{project_id}/chapters/{chapter_id}/snapshots` to return the chapter snapshots of a chapter.",
          operationId:
            "Stream_chapter_audio_v1_projects__project_id__chapters__chapter_id__snapshots__chapter_snapshot_id__stream_post",
          parameters: [
            {
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
              required: true,
              schema: {
                type: "string",
                title: "Project Id",
                description:
                  "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
              },
              example: "21m00Tcm4TlvDq8ikWAM",
              name: "project_id",
              in: "path",
            },
            {
              description:
                "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
              required: true,
              schema: {
                type: "string",
                title: "Chapter Id",
                description:
                  "The chapter_id of the chapter. You can query GET https://api.elevenlabs.io/v1/projects/{project_id}/chapters to list all available chapters for a project.",
              },
              example: "21m00Tcm4TlvDq8ikWAM",
              name: "chapter_id",
              in: "path",
            },
            {
              description:
                "The chapter_snapshot_id of the chapter snapshot. You can query GET /v1/projects/{project_id}/chapters/{chapter_id}/snapshots to the all available snapshots for a chapter.",
              required: true,
              schema: {
                type: "string",
                title: "Chapter Snapshot Id",
                description:
                  "The chapter_snapshot_id of the chapter snapshot. You can query GET /v1/projects/{project_id}/chapters/{chapter_id}/snapshots to the all available snapshots for a chapter.",
              },
              example: "21m00Tcm4TlvDq8ikWAM",
              name: "chapter_snapshot_id",
              in: "path",
            },
            {
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
              required: false,
              schema: {
                type: "string",
                title: "Xi-Api-Key",
                description:
                  "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
              },
              name: "xi-api-key",
              in: "header",
            },
          ],
          requestBody: {
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Body_Stream_chapter_audio_v1_projects__project_id__chapters__chapter_id__snapshots__chapter_snapshot_id__stream_post",
                },
              },
            },
          },
          responses: {
            "200": { description: "Successful Response" },
            "422": {
              description: "Validation Error",
              content: {
                "application/json": {
                  schema: { $ref: "#/components/schemas/HTTPValidationError" },
                },
              },
            },
          },
        },
      },
    "/v1/projects/{project_id}/update-pronunciation-dictionaries": {
      post: {
        tags: ["projects"],
        summary: "Update Pronunciation Dictionaries",
        description:
          "Updates the set of pronunciation dictionaries acting on a project. This will automatically mark text within this project as requiring reconverting where the new dictionary would apply or the old one no longer does.",
        operationId:
          "Update_Pronunciation_Dictionaries_v1_projects__project_id__update_pronunciation_dictionaries_post",
        parameters: [
          {
            description:
              "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            required: true,
            schema: {
              type: "string",
              title: "Project Id",
              description:
                "The project_id of the project, you can query GET https://api.elevenlabs.io/v1/projects to list all available projects.",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "project_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Update_Pronunciation_Dictionaries_v1_projects__project_id__update_pronunciation_dictionaries_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/dubbing": {
      post: {
        tags: ["dubbing"],
        summary: "Dub A Video Or An Audio File",
        description: "Dubs provided audio or video file into given language.",
        operationId: "Dub_a_video_or_an_audio_file_v1_dubbing_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/Body_Dub_a_video_or_an_audio_file_v1_dubbing_post",
              },
            },
          },
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/DoDubbingResponseModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/dubbing/{dubbing_id}": {
      get: {
        tags: ["dubbing"],
        summary: "Get Dubbing Project Metadata",
        description:
          "Returns metadata about a dubbing project, including whether it's still in progress or not",
        operationId: "Get_dubbing_project_metadata_v1_dubbing__dubbing_id__get",
        parameters: [
          {
            description: "ID of the dubbing project.",
            required: true,
            schema: {
              type: "string",
              title: "Dubbing Id",
              description: "ID of the dubbing project.",
            },
            name: "dubbing_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/DubbingMetadataResponse",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
      delete: {
        tags: ["dubbing"],
        summary: "Delete Dubbing Project",
        description: "Deletes a dubbing project.",
        operationId: "Delete_dubbing_project_v1_dubbing__dubbing_id__delete",
        parameters: [
          {
            description: "ID of the dubbing project.",
            required: true,
            schema: {
              type: "string",
              title: "Dubbing Id",
              description: "ID of the dubbing project.",
            },
            name: "dubbing_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/dubbing/{dubbing_id}/audio/{language_code}": {
      get: {
        tags: ["dubbing"],
        summary: "Get Dubbed File",
        description:
          "Returns dubbed file as a streamed file. Videos will be returned in MP4 format and audio only dubs will be returned in MP3.",
        operationId:
          "Get_dubbed_file_v1_dubbing__dubbing_id__audio__language_code__get",
        parameters: [
          {
            description: "ID of the dubbing project.",
            required: true,
            schema: {
              type: "string",
              title: "Dubbing Id",
              description: "ID of the dubbing project.",
            },
            name: "dubbing_id",
            in: "path",
          },
          {
            description: "ID of the language.",
            required: true,
            schema: {
              type: "string",
              title: "Language Code",
              description: "ID of the language.",
            },
            name: "language_code",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/octet-stream": {} },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/dubbing/{dubbing_id}/transcript/{language_code}": {
      get: {
        tags: ["dubbing"],
        summary: "Get Transcript For Dub",
        description: "Returns transcript for the dub as an SRT file.",
        operationId:
          "Get_transcript_for_dub_v1_dubbing__dubbing_id__transcript__language_code__get",
        parameters: [
          {
            description: "ID of the dubbing project.",
            required: true,
            schema: {
              type: "string",
              title: "Dubbing Id",
              description: "ID of the dubbing project.",
            },
            name: "dubbing_id",
            in: "path",
          },
          {
            description: "ID of the language.",
            required: true,
            schema: {
              type: "string",
              title: "Language Code",
              description: "ID of the language.",
            },
            name: "language_code",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": { schema: {} },
              "application/text": {},
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/admin/{admin_url_prefix}/sso-provider": {
      get: {
        tags: ["workspace"],
        summary: "Get Sso Provider Admin",
        operationId:
          "get_sso_provider_admin_admin__admin_url_prefix__sso_provider_get",
        parameters: [
          {
            required: true,
            schema: { type: "string", title: "Workspace Id" },
            name: "workspace_id",
            in: "query",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/SsoProviderDBModel" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/models": {
      get: {
        tags: ["models"],
        summary: "Get Models",
        description: "Gets a list of available models.",
        operationId: "Get_Models_v1_models_get",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  items: { $ref: "#/components/schemas/ModelResponseModel" },
                  type: "array",
                  title: "Response Get Models V1 Models Get",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/audio-native": {
      post: {
        tags: ["audio-native"],
        summary: "Creates Audionative Enabled Project.",
        description:
          "Creates AudioNative enabled project, optionally starts conversion and returns project id and embeddable html snippet.",
        operationId:
          "Creates_AudioNative_enabled_project__v1_audio_native_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/Body_Creates_AudioNative_enabled_project__v1_audio_native_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/AudioNativeCreateProjectResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/shared-voices": {
      get: {
        tags: ["voices"],
        summary: "Get Voices",
        description: "Gets a list of shared voices.",
        operationId: "Get_voices_v1_shared_voices_get",
        parameters: [
          {
            description:
              "How many shared voices to return at maximum. Can not exceed 100, defaults to 30.",
            required: false,
            schema: {
              type: "integer",
              title: "Page Size",
              description:
                "How many shared voices to return at maximum. Can not exceed 100, defaults to 30.",
              default: 30,
            },
            name: "page_size",
            in: "query",
          },
          {
            description: "voice category used for filtering",
            required: false,
            schema: {
              type: "string",
              title: "Category",
              description: "voice category used for filtering",
            },
            example: "professional",
            name: "category",
            in: "query",
          },
          {
            description: "gender used for filtering",
            required: false,
            schema: {
              type: "string",
              title: "Gender",
              description: "gender used for filtering",
            },
            example: "male",
            name: "gender",
            in: "query",
          },
          {
            description: "age used for filtering",
            required: false,
            schema: {
              type: "string",
              title: "Age",
              description: "age used for filtering",
            },
            example: "young",
            name: "age",
            in: "query",
          },
          {
            description: "accent used for filtering",
            required: false,
            schema: {
              type: "string",
              title: "Accent",
              description: "accent used for filtering",
            },
            example: "american",
            name: "accent",
            in: "query",
          },
          {
            description: "language used for filtering",
            required: false,
            schema: {
              type: "string",
              title: "Language",
              description: "language used for filtering",
            },
            example: "en",
            name: "language",
            in: "query",
          },
          {
            description: "search term used for filtering",
            required: false,
            schema: {
              type: "string",
              title: "Search",
              description: "search term used for filtering",
            },
            example: "tiktok",
            name: "search",
            in: "query",
          },
          {
            description: "use-case used for filtering",
            required: false,
            schema: {
              items: { type: "string" },
              type: "array",
              title: "Use Cases",
              description: "use-case used for filtering",
            },
            example: "audiobook",
            name: "use_cases",
            in: "query",
          },
          {
            description: "search term used for filtering",
            required: false,
            schema: {
              items: { type: "string" },
              type: "array",
              title: "Descriptives",
              description: "search term used for filtering",
            },
            example: "tiktok",
            name: "descriptives",
            in: "query",
          },
          {
            description: "Filter featured voices",
            required: false,
            schema: {
              type: "boolean",
              title: "Featured",
              description: "Filter featured voices",
              default: false,
            },
            example: true,
            name: "featured",
            in: "query",
          },
          {
            description: "Filter voices that are enabled for the reader app",
            required: false,
            schema: {
              type: "boolean",
              title: "Reader App Enabled",
              description: "Filter voices that are enabled for the reader app",
              default: false,
            },
            example: true,
            name: "reader_app_enabled",
            in: "query",
          },
          {
            description: "Filter voices by public owner ID",
            required: false,
            schema: {
              type: "string",
              title: "Owner Id",
              description: "Filter voices by public owner ID",
            },
            example:
              "7c9fab611d9a0e1fb2e7448a0c294a8804efc2bcc324b0a366a5d5232b7d1532",
            name: "owner_id",
            in: "query",
          },
          {
            description: "sort criteria",
            required: false,
            schema: {
              type: "string",
              title: "Sort",
              description: "sort criteria",
            },
            example: "created_date",
            name: "sort",
            in: "query",
          },
          {
            required: false,
            schema: { type: "integer", title: "Page", default: 0 },
            name: "page",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GetLibraryVoicesResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/pronunciation-dictionaries/add-from-file": {
      post: {
        tags: ["Pronunciation Dictionary"],
        summary: "Add A Pronunciation Dictionary",
        description:
          "Creates a new pronunciation dictionary from a lexicon .PLS file",
        operationId:
          "Add_a_pronunciation_dictionary_v1_pronunciation_dictionaries_add_from_file_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "multipart/form-data": {
              schema: {
                $ref: "#/components/schemas/Body_Add_a_pronunciation_dictionary_v1_pronunciation_dictionaries_add_from_file_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/AddPronunciationDictionaryResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/pronunciation-dictionaries/{pronunciation_dictionary_id}/add-rules": {
      post: {
        tags: ["Pronunciation Dictionary"],
        summary: "Add Rules To The Pronunciation Dictionary",
        description: "Add rules to the pronunciation dictionary",
        operationId:
          "Add_rules_to_the_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id__add_rules_post",
        parameters: [
          {
            description: "The id of the pronunciation dictionary",
            required: true,
            schema: {
              type: "string",
              title: "Pronunciation Dictionary Id",
              description: "The id of the pronunciation dictionary",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "pronunciation_dictionary_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Add_rules_to_the_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id__add_rules_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/AddPronunciationDictionaryRulesResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/pronunciation-dictionaries/{pronunciation_dictionary_id}/remove-rules":
      {
        post: {
          tags: ["Pronunciation Dictionary"],
          summary: "Remove Rules From The Pronunciation Dictionary",
          description: "Remove rules from the pronunciation dictionary",
          operationId:
            "Remove_rules_from_the_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id__remove_rules_post",
          parameters: [
            {
              description: "The id of the pronunciation dictionary",
              required: true,
              schema: {
                type: "string",
                title: "Pronunciation Dictionary Id",
                description: "The id of the pronunciation dictionary",
              },
              example: "21m00Tcm4TlvDq8ikWAM",
              name: "pronunciation_dictionary_id",
              in: "path",
            },
            {
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
              required: false,
              schema: {
                type: "string",
                title: "Xi-Api-Key",
                description:
                  "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
              },
              name: "xi-api-key",
              in: "header",
            },
          ],
          requestBody: {
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Body_Remove_rules_from_the_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id__remove_rules_post",
                },
              },
            },
            required: true,
          },
          responses: {
            "200": {
              description: "Successful Response",
              content: {
                "application/json": {
                  schema: {
                    $ref: "#/components/schemas/RemovePronunciationDictionaryRulesResponseModel",
                  },
                },
              },
            },
            "422": {
              description: "Validation Error",
              content: {
                "application/json": {
                  schema: { $ref: "#/components/schemas/HTTPValidationError" },
                },
              },
            },
          },
        },
      },
    "/v1/pronunciation-dictionaries/{dictionary_id}/{version_id}/download": {
      get: {
        tags: ["Pronunciation Dictionary"],
        summary: "Get Pls File With A Pronunciation Dictionary Version Rules",
        description:
          "Get PLS file with a pronunciation dictionary version rules",
        operationId:
          "Get_PLS_file_with_a_pronunciation_dictionary_version_rules_v1_pronunciation_dictionaries__dictionary_id___version_id__download_get",
        parameters: [
          {
            description: "The id of the pronunciation dictionary",
            required: true,
            schema: {
              type: "string",
              title: "Dictionary Id",
              description: "The id of the pronunciation dictionary",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "dictionary_id",
            in: "path",
          },
          {
            description:
              "The id of the version of the pronunciation dictionary",
            required: true,
            schema: {
              type: "string",
              title: "Version Id",
              description:
                "The id of the version of the pronunciation dictionary",
            },
            example: "BdF0s0aZ3oFoKnDYdTox",
            name: "version_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: { "text/plain": {} },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/pronunciation-dictionaries/{pronunciation_dictionary_id}/": {
      get: {
        tags: ["Pronunciation Dictionary"],
        summary: "Get Metadata For A Pronunciation Dictionary",
        description: "Get metadata for a pronunciation dictionary",
        operationId:
          "Get_metadata_for_a_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id___get",
        parameters: [
          {
            description: "The id of the pronunciation dictionary",
            required: true,
            schema: {
              type: "string",
              title: "Pronunciation Dictionary Id",
              description: "The id of the pronunciation dictionary",
            },
            example: "21m00Tcm4TlvDq8ikWAM",
            name: "pronunciation_dictionary_id",
            in: "path",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GetPronunciationDictionaryMetadataResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/pronunciation-dictionaries/": {
      get: {
        tags: ["Pronunciation Dictionary"],
        summary: "Get Pronunciation Dictionaries",
        description:
          "Get a list of the pronunciation dictionaries you have access to and their metadata",
        operationId:
          "Get_Pronunciation_Dictionaries_v1_pronunciation_dictionaries__get",
        parameters: [
          {
            description:
              "Used for fetching next page. Cursor is returned in the response.",
            required: false,
            schema: {
              type: "string",
              title: "Cursor",
              description:
                "Used for fetching next page. Cursor is returned in the response.",
            },
            name: "cursor",
            in: "query",
          },
          {
            description:
              "How many pronunciation dictionaries to return at maximum. Can not exceed 100, defaults to 30.",
            required: false,
            schema: {
              type: "integer",
              maximum: 100.0,
              minimum: 1.0,
              title: "Page Size",
              description:
                "How many pronunciation dictionaries to return at maximum. Can not exceed 100, defaults to 30.",
              default: 30,
            },
            name: "page_size",
            in: "query",
          },
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GetPronunciationDictionariesMetadataResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/workspace/invites/add": {
      post: {
        tags: ["workspace"],
        summary: "Invite User",
        description:
          "Sends an email invitation to join your workspace to the provided email. If the user doesn't have an account they will be prompted to create one. If the user accepts this invite they will be added as a user to your workspace and your subscription using one of your seats. This endpoint may only be called by workspace administrators.",
        operationId: "Invite_user_v1_workspace_invites_add_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Invite_user_v1_workspace_invites_add_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/workspace/invites": {
      delete: {
        tags: ["workspace"],
        summary: "Delete Existing Invitation",
        description:
          "Invalidates an existing email invitation. The invitation will still show up in the inbox it has been delivered to, but activating it to join the workspace won't work. This endpoint may only be called by workspace administrators.",
        operationId: "Delete_existing_invitation_v1_workspace_invites_delete",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Delete_existing_invitation_v1_workspace_invites_delete",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/v1/workspace/members": {
      post: {
        tags: ["workspace"],
        summary: "Update Member",
        description:
          "Updates attributes of a workspace member. Apart from the email identifier, all parameters will remain unchanged unless specified. This endpoint may only be called by workspace administrators.",
        operationId: "Update_member_v1_workspace_members_post",
        parameters: [
          {
            description:
              "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            required: false,
            schema: {
              type: "string",
              title: "Xi-Api-Key",
              description:
                "Your API key. This is required by most endpoints to access our API programatically. You can view your xi-api-key using the 'Profile' tab on the website.",
            },
            name: "xi-api-key",
            in: "header",
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Body_Update_member_v1_workspace_members_post",
              },
            },
          },
          required: true,
        },
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/profile/{handle}": {
      get: {
        tags: ["voices"],
        summary: "Get A Profile Page",
        description: "Gets a profile page based on a handle",
        operationId: "Get_a_profile_page_profile__handle__get",
        parameters: [
          {
            description: "Handle for a VA's profile page",
            required: true,
            schema: {
              type: "string",
              title: "Handle",
              description: "Handle for a VA's profile page",
            },
            example: "talexgeorge",
            name: "handle",
            in: "path",
          },
        ],
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/ProfilePageResponseModel",
                },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
      },
    },
    "/docs": {
      get: {
        summary: "Redirect To Mintlify",
        operationId: "redirect_to_mintlify_docs_get",
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
        },
      },
    },
  },
  components: {
    schemas: {
      AddProjectResponseModel: {
        properties: {
          project: { $ref: "#/components/schemas/ProjectResponseModel" },
        },
        type: "object",
        required: ["project"],
        title: "AddProjectResponseModel",
      },
      AddPronunciationDictionaryResponseModel: {
        properties: {
          id: { type: "string", title: "Id" },
          name: { type: "string", title: "Name" },
          created_by: { type: "string", title: "Created By" },
          creation_time_unix: { type: "integer", title: "Creation Time Unix" },
          version_id: { type: "string", title: "Version Id" },
          description: { type: "string", title: "Description" },
        },
        type: "object",
        required: [
          "id",
          "name",
          "created_by",
          "creation_time_unix",
          "version_id",
        ],
        title: "AddPronunciationDictionaryResponseModel",
      },
      AddPronunciationDictionaryRulesResponseModel: {
        properties: {
          id: { type: "string", title: "Id" },
          version_id: { type: "string", title: "Version Id" },
        },
        type: "object",
        required: ["id", "version_id"],
        title: "AddPronunciationDictionaryRulesResponseModel",
      },
      AddVoiceResponseModel: {
        properties: { voice_id: { type: "string", title: "Voice Id" } },
        type: "object",
        required: ["voice_id"],
        title: "AddVoiceResponseModel",
      },
      AudioNativeCreateProjectResponseModel: {
        properties: {
          project_id: { type: "string", title: "Project Id" },
          converting: { type: "boolean", title: "Converting" },
          html_snippet: { type: "string", title: "Html Snippet" },
        },
        type: "object",
        required: ["project_id", "converting", "html_snippet"],
        title: "AudioNativeCreateProjectResponseModel",
      },
      Body_Add_a_pronunciation_dictionary_v1_pronunciation_dictionaries_add_from_file_post:
        {
          properties: {
            file: {
              type: "string",
              format: "binary",
              title: "File",
              description:
                "A lexicon .pls file which we will use to initialize the project with.",
            },
            name: {
              type: "string",
              title: "Name",
              description:
                "The name of the pronunciation dictionary, used for identification only.",
            },
            description: {
              type: "string",
              title: "Description",
              description:
                "A description of the pronunciation dictionary, used for identification only.",
            },
            workspace_access: {
              type: "string",
              enum: ["admin", "editor", "viewer"],
              title: "Workspace Access",
              description:
                "Should be one of 'editor' or 'viewer'. If not provided, defaults to no access.",
            },
          },
          type: "object",
          required: ["name"],
          title:
            "Body_Add_a_pronunciation_dictionary_v1_pronunciation_dictionaries_add_from_file_post",
        },
      Body_Add_project_v1_projects_add_post: {
        properties: {
          name: {
            type: "string",
            title: "Name",
            description:
              "The name of the project, used for identification only.",
          },
          from_url: {
            type: "string",
            title: "From Url",
            description:
              "An optional URL from which we will extract content to initialize the project. If this is set, 'from_url' must be null. If neither 'from_url' or 'from_document' are provided we will initialize the project as blank.",
          },
          from_document: {
            type: "string",
            format: "binary",
            title: "From Document",
            description:
              "An optional .epub, .pdf, .txt or similar file can be provided. If provided, we will initialize the project with its content. If this is set, 'from_url' must be null.  If neither 'from_url' or 'from_document' are provided we will initialize the project as blank.",
          },
          default_title_voice_id: {
            type: "string",
            title: "Default Title Voice Id",
            description:
              "The voice_id that corresponds to the default voice used for new titles.",
          },
          default_paragraph_voice_id: {
            type: "string",
            title: "Default Paragraph Voice Id",
            description:
              "The voice_id that corresponds to the default voice used for new paragraphs.",
          },
          default_model_id: {
            type: "string",
            title: "Default Model Id",
            description:
              "The model_id of the model to be used for this project, you can query GET https://api.elevenlabs.io/v1/models to list all available models.",
          },
          quality_preset: {
            type: "string",
            title: "Quality of the generated audio.",
            description:
              "Output quality of the generated audio. Must be one of:\nstandard - standard output format, 128kbps with 44.1kHz sample rate.\nhigh - high quality output format, 192kbps with 44.1kHz sample rate and major improvements on our side. Using this setting increases the character cost by 20%.\nultra - ultra quality output format, 192kbps with 44.1kHz sample rate and highest improvements on our side. Using this setting increases the character cost by 50%.\nultra lossless - ultra quality output format, 705.6kbps with 44.1kHz sample rate and highest improvements on our side in a fully lossless format. Using this setting increases the character cost by 100%.\n",
            default: "standard",
            include_in_schema: true,
          },
          title: {
            type: "string",
            title: "Title",
            description:
              "An optional name of the author of the project, this will be added as metadata to the mp3 file on project / chapter download.",
          },
          author: {
            type: "string",
            title: "Author",
            description:
              "An optional name of the author of the project, this will be added as metadata to the mp3 file on project / chapter download.",
          },
          isbn_number: {
            type: "string",
            title: "Isbn Number",
            description:
              "An optional ISBN number of the project you want to create, this will be added as metadata to the mp3 file on project / chapter download.",
          },
          acx_volume_normalization: {
            type: "boolean",
            title: "Acx Volume Normalization",
            description:
              "[Deprecated] When the project is downloaded, should the returned audio have postprocessing in order to make it compliant with audiobook normalized volume requirements",
            default: false,
            include_in_schema: false,
          },
          volume_normalization: {
            type: "boolean",
            title: "Volume Normalization",
            description:
              "When the project is downloaded, should the returned audio have postprocessing in order to make it compliant with audiobook normalized volume requirements",
            default: false,
          },
          pronunciation_dictionary_locators: {
            items: { type: "string" },
            type: "array",
            title: "Pronunciation Dictionary Locators",
            description:
              'A list of pronunciation dictionary locators (pronunciation_dictionary_id, version_id) encoded as a list of JSON strings for pronunciation dictionaries to be applied to the text.  A list of json encoded strings is required as adding projects may occur through formData as opposed to jsonBody. To specify multiple dictionaries use multiple --form lines in your curl, such as --form \'pronunciation_dictionary_locators="{\\"pronunciation_dictionary_id\\":\\"Vmd4Zor6fplcA7WrINey\\",\\"version_id\\":\\"hRPaxjlTdR7wFMhV4w0b\\"}"\' --form \'pronunciation_dictionary_locators="{\\"pronunciation_dictionary_id\\":\\"JzWtcGQMJ6bnlWwyMo7e\\",\\"version_id\\":\\"lbmwxiLu4q6txYxgdZqn\\"}"\'. Note that multiple dictionaries are not currently supported by our UI which will only show the first.',
          },
          callback_url: {
            type: "string",
            title: "Callback Url",
            description:
              "A url that will be called by our service when the project is converted with a json containing the status of the conversion",
            include_in_schema: false,
          },
        },
        type: "object",
        required: [
          "name",
          "default_title_voice_id",
          "default_paragraph_voice_id",
          "default_model_id",
        ],
        title: "Body_Add_project_v1_projects_add_post",
      },
      Body_Add_rules_to_the_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id__add_rules_post:
        {
          properties: {
            rules: {
              items: {
                anyOf: [
                  {
                    $ref: "#/components/schemas/PronunciationDictionaryAliasRuleRequestModel",
                  },
                  {
                    $ref: "#/components/schemas/PronunciationDictionaryPhonemeRuleRequestModel",
                  },
                ],
              },
              type: "array",
              title: "Rules",
              description:
                "List of pronunciation rules. Rule can be either:\n    an alias rule: {'string_to_replace': 'a', 'type': 'alias', 'alias': 'b', }\n    or a phoneme rule: {'string_to_replace': 'a', 'type': 'phoneme', 'phoneme': 'b', 'alphabet': 'ipa' }",
            },
          },
          type: "object",
          required: ["rules"],
          title:
            "Body_Add_rules_to_the_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id__add_rules_post",
        },
      Body_Add_sharing_voice_v1_voices_add__public_user_id___voice_id__post: {
        properties: {
          new_name: {
            type: "string",
            title: "New Name",
            description:
              "The name that identifies this voice. This will be displayed in the dropdown of the website.",
          },
        },
        type: "object",
        required: ["new_name"],
        title:
          "Body_Add_sharing_voice_v1_voices_add__public_user_id___voice_id__post",
      },
      Body_Add_voice_v1_voices_add_post: {
        properties: {
          name: {
            type: "string",
            title: "Name",
            description:
              "The name that identifies this voice. This will be displayed in the dropdown of the website.",
          },
          files: {
            items: { type: "string", format: "binary" },
            type: "array",
            title: "Files",
            description:
              "A list of file paths to audio recordings intended for voice cloning",
          },
          description: {
            type: "string",
            title: "Description",
            description: "How would you describe the voice?",
          },
          labels: {
            type: "string",
            title: "Labels",
            description: "Serialized labels dictionary for the voice.",
          },
        },
        type: "object",
        required: ["name", "files"],
        title: "Body_Add_voice_v1_voices_add_post",
      },
      Body_Create_a_previously_generated_voice_v1_voice_generation_create_voice_post:
        {
          properties: {
            voice_name: {
              type: "string",
              title: "Voice Name",
              description: "Name to use for the created voice.",
            },
            voice_description: {
              type: "string",
              title: "Voice Description",
              description: "Description to use for the created voice.",
            },
            generated_voice_id: {
              type: "string",
              title: "Generated Voice Id",
              description:
                "The generated_voice_id to create, call POST /v1/voice-generation/generate-voice and fetch the generated_voice_id from the response header if don't have one yet.",
            },
            labels: {
              additionalProperties: { type: "string" },
              type: "object",
              title: "Labels",
              description:
                "Optional, metadata to add to the created voice. Defaults to None.",
              name: "Voice metadata",
            },
          },
          type: "object",
          required: ["voice_name", "voice_description", "generated_voice_id"],
          title:
            "Body_Create_a_previously_generated_voice_v1_voice_generation_create_voice_post",
        },
      Body_Creates_AudioNative_enabled_project__v1_audio_native_post: {
        properties: {
          name: { type: "string", title: "Name", description: "Project name." },
          image: {
            type: "string",
            title: "Image",
            description:
              "Image URL used in the player. If not provided, default image set in the Player settings is used.",
          },
          author: {
            type: "string",
            title: "Author",
            description:
              "Author used in the player and inserted at the start of the uploaded article. If not provided, the default author set in the Player settings is used.",
          },
          title: {
            type: "string",
            title: "Title",
            description:
              "Title used in the player and inserted at the top of the uploaded article. If not provided, the default title set in the Player settings is used.",
          },
          small: {
            type: "boolean",
            title: "Small",
            description:
              "Whether to use small player or not. If not provided, default value set in the Player settings is used.",
            default: false,
          },
          text_color: {
            type: "string",
            title: "Text Color",
            description:
              "Text color used in the player. If not provided, default text color set in the Player settings is used.",
          },
          background_color: {
            type: "string",
            title: "Background Color",
            description:
              "Background color used in the player. If not provided, default background color set in the Player settings is used.",
          },
          sessionization: {
            type: "integer",
            title: "Sessionization",
            description:
              "Specifies for how many minutes to persist the session across page reloads. If not provided, default sessionization set in the Player settings is used.",
            default: 0,
          },
          voice_id: {
            type: "string",
            title: "Voice Id",
            description:
              "Voice ID used to voice the content. If not provided, default voice ID set in the Player settings is used.",
          },
          model_id: {
            type: "string",
            title: "Model Id",
            description:
              "TTS Model ID used in the player. If not provided, default model ID set in the Player settings is used.",
          },
          file: {
            type: "string",
            format: "binary",
            title: "File",
            description:
              "Either txt or HTML input file containing the article content. HTML should be formatted as follows '&lt;html&gt;&lt;body&gt;&lt;div&gt;&lt;p&gt;Your content&lt;/p&gt;&lt;h5&gt;More of your content&lt;/h5&gt;&lt;p&gt;Some more of your content&lt;/p&gt;&lt;/div&gt;&lt;/body&gt;&lt;/html&gt;'",
          },
          auto_convert: {
            type: "boolean",
            title: "Auto Convert",
            description: "Whether to auto convert the project to audio or not.",
            default: false,
          },
        },
        type: "object",
        required: ["name", "file"],
        title: "Body_Creates_AudioNative_enabled_project__v1_audio_native_post",
      },
      Body_Delete_existing_invitation_v1_workspace_invites_delete: {
        properties: {
          email: {
            type: "string",
            title: "Email",
            description: "Email of the target user.",
          },
        },
        type: "object",
        required: ["email"],
        title: "Body_Delete_existing_invitation_v1_workspace_invites_delete",
      },
      Body_Download_history_items_v1_history_download_post: {
        properties: {
          history_item_ids: {
            items: { type: "string" },
            type: "array",
            title: "History Item Ids",
            description:
              "A list of history items to download, you can get IDs of history items and other metadata using the GET https://api.elevenlabs.io/v1/history endpoint.",
          },
          output_format: {
            type: "string",
            title: "Output Format",
            description:
              "Output format to transcode the audio file, can be wav or default.",
          },
        },
        type: "object",
        required: ["history_item_ids"],
        title: "Body_Download_history_items_v1_history_download_post",
      },
      Body_Dub_a_video_or_an_audio_file_v1_dubbing_post: {
        properties: {
          mode: {
            type: "string",
            title: "Mode",
            description:
              "automatic or manual. Manual mode is only supported when creating a dubbing studio project",
          },
          file: {
            type: "string",
            format: "binary",
            title: "File",
            description:
              "A list of file paths to audio recordings intended for voice cloning",
          },
          csv_file: {
            type: "string",
            format: "binary",
            title: "Csv File",
            description:
              "CSV file containing transcription/translation metadata",
          },
          foreground_audio_file: {
            type: "string",
            format: "binary",
            title: "Foreground Audio File",
            description: "For use only with csv input",
          },
          background_audio_file: {
            type: "string",
            format: "binary",
            title: "Background Audio File",
            description: "For use only with csv input",
          },
          name: {
            type: "string",
            title: "Name",
            description: "Name of the dubbing project.",
          },
          source_url: {
            type: "string",
            title: "Source Url",
            description: "URL of the source video/audio file.",
          },
          source_lang: {
            type: "string",
            title: "Source Lang",
            description: "Source language.",
            default: "auto",
          },
          target_lang: {
            type: "string",
            title: "Target Lang",
            description:
              "The Target language to dub the content into. Can be none if dubbing studio editor is enabled and running manual mode",
          },
          num_speakers: {
            type: "integer",
            title: "Num Speakers",
            description:
              "Number of speakers to use for the dubbing. Set to 0 to automatically detect the number of speakers",
            default: 0,
          },
          watermark: {
            type: "boolean",
            title: "Watermark",
            description: "Whether to apply watermark to the output video.",
            default: false,
          },
          start_time: {
            type: "integer",
            title: "Start Time",
            description: "Start time of the source video/audio file.",
          },
          end_time: {
            type: "integer",
            title: "End Time",
            description: "End time of the source video/audio file.",
          },
          highest_resolution: {
            type: "boolean",
            title: "Highest Resolution",
            description: "Whether to use the highest resolution available.",
            default: false,
          },
          dubbing_studio: {
            type: "boolean",
            title: "Dubbing Studio",
            description: "Whether to prepare dub for edits in dubbing studio.",
            default: false,
          },
        },
        type: "object",
        title: "Body_Dub_a_video_or_an_audio_file_v1_dubbing_post",
      },
      Body_Edit_basic_project_info_v1_projects__project_id__post: {
        properties: {
          name: {
            type: "string",
            title: "Name",
            description:
              "The name of the project, used for identification only.",
          },
          default_title_voice_id: {
            type: "string",
            title: "Default Title Voice Id",
            description:
              "The voice_id that corresponds to the default voice used for new titles.",
          },
          default_paragraph_voice_id: {
            type: "string",
            title: "Default Paragraph Voice Id",
            description:
              "The voice_id that corresponds to the default voice used for new paragraphs.",
          },
          title: {
            type: "string",
            title: "Title",
            description:
              "An optional name of the author of the project, this will be added as metadata to the mp3 file on project / chapter download.",
          },
          author: {
            type: "string",
            title: "Author",
            description:
              "An optional name of the author of the project, this will be added as metadata to the mp3 file on project / chapter download.",
          },
          isbn_number: {
            type: "string",
            title: "Isbn Number",
            description:
              "An optional ISBN number of the project you want to create, this will be added as metadata to the mp3 file on project / chapter download.",
          },
          volume_normalization: {
            type: "boolean",
            title: "Volume Normalization",
            description:
              "When the project is downloaded, should the returned audio have postprocessing in order to make it compliant with audiobook normalized volume requirements",
            default: false,
          },
        },
        type: "object",
        required: [
          "name",
          "default_title_voice_id",
          "default_paragraph_voice_id",
        ],
        title: "Body_Edit_basic_project_info_v1_projects__project_id__post",
      },
      Body_Edit_voice_v1_voices__voice_id__edit_post: {
        properties: {
          name: {
            type: "string",
            title: "Name",
            description:
              "The name that identifies this voice. This will be displayed in the dropdown of the website.",
          },
          files: {
            items: { type: "string", format: "binary" },
            type: "array",
            title: "Files",
            description: "Audio files to add to the voice",
          },
          description: {
            type: "string",
            title: "Description",
            description: "How would you describe the voice?",
          },
          labels: {
            type: "string",
            title: "Labels",
            description: "Serialized labels dictionary for the voice.",
          },
        },
        type: "object",
        required: ["name"],
        title: "Body_Edit_voice_v1_voices__voice_id__edit_post",
      },
      Body_Generate_a_random_voice_v1_voice_generation_generate_voice_post: {
        properties: {
          gender: {
            type: "string",
            enum: ["female", "male"],
            title: "Gender",
            description:
              "Category code corresponding to the gender of the generated voice. Possible values: female, male.",
          },
          accent: {
            type: "string",
            title: "Accent",
            description:
              "Category code corresponding to the accent of the generated voice. Possible values: american, british, african, australian, indian.",
          },
          age: {
            type: "string",
            enum: ["young", "middle_aged", "old"],
            title: "Age",
            description:
              "Category code corresponding to the age of the generated voice. Possible values: young, middle_aged, old.",
          },
          accent_strength: {
            type: "number",
            title: "Accent Strength",
            description:
              "The strength of the accent of the generated voice. Has to be between 0.3 and 2.0.",
          },
          text: {
            type: "string",
            title: "Text",
            description:
              "Text to generate, text length has to be between 100 and 1000.",
          },
        },
        type: "object",
        required: ["gender", "accent", "age", "accent_strength", "text"],
        title:
          "Body_Generate_a_random_voice_v1_voice_generation_generate_voice_post",
      },
      Body_Invite_user_v1_workspace_invites_add_post: {
        properties: {
          email: {
            type: "string",
            title: "Email",
            description: "Email of the target user.",
          },
        },
        type: "object",
        required: ["email"],
        title: "Body_Invite_user_v1_workspace_invites_add_post",
      },
      Body_Remove_rules_from_the_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id__remove_rules_post:
        {
          properties: {
            rule_strings: {
              items: { type: "string" },
              type: "array",
              title: "Rule Strings",
              description:
                "List of strings to remove from the pronunciation dictionary.",
            },
          },
          type: "object",
          required: ["rule_strings"],
          title:
            "Body_Remove_rules_from_the_pronunciation_dictionary_v1_pronunciation_dictionaries__pronunciation_dictionary_id__remove_rules_post",
        },
      Body_Speech_to_Speech_Streaming_v1_speech_to_speech__voice_id__stream_post:
        {
          properties: {
            audio: {
              type: "string",
              format: "binary",
              title: "Audio",
              description:
                "The audio file which holds the content and emotion that will control the generated speech.",
            },
            model_id: {
              type: "string",
              title: "Model Id",
              description:
                "Identifier of the model that will be used, you can query them using GET /v1/models. The model needs to have support for speech to speech, you can check this using the can_do_voice_conversion property.",
              default: "eleven_english_sts_v2",
            },
            voice_settings: {
              type: "string",
              title: "Voice Settings",
              description:
                "Voice settings overriding stored setttings for the given voice. They are applied only on the given request. Needs to be send as a JSON encoded string.",
            },
            seed: {
              type: "integer",
              title: "Seed",
              description:
                "If specified, our system will make a best effort to sample deterministically, such that repeated requests with the same seed and parameters should return the same result. Determinism is not guaranteed.",
            },
          },
          type: "object",
          required: ["audio"],
          title:
            "Body_Speech_to_Speech_Streaming_v1_speech_to_speech__voice_id__stream_post",
        },
      Body_Speech_to_Speech_v1_speech_to_speech__voice_id__post: {
        properties: {
          audio: {
            type: "string",
            format: "binary",
            title: "Audio",
            description:
              "The audio file which holds the content and emotion that will control the generated speech.",
          },
          model_id: {
            type: "string",
            title: "Model Id",
            description:
              "Identifier of the model that will be used, you can query them using GET /v1/models. The model needs to have support for speech to speech, you can check this using the can_do_voice_conversion property.",
            default: "eleven_english_sts_v2",
          },
          voice_settings: {
            type: "string",
            title: "Voice Settings",
            description:
              "Voice settings overriding stored setttings for the given voice. They are applied only on the given request. Needs to be send as a JSON encoded string.",
          },
          seed: {
            type: "integer",
            title: "Seed",
            description:
              "If specified, our system will make a best effort to sample deterministically, such that repeated requests with the same seed and parameters should return the same result. Determinism is not guaranteed.",
          },
        },
        type: "object",
        required: ["audio"],
        title: "Body_Speech_to_Speech_v1_speech_to_speech__voice_id__post",
      },
      Body_Stream_chapter_audio_v1_projects__project_id__chapters__chapter_id__snapshots__chapter_snapshot_id__stream_post:
        {
          properties: {
            convert_to_mpeg: {
              type: "boolean",
              title: "Convert To Mpeg",
              description: "Whether to convert the audio to mpeg format.",
              default: false,
            },
          },
          type: "object",
          title:
            "Body_Stream_chapter_audio_v1_projects__project_id__chapters__chapter_id__snapshots__chapter_snapshot_id__stream_post",
        },
      Body_Stream_project_audio_v1_projects__project_id__snapshots__project_snapshot_id__stream_post:
        {
          properties: {
            convert_to_mpeg: {
              type: "boolean",
              title: "Convert To Mpeg",
              description: "Whether to convert the audio to mpeg format.",
              default: false,
            },
          },
          type: "object",
          title:
            "Body_Stream_project_audio_v1_projects__project_id__snapshots__project_snapshot_id__stream_post",
        },
      Body_Text_to_speech_streaming_v1_text_to_speech__voice_id__stream_post: {
        properties: {
          text: {
            type: "string",
            title: "Text",
            description: "The text that will get converted into speech.",
          },
          model_id: {
            type: "string",
            title: "Model Id",
            description:
              "Identifier of the model that will be used, you can query them using GET /v1/models. The model needs to have support for text to speech, you can check this using the can_do_text_to_speech property.",
            default: "eleven_monolingual_v1",
          },
          voice_settings: {
            allOf: [
              { $ref: "#/components/schemas/VoiceSettingsResponseModel" },
            ],
            title: "Voice Settings",
            description:
              "Voice settings overriding stored setttings for the given voice. They are applied only on the given request.",
          },
          pronunciation_dictionary_locators: {
            items: {
              $ref: "#/components/schemas/PronunciationDictionaryVersionLocatorDBModel",
            },
            type: "array",
            title: "Pronunciation Dictionary Locators",
            description:
              "A list of pronunciation dictionary locators (id, version_id) to be applied to the text. They will be applied in order. You may have up to 3 locators per request",
            default: [],
          },
          seed: {
            type: "integer",
            title: "Seed",
            description:
              "If specified, our system will make a best effort to sample deterministically, such that repeated requests with the same seed and parameters should return the same result. Determinism is not guaranteed.",
          },
          previous_text: {
            type: "string",
            title: "Previous Text",
            description:
              "The text that came before the text of the current request. Can be used to improve the flow of prosody when concatenating together multiple generations or to influence the prosody in the current generation.",
          },
          next_text: {
            type: "string",
            title: "Next Text",
            description:
              "The text that comes after the text of the current request. Can be used to improve the flow of prosody when concatenating together multiple generations or to influence the prosody in the current generation.",
          },
          previous_request_ids: {
            items: { type: "string" },
            type: "array",
            title: "Previous Request Ids",
            description:
              "A list of request_id of the samples that were generated before this generation. Can be used to improve the flow of prosody when splitting up a large task into multiple requests. The results will be best when the same model is used across the generations. In case both previous_text and previous_request_ids is send, previous_text will be ignored. A maximum of 3 request_ids can be send.",
            default: [],
          },
          next_request_ids: {
            items: { type: "string" },
            type: "array",
            title: "Next Request Ids",
            description:
              "A list of request_id of the samples that were generated before this generation. Can be used to improve the flow of prosody when splitting up a large task into multiple requests. The results will be best when the same model is used across the generations. In case both next_text and next_request_ids is send, next_text will be ignored. A maximum of 3 request_ids can be send.",
            default: [],
          },
        },
        type: "object",
        required: ["text"],
        title:
          "Body_Text_to_speech_streaming_v1_text_to_speech__voice_id__stream_post",
      },
      Body_Text_to_speech_streaming_with_timestamps_v1_text_to_speech__voice_id__stream_with_timestamps_post:
        {
          properties: {
            text: {
              type: "string",
              title: "Text",
              description: "The text that will get converted into speech.",
            },
            model_id: {
              type: "string",
              title: "Model Id",
              description:
                "Identifier of the model that will be used, you can query them using GET /v1/models. The model needs to have support for text to speech, you can check this using the can_do_text_to_speech property.",
              default: "eleven_monolingual_v1",
            },
            voice_settings: {
              allOf: [
                { $ref: "#/components/schemas/VoiceSettingsResponseModel" },
              ],
              title: "Voice Settings",
              description:
                "Voice settings overriding stored setttings for the given voice. They are applied only on the given request.",
            },
            pronunciation_dictionary_locators: {
              items: {
                $ref: "#/components/schemas/PronunciationDictionaryVersionLocatorDBModel",
              },
              type: "array",
              title: "Pronunciation Dictionary Locators",
              description:
                "A list of pronunciation dictionary locators (id, version_id) to be applied to the text. They will be applied in order. You may have up to 3 locators per request",
              default: [],
            },
            seed: {
              type: "integer",
              title: "Seed",
              description:
                "If specified, our system will make a best effort to sample deterministically, such that repeated requests with the same seed and parameters should return the same result. Determinism is not guaranteed.",
            },
            previous_text: {
              type: "string",
              title: "Previous Text",
              description:
                "The text that came before the text of the current request. Can be used to improve the flow of prosody when concatenating together multiple generations or to influence the prosody in the current generation.",
            },
            next_text: {
              type: "string",
              title: "Next Text",
              description:
                "The text that comes after the text of the current request. Can be used to improve the flow of prosody when concatenating together multiple generations or to influence the prosody in the current generation.",
            },
            previous_request_ids: {
              items: { type: "string" },
              type: "array",
              title: "Previous Request Ids",
              description:
                "A list of request_id of the samples that were generated before this generation. Can be used to improve the flow of prosody when splitting up a large task into multiple requests. The results will be best when the same model is used across the generations. In case both previous_text and previous_request_ids is send, previous_text will be ignored. A maximum of 3 request_ids can be send.",
              default: [],
            },
            next_request_ids: {
              items: { type: "string" },
              type: "array",
              title: "Next Request Ids",
              description:
                "A list of request_id of the samples that were generated before this generation. Can be used to improve the flow of prosody when splitting up a large task into multiple requests. The results will be best when the same model is used across the generations. In case both next_text and next_request_ids is send, next_text will be ignored. A maximum of 3 request_ids can be send.",
              default: [],
            },
          },
          type: "object",
          required: ["text"],
          title:
            "Body_Text_to_speech_streaming_with_timestamps_v1_text_to_speech__voice_id__stream_with_timestamps_post",
        },
      Body_Text_to_speech_v1_text_to_speech__voice_id__post: {
        properties: {
          text: {
            type: "string",
            title: "Text",
            description: "The text that will get converted into speech.",
          },
          model_id: {
            models,
            type: "string",
            title: "Model Id",
            description:
              "Identifier of the model that will be used, you can query them using GET /v1/models. The model needs to have support for text to speech, you can check this using the can_do_text_to_speech property.",
            default: "eleven_monolingual_v1",
          },
          voices: {
            voices,
            type: "string",
            title: "Voice ID",
            description:
              "Choose the voice for the audio",
            default: "Rachel",
          },

          voice_settings: {
            allOf: [
              { $ref: "#/components/schemas/VoiceSettingsResponseModel" },
            ],
            title: "Voice Settings",
            description:
              "Voice settings overriding stored setttings for the given voice. They are applied only on the given request.",
          },
          // pronunciation_dictionary_locators: {
          //   items: {
          //     $ref: "#/components/schemas/PronunciationDictionaryVersionLocatorDBModel",
          //   },
          //   type: "array",
          //   title: "Pronunciation Dictionary Locators",
          //   description:
          //     "A list of pronunciation dictionary locators (id, version_id) to be applied to the text. They will be applied in order. You may have up to 3 locators per request",
          //   default: [],
          // },
          // seed: {
          //   type: "integer",
          //   title: "Seed",
          //   description:
          //     "If specified, our system will make a best effort to sample deterministically, such that repeated requests with the same seed and parameters should return the same result. Determinism is not guaranteed.",
          // },
          // previous_text: {
          //   type: "string",
          //   title: "Previous Text",
          //   description:
          //     "The text that came before the text of the current request. Can be used to improve the flow of prosody when concatenating together multiple generations or to influence the prosody in the current generation.",
          // },
          // next_text: {
          //   type: "string",
          //   title: "Next Text",
          //   description:
          //     "The text that comes after the text of the current request. Can be used to improve the flow of prosody when concatenating together multiple generations or to influence the prosody in the current generation.",
          // },
          // previous_request_ids: {
          //   items: { type: "string" },
          //   type: "array",
          //   title: "Previous Request Ids",
          //   description:
          //     "A list of request_id of the samples that were generated before this generation. Can be used to improve the flow of prosody when splitting up a large task into multiple requests. The results will be best when the same model is used across the generations. In case both previous_text and previous_request_ids is send, previous_text will be ignored. A maximum of 3 request_ids can be send.",
          //   default: [],
          // },
          // next_request_ids: {
          //   items: { type: "string" },
          //   type: "array",
          //   title: "Next Request Ids",
          //   description:
          //     "A list of request_id of the samples that were generated before this generation. Can be used to improve the flow of prosody when splitting up a large task into multiple requests. The results will be best when the same model is used across the generations. In case both next_text and next_request_ids is send, next_text will be ignored. A maximum of 3 request_ids can be send.",
          //   default: [],
          // },
        },
        type: "object",
        required: ["text"],
        title: "Body_Text_to_speech_v1_text_to_speech__voice_id__post",
      },
      Body_Text_to_speech_with_timestamps_v1_text_to_speech__voice_id__with_timestamps_post:
        {
          properties: {
            text: {
              type: "string",
              title: "Text",
              description: "The text that will get converted into speech.",
            },
            model_id: {
              type: "string",
              title: "Model Id",
              description:
                "Identifier of the model that will be used, you can query them using GET /v1/models. The model needs to have support for text to speech, you can check this using the can_do_text_to_speech property.",
              default: "eleven_monolingual_v1",
            },
            voice_settings: {
              allOf: [
                { $ref: "#/components/schemas/VoiceSettingsResponseModel" },
              ],
              title: "Voice Settings",
              description:
                "Voice settings overriding stored setttings for the given voice. They are applied only on the given request.",
            },
            pronunciation_dictionary_locators: {
              items: {
                $ref: "#/components/schemas/PronunciationDictionaryVersionLocatorDBModel",
              },
              type: "array",
              title: "Pronunciation Dictionary Locators",
              description:
                "A list of pronunciation dictionary locators (id, version_id) to be applied to the text. They will be applied in order. You may have up to 3 locators per request",
              default: [],
            },
            seed: {
              type: "integer",
              title: "Seed",
              description:
                "If specified, our system will make a best effort to sample deterministically, such that repeated requests with the same seed and parameters should return the same result. Determinism is not guaranteed.",
            },
            previous_text: {
              type: "string",
              title: "Previous Text",
              description:
                "The text that came before the text of the current request. Can be used to improve the flow of prosody when concatenating together multiple generations or to influence the prosody in the current generation.",
            },
            next_text: {
              type: "string",
              title: "Next Text",
              description:
                "The text that comes after the text of the current request. Can be used to improve the flow of prosody when concatenating together multiple generations or to influence the prosody in the current generation.",
            },
            previous_request_ids: {
              items: { type: "string" },
              type: "array",
              title: "Previous Request Ids",
              description:
                "A list of request_id of the samples that were generated before this generation. Can be used to improve the flow of prosody when splitting up a large task into multiple requests. The results will be best when the same model is used across the generations. In case both previous_text and previous_request_ids is send, previous_text will be ignored. A maximum of 3 request_ids can be send.",
              default: [],
            },
            next_request_ids: {
              items: { type: "string" },
              type: "array",
              title: "Next Request Ids",
              description:
                "A list of request_id of the samples that were generated before this generation. Can be used to improve the flow of prosody when splitting up a large task into multiple requests. The results will be best when the same model is used across the generations. In case both next_text and next_request_ids is send, next_text will be ignored. A maximum of 3 request_ids can be send.",
              default: [],
            },
          },
          type: "object",
          required: ["text"],
          title:
            "Body_Text_to_speech_with_timestamps_v1_text_to_speech__voice_id__with_timestamps_post",
        },
      Body_Update_Pronunciation_Dictionaries_v1_projects__project_id__update_pronunciation_dictionaries_post:
        {
          properties: {
            pronunciation_dictionary_locators: {
              items: {
                $ref: "#/components/schemas/PronunciationDictionaryVersionLocatorDBModel",
              },
              type: "array",
              title: "Pronunciation Dictionary Locators",
              description:
                'A list of pronunciation dictionary locators (pronunciation_dictionary_id, version_id) encoded as a list of JSON strings for pronunciation dictionaries to be applied to the text.  A list of json encoded strings is required as adding projects may occur through formData as opposed to jsonBody. To specify multiple dictionaries use multiple --form lines in your curl, such as --form \'pronunciation_dictionary_locators="{\\"pronunciation_dictionary_id\\":\\"Vmd4Zor6fplcA7WrINey\\",\\"version_id\\":\\"hRPaxjlTdR7wFMhV4w0b\\"}"\' --form \'pronunciation_dictionary_locators="{\\"pronunciation_dictionary_id\\":\\"JzWtcGQMJ6bnlWwyMo7e\\",\\"version_id\\":\\"lbmwxiLu4q6txYxgdZqn\\"}"\'. Note that multiple dictionaries are not currently supported by our UI which will only show the first.',
            },
          },
          type: "object",
          required: ["pronunciation_dictionary_locators"],
          title:
            "Body_Update_Pronunciation_Dictionaries_v1_projects__project_id__update_pronunciation_dictionaries_post",
        },
      Body_Update_member_v1_workspace_members_post: {
        properties: {
          email: {
            type: "string",
            title: "Email",
            description: "Email of the target user.",
          },
          is_locked: {
            type: "boolean",
            title: "Is Locked",
            description: "Whether to lock or unlock the user account.",
          },
          workspace_role: {
            type: "string",
            enum: ["workspace_admin", "workspace_member"],
            title: "Workspace Role",
            description: "Role dictating permissions in the workspace.",
          },
        },
        type: "object",
        required: ["email"],
        title: "Body_Update_member_v1_workspace_members_post",
      },
      ChapterResponseModel: {
        properties: {
          chapter_id: { type: "string", title: "Chapter Id" },
          name: { type: "string", title: "Name" },
          last_conversion_date_unix: {
            type: "integer",
            title: "Last Conversion Date Unix",
          },
          conversion_progress: { type: "number", title: "Conversion Progress" },
          can_be_downloaded: { type: "boolean", title: "Can Be Downloaded" },
          state: {
            type: "string",
            enum: ["default", "converting"],
            title: "State",
          },
          statistics: {
            $ref: "#/components/schemas/ChapterStatisticsResponseModel",
          },
        },
        type: "object",
        required: [
          "chapter_id",
          "name",
          "last_conversion_date_unix",
          "conversion_progress",
          "can_be_downloaded",
          "state",
          "statistics",
        ],
        title: "ChapterResponseModel",
      },
      ChapterSnapshotResponseModel: {
        properties: {
          chapter_snapshot_id: { type: "string", title: "Chapter Snapshot Id" },
          project_id: { type: "string", title: "Project Id" },
          chapter_id: { type: "string", title: "Chapter Id" },
          created_at_unix: { type: "integer", title: "Created At Unix" },
          name: { type: "string", title: "Name" },
        },
        type: "object",
        required: [
          "chapter_snapshot_id",
          "project_id",
          "chapter_id",
          "created_at_unix",
          "name",
        ],
        title: "ChapterSnapshotResponseModel",
      },
      ChapterSnapshotsResponseModel: {
        properties: {
          snapshots: {
            items: {
              $ref: "#/components/schemas/ChapterSnapshotResponseModel",
            },
            type: "array",
            title: "Snapshots",
          },
        },
        type: "object",
        required: ["snapshots"],
        title: "ChapterSnapshotsResponseModel",
      },
      ChapterStatisticsResponseModel: {
        properties: {
          characters_unconverted: {
            type: "integer",
            title: "Characters Unconverted",
          },
          characters_converted: {
            type: "integer",
            title: "Characters Converted",
          },
          paragraphs_converted: {
            type: "integer",
            title: "Paragraphs Converted",
          },
          paragraphs_unconverted: {
            type: "integer",
            title: "Paragraphs Unconverted",
          },
        },
        type: "object",
        required: [
          "characters_unconverted",
          "characters_converted",
          "paragraphs_converted",
          "paragraphs_unconverted",
        ],
        title: "ChapterStatisticsResponseModel",
      },
      DoDubbingResponseModel: {
        properties: {
          dubbing_id: { type: "string", title: "Dubbing Id" },
          expected_duration_sec: {
            type: "number",
            title: "Expected Duration Sec",
          },
        },
        type: "object",
        required: ["dubbing_id", "expected_duration_sec"],
        title: "DoDubbingResponseModel",
      },
      DubbingMetadataResponse: {
        properties: {
          dubbing_id: { type: "string", title: "Dubbing Id" },
          name: { type: "string", title: "Name" },
          status: { type: "string", title: "Status" },
          target_languages: {
            items: { type: "string" },
            type: "array",
            title: "Target Languages",
          },
          error: { type: "string", title: "Error" },
        },
        type: "object",
        required: ["dubbing_id", "name", "status", "target_languages"],
        title: "DubbingMetadataResponse",
      },
      EditProjectResponseModel: {
        properties: {
          project: { $ref: "#/components/schemas/ProjectResponseModel" },
        },
        type: "object",
        required: ["project"],
        title: "EditProjectResponseModel",
      },
      ExtendedSubscriptionResponseModel: {
        properties: {
          tier: { type: "string", title: "Tier" },
          character_count: { type: "integer", title: "Character Count" },
          character_limit: { type: "integer", title: "Character Limit" },
          can_extend_character_limit: {
            type: "boolean",
            title: "Can Extend Character Limit",
          },
          allowed_to_extend_character_limit: {
            type: "boolean",
            title: "Allowed To Extend Character Limit",
          },
          next_character_count_reset_unix: {
            type: "integer",
            title: "Next Character Count Reset Unix",
          },
          voice_limit: { type: "integer", title: "Voice Limit" },
          max_voice_add_edits: {
            type: "integer",
            title: "Max Voice Add Edits",
          },
          voice_add_edit_counter: {
            type: "integer",
            title: "Voice Add Edit Counter",
          },
          professional_voice_limit: {
            type: "integer",
            title: "Professional Voice Limit",
          },
          can_extend_voice_limit: {
            type: "boolean",
            title: "Can Extend Voice Limit",
          },
          can_use_instant_voice_cloning: {
            type: "boolean",
            title: "Can Use Instant Voice Cloning",
          },
          can_use_professional_voice_cloning: {
            type: "boolean",
            title: "Can Use Professional Voice Cloning",
          },
          currency: { type: "string", enum: ["usd", "eur"], title: "Currency" },
          status: {
            type: "string",
            enum: [
              "trialing",
              "active",
              "incomplete",
              "incomplete_expired",
              "past_due",
              "canceled",
              "unpaid",
              "free",
            ],
            title: "Status",
          },
          billing_period: {
            type: "string",
            enum: ["monthly_period", "annual_period"],
            title: "Billing Period",
          },
          character_refresh_period: {
            type: "string",
            enum: ["monthly_period", "annual_period"],
            title: "Character Refresh Period",
          },
          next_invoice: { $ref: "#/components/schemas/InvoiceResponseModel" },
          has_open_invoices: { type: "boolean", title: "Has Open Invoices" },
        },
        type: "object",
        required: [
          "tier",
          "character_count",
          "character_limit",
          "can_extend_character_limit",
          "allowed_to_extend_character_limit",
          "next_character_count_reset_unix",
          "voice_limit",
          "max_voice_add_edits",
          "voice_add_edit_counter",
          "professional_voice_limit",
          "can_extend_voice_limit",
          "can_use_instant_voice_cloning",
          "can_use_professional_voice_cloning",
          "currency",
          "status",
          "billing_period",
          "character_refresh_period",
          "next_invoice",
          "has_open_invoices",
        ],
        title: "ExtendedSubscriptionResponseModel",
      },
      FeedbackResponseModel: {
        properties: {
          thumbs_up: { type: "boolean", title: "Thumbs Up" },
          feedback: { type: "string", title: "Feedback" },
          emotions: { type: "boolean", title: "Emotions" },
          inaccurate_clone: { type: "boolean", title: "Inaccurate Clone" },
          glitches: { type: "boolean", title: "Glitches" },
          audio_quality: { type: "boolean", title: "Audio Quality" },
          other: { type: "boolean", title: "Other" },
          review_status: {
            type: "string",
            title: "Review Status",
            default: "not_reviewed",
          },
        },
        type: "object",
        required: [
          "thumbs_up",
          "feedback",
          "emotions",
          "inaccurate_clone",
          "glitches",
          "audio_quality",
          "other",
        ],
        title: "FeedbackResponseModel",
      },
      FineTuningResponseModel: {
        properties: {
          is_allowed_to_fine_tune: {
            type: "boolean",
            title: "Is Allowed To Fine Tune",
          },
          finetuning_state: {
            type: "string",
            enum: [
              "not_started",
              "queued",
              "fine_tuning",
              "fine_tuned",
              "failed",
              "delayed",
            ],
            title: "Finetuning State",
          },
          verification_failures: {
            items: { type: "string" },
            type: "array",
            title: "Verification Failures",
          },
          verification_attempts_count: {
            type: "integer",
            title: "Verification Attempts Count",
          },
          manual_verification_requested: {
            type: "boolean",
            title: "Manual Verification Requested",
          },
          language: { type: "string", title: "Language" },
          finetuning_progress: {
            additionalProperties: { type: "number" },
            type: "object",
            title: "Finetuning Progress",
          },
          message: { type: "string", title: "Message" },
          dataset_duration_seconds: {
            type: "number",
            title: "Dataset Duration Seconds",
          },
          verification_attempts: {
            items: {
              $ref: "#/components/schemas/VerificationAttemptResponseModel",
            },
            type: "array",
            title: "Verification Attempts",
          },
          slice_ids: {
            items: { type: "string" },
            type: "array",
            title: "Slice Ids",
          },
          manual_verification: {
            $ref: "#/components/schemas/ManualVerificationResponseModel",
          },
        },
        type: "object",
        required: [
          "is_allowed_to_fine_tune",
          "finetuning_state",
          "verification_failures",
          "verification_attempts_count",
          "manual_verification_requested",
        ],
        title: "FineTuningResponseModel",
      },
      GetChaptersResponseModel: {
        properties: {
          chapters: {
            items: { $ref: "#/components/schemas/ChapterResponseModel" },
            type: "array",
            title: "Chapters",
          },
        },
        type: "object",
        required: ["chapters"],
        title: "GetChaptersResponseModel",
      },
      GetLibraryVoicesResponseModel: {
        properties: {
          voices: {
            items: { $ref: "#/components/schemas/LibraryVoiceResponseModel" },
            type: "array",
            title: "Voices",
          },
          has_more: { type: "boolean", title: "Has More" },
          last_sort_id: { type: "string", title: "Last Sort Id" },
        },
        type: "object",
        required: ["voices", "has_more"],
        title: "GetLibraryVoicesResponseModel",
      },
      GetProjectsResponseModel: {
        properties: {
          projects: {
            items: { $ref: "#/components/schemas/ProjectResponseModel" },
            type: "array",
            title: "Projects",
          },
        },
        type: "object",
        required: ["projects"],
        title: "GetProjectsResponseModel",
      },
      GetPronunciationDictionariesMetadataResponseModel: {
        properties: {
          pronunciation_dictionaries: {
            items: {
              $ref: "#/components/schemas/GetPronunciationDictionaryMetadataResponseModel",
            },
            type: "array",
            title: "Pronunciation Dictionaries",
          },
          next_cursor: { type: "string", title: "Next Cursor" },
          has_more: { type: "boolean", title: "Has More" },
        },
        type: "object",
        required: ["pronunciation_dictionaries", "next_cursor", "has_more"],
        title: "GetPronunciationDictionariesMetadataResponseModel",
      },
      GetPronunciationDictionaryMetadataResponseModel: {
        properties: {
          id: { type: "string", title: "Id" },
          latest_version_id: { type: "string", title: "Latest Version Id" },
          name: { type: "string", title: "Name" },
          created_by: { type: "string", title: "Created By" },
          creation_time_unix: { type: "integer", title: "Creation Time Unix" },
          description: { type: "string", title: "Description" },
        },
        type: "object",
        required: [
          "id",
          "latest_version_id",
          "name",
          "created_by",
          "creation_time_unix",
        ],
        title: "GetPronunciationDictionaryMetadataResponseModel",
      },
      GetSpeechHistoryResponseModel: {
        properties: {
          history: {
            items: {
              $ref: "#/components/schemas/SpeechHistoryItemResponseModel",
            },
            type: "array",
            title: "History",
          },
          last_history_item_id: {
            type: "string",
            title: "Last History Item Id",
          },
          has_more: { type: "boolean", title: "Has More" },
        },
        type: "object",
        required: ["history", "last_history_item_id", "has_more"],
        title: "GetSpeechHistoryResponseModel",
      },
      GetVoicesResponseModel: {
        properties: {
          voices: {
            items: { $ref: "#/components/schemas/VoiceResponseModel" },
            type: "array",
            title: "Voices",
          },
        },
        type: "object",
        required: ["voices"],
        title: "GetVoicesResponseModel",
      },
      HTTPValidationError: {
        properties: {
          detail: {
            items: { $ref: "#/components/schemas/ValidationError" },
            type: "array",
            title: "Detail",
          },
        },
        type: "object",
        title: "HTTPValidationError",
      },
      InvoiceResponseModel: {
        properties: {
          amount_due_cents: { type: "integer", title: "Amount Due Cents" },
          next_payment_attempt_unix: {
            type: "integer",
            title: "Next Payment Attempt Unix",
          },
        },
        type: "object",
        required: ["amount_due_cents", "next_payment_attempt_unix"],
        title: "InvoiceResponseModel",
      },
      LanguageResponseModel: {
        properties: {
          language_id: { type: "string", title: "Language Id" },
          name: { type: "string", title: "Name" },
        },
        type: "object",
        required: ["language_id", "name"],
        title: "LanguageResponseModel",
      },
      LibraryVoiceResponseModel: {
        properties: {
          public_owner_id: { type: "string", title: "Public Owner Id" },
          voice_id: { type: "string", title: "Voice Id" },
          date_unix: { type: "integer", title: "Date Unix" },
          name: { type: "string", title: "Name" },
          accent: { type: "string", title: "Accent" },
          gender: { type: "string", title: "Gender" },
          age: { type: "string", title: "Age" },
          descriptive: { type: "string", title: "Descriptive" },
          use_case: { type: "string", title: "Use Case" },
          category: { type: "string", title: "Category" },
          language: { type: "string", title: "Language" },
          description: { type: "string", title: "Description" },
          preview_url: { type: "string", title: "Preview Url" },
          usage_character_count_1y: {
            type: "integer",
            title: "Usage Character Count 1Y",
          },
          usage_character_count_7d: {
            type: "integer",
            title: "Usage Character Count 7D",
          },
          play_api_usage_character_count_1y: {
            type: "integer",
            title: "Play Api Usage Character Count 1Y",
          },
          cloned_by_count: { type: "integer", title: "Cloned By Count" },
          rate: { type: "number", title: "Rate" },
          free_users_allowed: { type: "boolean", title: "Free Users Allowed" },
          live_moderation_enabled: {
            type: "boolean",
            title: "Live Moderation Enabled",
          },
          featured: { type: "boolean", title: "Featured" },
          notice_period: { type: "integer", title: "Notice Period" },
          instagram_username: { type: "string", title: "Instagram Username" },
          twitter_username: { type: "string", title: "Twitter Username" },
          youtube_username: { type: "string", title: "Youtube Username" },
          tiktok_username: { type: "string", title: "Tiktok Username" },
        },
        type: "object",
        required: [
          "public_owner_id",
          "voice_id",
          "date_unix",
          "name",
          "accent",
          "gender",
          "age",
          "descriptive",
          "use_case",
          "category",
          "language",
          "description",
          "preview_url",
          "usage_character_count_1y",
          "usage_character_count_7d",
          "play_api_usage_character_count_1y",
          "cloned_by_count",
          "rate",
          "free_users_allowed",
          "live_moderation_enabled",
          "featured",
        ],
        title: "LibraryVoiceResponseModel",
      },
      ManualVerificationFileResponseModel: {
        properties: {
          file_id: { type: "string", title: "File Id" },
          file_name: { type: "string", title: "File Name" },
          mime_type: { type: "string", title: "Mime Type" },
          size_bytes: { type: "integer", title: "Size Bytes" },
          upload_date_unix: { type: "integer", title: "Upload Date Unix" },
        },
        type: "object",
        required: [
          "file_id",
          "file_name",
          "mime_type",
          "size_bytes",
          "upload_date_unix",
        ],
        title: "ManualVerificationFileResponseModel",
      },
      ManualVerificationResponseModel: {
        properties: {
          extra_text: { type: "string", title: "Extra Text" },
          request_time_unix: { type: "integer", title: "Request Time Unix" },
          files: {
            items: {
              $ref: "#/components/schemas/ManualVerificationFileResponseModel",
            },
            type: "array",
            title: "Files",
          },
        },
        type: "object",
        required: ["extra_text", "request_time_unix", "files"],
        title: "ManualVerificationResponseModel",
      },
      ModelResponseModel: {
        properties: {
          model_id: { type: "string", title: "Model Id" },
          name: { type: "string", title: "Name" },
          can_be_finetuned: { type: "boolean", title: "Can Be Finetuned" },
          can_do_text_to_speech: {
            type: "boolean",
            title: "Can Do Text To Speech",
          },
          can_do_voice_conversion: {
            type: "boolean",
            title: "Can Do Voice Conversion",
          },
          can_use_style: { type: "boolean", title: "Can Use Style" },
          can_use_speaker_boost: {
            type: "boolean",
            title: "Can Use Speaker Boost",
          },
          serves_pro_voices: { type: "boolean", title: "Serves Pro Voices" },
          token_cost_factor: { type: "number", title: "Token Cost Factor" },
          description: { type: "string", title: "Description" },
          requires_alpha_access: {
            type: "boolean",
            title: "Requires Alpha Access",
          },
          max_characters_request_free_user: {
            type: "integer",
            title: "Max Characters Request Free User",
          },
          max_characters_request_subscribed_user: {
            type: "integer",
            title: "Max Characters Request Subscribed User",
          },
          maximum_text_length_per_request: {
            type: "integer",
            title: "Maximum Text Length Per Request",
          },
          languages: {
            items: { $ref: "#/components/schemas/LanguageResponseModel" },
            type: "array",
            title: "Languages",
          },
        },
        type: "object",
        required: [
          "model_id",
          "name",
          "can_be_finetuned",
          "can_do_text_to_speech",
          "can_do_voice_conversion",
          "can_use_style",
          "can_use_speaker_boost",
          "serves_pro_voices",
          "token_cost_factor",
          "description",
          "requires_alpha_access",
          "max_characters_request_free_user",
          "max_characters_request_subscribed_user",
          "maximum_text_length_per_request",
          "languages",
        ],
        title: "ModelResponseModel",
      },
      ProfilePageResponseModel: {
        properties: {
          handle: { type: "string", title: "Handle" },
          public_user_id: { type: "string", title: "Public User Id" },
          name: { type: "string", title: "Name" },
          bio: { type: "string", title: "Bio" },
          profile_picture: { type: "string", title: "Profile Picture" },
        },
        type: "object",
        required: [
          "handle",
          "public_user_id",
          "name",
          "bio",
          "profile_picture",
        ],
        title: "ProfilePageResponseModel",
      },
      ProjectExtendedResponseModel: {
        properties: {
          project_id: { type: "string", title: "Project Id" },
          name: { type: "string", title: "Name" },
          create_date_unix: { type: "integer", title: "Create Date Unix" },
          default_title_voice_id: {
            type: "string",
            title: "Default Title Voice Id",
          },
          default_paragraph_voice_id: {
            type: "string",
            title: "Default Paragraph Voice Id",
          },
          default_model_id: { type: "string", title: "Default Model Id" },
          last_conversion_date_unix: {
            type: "integer",
            title: "Last Conversion Date Unix",
          },
          can_be_downloaded: { type: "boolean", title: "Can Be Downloaded" },
          state: {
            type: "string",
            enum: ["default", "converting", "in_queue"],
            title: "State",
          },
          chapters: {
            items: { $ref: "#/components/schemas/ChapterResponseModel" },
            type: "array",
            title: "Chapters",
          },
        },
        type: "object",
        required: [
          "project_id",
          "name",
          "create_date_unix",
          "default_title_voice_id",
          "default_paragraph_voice_id",
          "default_model_id",
          "last_conversion_date_unix",
          "can_be_downloaded",
          "state",
          "chapters",
        ],
        title: "ProjectExtendedResponseModel",
      },
      ProjectResponseModel: {
        properties: {
          project_id: { type: "string", title: "Project Id" },
          name: { type: "string", title: "Name" },
          create_date_unix: { type: "integer", title: "Create Date Unix" },
          default_title_voice_id: {
            type: "string",
            title: "Default Title Voice Id",
          },
          default_paragraph_voice_id: {
            type: "string",
            title: "Default Paragraph Voice Id",
          },
          default_model_id: { type: "string", title: "Default Model Id" },
          last_conversion_date_unix: {
            type: "integer",
            title: "Last Conversion Date Unix",
          },
          can_be_downloaded: { type: "boolean", title: "Can Be Downloaded" },
          title: { type: "string", title: "Title" },
          author: { type: "string", title: "Author" },
          isbn_number: { type: "string", title: "Isbn Number" },
          volume_normalization: {
            type: "boolean",
            title: "Volume Normalization",
          },
          state: {
            type: "string",
            enum: ["default", "converting"],
            title: "State",
          },
        },
        type: "object",
        required: [
          "project_id",
          "name",
          "create_date_unix",
          "default_title_voice_id",
          "default_paragraph_voice_id",
          "default_model_id",
          "last_conversion_date_unix",
          "can_be_downloaded",
          "title",
          "author",
          "isbn_number",
          "volume_normalization",
          "state",
        ],
        title: "ProjectResponseModel",
      },
      ProjectSnapshotResponseModel: {
        properties: {
          project_snapshot_id: { type: "string", title: "Project Snapshot Id" },
          project_id: { type: "string", title: "Project Id" },
          created_at_unix: { type: "integer", title: "Created At Unix" },
          name: { type: "string", title: "Name" },
        },
        type: "object",
        required: [
          "project_snapshot_id",
          "project_id",
          "created_at_unix",
          "name",
        ],
        title: "ProjectSnapshotResponseModel",
      },
      ProjectSnapshotsResponseModel: {
        properties: {
          snapshots: {
            items: {
              $ref: "#/components/schemas/ProjectSnapshotResponseModel",
            },
            type: "array",
            title: "Snapshots",
          },
        },
        type: "object",
        required: ["snapshots"],
        title: "ProjectSnapshotsResponseModel",
      },
      PronunciationDictionaryAliasRuleRequestModel: {
        properties: {
          type: { type: "string", enum: ["alias"], title: "Type" },
          string_to_replace: { type: "string", title: "String To Replace" },
          alias: { type: "string", title: "Alias" },
        },
        type: "object",
        required: ["type", "string_to_replace", "alias"],
        title: "PronunciationDictionaryAliasRuleRequestModel",
      },
      PronunciationDictionaryPhonemeRuleRequestModel: {
        properties: {
          type: { type: "string", enum: ["phoneme"], title: "Type" },
          string_to_replace: { type: "string", title: "String To Replace" },
          phoneme: { type: "string", title: "Phoneme" },
          alphabet: { type: "string", title: "Alphabet" },
        },
        type: "object",
        required: ["type", "string_to_replace", "phoneme", "alphabet"],
        title: "PronunciationDictionaryPhonemeRuleRequestModel",
      },
      PronunciationDictionaryVersionLocatorDBModel: {
        properties: {
          pronunciation_dictionary_id: {
            type: "string",
            title: "Pronunciation Dictionary Id",
          },
          version_id: { type: "string", title: "Version Id" },
        },
        type: "object",
        required: ["pronunciation_dictionary_id", "version_id"],
        title: "PronunciationDictionaryVersionLocatorDBModel",
      },
      RecordingResponseModel: {
        properties: {
          recording_id: { type: "string", title: "Recording Id" },
          mime_type: { type: "string", title: "Mime Type" },
          size_bytes: { type: "integer", title: "Size Bytes" },
          upload_date_unix: { type: "integer", title: "Upload Date Unix" },
          transcription: { type: "string", title: "Transcription" },
        },
        type: "object",
        required: [
          "recording_id",
          "mime_type",
          "size_bytes",
          "upload_date_unix",
          "transcription",
        ],
        title: "RecordingResponseModel",
      },
      RemovePronunciationDictionaryRulesResponseModel: {
        properties: {
          id: { type: "string", title: "Id" },
          version_id: { type: "string", title: "Version Id" },
        },
        type: "object",
        required: ["id", "version_id"],
        title: "RemovePronunciationDictionaryRulesResponseModel",
      },
      SampleResponseModel: {
        properties: {
          sample_id: { type: "string", title: "Sample Id" },
          file_name: { type: "string", title: "File Name" },
          mime_type: { type: "string", title: "Mime Type" },
          size_bytes: { type: "integer", title: "Size Bytes" },
          hash: { type: "string", title: "Hash" },
        },
        type: "object",
        required: ["sample_id", "file_name", "mime_type", "size_bytes", "hash"],
        title: "SampleResponseModel",
      },
      SpeechHistoryItemResponseModel: {
        properties: {
          history_item_id: { type: "string", title: "History Item Id" },
          request_id: { type: "string", title: "Request Id" },
          voice_id: { type: "string", title: "Voice Id" },
          model_id: { type: "string", title: "Model Id" },
          voice_name: { type: "string", title: "Voice Name" },
          voice_category: {
            type: "string",
            enum: ["premade", "cloned", "generated", "professional"],
            title: "Voice Category",
          },
          text: { type: "string", title: "Text" },
          date_unix: { type: "integer", title: "Date Unix" },
          character_count_change_from: {
            type: "integer",
            title: "Character Count Change From",
          },
          character_count_change_to: {
            type: "integer",
            title: "Character Count Change To",
          },
          content_type: { type: "string", title: "Content Type" },
          state: {
            type: "string",
            enum: ["created", "deleted", "processing"],
            title: "State",
          },
          settings: { type: "object", title: "Settings" },
          feedback: { $ref: "#/components/schemas/FeedbackResponseModel" },
          share_link_id: { type: "string", title: "Share Link Id" },
          source: { type: "string", enum: ["TTS", "STS"], title: "Source" },
        },
        type: "object",
        required: [
          "history_item_id",
          "request_id",
          "voice_id",
          "model_id",
          "voice_name",
          "voice_category",
          "text",
          "date_unix",
          "character_count_change_from",
          "character_count_change_to",
          "content_type",
          "state",
          "settings",
          "feedback",
          "share_link_id",
          "source",
        ],
        title: "SpeechHistoryItemResponseModel",
      },
      SsoProviderDBModel: {
        properties: {
          provider_type: {
            type: "string",
            enum: ["saml", "oidc"],
            title: "Provider Type",
          },
          provider_id: { type: "string", title: "Provider Id" },
          domains: {
            items: { type: "string" },
            type: "array",
            title: "Domains",
          },
        },
        type: "object",
        required: ["provider_type", "provider_id", "domains"],
        title: "SsoProviderDBModel",
      },
      SubscriptionResponseModel: {
        properties: {
          tier: { type: "string", title: "Tier" },
          character_count: { type: "integer", title: "Character Count" },
          character_limit: { type: "integer", title: "Character Limit" },
          can_extend_character_limit: {
            type: "boolean",
            title: "Can Extend Character Limit",
          },
          allowed_to_extend_character_limit: {
            type: "boolean",
            title: "Allowed To Extend Character Limit",
          },
          next_character_count_reset_unix: {
            type: "integer",
            title: "Next Character Count Reset Unix",
          },
          voice_limit: { type: "integer", title: "Voice Limit" },
          max_voice_add_edits: {
            type: "integer",
            title: "Max Voice Add Edits",
          },
          voice_add_edit_counter: {
            type: "integer",
            title: "Voice Add Edit Counter",
          },
          professional_voice_limit: {
            type: "integer",
            title: "Professional Voice Limit",
          },
          can_extend_voice_limit: {
            type: "boolean",
            title: "Can Extend Voice Limit",
          },
          can_use_instant_voice_cloning: {
            type: "boolean",
            title: "Can Use Instant Voice Cloning",
          },
          can_use_professional_voice_cloning: {
            type: "boolean",
            title: "Can Use Professional Voice Cloning",
          },
          currency: { type: "string", enum: ["usd", "eur"], title: "Currency" },
          status: {
            type: "string",
            enum: [
              "trialing",
              "active",
              "incomplete",
              "incomplete_expired",
              "past_due",
              "canceled",
              "unpaid",
              "free",
            ],
            title: "Status",
          },
          billing_period: {
            type: "string",
            enum: ["monthly_period", "annual_period"],
            title: "Billing Period",
          },
          character_refresh_period: {
            type: "string",
            enum: ["monthly_period", "annual_period"],
            title: "Character Refresh Period",
          },
        },
        type: "object",
        required: [
          "tier",
          "character_count",
          "character_limit",
          "can_extend_character_limit",
          "allowed_to_extend_character_limit",
          "next_character_count_reset_unix",
          "voice_limit",
          "max_voice_add_edits",
          "voice_add_edit_counter",
          "professional_voice_limit",
          "can_extend_voice_limit",
          "can_use_instant_voice_cloning",
          "can_use_professional_voice_cloning",
          "currency",
          "status",
          "billing_period",
          "character_refresh_period",
        ],
        title: "SubscriptionResponseModel",
      },
      UserResponseModel: {
        properties: {
          subscription: {
            $ref: "#/components/schemas/SubscriptionResponseModel",
          },
          is_new_user: { type: "boolean", title: "Is New User" },
          xi_api_key: { type: "string", title: "Xi Api Key" },
          can_use_delayed_payment_methods: {
            type: "boolean",
            title: "Can Use Delayed Payment Methods",
          },
          is_onboarding_completed: {
            type: "boolean",
            title: "Is Onboarding Completed",
          },
          is_onboarding_checklist_completed: {
            type: "boolean",
            title: "Is Onboarding Checklist Completed",
          },
          first_name: { type: "string", title: "First Name" },
        },
        type: "object",
        required: [
          "subscription",
          "is_new_user",
          "xi_api_key",
          "can_use_delayed_payment_methods",
          "is_onboarding_completed",
          "is_onboarding_checklist_completed",
        ],
        title: "UserResponseModel",
      },
      ValidationError: {
        properties: {
          loc: {
            items: { anyOf: [{ type: "string" }, { type: "integer" }] },
            type: "array",
            title: "Location",
          },
          msg: { type: "string", title: "Message" },
          type: { type: "string", title: "Error Type" },
        },
        type: "object",
        required: ["loc", "msg", "type"],
        title: "ValidationError",
      },
      VerificationAttemptResponseModel: {
        properties: {
          text: { type: "string", title: "Text" },
          date_unix: { type: "integer", title: "Date Unix" },
          accepted: { type: "boolean", title: "Accepted" },
          similarity: { type: "number", title: "Similarity" },
          levenshtein_distance: {
            type: "number",
            title: "Levenshtein Distance",
          },
          recording: { $ref: "#/components/schemas/RecordingResponseModel" },
        },
        type: "object",
        required: [
          "text",
          "date_unix",
          "accepted",
          "similarity",
          "levenshtein_distance",
        ],
        title: "VerificationAttemptResponseModel",
      },
      VoiceGenerationParameterOptionResponseModel: {
        properties: {
          name: { type: "string", title: "Name" },
          code: { type: "string", title: "Code" },
        },
        type: "object",
        required: ["name", "code"],
        title: "VoiceGenerationParameterOptionResponseModel",
      },
      VoiceGenerationParameterResponseModel: {
        properties: {
          genders: {
            items: {
              $ref: "#/components/schemas/VoiceGenerationParameterOptionResponseModel",
            },
            type: "array",
            title: "Genders",
          },
          accents: {
            items: {
              $ref: "#/components/schemas/VoiceGenerationParameterOptionResponseModel",
            },
            type: "array",
            title: "Accents",
          },
          ages: {
            items: {
              $ref: "#/components/schemas/VoiceGenerationParameterOptionResponseModel",
            },
            type: "array",
            title: "Ages",
          },
          minimum_characters: { type: "integer", title: "Minimum Characters" },
          maximum_characters: { type: "integer", title: "Maximum Characters" },
          minimum_accent_strength: {
            type: "number",
            title: "Minimum Accent Strength",
          },
          maximum_accent_strength: {
            type: "number",
            title: "Maximum Accent Strength",
          },
        },
        type: "object",
        required: [
          "genders",
          "accents",
          "ages",
          "minimum_characters",
          "maximum_characters",
          "minimum_accent_strength",
          "maximum_accent_strength",
        ],
        title: "VoiceGenerationParameterResponseModel",
      },
      VoiceResponseModel: {
        properties: {
          voice_id: { type: "string", title: "Voice Id" },
          name: { type: "string", title: "Name" },
          samples: {
            items: { $ref: "#/components/schemas/SampleResponseModel" },
            type: "array",
            title: "Samples",
          },
          category: { type: "string", title: "Category" },
          fine_tuning: { $ref: "#/components/schemas/FineTuningResponseModel" },
          labels: {
            additionalProperties: { type: "string" },
            type: "object",
            title: "Labels",
          },
          description: { type: "string", title: "Description" },
          preview_url: { type: "string", title: "Preview Url" },
          available_for_tiers: {
            items: { type: "string" },
            type: "array",
            title: "Available For Tiers",
          },
          settings: { $ref: "#/components/schemas/VoiceSettingsResponseModel" },
          sharing: { $ref: "#/components/schemas/VoiceSharingResponseModel" },
          high_quality_base_model_ids: {
            items: { type: "string" },
            type: "array",
            title: "High Quality Base Model Ids",
          },
          safety_control: {
            type: "string",
            enum: ["NONE", "BAN", "CAPTCHA", "CAPTCHA_AND_MODERATION"],
            title: "Safety Control",
          },
          voice_verification: {
            $ref: "#/components/schemas/VoiceVerificationResponseModel",
          },
          owner_id: { type: "string", title: "Owner Id" },
          permission_on_resource: {
            type: "string",
            title: "Permission On Resource",
          },
        },
        type: "object",
        required: [
          "voice_id",
          "name",
          "samples",
          "category",
          "fine_tuning",
          "labels",
          "description",
          "preview_url",
          "available_for_tiers",
          "settings",
          "sharing",
          "high_quality_base_model_ids",
        ],
        title: "VoiceResponseModel",
      },
      VoiceSettingsResponseModel: {
        properties: {
          stability: { type: "number", title: "Stability" },
          similarity_boost: { type: "number", title: "Similarity Boost" },
          style: { type: "number", title: "Style", default: 0.0 },
          use_speaker_boost: {
            type: "boolean",
            title: "Use Speaker Boost",
            default: true,
          },
        },
        type: "object",
        required: ["stability", "similarity_boost"],
        title: "VoiceSettingsResponseModel",
      },
      VoiceSharingResponseModel: {
        properties: {
          status: {
            type: "string",
            enum: ["enabled", "disabled", "copied", "copied_disabled"],
            title: "Status",
          },
          history_item_sample_id: {
            type: "string",
            title: "History Item Sample Id",
          },
          date_unix: { type: "integer", title: "Date Unix" },
          whitelisted_emails: {
            items: { type: "string" },
            type: "array",
            title: "Whitelisted Emails",
          },
          public_owner_id: { type: "string", title: "Public Owner Id" },
          original_voice_id: { type: "string", title: "Original Voice Id" },
          financial_rewards_enabled: {
            type: "boolean",
            title: "Financial Rewards Enabled",
          },
          free_users_allowed: { type: "boolean", title: "Free Users Allowed" },
          live_moderation_enabled: {
            type: "boolean",
            title: "Live Moderation Enabled",
          },
          rate: { type: "number", title: "Rate" },
          notice_period: { type: "integer", title: "Notice Period" },
          disable_at_unix: { type: "integer", title: "Disable At Unix" },
          voice_mixing_allowed: {
            type: "boolean",
            title: "Voice Mixing Allowed",
          },
          featured: { type: "boolean", title: "Featured" },
          category: {
            type: "string",
            enum: ["generated", "professional", "high_quality"],
            title: "Category",
          },
          reader_app_enabled: { type: "boolean", title: "Reader App Enabled" },
          ban_reason: { type: "string", title: "Ban Reason" },
          liked_by_count: { type: "integer", title: "Liked By Count" },
          cloned_by_count: { type: "integer", title: "Cloned By Count" },
          name: { type: "string", title: "Name" },
          description: { type: "string", title: "Description" },
          labels: {
            additionalProperties: { type: "string" },
            type: "object",
            title: "Labels",
          },
          review_status: {
            type: "string",
            enum: [
              "not_requested",
              "pending",
              "declined",
              "allowed",
              "allowed_with_changes",
            ],
            title: "Review Status",
          },
          review_message: { type: "string", title: "Review Message" },
          enabled_in_library: { type: "boolean", title: "Enabled In Library" },
          instagram_username: { type: "string", title: "Instagram Username" },
          twitter_username: { type: "string", title: "Twitter Username" },
          youtube_username: { type: "string", title: "Youtube Username" },
          tiktok_username: { type: "string", title: "Tiktok Username" },
        },
        type: "object",
        required: [
          "status",
          "history_item_sample_id",
          "date_unix",
          "whitelisted_emails",
          "public_owner_id",
          "original_voice_id",
          "financial_rewards_enabled",
          "free_users_allowed",
          "live_moderation_enabled",
          "rate",
          "notice_period",
          "disable_at_unix",
          "voice_mixing_allowed",
          "featured",
          "category",
          "reader_app_enabled",
          "ban_reason",
          "liked_by_count",
          "cloned_by_count",
          "name",
          "description",
          "labels",
          "review_status",
          "review_message",
          "enabled_in_library",
        ],
        title: "VoiceSharingResponseModel",
      },
      VoiceVerificationResponseModel: {
        properties: {
          requires_verification: {
            type: "boolean",
            title: "Requires Verification",
          },
          is_verified: { type: "boolean", title: "Is Verified" },
          verification_failures: {
            items: { type: "string" },
            type: "array",
            title: "Verification Failures",
          },
          verification_attempts_count: {
            type: "integer",
            title: "Verification Attempts Count",
          },
          language: { type: "string", title: "Language" },
          verification_attempts: {
            items: {
              $ref: "#/components/schemas/VerificationAttemptResponseModel",
            },
            type: "array",
            title: "Verification Attempts",
          },
        },
        type: "object",
        required: [
          "requires_verification",
          "is_verified",
          "verification_failures",
          "verification_attempts_count",
        ],
        title: "VoiceVerificationResponseModel",
      },
    },
  },
  tags: [
    {
      name: "text-to-speech",
      description:
        "Convert text into lifelike speech using a voice of your choice.",
    },
    {
      name: "speech-to-speech",
      description:
        "Create speech by combining the style and content of an audio file you upload with a voice of your choice.",
    },
    {
      name: "models",
      description: "Access the different models of the platform.",
    },
    {
      name: "voices",
      description: "Access to voices created either by you or us.",
    },
    {
      name: "samples",
      description:
        "Access to your samples. A sample is any audio file you attached to a voice. A voice can have one or more samples.",
    },
    {
      name: "speech-history",
      description:
        "Accesses your speech history. Your speech history is a list of all your created audio including its metadata using our text-to-speech and speech-to-speech models.",
    },
    {
      name: "projects",
      description:
        "Access, create and convert Projects programmatically, only specifically whitelisted accounts can access the Projects API. If you need access please contact our sales team.",
    },
  ],
};

export default { specs, title, modes, voices};
