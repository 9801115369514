import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useCurrentFrame, OffthreadVideo } from 'remotion';
import Moveable from 'react-moveable';
import { useWebSocket } from '../../lib/components/WebSocketContext';
import { useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { editorState, setAssetSelected, setSelectedComponentId } from '../../stores/EditorStore';
import { filterConvert } from '../../react-effect/filter';
import { ImageContainer } from '../../react-effect';

interface ResizableVideoProps {
  src: string;
  style: React.CSSProperties;
  className?: string;
  componentId: string;
  playbackRate: number;
  volume: number;
}

interface TransformState {
  translateX: number;
  translateY: number;
  rotate: number;
  scaleX: number;
  scaleY: number;
}

const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const parseTransform = (transform: string): TransformState => {
  const defaultState: TransformState = {
    translateX: 0, translateY: 0, rotate: 0, scaleX: 1, scaleY: 1
  };

  const translateMatch = transform.match(/translate\(([-\d.]+)px,\s*([-\d.]+)px\)/);
  const rotateMatch = transform.match(/rotate\(([-\d.]+)deg\)/);
  const scaleMatch = transform.match(/scale\(([-\d.]+)(?:,\s*([-\d.]+))?\)/);

  if (translateMatch) {
    defaultState.translateX = parseFloat(translateMatch[1]);
    defaultState.translateY = parseFloat(translateMatch[2]);
  }
  if (rotateMatch) {
    defaultState.rotate = parseFloat(rotateMatch[1]);
  }
  if (scaleMatch) {
    defaultState.scaleX = parseFloat(scaleMatch[1]);
    defaultState.scaleY = scaleMatch[2] ? parseFloat(scaleMatch[2]) : parseFloat(scaleMatch[1]);
  }

  return defaultState;
};

export const ResizableVideo: React.FC<ResizableVideoProps> = ({
  src,
  style,
  className,
  componentId,
  playbackRate,
  volume
}) => {
  const frame = useCurrentFrame();
  const { send } = useWebSocket();
  let { videoId = "" } = useParams();
  const targetRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState<any>({});
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const [size, setSize] = useState({ width: style.width, height: style.height });
  const [transformState, setTransformState] = useState<TransformState>(() =>
    parseTransform(style.transform as string)
  );
  const { selectedComponentId, editorProps } = useSnapshot(editorState);
  const [dragStartPosition, setDragStartPosition] = useState<TransformState | null>(null);

  useEffect(() => {
    // Check if this component should be selected based on global state
    if (selectedComponentId === componentId) {
      setTarget(targetRef.current);
    } else {
      setTarget(null);
    }
  }, [selectedComponentId, componentId]);

  useEffect(() => {
    setTransformState(parseTransform(style.transform as string));
  }, [style.transform]);

  const debouncedSend = useCallback(
    debounce((payload: any) => send(payload), 300),
    [send]
  );

  const updateServer = (props: any) => {
    const payload = {
      action: "updateProps",
      video: videoId,
      props,
      refresh: false
    };
    debouncedSend(payload);
  };

  const getTransformString = (state: TransformState) => {
    return `translate(${state.translateX}px, ${state.translateY}px) rotate(${state.rotate}deg) scale(${state.scaleX}, ${state.scaleY})`;
  };

  const combinedStyle: React.CSSProperties = {
    ...style,
    position: 'absolute',
    width: typeof Number(size.width) === 'number' ? `${size.width}px` : size.width,
    height: typeof Number(size.height) === 'number' ? `${size.height}px` : size.height,
    transform: getTransformString(transformState),
  };

  useEffect(() => {
    const filterProp = editorProps[`${componentId}_filter`];
    setFilter(filterConvert(filterProp as string))
  }, [componentId])

  if (filter.filter) {
    return (
      <>
        <div ref={targetRef} style={combinedStyle} className={className} onMouseDown={() => {
          setTarget(targetRef.current)
        }} onClick={() => {
          // Update the global state when this component is clicked
          setSelectedComponentId(componentId);
          setAssetSelected("Video");
        }}>
          <ImageContainer
            className={`cursor-pointer border-2'}`}
            options={filter}
          >
            <OffthreadVideo
              src={src}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              playbackRate={playbackRate}
              volume={volume}
            />
          </ImageContainer>
        </div>
        <Moveable
          target={target}
          resizable={true}
          draggable={true}
          rotatable={true}
          scalable={true}
          keepRatio={true}
          throttleResize={1}
          renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
          edge={false}
          zoom={1}
          origin={false}
          padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
          onResize={({ target, width, height, drag }) => {
            const newWidth = width;
            const newHeight = height;

            setSize({ width: newWidth, height: newHeight });
            target.style.width = `${newWidth}px`;
            target.style.height = `${newHeight}px`;

            const [newTranslateX, newTranslateY] = drag.beforeTranslate;

            setTransformState(prev => ({
              ...prev,
              translateX: newTranslateX,
              translateY: newTranslateY,
            }));

            target.style.transform = getTransformString({
              ...transformState,
              translateX: newTranslateX,
              translateY: newTranslateY,
            });
          }}
          onResizeEnd={() => {
            updateServer({
              [`${componentId}_width`]: size.width,
              [`${componentId}_height`]: size.height,
              [`${componentId}_translateX`]: transformState.translateX,
              [`${componentId}_translateY`]: transformState.translateY,
            });
          }}
          onDragStart={({ set }) => {
            set([transformState.translateX, transformState.translateY]);
            setDragStartPosition({ ...transformState });
          }}
          onDrag={({ transform }) => {
            const newTransform = parseTransform(transform);
            setTransformState(prev => ({
              ...prev,
              translateX: newTransform.translateX,
              translateY: newTransform.translateY,
            }));
          }}
          onDragEnd={() => {
            if (
              dragStartPosition &&
              (dragStartPosition.translateX !== transformState.translateX ||
                dragStartPosition.translateY !== transformState.translateY)
            ) {
              updateServer({
                [`${componentId}_translateX`]: transformState.translateX,
                [`${componentId}_translateY`]: transformState.translateY,
              });
            }
          }}
          onRotateStart={({ set }) => {
            set(transformState.rotate);
          }}
          onRotate={({ rotate }) => {
            setTransformState(prev => ({
              ...prev,
              rotate,
            }));
          }}
          onRotateEnd={() => {
            updateServer({ [`${componentId}_rotate`]: transformState.rotate });
          }}
          onScaleStart={({ set }) => {
            set([transformState.scaleX, transformState.scaleY]);
          }}
          onScale={({ scale }) => {
            setTransformState(prev => ({
              ...prev,
              scaleX: scale[0],
              scaleY: scale[1],
            }));
          }}
          onScaleEnd={() => {
            updateServer({
              [`${componentId}_scaleX`]: transformState.scaleX,
              [`${componentId}_scaleY`]: transformState.scaleY,
            });
          }}
        />
      </>
    )
  }

  return (
    <>
      <div ref={targetRef} style={combinedStyle} className={className} onMouseDown={() => {
        setTarget(targetRef.current)
      }} onClick={() => {
        // Update the global state when this component is clicked
        setSelectedComponentId(componentId);
        setAssetSelected("Video");
      }}>
        <OffthreadVideo
          src={src}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          playbackRate={playbackRate}
          volume={volume}
        />
      </div>
      <Moveable
        target={target}
        resizable={true}
        draggable={true}
        rotatable={true}
        scalable={true}
        keepRatio={true}
        throttleResize={1}
        renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
        edge={false}
        zoom={1}
        origin={false}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        onResize={({ target, width, height, drag }) => {
          const newWidth = width;
          const newHeight = height;

          setSize({ width: newWidth, height: newHeight });
          target.style.width = `${newWidth}px`;
          target.style.height = `${newHeight}px`;

          const [newTranslateX, newTranslateY] = drag.beforeTranslate;

          setTransformState(prev => ({
            ...prev,
            translateX: newTranslateX,
            translateY: newTranslateY,
          }));

          target.style.transform = getTransformString({
            ...transformState,
            translateX: newTranslateX,
            translateY: newTranslateY,
          });
        }}
        onResizeEnd={() => {
          updateServer({
            [`${componentId}_width`]: size.width,
            [`${componentId}_height`]: size.height,
            [`${componentId}_translateX`]: transformState.translateX,
            [`${componentId}_translateY`]: transformState.translateY,
          });
        }}
        onDragStart={({ set }) => {
          set([transformState.translateX, transformState.translateY]);
          setDragStartPosition({ ...transformState });
        }}
        onDrag={({ transform }) => {
          const newTransform = parseTransform(transform);
          setTransformState(prev => ({
            ...prev,
            translateX: newTransform.translateX,
            translateY: newTransform.translateY,
          }));
        }}
        onDragEnd={() => {
          if (
            dragStartPosition &&
            (dragStartPosition.translateX !== transformState.translateX ||
              dragStartPosition.translateY !== transformState.translateY)
          ) {
            updateServer({
              [`${componentId}_translateX`]: transformState.translateX,
              [`${componentId}_translateY`]: transformState.translateY,
            });
          }
        }}
        onRotateStart={({ set }) => {
          set(transformState.rotate);
        }}
        onRotate={({ rotate }) => {
          setTransformState(prev => ({
            ...prev,
            rotate,
          }));
        }}
        onRotateEnd={() => {
          updateServer({ [`${componentId}_rotate`]: transformState.rotate });
        }}
        onScaleStart={({ set }) => {
          set([transformState.scaleX, transformState.scaleY]);
        }}
        onScale={({ scale }) => {
          setTransformState(prev => ({
            ...prev,
            scaleX: scale[0],
            scaleY: scale[1],
          }));
        }}
        onScaleEnd={() => {
          updateServer({
            [`${componentId}_scaleX`]: transformState.scaleX,
            [`${componentId}_scaleY`]: transformState.scaleY,
          });
        }}
      />
    </>
  );
};

export default ResizableVideo;