import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Blog() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="relative py-12 md:py-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="relative max-w-sm md:max-w-8xl mx-auto">
              <div className="absolute top-0 left-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
              <div className="absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
              <div className="relative flex flex-wrap items-end -mx-4 mb-12">
                <div className="w-full lg:w-3/5 xl:w-auto px-4 mb-8 lg:mb-0">
                  <div>
                    <span className="block text-lg text-gray-500 tracking-tight mb-6">
                      Type Caption Here
                    </span>
                    <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight">
                      Recent Blog &amp; Articles
                    </h2>
                  </div>
                </div>
                <div className="w-full lg:w-2/5 xl:w-auto ml-auto px-4 lg:text-right">
                  <a
                    className="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-800 bg-violet-500 hover:bg-white transition duration-200 rounded-full"
                    href="#"
                  >
                    <span className="mr-2">SEE OUR PLANS</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="relative flex flex-wrap -mx-4">
                <div className="w-full md:w-1/3 px-4 mb-12 lg:mb-0">
                  <div>
                    <img
                      className="block w-full mb-6"
                      src="homepage/bromo-assets/blog/post-image1.png"
                      alt=""
                    />
                    <h3 className="text-2xl text-white font-medium mb-4">
                      Type you blog title here
                    </h3>
                    <p className="text-sm text-gray-300 leading-snug mb-4">
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions. Simply dummy
                      text of the printing and typesetting industry.
                    </p>
                    <a className="group flex items-center" href="#">
                      <div className="flex items-center justify-center w-14 h-14 rounded-full border border-gray-700 group-hover:bg-violet-600 group-hover:border-violet-600 transition duration-200">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <span className="ml-5 text-sm font-medium text-white">
                        Read more
                      </span>
                    </a>
                  </div>
                </div>
                <div className="w-full md:w-1/3 px-4 mb-12 lg:mb-0">
                  <div>
                    <img
                      className="block w-full mb-6"
                      src="homepage/bromo-assets/blog/post-image2.png"
                      alt=""
                    />
                    <h3 className="text-2xl text-white font-medium mb-4">
                      Type you blog title here
                    </h3>
                    <p className="text-sm text-gray-300 leading-snug mb-4">
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions. Simply dummy
                      text of the printing and typesetting industry.
                    </p>
                    <a className="group flex items-center" href="#">
                      <div className="flex items-center justify-center w-14 h-14 rounded-full border border-gray-700 group-hover:bg-violet-600 group-hover:border-violet-600 transition duration-200">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <span className="ml-5 text-sm font-medium text-white">
                        Read more
                      </span>
                    </a>
                  </div>
                </div>
                <div className="w-full md:w-1/3 px-4">
                  <div>
                    <img
                      className="block w-full mb-6"
                      src="homepage/bromo-assets/blog/post-image3.png"
                      alt=""
                    />
                    <h3 className="text-2xl text-white font-medium mb-4">
                      Type you blog title here
                    </h3>
                    <p className="text-sm text-gray-300 leading-snug mb-4">
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions. Simply dummy
                      text of the printing and typesetting industry.
                    </p>
                    <a className="group flex items-center" href="#">
                      <div className="flex items-center justify-center w-14 h-14 rounded-full border border-gray-700 group-hover:bg-violet-600 group-hover:border-violet-600 transition duration-200">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <span className="ml-5 text-sm font-medium text-white">
                        Read more
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-12 md:py-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="relative max-w-lg lg:max-w-8xl mx-auto">
              <div className="absolute top-0 left-0 -ml-64 -mt-80 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
              <div className="absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
              <div className="relative">
                <div className="max-w-2xl mx-auto mb-18 text-center">
                  <span className="block text-lg text-gray-500 tracking-tight mb-6">
                    Type Caption Here
                  </span>
                  <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight">
                    Blog &amp; Articles
                  </h2>
                </div>
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
                    <a className="block" href="#">
                      <img
                        className="block mb-5 w-full"
                        src="homepage/bromo-assets/blog/blog-5-article1.png"
                        alt=""
                      />
                      <div className="max-w-lg">
                        <span className="block text-base tracking-tight text-gray-200 opacity-70 mb-5">
                          Jul 20, 2022
                        </span>
                        <h3 className="text-3xl font-medium text-white mb-5">
                          Consectures Dummy Content Velit officia consequat duis
                          enim velit
                        </h3>
                        <p className="text-lg tracking-tight text-gray-100 opacity-60">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor do amet sint. Velit officia consequat duis enim
                          velit mollit xercitation.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="w-full lg:w-1/2 px-4">
                    <a
                      className="flex flex-wrap items-center -mx-4 mb-8"
                      href="#"
                    >
                      <div className="w-full sm:w-1/3 px-4 mb-6 sm:mb-0">
                        <img
                          className="block h-40 object-cover rounded-lg"
                          src="homepage/bromo-assets/blog/blog-5-article2.png"
                          alt=""
                        />
                      </div>
                      <div className="w-full sm:w-2/3 px-4">
                        <div className="max-w-xs">
                          <span className="block text-base tracking-tight text-gray-200 opacity-70 mb-2">
                            Jul 20, 2022
                          </span>
                          <h3 className="text-xl font-medium text-white tracking-tight leading-relaxed">
                            Consectures Content Velitpato officia consequat duis
                            enim velit mollit
                          </h3>
                        </div>
                      </div>
                    </a>
                    <a
                      className="flex flex-wrap items-center -mx-4 mb-8"
                      href="#"
                    >
                      <div className="w-full sm:w-1/3 px-4 mb-6 sm:mb-0">
                        <img
                          className="block h-40 object-cover rounded-lg"
                          src="homepage/bromo-assets/blog/blog-5-article3.png"
                          alt=""
                        />
                      </div>
                      <div className="w-full sm:w-2/3 px-4">
                        <div className="max-w-xs">
                          <span className="block text-base tracking-tight text-gray-200 opacity-70 mb-2">
                            Jul 20, 2022
                          </span>
                          <h3 className="text-xl font-medium text-white tracking-tight leading-relaxed">
                            Consectures Content Velitpato officia consequat duis
                            enim velit mollit
                          </h3>
                        </div>
                      </div>
                    </a>
                    <a className="flex flex-wrap items-center -mx-4" href="#">
                      <div className="w-full sm:w-1/3 px-4 mb-6 sm:mb-0">
                        <img
                          className="block h-40 object-cover rounded-lg"
                          src="homepage/bromo-assets/blog/blog-5-article4.png"
                          alt=""
                        />
                      </div>
                      <div className="w-full sm:w-2/3 px-4">
                        <div className="max-w-xs">
                          <span className="block text-base tracking-tight text-gray-200 opacity-70 mb-2">
                            Jul 20, 2022
                          </span>
                          <h3 className="text-xl font-medium text-white tracking-tight leading-relaxed">
                            Consectures Content Velitpato officia consequat duis
                            enim velit mollit
                          </h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
