import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  VStack,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Flex,
  Alert,
  AlertIcon,
  Spinner,
  Tag,
} from '@chakra-ui/react';
import { FiPlusSquare, FiUsers, FiDollarSign } from 'react-icons/fi';
import DashboardWrapper from '../../lib/components/Wrapper';
import { useNavigate } from 'react-router-dom';
import { SendRequest } from '../../utils/main';
import { HeadContent } from '../../lib/components/Headcontent';

interface Team {
  id: number;
  name: string;
  description: string;
  organization: any;
  admin: any;
}

interface Organization {
  id: number;
  name: string;
  description: string;
  members: any[];
}

interface PaymentPlan {
  status: string;
  plan: string;
  balance: number;
  features: string[];
  currentPeriodEnd: string;
  currentPeriodStart?: string;
  validUpto: string;
  amount: number;
}


const TeamTable: React.FC<{ teams: Team[] }> = ({ teams }) => (
  <Table variant="simple">
    <Thead>
      <Tr>
        <Th>Team Name</Th>
        <Th>Description</Th>
        <Th>Organization</Th>
        <Th>Leader</Th>
      </Tr>
    </Thead>
    <Tbody>
      {teams.map((team) => (
        <Tr key={team.id}>
          <Td>{team.name}</Td>
          <Td>{team.description}</Td>
          <Td>{team.organization?.name}</Td>
          <Td><Tag>{team.admin.email}</Tag></Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

const OrganizationTable: React.FC<{ organizations: Organization[] }> = ({ organizations }) => (
  <Table variant="simple">
    <Thead>
      <Tr>
        <Th>Organization Name</Th>
        <Th>Description</Th>
        <Th>Members</Th>
      </Tr>
    </Thead>
    <Tbody>
      {organizations.map((org) => (
        <Tr key={org.id}>
          <Td>{org.name}</Td>
          <Td>{org.description}</Td>
          <Td>{org.members.map((org) => (<Tag>{org.email}</Tag>))}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

const PaymentPlanInfo: React.FC<{ plan: PaymentPlan }> = ({ plan }) => {
  const navigate = useNavigate();
  if (!plan.amount) {
    return <Box borderWidth="1px" borderRadius="lg" p="4">
      <Heading as="h4" size="md" mb="2">
        Current Plan: Trial
      </Heading>
      <Heading as="h4" size="md" mb="2">
        Current Balance: {plan.balance}
      </Heading>
      <Heading as="h4" size="md" p={2}>ValidUpto: {new Date(plan.validUpto).toLocaleString()}</Heading>
      <Button mt="4" colorScheme="blue" size="sm" onClick={() => navigate("/payments")}>
        Upgrade Plan
      </Button>
    </Box>
  }


  return <Box borderWidth="1px" borderRadius="lg" p="4">
    <Heading as="h4" size="md" mb="2" p={2}>
      Plan Status: {plan.status}
    </Heading>
    <Heading as="h4" size="md"  p={2}>Price: {plan.amount / 100}$/month</Heading>
    <Heading as="h4" size="md" mb="2" p={2}>
      Current Balance: {plan.balance}
    </Heading>
    {/* <Text>Price: ${plan.price}/month</Text> */}
    <Heading as="h4" size="md" p={2}>ValidUpto: {new Date(plan.validUpto).toLocaleString()}</Heading>
    {/* <Text>Features: {plan.features.join(', ')}</Text> */}
    {plan.plan === "premium" && <Button leftIcon={<FiPlusSquare />} colorScheme="green" onClick={() => { navigate('/payments/topup') }} mr={2}>
      Topup
    </Button>}
    {plan.plan === "free" && <Button leftIcon={<FiPlusSquare />} colorScheme="green" onClick={() => { navigate('/payments') }} mr={2}>
      Upgrade
    </Button>}
  </Box>
};

const Profile: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [plan, setPlan] = useState<any>();
  const sidebar = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resp = await SendRequest('/api/v2/user/info', null, "GET");
        console.log({ resp });

        setTeams(resp.data.teams.docs);
        setOrganizations(resp.data.orgs.docs);
        setPlan({ ...resp.data.subscription, ...resp.data.credits })
        setError(null);
      } catch (err) {
        setError('Failed to fetch data. Please try again later.');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <DashboardWrapper>
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      </DashboardWrapper>
    );
  }

  if (error) {
    return (
      <DashboardWrapper>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <HeadContent
        sidebar={sidebar}
        hideSearch={true}
      />
      <VStack spacing={8} align="stretch">
        <Flex justify="space-between" align="center">
          <Heading as="h2" size="xl" p={2}>
            Your Profile
          </Heading>
        </Flex>
        <Box>
          <Flex align="center" mb="4" justify="space-between">
            <HStack>
              <Heading as="h3" size="lg" ml="2">
                Your Organizations
              </Heading>
              <FiUsers size="24px" />
            </HStack>
            <Button leftIcon={<FiPlusSquare />} colorScheme="green" onClick={() => { navigate('/organization/create') }} mr={2}>
              Create Organization
            </Button>
          </Flex>
          <OrganizationTable organizations={organizations} />
        </Box>

        <Box>
          <Flex align="center" mb="4" justify="space-between">
            <HStack>
              <Heading as="h3" size="lg" ml="2">
                Your Teams
              </Heading>
              <FiUsers size="24px" />
            </HStack>
            <Button leftIcon={<FiPlusSquare />} colorScheme="blue" onClick={() => { navigate('/team/create') }} mr={2}>
              Create Team
            </Button>
          </Flex>
          <TeamTable teams={teams} />
        </Box>

        <Box>
          <Flex align="center" mb="4">
            <Heading as="h3" size="lg" ml="2">
              Payment Plan
            </Heading>
            <FiDollarSign size="24px" />

          </Flex>
          <PaymentPlanInfo plan={plan} />
        </Box>
      </VStack>
    </DashboardWrapper>
  );
};

export default Profile;