import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Heading,
    useToast,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import DashboardWrapper from '../../lib/components/Wrapper';
import { useWebSocket } from '../../lib/components/WebSocketContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';


const BrandAsset: React.FC = () => {
    const { send, addEventListener, removeEventListener } = useWebSocket();
    const toast = useToast();
    const navigate = useNavigate();
    const [brandAssets, setBrandAssets] = useState<any[]>([]);
    let { brandId = "" } = useParams();
    useEffect(() => {
        addEventListener('open', handleOpen);
        addEventListener('message', handleMessage);

        return () => {
            removeEventListener('open', handleOpen);
            removeEventListener('message', handleMessage);
        };
    }, [addEventListener, removeEventListener]);

    const handleMessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data);
        console.log("message", message, message.true);

        if (!message.status) {
            toast({
                title: `Error video:${message.message}`,
                position: "top-right",
                variant: "top-accent",
                isClosable: true,
            });
            if (message.action === "auth") {
                navigate("/login");
            }
            return;
        }

        switch (message.action) {
            case "brandAssets":
                console.log("current brands:", message.brands);
                setBrandAssets(message.data);
                break;
            case "error":
                console.log("Error video:", message.message, event);
                toast({
                    title: `Error video:${message.message}`,
                    position: "top-right",
                    variant: "top-accent",
                    isClosable: true,
                });
                break;
            default:
                break;
        }
    };
    function loadBrandAssets() {
        const payload = {
            action: "loadBrandAssets",
            id: brandId
        };
        send(payload);
    }
    const handleOpen = () => {
        loadBrandAssets();
    };

    useEffect(() => {
        loadBrandAssets();
    }, [])

    return (
        <DashboardWrapper>
            <Box  mx="auto" mt="10">
                <Heading as="h1" size="xl" mb="6">
                    Your Brand Assets
                </Heading>
                <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" />
                    </label>
                  </th>
                  <th>Preview</th>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {brandAssets?.map((asset, index) => {
                  return (
                    <tr key={index}>
                      <th>
                        <label>
                          <input type="checkbox" className="checkbox" />
                        </label>
                      </th>
                      <th>
                        {asset.type === "Img" && (
                          <div className="mask mask-squircle w-20 h-20">
                            <img src={asset.url} alt={asset.title} />
                          </div>
                        )}
                        {asset.type === "Video" && (
                          <div className="mask mask-squircle w-20 h-20">
                            <video src={asset.url} />
                          </div>
                        )}
                      </th>
                      <td>
                        <div className="text-sm ">{asset.title}</div>
                      </td>
                      <td>
                        Not available
                        <br />
                        {/* <span className="badge badge-ghost badge-sm">Desktop Support Technician</span> */}
                      </td>
                      <td>{asset.type}</td>
                      
                    </tr>
                  );
                })}
              </tbody>
              {/* foot */}
            </table>
            </Box>

        </DashboardWrapper>
    );
};

export default BrandAsset;
