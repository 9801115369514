import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import React, { FC } from "react";

const shortcuts = [
  { title: "New Video", keys: ["Ctrl", "Shift", "n"] },
  { title: "Add Image", keys: ["Ctrl", "Shift", "i"] },
  { title: "Add Audio", keys: ["Ctrl", "Shift", "a"] },
  { title: "Add Video", keys: ["Ctrl", "Shift", "v"] },
  { title: "Add Font", keys: ["Ctrl", "Shift", "f"] },
  { title: "Add Text", keys: ["Ctrl", "Shift", "t"] },
  { title: "Generate Image", keys: ["Ctrl", "Shift", "g"] },
  { title: "Generate Audio", keys: ["Ctrl", "Shift", "m"] },
  { title: "Generate Video", keys: ["Ctrl", "Shift", "j"] },
  //   { title: "Generate Avatar", keys: ["Ctrl", "n"] },
  { title: "Export", keys: ["Ctrl", "Shift", "e"] },
  { title: "Switch to Storyboard", keys: ["Ctrl", "Shift", "s"] },
  { title: "View Generations", keys: ["Ctrl", "Shift", "x"] },
];

export const KeyboardModal: FC<{
  shortcutIsOpen: boolean;
  shortcutOnClose: () => void;
}> = ({ shortcutIsOpen, shortcutOnClose }) => {
  return (
    <Modal isOpen={shortcutIsOpen} onClose={shortcutOnClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Keyboard Shortcuts</ModalHeader>
        <ModalCloseButton />
        <ModalBody></ModalBody>
        <ModalFooter>
          <div className="artboard ">
            <ul>
              {shortcuts.map((shortcut) => {
                return (
                  <li className="flex justify-between items-center gap-x-2 py-2.5 border-t border-gray-200 first:border-t-0" key={shortcut.title}>
                    <span className="font-bold text-gray-800">
                      {shortcut.title}
                    </span>
                    <span className="flex flex-wrap items-center gap-x-1 text-sm text-gray-600">
                      <span className="min-h-[30px] inline-flex justify-center items-center py-1 px-1.5 bg-gray-200 border border-transparent font-mono text-sm text-gray-800 rounded-md">
                        {shortcut.keys[0]}
                      </span>
                      +
                      <span className="min-h-[30px] inline-flex justify-center items-center py-1 px-1.5 bg-gray-200 border border-transparent font-mono text-sm text-gray-800 rounded-md">
                        {shortcut.keys[1]}
                      </span>
                      +
                      <span className="min-h-[30px] inline-flex justify-center items-center py-1 px-1.5 bg-gray-200 border border-transparent font-mono text-sm text-gray-800 rounded-md">
                        {shortcut.keys[2]}
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
