import { useState, useEffect } from "react";

export const useAuth = () => {
  const [auth, setAuth] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async (): Promise<void> => {
    try {
      const response = localStorage.getItem("auth");
      setAuth(JSON.parse(response || "{}"));
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return [auth, error, loading] as const;
};
