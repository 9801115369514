import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { MdHome } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import "../Tailwind.css";
import { handleShowAlert } from "./Signup";
import { SidebarContent } from "../lib/components/Sidebar";
import { useForm } from "react-hook-form";
import { SendRequest } from "../utils/main";
import { HeadContent } from "../lib/components/Headcontent";


const WaitListed: React.FC = () => {
  const [videos, setVideos] = useState<any[]>([]);
  const [creating, setCurrentCreating] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVideos, setFilteredVideos] = useState(videos);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Crackviral | Onboarding";
  }, []);

  useEffect(() => {
    setFilteredVideos(videos);
  }, [videos]);

  const sidebar = useDisclosure();
  const integrations = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
  const {
    isOpen: videoIsOpen,
    onOpen: videoOpen,
    onClose: videoOnClose,
  } = useDisclosure();

  // Listen for messages from the WebSocket server

  const handleSearch = () => {
    console.log("Search Query", searchQuery);
    if (searchQuery !== null && searchQuery !== undefined) {
      const query = searchQuery.toLowerCase();
      const filtered = videos.filter((vid) => {
        if (vid?.title && vid?.description) {
          return (
            vid.title.toLowerCase().includes(query) ||
            vid.description.toLowerCase().includes(query)
          );
        }
        if (vid?.title) {
          return vid.title.toLowerCase().includes(query);
        }
        if (vid?.description) {
          return vid.description.toLowerCase().includes(query);
        }
        if (!vid?.title && !vid?.description) {
          return;
        }
      });
      setFilteredVideos(filtered);
    }
  };


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const auth = JSON.parse(localStorage.getItem("auth") || "{}");
      const response = await SendRequest(
        `/api/users/${auth.user.id}`,
        {
          details: data,
        },
        "PATCH"
      );
      if (response.data) {
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        auth.user = response.data.doc;
        localStorage.setItem("auth", JSON.stringify(auth));
        handleShowAlert("success", "Success", "Details have been saved.");
        navigate("/dashboard");
      } else {
        handleShowAlert(
          "error",
          "Error",
          `Error saving onboarding details:${response.error}`
        );
      }
    } catch (error) {
      handleShowAlert("error", "Error", `Network or server error:${error}`);
    }
  };

  return (
    <Box
      as="section"
      bg="gray.50"
      _dark={{
        bg: "gray.700",
      }}
      minH="100vh"
    >
      <SidebarContent
        display={{
          base: "none",
          md: "unset",
        }}
        navigation={navigate}
        integrations={integrations}
        color={color}
      />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent
            w="full"
            borderRight="none"
            navigation={navigate}
            integrations={integrations}
            color={color}
          />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{
          base: 0,
          md: 60,
        }}
        transition=".3s ease"
      >
        <HeadContent
          sidebar={sidebar}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
        />
        <div className="hero min-h-screen bg-base-200">
          <div className="hero-content flex-col">
            <h1 className="text-5xl font-bold text-center lg:text-left">
              Get Started with Crackviral!
            </h1>
            {/* <ul className="steps">
              <li className="step step-primary">Onboarding</li>
              <li className="step">Make Payment</li>
              <li className="step">Start Using Product</li>
            </ul> */}
            <p className="py-6">
              Hello! <br /> Thank you for providing your details. <br /> We are
              currently in a closed beta phase and are collaborating with
              individuals who are open to offering consistent feedback. <br />{" "}
              We will contact you via email once our beta phase concludes.{" "}
              <br /> If you're interested in participating in the beta, please
              feel free to reach out to me at bhanu@crackviral.com. Cheers!
            </p>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default WaitListed;
