import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import "../Tailwind.css";
import { wssurl } from "../lib/utils/Misc";
import { NewVideoModal } from "../lib/modals/NewVideo";
import { useAuth } from "../components/lib/useAuth";
import { SidebarContent } from "../lib/components/Sidebar";
import { ModelDict } from "../apis/sd/spec";
import { HeadContent } from "../lib/components/Headcontent";
import { sendMessage } from "../lib/utils/EditorUtil";
import { useSnapshot } from "valtio";
import { editorState } from "../stores/EditorStore";
import { useWebSocket } from "../lib/components/WebSocketContext";
import DashboardWrapper from "../lib/components/Wrapper";

const Models: React.FC = () => {
  const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
  const [videos, setVideos] = useState<any[]>([]);
  const [creating, setCurrentCreating] = useState<number>(0);
  const [video, setVideo] = useState<any>();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVideos, setFilteredVideos] = useState(videos);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [auth, error, loading] = useAuth();

  const {
    newVideo: videoNew,
  } = useSnapshot(editorState);

  useEffect(() => {
    document.title = "Crackviral | Models";
  }, []);

  useEffect(() => {
    addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);


  useEffect(() => {
    setFilteredVideos(videos);
  }, [videos]);

  const sidebar = useDisclosure();
  const integrations = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
  const {
    isOpen: videoIsOpen,
    onOpen: videoOpen,
    onClose: videoOnClose,
  } = useDisclosure();

  const {
    isOpen: generateIsOpen,
    onOpen: generateOpen,
    onClose: generateOnClose,
  } = useDisclosure();

  // Listen for messages from the WebSocket server
  const handleMessage = async (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status) {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "videos":
        console.log("Updated video:", message.videos);
        setVideos(message.videos);
        break;
      case "video":
        console.log("Updated video:", message.video);
        loadVideos();
        break;
      case "sceneFull":
        console.log("experiment obj:", message.experimentObj);
        navigate(`/storyboard/${message.experimentObj._id}`);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "error":
        console.log("Error video:", message.message, event);
        toast({
          title: `Error video:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };
  const handleSearch = () => {
    console.log("Search Query", searchQuery);
    if (searchQuery !== null && searchQuery !== undefined) {
      const query = searchQuery.toLowerCase();
      const filtered = videos.filter((vid) => {
        if (vid?.title && vid?.description) {
          return (
            vid.title.toLowerCase().includes(query) ||
            vid.description.toLowerCase().includes(query)
          );
        }
        if (vid?.title) {
          return vid.title.toLowerCase().includes(query);
        }
        if (vid?.description) {
          return vid.description.toLowerCase().includes(query);
        }
        if (!vid?.title && !vid?.description) {
          return;
        }
      });
      setFilteredVideos(filtered);
    }
  };
  function loadVideos() {
    const payload = {
      action: "getVideos",
    };
    send(payload);
  }

  function submitAuth() {
    const payload = {
      action: "auth",
      data: localStorage.getItem("auth"),
    };
    send(payload);
  }

  function generateScenes(meta: any) {
    const payload = {
      action: "storyToScenes",
      data: meta,
    };
    send(payload);
  }
  const createVideo = (obj: any) => {
    const payload = {
      action: "createVideo",
      video: {
        title: obj.title,
        url: obj.url,
        description: obj.description,
        meta: {
          ai: { details: obj.details },
          props: { height: obj.meta.props.height, width: obj.meta.props.width },
        },
      },
    };
    send(payload);
  };
  const handleOpen = () => {
    submitAuth();
    loadVideos();
  };
  function newVideo() {
    switch (creating) {
      case 0:
        console.log("VIDEO :", video);
        createVideo(video);
        break;

      default:
        break;
    }
    videoOnClose();
  }

  function getSubString(str: any, limit: number): React.ReactNode {
    if (str.length < limit) return str;
    return `${str.substring(0, limit)}...`;
  }

  return (
    <DashboardWrapper>
      <HeadContent
        sidebar={sidebar}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
      />

      <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
        {ModelDict?.map((vid, index) => {
          return (
            <div className="card w-96 bg-base-100 shadow-xl">
              <figure>
                <img
                  src={vid.thumbnailUrl}
                  alt={vid.title}
                  className="h-full w-full object-cover"
                />
              </figure>

              <div className="card-body">
                {vid.status ? (
                  <div className="badge badge-primary  ">Supported</div>
                ) : (
                  <div className="badge badge-secondary ">Coming Soon</div>
                )}
                <h2 className="card-title">{getSubString(vid.title, 30)}</h2>
                <p>{getSubString(vid.shortDescription, 200)}</p>

                <div className="card-actions justify-end">
                  {vid.tags.map((t) => (
                    <div className="badge badge-outline">{t}</div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <NewVideoModal
        videoIsOpen={videoIsOpen}
        videoOnClose={videoOnClose}
        setCurrentCreating={setCurrentCreating}
        creating={creating}
        newVideo={videoNew}
        auth={auth}
        generateScenes={generateScenes}
        generateOpen={generateOpen}
      />
      {showLoader && (
        <CircularProgress
          isIndeterminate
          color="green.300"
          size={"xl"}
          style={{ left: "30%" }}
        />
      )}
    </DashboardWrapper>
  );
};

export default Models;
