export const filterNameProps: any = {
  '1977': ['saturation', 'brightness', 'contrast'],
  'custom': ['saturation', 'brightness', 'contrast', 'hueRotate', 'sepia', 'grayscale', 'backgroundAfter', 'backgroundBefore'],
  'aden': ['hueRotate', 'saturation', 'brightness', 'contrast'],
  'amaro': ['hueRotate', 'saturation', 'brightness', 'contrast'],
  'brannan': ['sepia', 'contrast'],
  'clarendon': ['saturation', 'contrast'],
  'earlyBird': ['sepia', 'contrast'],
  'gingham': ['brightness', 'hueRotate'],
  'hudson': ['saturation', 'brightness', 'contrast'],
  'inkwell': ['sepia', 'brightness', 'contrast', 'grayscale'],
  'kelvin': ['backgroundAfter', 'backgroundBefore'],
  'lark': ['contrast'],
  'lofi': ['saturation', 'contrast'],
  'maven': ['saturation', 'brightness', 'contrast', 'sepia'],
  'mayfair': ['saturation', 'contrast'],
  'moon': ['grayscale', 'brightness', 'contrast'],
  'nashville': ['saturation', 'brightness', 'contrast', 'sepia'],
  'perpetua': [],
  'reyes': ['saturation', 'brightness', 'contrast', 'sepia'],
  'rise': ['saturation', 'brightness', 'contrast', 'sepia'],
  'slumber': ['saturation', 'brightness'],
  'stinson': ['saturation', 'brightness', 'contrast'],
  'toaster': ['brightness', 'contrast'],
  'valencia': ['sepia', 'brightness', 'contrast'],
  'walden': ['saturation', 'sepia', 'brightness', 'hueRotate'],
  'willow': ['grayscale', 'brightness', 'contrast'],
  'xpro': ['sepia']
};

export interface FilterProps {
  contrast?: number;
  brightness?: number;
  saturation?: number;
  sepia?: number;
  hue?: string;
  hueRotate?: string;
  grayscale?: number;
  bright?: number;
  backgroundBefore?: string;
  backgroundAfter?: string;
}

export function contrastBrightness(contrast: number, brightness: number): string {
  return `
    contrast(${contrast})
    brightness(${brightness})
  `;
}

export function hueBrightness(hueRotate: string, brightness: number): string {
  return `
    hue-rotate(${hueRotate})
    brightness(${brightness})
  `;
}

export function cbs(props: FilterProps): string {
  return `
    -webkit-filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      saturate(${props.saturation});
    filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      saturate(${props.saturation});
  `;
}

export function cbsep(props: FilterProps): string {
  return `
    -webkit-filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      sepia(${props.sepia});
    filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      sepia(${props.sepia});
  `;
}

export function hcbs(props: FilterProps): string {
  return `
    -webkit-filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      hue-rotate(${props.hue})
      saturate(${props.saturation});
    filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      hue-rotate(${props.hue})
      saturate(${props.saturation});
  `;
}

export function cs(props: FilterProps): string {
  return `
    -webkit-filter:
      contrast(${props.contrast})
      sepia(${props.sepia});
    filter:
      contrast(${props.contrast})
      sepia(${props.sepia});
  `;
}

export function csat(props: FilterProps): string {
  return `
    -webkit-filter:
      contrast(${props.contrast})
      saturate(${props.saturation});
    filter:
      contrast(${props.contrast})
      saturate(${props.saturation});
  `;
}

export function scbsat(props: FilterProps): string {
  return `
    -webkit-filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      saturate(${props.saturation})
      sepia(${props.sepia!});
    filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      saturate(${props.saturation})
      sepia(${props.sepia!});
  `;
}

export function gcb(props: FilterProps): string {
  return `
    -webkit-filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      grayscale(${props.grayscale});
    filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      grayscale(${props.grayscale});
  `;
}

export function hbss(props: FilterProps): string {
  return `
    -webkit-filter:
      ${hueBrightness(props.hueRotate!, props.bright!)}
      sepia(${props.sepia})
      saturate(${props.saturation})
    filter:
      ${hueBrightness(props.hueRotate!, props.bright!)}
      sepia(${props.sepia})
      saturate(${props.saturation})
  `;
}

export function hb(props: FilterProps): string {
  return `
    -webkit-filter:
      ${hueBrightness(props.hueRotate!, props.bright!)}
    filter:
      ${hueBrightness(props.hueRotate!, props.bright!)}
  `;
}

export function scbg(props: FilterProps): string {
  return `
    -webkit-filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      sepia(${props.sepia})
      grayscale(${props.grayscale});
    filter:
      ${contrastBrightness(props.contrast!, props.bright!)}
      sepia(${props.sepia})
      grayscale(${props.grayscale});
  `;
}

export function sb(props: FilterProps): string {
  return `
    -webkit-filter:
      saturation(${props.saturation})
      bright(${props.bright});
    filter:
      saturation(${props.saturation})
      bright(${props.bright});
  `;
}