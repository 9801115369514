import React, { useState, useEffect, MouseEvent } from 'react';
import {
  editorState,
} from "../../stores/EditorStore";
import { useSnapshot } from "valtio";
import UploadComponent from "../components/UploadComponent"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  SimpleGrid,
  Image,
  Box,
  Text,
  Button,
  VStack,
  HStack,
  useToast,
  Select,
} from '@chakra-ui/react';
import { SendRequest } from '../../utils/main';
import { useParams } from "react-router-dom";
import { updateStringProps } from '../utils/EditorUtil';
import { useWebSocket } from '../components/WebSocketContext';

interface AssetLibraryProps {
  isOpen: boolean;
  onClose: () => void;
  assetImporting: string;
}

interface Video {
  id: number;
  image: string;
  video_files: { link: string; id: string; quality: string; width: number; height: number }[];
  tags: string[];
  user: { name: string };
}

interface BrandMedia {
  id: number;
  title: string;
  type: string;
  url: string;
  source: string;
}

interface SelectedQualities {
  [videoId: number]: string;
}

const StockVideoItem: React.FC<{
  video: Video;
  selectedQualities: SelectedQualities;
  onQualityChange: (videoId: number, quality: string) => void;
  onUpdate: (videoUrl: string) => void;
}> = ({ video, selectedQualities, onQualityChange, onUpdate }) => {
  const handleQualityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onQualityChange(video.id, event.target.value);
  };

  const handleUpdate = (videoId: number) => {
    const selectedQuality = selectedQualities[videoId];
    const selectedFile = video.video_files.find(file => file.quality === selectedQuality);

    if (selectedFile) {
      onUpdate(selectedFile.link);
    }
  };

  return (
    <Box>
      <Image src={video.image} alt={`Stock video ${video.id}`} />
      <Text fontSize="sm" mt={2}>Artist: {video.user.name}</Text>
      <HStack mt={2}>
        <Select value={selectedQualities[video.id]} onChange={handleQualityChange}>
          {video.video_files.map((file) => (
            <option key={file.id} value={file.quality}>
              {file.quality} ({file.width}x{file.height})
            </option>
          ))}
        </Select>
        <Button onClick={() => handleUpdate(video.id)}>Update</Button>
      </HStack>
    </Box>
  );
};

interface Photo {
  id: number;
  src: {
    original: string;
    large2x: string;
    large: string;
    medium: string;
    small: string;
    portrait: string;
    landscape: string;
    tiny: string;
  };
  photographer: string;
  alt: string;
}

const StockImageItem: React.FC<{
  photo: Photo;
  onUpdate: (imageUrl: string) => void;
}> = ({ photo, onUpdate }) => {
  const [selectedSize, setSelectedSize] = useState<string>('medium');

  const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSize(event.target.value);
  };

  const handleUpdate = () => {
    onUpdate(photo.src[selectedSize as keyof typeof photo.src]);
  };

  return (
    <Box>
      <Image src={photo.src.medium} alt={photo.alt} />
      <Text fontSize="sm" mt={2}>Photographer: {photo.photographer}</Text>
      <HStack mt={2}>
        <Select value={selectedSize} onChange={handleSizeChange}>
          <option value="original">Original</option>
          <option value="large2x">Large 2x</option>
          <option value="large">Large</option>
          <option value="medium">Medium</option>
          <option value="small">Small</option>
          <option value="portrait">Portrait</option>
          <option value="landscape">Landscape</option>
          <option value="tiny">Tiny</option>
        </Select>
        <Button onClick={handleUpdate}>Update</Button>
      </HStack>
    </Box>
  );
};

const RenderStockVideo = ({assetImporting, stockVideoQuery, setStockVideoQuery, orientation, setOrientation, resolution, setResolution, stockVideos, selectedQualities, handleQualityChange, handleUpdate, loading, loadMoreStockVideos}: {assetImporting: string, stockVideoQuery: string, setStockVideoQuery: Function, orientation: string, setOrientation: Function, resolution: string, setResolution: Function, stockVideos: Video[], selectedQualities: SelectedQualities, handleQualityChange: (videoId: number, quality: string) => void, handleUpdate: (videoUrl: string) => void, loading: boolean | undefined, loadMoreStockVideos: (event: MouseEvent) => void}) => (
  <VStack spacing={4} align="stretch" width="100%">
    <Input
      placeholder={`Search Stock ${assetImporting}s...`}
      value={stockVideoQuery}
      onChange={(e) => setStockVideoQuery(e.target.value)}
    />
    <HStack>
      <Select value={orientation} onChange={(e) => setOrientation(e.target.value)}>
        <option value="all">All Orientations</option>
        <option value="landscape">Landscape</option>
        <option value="portrait">Portrait</option>
        <option value="square">Square</option>
      </Select>
      <Select value={resolution} onChange={(e) => setResolution(e.target.value)}>
        <option value="all">All Resolutions</option>
        <option value="large">4K</option>
        <option value="medium">1080p</option>
        <option value="small">720p</option>
      </Select>
    </HStack>
    <SimpleGrid columns={3} spacing={4}>
      {stockVideos.map((item: any) => {
        if (assetImporting === "Video") {
          return <StockVideoItem
            key={item.id}
            video={item}
            selectedQualities={selectedQualities}
            onQualityChange={handleQualityChange}
            onUpdate={handleUpdate}
          />
        }
        if (assetImporting === "Image") {
          return <StockImageItem
            key={item.id}
            photo={item}
            onUpdate={handleUpdate}
          />
        }
      }
      )}
    </SimpleGrid>
    {stockVideos.length > 0 && (
      <Button onClick={loadMoreStockVideos} isLoading={loading}>
        + Load more
      </Button>
    )}
  </VStack>
);

const BrandingGrid = ({ brandMedia, updateProperty, selectedComponent, totalBrandMedia, loading, loadMoreBrandMedia }: { brandMedia: any, updateProperty: (selectedComponent: string, url: any) => void, selectedComponent: string, totalBrandMedia: number, loading: boolean | undefined, loadMoreBrandMedia: (event: MouseEvent) => void }) => {
  return <><SimpleGrid columns={3} spacing={4}>
    {brandMedia && brandMedia.map((media: any) => (
      <Box key={media.id}>
        {media.type === 'Img' ? (
          <Image src={media.url} alt={media.title} />
        ) : (
          <video src={media.url} controls={true} />
        )}
        <Text fontSize="sm" mt={2}>{media.title}</Text>
        <Text fontSize="sm" mt={1}>Source: {media.source}</Text>
        {/* <Wrap mt={2}>
        {media.tags.slice(0, 3).map((tag, index) => (
          <WrapItem key={index}>
            <Tag size="sm">{tag}</Tag>
          </WrapItem>
        ))}
      </Wrap> */}
        <Button onClick={() => updateProperty(selectedComponent, media.url)}>Update</Button>
      </Box>
    ))}
  </SimpleGrid>
    {brandMedia && brandMedia.length < totalBrandMedia && (
      <Button onClick={loadMoreBrandMedia} isLoading={loading}>
        + Load more
      </Button>
    )}</>
}

const RenderExistingMedia = ({ brandMediaQuery, setBrandMediaQuery, brandMedia, updateProperty, selectedComponent, totalBrandMedia, loading, loadMoreBrandMedia }: { brandMediaQuery: string, setBrandMediaQuery: Function, brandMedia: any, updateProperty: (selectedComponent: string, url: any) => void, selectedComponent: string, totalBrandMedia: number, loading: boolean | undefined, loadMoreBrandMedia: (event: MouseEvent) => void }) => {
  return <VStack spacing={4} align="stretch" width="100%">
    <Input
      placeholder="Search brand media..."
      value={brandMediaQuery}
      onChange={(e) => setBrandMediaQuery(e.target.value)}
    />
    <HStack>
      {/* <Select value={orientation} onChange={(e) => setOrientation(e.target.value)}>
        <option value="all">All Orientations</option>
        <option value="landscape">Landscape</option>
        <option value="portrait">Portrait</option>
        <option value="square">Square</option>
      </Select>
      <Select value={resolution} onChange={(e) => setResolution(e.target.value)}>
        <option value="all">All Resolutions</option>
        <option value="4k">4K</option>
        <option value="1080p">1080p</option>
        <option value="720p">720p</option>
      </Select> */}
    </HStack>
    <BrandingGrid brandMedia={brandMedia} updateProperty={brandMedia} selectedComponent={selectedComponent} totalBrandMedia={totalBrandMedia} loading={loading} loadMoreBrandMedia={loadMoreBrandMedia} />
  </VStack>
};



const ReplacAssetLibrary: React.FC<AssetLibraryProps> = ({ isOpen, onClose, assetImporting }) => {
  const [activeTab, setActiveTab] = useState<string>('stockVideo');
  const [stockVideoQuery, setStockVideoQuery] = useState<string>('');
  const [brandMediaQuery, setBrandMediaQuery] = useState<string>('');
  const [stockVideos, setStockVideos] = useState<any>([]);
  const [brandMedia, setBrandMedia] = useState<BrandMedia[]>([]);
  const [stockVideoPage, setStockVideoPage] = useState<number>(1);
  const [brandMediaPage, setBrandMediaPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalBrandMedia, setTotalBrandMedia] = useState<number>(0);
  const [orientation, setOrientation] = useState<string>('all');
  const [resolution, setResolution] = useState<string>('all');
  const [selectedQualities, setSelectedQualities] = useState<SelectedQualities>({});
  const toast = useToast();
  let { videoId = "" } = useParams();
  const { send } = useWebSocket();
  const {
    selectedComponent,
  } = useSnapshot(editorState);
  const handleTabChange = (index: number) => {
    setActiveTab(index === 0 ? 'stockVideo' : index === 1 ? 'existingMedia' : 'newMedia');
  };

  const updateProperty = (id: string, url: string) => {
    // setData(e);
    const propPayload: any = {};
    propPayload[`${id}_url`] = url;
    updateStringProps(propPayload, videoId, send);
    return true;
  };

  const searchStockVideos = async (query: string, pageNum: number) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      queryParams.append("query", query);
      queryParams.append("page", pageNum.toString());
      queryParams.append("per_page", "10");
      let queryPath = "";

      if (orientation !== "all") {
        queryParams.append("orientation", orientation);
      }
      if (resolution !== "all") {
        queryParams.append("size", resolution);
      }
      if (assetImporting === "Video") {
        queryParams.append("type", "video");
      }

      if (assetImporting === "Image") {
        queryParams.append("type", "photo");
      }
      queryPath = `/api/v2/search-assets?${queryParams.toString()}`;

      const response = await SendRequest(queryPath, null, "GET");

      if (pageNum === 1) {
        setStockVideos(response.data.videos || response.data.photos);
      } else {
        setStockVideos((prevVideos: any) => [...prevVideos, ...(response.data.videos || response.data.photos)]);
      }
      if (assetImporting === "Video") {
        const initialQualities: SelectedQualities = {};
        stockVideos.forEach((video: Video) => {
          initialQualities[video.id] = video.video_files[0]?.quality || '';
        });
        setSelectedQualities(initialQualities);
      }

      setStockVideoPage(pageNum);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setLoading(false);
    }
  };

  const searchBrandMedia = async (query: string, pageNum: number) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      queryParams.append("query", query);
      queryParams.append("page", pageNum.toString());
      queryParams.append("per_page", "10");
      if (assetImporting === "Video") {
        queryParams.append("type", "Video");
      }

      if (assetImporting === "Image") {
        queryParams.append("type", "Img");
      }
      const response = await SendRequest(`/api/v2/assets/brand?${queryParams.toString()}`, null, "GET");

      if (pageNum === 1) {
        setBrandMedia(response.data.data.docs);
      } else {
        setBrandMedia(prevMedia => [...prevMedia, ...response.data.data.docs]);
      }
      setBrandMediaPage(pageNum);
      setTotalBrandMedia(response.data.data.totalDocs);
    } catch (error) {
      console.error('Error fetching brand media:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stockVideoQuery) {
      searchStockVideos(stockVideoQuery, 1);
    }
  }, [stockVideoQuery, orientation, resolution]);

  useEffect(() => {
    if (brandMediaQuery) {
      searchBrandMedia(brandMediaQuery, 1);
    }
  }, [brandMediaQuery, orientation, resolution]);

  const loadMoreStockVideos = () => {
    searchStockVideos(stockVideoQuery, stockVideoPage + 1);
  };

  const loadMoreBrandMedia = () => {
    searchBrandMedia(brandMediaQuery, brandMediaPage + 1);
  };



  const handleQualityChange = (videoId: number, quality: string) => {
    setSelectedQualities(prev => ({ ...prev, [videoId]: quality }));
  };

  const handleUpdate = (videoUrl: string) => {
    updateProperty(selectedComponent, videoUrl);
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent maxHeight="80vh">
        <ModalHeader>Replace Asset</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="auto">
          <Tabs onChange={handleTabChange}>
            <TabList>
              <Tab>Existing Assets</Tab>
              <Tab>Stock {assetImporting}s</Tab>
              <Tab>New Media</Tab>
            </TabList>
            <TabPanels>
              <TabPanel><RenderExistingMedia brandMediaQuery={brandMediaQuery} setBrandMediaQuery={setBrandMediaQuery} brandMedia={brandMedia} updateProperty={updateProperty} selectedComponent={selectedComponent} totalBrandMedia={totalBrandMedia} loading={loading} loadMoreBrandMedia={loadMoreBrandMedia} /></TabPanel>
              <TabPanel><RenderStockVideo assetImporting={assetImporting} stockVideoQuery={stockVideoQuery} setStockVideoQuery={setStockVideoQuery} orientation={orientation} setOrientation={setOrientation} resolution={resolution} setResolution={setResolution} stockVideos={stockVideos} selectedQualities={selectedQualities} handleQualityChange={handleQualityChange} handleUpdate={handleUpdate} loading={loading} loadMoreStockVideos={loadMoreStockVideos} /></TabPanel>
              <TabPanel><UploadComponent videoId={videoId} type={assetImporting} onAction={(opts, video) => {
                updateProperty(selectedComponent, video.url)
              }} /></TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReplacAssetLibrary;