import { VStack } from "@chakra-ui/react";
import { useSnapshot } from "valtio";
import { setFormData, setMode, genState } from "../../stores/GenerationStore";
import { cloneDeep } from "lodash";
import { DynamicForm } from "../components/inputs/DynamicForm";
import { useEffect } from "react";

export const GenerationForm = ({ handleSubmit }: any) => {
  const { formData, modes, props, mode } = useSnapshot(genState);

  const handleChange = (e: any, key: string) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  useEffect(() => {
    const initValues: any = {};
    props &&
    props.map(([key, value]: any, index: number) => {
        switch (value.type) {
          case "string":
            if (value.title === "Audio Url") {
              initValues[key] = value.examples[0];
              return null;
            }
            if (value.title === "Prompt") {
    
                initValues[key] = value.description;

              return null;
            }
            if (value.enum) {
              initValues[key] = value.enum[0];
              return null;
            }
            initValues[key] = value.default;
            return null;

          case "integer":
            initValues[key] = value.default;
            return null;
          case "number":
            initValues[key] = formData[key] || 0;
            return null;
          case "boolean":
            initValues[key] = false;
            return null;
          case "array":
            initValues[key] = value.default;
            return null;
          default:
            if (value.anyOf) {
              const enums = value.anyOf[1].enum;
              initValues[key] = enums[0];
              return null;
            }
            console.log(value.type, "is unhandled", value);
        }
      });
    setFormData({ ...cloneDeep(formData), ...initValues });
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <div role="tablist" className="tabs tabs-bordered">
          {modes.map((m: string) => (
            <button
              role="tab"
              className={`tab ${mode === m ? "tab-active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setMode(m);
              }}
              key={m}
            >
              {m}
            </button>
          ))}
        </div>
        <DynamicForm
          handleChange={handleChange}
          formData={formData}
          properties={props}
          setFormData={setFormData}
        />
        {/* <Button mt={4} colorScheme="blue" type="submit">
            Submit
          </Button> */}
      </VStack>
    </form>
  );
};
