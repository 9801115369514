import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import "../../Tailwind.css";
import { handleShowAlert } from "../Signup";
import { SidebarContent } from "../../lib/components/Sidebar";
import { useForm } from "react-hook-form";
import { SendRequest } from "../../utils/main";
import { HeadContent } from "../../lib/components/Headcontent";

const Onboarding: React.FC = () => {
  const [videos, setVideos] = useState<any[]>([]);
  const [creating, setCurrentCreating] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVideos, setFilteredVideos] = useState(videos);
  const navigate = useNavigate();


  useEffect(() => {
    document.title = "Crackviral | Onboarding";
  }, []);

  useEffect(() => {
    setFilteredVideos(videos);
  }, [videos]);

  const sidebar = useDisclosure();
  const integrations = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
  const {
    isOpen: videoIsOpen,
    onOpen: videoOpen,
    onClose: videoOnClose,
  } = useDisclosure();



  // Listen for messages from the WebSocket server

  const handleSearch = () => {
    console.log("Search Query", searchQuery);
    if (searchQuery !== null && searchQuery !== undefined) {
      const query = searchQuery.toLowerCase();
      const filtered = videos.filter((vid) => {
        if (vid?.title && vid?.description) {
          return (
            vid.title.toLowerCase().includes(query) ||
            vid.description.toLowerCase().includes(query)
          );
        }
        if (vid?.title) {
          return vid.title.toLowerCase().includes(query);
        }
        if (vid?.description) {
          return vid.description.toLowerCase().includes(query);
        }
        if (!vid?.title && !vid?.description) {
          return;
        }
      });
      setFilteredVideos(filtered);
    }
  };


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const auth = JSON.parse(localStorage.getItem("auth") || "{}");
      const response = await SendRequest(
        `/api/users/${auth.user.id}`,
        {
          details: data,
        },
        "PATCH"
      );
      if (response.data) {
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        auth.user = response.data.doc;
        localStorage.setItem("auth", JSON.stringify(auth));
        handleShowAlert("success", "Success", "Details have been saved.");
        navigate("/onboarding/new");
      } else {
        handleShowAlert(
          "error",
          "Error",
          `Error saving onboarding details:${response.error}`
        );
      }
    } catch (error) {
      handleShowAlert("error", "Error", `Network or server error:${error}`);
    }
  };

  return (
    <Box
      as="section"
      bg="gray.50"
      _dark={{
        bg: "gray.700",
      }}
      minH="100vh"
    >
      <SidebarContent
        display={{
          base: "none",
          md: "unset",
        }}
        navigation={navigate}
        integrations={integrations}
        color={color}
      />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent
            w="full"
            borderRight="none"
            navigation={navigate}
            integrations={integrations}
            color={color}
          />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{
          base: 0,
          md: 60,
        }}
        transition=".3s ease"
      >
        <HeadContent
          sidebar={sidebar}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
        />
        <div className="hero min-h-screen bg-base-200">
          <div className="hero-content flex-col">
            <h1 className="text-5xl font-bold text-center lg:text-left">
              Get Started with Crackviral!
            </h1>
            {/* <ul className="steps">
              <li className="step step-primary">Onboarding</li>
              <li className="step">Make Payment</li>
              <li className="step">Start Using Product</li>
            </ul> */}
            <p className="py-6">
              Hey there! Just so you know, your info is safe with us. We won’t
              share it outside our company or sell it to anyone, promise! We
              just want to get to know our creators better. And hey, if you've
              got something to say or any cool ideas, don’t hesitate to drop me
              an email at bhanu@crackviral.com. Your Inputs really do make a
              difference, and we want you to help shape what we do next. Cheers!
            </p>

            <div className="flex flex-col lg:flex-row">
              <div className="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100 lg:ml-10">
                <form className="card-body" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Name</span>
                    </label>
                    {errors.name && <span>We want to know who you are.</span>}
                    <input
                      type="text"
                      placeholder="Full name"
                      className="input input-bordered"
                      required
                      {...register("name", {
                        required: "Name is required",
                      })}
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Industry</span>
                    </label>
                    {errors.industry && (
                      <span>Please Share your target industry</span>
                    )}
                    <select
                      className="select select-bordered"
                      required
                      {...register("industry", {
                        required: "Industry is required",
                      })}
                    >
                      <option disabled selected value="">
                        Choose your intended target industry
                      </option>
                      <option value="film_tv">Film and Television</option>
                      <option value="advertising_marketing">
                        Advertising and Marketing
                      </option>
                      <option value="corporate">
                        Corporate (Internal Communications)
                      </option>
                      <option value="education">Education</option>
                      <option value="news_journalism">
                        News and Journalism
                      </option>
                      <option value="event_management">Event Management</option>
                      <option value="real_estate">Real Estate</option>
                      <option value="healthcare">Healthcare</option>
                      <option value="government">Government</option>
                      <option value="non_profit">Non-Profit</option>
                      <option value="fashion">Fashion</option>
                      <option value="music">Music Industry</option>
                      <option value="sports">Sports</option>
                      <option value="content_creation">
                        Independent Content Creation
                      </option>
                      <option value="gaming_streaming">
                        Gaming and Streaming
                      </option>
                      <option value="travel_tourism">Travel and Tourism</option>
                      <option value="social_media">
                        Social Media Influencers
                      </option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">
                        Purpose of Using This Tool
                      </span>
                    </label>
                    {errors.purpose && (
                      <span>Please share your expectations from the tool.</span>
                    )}
                    <textarea
                      placeholder="Describe your purpose"
                      className="textarea textarea-bordered"
                      required
                      {...register("purpose", {
                        required: "Purpose is required",
                      })}
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">
                        Social Media Links
                      </span>
                    </label>
                    {errors.social && (
                      <span>Please share your social media links which will be used to verify the account.</span>
                    )}
                    <textarea
                      placeholder="Ex: twitter/instagram link"
                      className="textarea textarea-bordered"
                      required
                      {...register("social", {
                        required: "Social Links required",
                      })}
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">
                        How did you find our platform?
                      </span>
                    </label>
                    {errors.found && (
                      <span>Please share how you found us.</span>
                    )}
                    <select
                      className="select select-bordered"
                      required
                      {...register("found", {
                        required:
                          "Kindly share how you found us, it can help us.",
                      })}
                    >
                      <option disabled selected>
                        Choose one
                      </option>
                      <option value="search_engine">Search Engine</option>
                      <option value="social_media">Social Media</option>
                      <option value="friend">Friend / Word of Mouth</option>
                      <option value="advertisement">Advertisement</option>
                      <option value="staff">You met someone from Crackviral.</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="form-control mt-6">
                    <button className="btn btn-primary" type="submit">
                      Get Started
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Onboarding;
