import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  TabPanels,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Text,
  Select,
  useToast,
  Spinner
} from '@chakra-ui/react';
import DashboardWrapper from '../../lib/components/Wrapper';
import { useNavigate } from 'react-router-dom';
import { useWebSocket } from '../../lib/components/WebSocketContext';
import { SendRequest } from '../../utils/main';

// interface BrandFormProps {
//   onSave: (data: BrandFormData) => void;
// }

interface BrandFormData {
  brandName: string;
  brandUrl: string;
  brandDescription: string;
  videoGoal: string;
  instagramUsername: string;
}

const BrandCreate: React.FC = () => {
  const [brandName, setBrandName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [brandUrl, setBrandUrl] = useState('');
  const [brandDescription, setBrandDescription] = useState('');
  const [videoGoal, setVideoGoal] = useState('');
  const [instagramUsername, setInstagramUsername] = useState('');
  const { send, addEventListener, removeEventListener } = useWebSocket();
  const toast = useToast();
  const navigate = useNavigate();
  const [brands, setBrands] = useState<any[]>([]);

  useEffect(() => {
    // addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      // removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);

  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status) {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "brands":
        console.log("current brands:", message.brands);
        setBrands(message.brands);
        break;
      case "error":
        console.log("Error video:", message.message, event);
        toast({
          title: `Error video:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const data: BrandFormData = {
      brandName,
      brandUrl,
      brandDescription,
      videoGoal,
      instagramUsername,
    };
    if (!data.brandName) {
      toast({
        title: "Team name is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await SendRequest(
        `/api/v2/brand/new`,
        data,
        "POST"
      );
      if (response.data) {
        console.log(response);
        
        toast({
          title: "Brand has been created",
          description: "Your brand has been created.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // navigate("/brand.");
      } else {
        console.log({response});
        
        toast({
          title: "Brand Creation Error",
          description: `Error saving Brand details:${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Brand Creation Error",
        description: `Network or server error:${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }

  };
  return (
    <DashboardWrapper>
      <Box maxW="md" mx="auto" mt="10">
        <Heading as='h3' size='lg'>
          Add your brand
        </Heading>
        <br />
        <form>
          <FormControl id="brandName" mb="4">
            <FormLabel>Brand Name</FormLabel>
            <Input
              type="text"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
            />
          </FormControl>
          <FormControl id="brandUrl" mb="4">
            <FormLabel>Brand URL (Optional)</FormLabel>
            <Input
              type="text"
              value={brandUrl}
              onChange={(e) => setBrandUrl(e.target.value)}
            />
            <Text as='i' fontSize='xs'>Crackviral will automatically fetch brand colors, fonts, and logos from this URL</Text>
          </FormControl>
          <FormControl id="brandDescription" mb="4">
            <FormLabel>Brand Description</FormLabel>
            <Input
              type="text"
              value={brandDescription}
              onChange={(e) => setBrandDescription(e.target.value)}
            />
            <Text as='i' fontSize='xs'>Summarize your brand's function and style briefly.</Text>
          </FormControl>
          <FormControl id="videoGoal" mb="4">
            <FormLabel>Video Goal</FormLabel>
            <Input
              type="text"
              value={videoGoal}
              onChange={(e) => setVideoGoal(e.target.value)}
            />
            <Text as='i' fontSize='xs'>Define your marketing objective</Text>
          </FormControl>
          <FormControl id="instagramUsername" mb="4">
            <FormLabel>Instagram Username (*Coming Soon)</FormLabel>
            <Input
              type="text"
              disabled
              value={instagramUsername}
              onChange={(e) => setInstagramUsername(e.target.value)}
            />
            <Text as='i' fontSize='xs'>Crackviral automatically imports your Instagram content for use in your brand media.</Text>
          </FormControl>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={isSubmitting}
            leftIcon={isSubmitting ? <Spinner size="sm" /> : undefined}
          >
            {isSubmitting ? 'Submitting...' : 'Save Changes'}
          </Button>
        </form>
      </Box>

    </DashboardWrapper>
  );
};

export default BrandCreate;
