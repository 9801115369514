import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Tooltip,
} from "@chakra-ui/react";
import { useSnapshot } from "valtio";
import { editorState, setAPISelected } from "../../stores/EditorStore";
import { useEffect, useRef, useState } from "react";
import { ShowLogType } from "../components/ShowLogType";
import { ShowPreview } from "../components/ShowPreview";
import { ShowLogs } from "../components/Showlogs";
import { pullAll, cloneDeep } from "lodash";

export const RunsDialog = ({
  runOnClose,
  runIsOpen,
  sendData,
  videoId,
}: {
  runOnClose: () => void;
  runIsOpen: boolean;
  sendData: Function;
  videoId: string;
}) => {
  const { runs, apis, APISelected, selectedScreen } = useSnapshot(editorState);
  const [importedFlag, setImportedFlag] = useState<boolean>(true);
  const [filteredAPIs, setFilteredAPIs] = useState<any>(apis.filter((api: any) => !api.imported));
  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef<any>(null);

  // Start the timer
  const startSync = () => {
    if (!isActive && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        sendData("getRunsForVideo", { id: videoId });
      }, 10000);
      setIsActive(true);
    }
  };

  // Stop the timer
  const stopSync = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setIsActive(false);
    }
  };

  useEffect(() => {
    // Clean up the interval on component unmount
    if (runIsOpen) {
      startSync();
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [runIsOpen]);

  return (
    <Drawer
      placement={"right"}
      onClose={runOnClose}
      isOpen={runIsOpen}
      size={"xl"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          Generations -{" "}
          {/* {importedFlag ? "Hide Imported Media" : "Show Imported Media"}{" "} */}
          <Tooltip label="Already Imported">
            <input
              type="radio"
              name="radio-5"
              className="radio radio-success"
              checked={!importedFlag}
              onChange={() => {
                setFilteredAPIs(apis.filter((api: any) => api.imported))
                setImportedFlag(false);

              }}
            />
          </Tooltip>
          <Tooltip label="New Generations">
            <input
              type="radio"
              name="radio-5"
              className="radio radio-secondary"
              checked={importedFlag}
              onChange={() => {
                setFilteredAPIs(apis.filter((api: any) => !api.imported))
                setImportedFlag(true);
              }}
            />
          </Tooltip>

        </DrawerHeader>
        <DrawerBody>
          <div className="join join-vertical w-full">
            {filteredAPIs && filteredAPIs.length === 0 && <p>No generations created</p>}
            {filteredAPIs &&
              filteredAPIs.map((api: any, i: number) => {
                const selected = APISelected.includes(api._id);
                return (
                  <div
                    className="collapse collapse-arrow join-item border border-base-300"
                    key={i}
                  >
                    <input type="radio" name="runs-generated" defaultChecked />
                    <div className="collapse-title text-xl font-medium">
                      <div className="badge badge-outline">{api.meta.info.model_name}</div>
                      <div className="badge badge-outline">{api.meta.info.mode}</div>
                    </div>
                    <div className="collapse-content">
                      <TableContainer>
                        <Table variant="simple">
                          <Tr>

                            <Td>
                              <ShowPreview api={api} />
                            </Td>


                            <Td>
                              <Button
                                variant={selected ? "outline" : "solid"}
                                backgroundColor={selected ? "green" : "orange"}
                                color={"white"}
                                loadingText="Generating Scenes"
                                spinnerPlacement="start"
                                onClick={() => {
                                  if (selected) {
                                    const removed = pullAll(
                                      cloneDeep(APISelected),
                                      [api._id]
                                    );
                                    setAPISelected(removed as any);
                                  } else {
                                    setAPISelected([...APISelected, api._id]);
                                  }
                                }}
                              >
                                {selected ? "Selected" : "Select"}
                              </Button>
                            </Td>
                          </Tr>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                );
              })}
          </div>
          {APISelected.length > 0 && (
            <Button
              // isLoading
              style={{ position: "fixed", bottom: "10px", right: "380px" }}
              loadingText="Generating Scenes"
              colorScheme="teal"
              // variant="outline"
              spinnerPlacement="start"
              onClick={() => {
                sendData("importAPI",{ apis: APISelected, selectedScreen });
                setAPISelected([]);
                runOnClose();
                window.location.reload();
                //   syncRun(variation);
              }}
            >
              Import Selected
            </Button>
          )}

          <Button
            // isLoading
            style={{ position: "fixed", bottom: "10px", right: "180px" }}
            loadingText="Generating Scenes"
            colorScheme="blue"
            // variant="outline"
            spinnerPlacement="start"
            onClick={() => {
              if (intervalRef.current) {
                stopSync();
              } else {
                startSync();
              }
              // getRunsForVideo({ id: videoId });
              //   syncRun(variation);
            }}
          >
            {intervalRef.current && "Stop Automatic Sync"}
            {!intervalRef.current && "Start Automatic Sync"}
          </Button>
          <Button
            // isLoading
            style={{ position: "fixed", bottom: "10px", right: "100px" }}
            loadingText="Generating Scenes"
            colorScheme="red"
            // variant="outline"
            spinnerPlacement="start"
            onClick={() => {
              runOnClose();
            }}
          >
            Close
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
