import {
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  ModalFooter,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  FormControl,
  FormLabel,
  Textarea,
  Tooltip,
  Input,
} from "@chakra-ui/react";
import React, { FC, SyntheticEvent, useState } from "react";
import { ComponentProps } from "../utils/EditorUtil";
import { FormProvider, useForm } from "react-hook-form";
import { VariationForm } from "../components/VariationForm";
import {
  Subtitle,
  editorState,
  setEditorProps,
  setSubtitles,
} from "../../stores/EditorStore";
import { useSnapshot } from "valtio";
import { setFormData } from "../../stores/StoryStore";
import { duration } from "moment";

export const PropertyModal: FC<{
  propIsOpen: boolean;
  proponClose: () => void;
  methods: any;
  videoId: string;
  sendData: (action: string, data?: any, id?: string) => void;
  saveVideoUpdates: any;
  openAsset: Function;
}> = ({
  propIsOpen,
  proponClose,
  methods,
  videoId,
  saveVideoUpdates,
  sendData,
  openAsset,
}) => {
  const { register, getValues, setValue } = methods;
  const {
    editorProps,
    screenprops,
    selectedScreen,
    selectedComponent,
    selectedComponentName,
    importing,
    componentRef,
    compProperties,
    formData,
    subtitles,
  } = useSnapshot(editorState);
  // const [, setSubtitles] = useState<Subtitle[]>([
  //   {
  //     text: compProperties?.meta?.prompt || compProperties?.meta?.text || "",
  //     color: "#000000",
  //     fontSize: 14,
  //     from: editorProps[`${selectedComponent}_from`] || 0,
  //     durationInFrames:
  //       editorProps[`${selectedComponent}_durationInFrames`] || 0,
  //   },
  // ]);

  const handleAddSubtitle = () => {
    setSubtitles([
      ...subtitles,
      {
        text: "",
        color: "#000000",
        fontSize: 14,
        from: 0,
        durationInFrames: 0,
      },
    ]);
  };

  const handleDeleteSubtitle = (index: number) => {
    const newSubtitles = subtitles.filter(
      (_: Subtitle, i: number) => i !== index
    );
    setSubtitles(newSubtitles);
  };

  const handleInputChange = (
    index: number,
    field: keyof Subtitle,
    value: string | number
  ) => {
    const newSubtitles = [...subtitles];
    newSubtitles[index] = { ...newSubtitles[index], [field]: value };
    setSubtitles(newSubtitles);
  };

  // const [formData, setFormData] = useState({});

  return (
    <Drawer
      placement={"right"}
      onClose={proponClose}
      isOpen={propIsOpen}
      size={"xl"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          Edit{" "}
          <span
            contentEditable={true}
            onBlur={(e) => {
              const updatedText = e.currentTarget.textContent;
              setEditorProps(
                Object.assign({}, editorProps, {
                  [`${selectedComponent}_name`]: updatedText,
                })
              );
              // proponClose();
              // setSavePending(true);
            }}
          >
            {selectedComponentName}
          </span>
        </DrawerHeader>
        <DrawerBody>
          <FormProvider {...methods}>
            <form
              onSubmit={(e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
                e.preventDefault();
                const values = getValues();
                const filterValues: Record<string, any> = {};

                Object.keys(values).forEach((key) => {
                  if (key.includes(selectedComponent)) {
                    filterValues[key] = values[key];
                  }
                });
                const updatedProps = Object.assign(
                  {},
                  editorProps,
                  filterValues
                );
                setEditorProps(updatedProps);
                saveVideoUpdates(updatedProps);
                proponClose();
              }}
            >
              <ModalCloseButton />
              <Tabs position="relative" variant="unstyled" defaultIndex={0}>
                <TabList>
                  <Tab>Main</Tab>
                  <Tab>Position</Tab>
                  <Tab>Timing</Tab>
                </TabList>
                <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />
                <TabPanels>
                  <TabPanel>
                    <ComponentProps
                      type="meta"
                      register={register}
                      screenprops={screenprops}
                      selectedComponent={selectedComponent}
                      selectedScreen={selectedScreen}
                      videoId={videoId}
                      editorProps={editorProps}
                      setValue={setValue}
                      openAsset={openAsset}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ComponentProps
                      type="position"
                      register={register}
                      screenprops={screenprops}
                      selectedComponent={selectedComponent}
                      selectedScreen={selectedScreen}
                      videoId={videoId}
                      editorProps={editorProps}
                      setValue={setValue}
                      openAsset={openAsset}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ComponentProps
                      type="timing"
                      register={register}
                      screenprops={screenprops}
                      selectedComponent={selectedComponent}
                      selectedScreen={selectedScreen}
                      videoId={videoId}
                      editorProps={editorProps}
                      setValue={setValue}
                      openAsset={openAsset}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  Save
                </Button>
                <Button variant="ghost" onClick={proponClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
          <Divider />
          {componentRef?.tag === "Audio" && (
            <>
              <DrawerHeader borderBottomWidth="1px">Add Subtitles</DrawerHeader>
              <FormControl>
                <table className="min-w-full border-collapse">
                  <thead>
                    <tr>
                      <th className="border px-4 py-2">Text</th>
                      <th className="border px-4 py-2">Color</th>
                      <th className="border px-4 py-2">Font Size</th>
                      <th className="border px-4 py-2">Visible from second</th>
                      <th className="border px-4 py-2">Duration in seconds</th>
                      <th className="border px-4 py-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subtitles &&
                      subtitles.map((subtitle: Subtitle, index: number) => (
                        <tr key={index} className="border-t">
                          <td className="border px-4 py-2 w-2/5">
                            <Textarea
                              placeholder="Subtitles to add"
                              value={subtitle.text}
                              onChange={(e) =>
                                handleInputChange(index, "text", e.target.value)
                              }
                            />
                          </td>
                          <td className="border px-4 py-2">
                            <Input
                              placeholder="Select Color"
                              type="color"
                              value={subtitle.color}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "color",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className="border px-4 py-2">
                            <Input
                              placeholder="Add font Size"
                              type="number"
                              value={subtitle.fontSize}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "fontSize",
                                  parseInt(e.target.value, 10)
                                )
                              }
                            />
                          </td>
                          <td className="border px-4 py-2">
                            <Input
                              placeholder="Visible from second"
                              type="number"
                              value={subtitle.from}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "from",
                                  parseInt(e.target.value, 10)
                                )
                              }
                            />
                          </td>
                          <td className="border px-4 py-2">
                            <Input
                              placeholder="Duration in seconds"
                              type="number"
                              value={subtitle.durationInFrames}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "durationInFrames",
                                  parseInt(e.target.value, 10)
                                )
                              }
                            />
                          </td>
                          <td className="border px-4 py-2">
                            <Button
                              colorScheme="red"
                              onClick={() => handleDeleteSubtitle(index)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Button mt={4} colorScheme="blue" onClick={handleAddSubtitle}>
                  Add Additional row
                </Button>
                <ModalFooter>
                  <Button
                    colorScheme="green"
                    mr={3}
                    onClick={() =>
                      sendData("genSubtitles", {
                        video: videoId,
                        url: editorProps[`${selectedComponent}_url`],
                        component: selectedComponent,
                      })
                    }
                  >
                    Generate Subs from Audio using AI
                  </Button>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      sendData("addSubtitles", {
                        subtitles: subtitles,
                        component: "Text",
                        screen: selectedScreen,
                        videoId,
                      });
                    }}
                  >
                    Add to screen
                  </Button>
                  <Button variant="ghost" onClick={proponClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </FormControl>
            </>
          )}
          {/* <DrawerHeader borderBottomWidth="1px">
            Generate Variations
          </DrawerHeader>
          <FormProvider {...methods}>
            <form
              onSubmit={(e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
                e.preventDefault();
                const values = getValues();
                // const filterValues: Record<string, any> = {};

                // Object.keys(values).forEach((key) => {
                //   if (key.includes(selectedComponent)) {
                //     filterValues[key] = values[key];
                //   }
                // });
                sendData("variations",{
                  ...formData,
                  component: selectedComponent,
                  screen: selectedScreen,
                });
                // setEditorProps(Object.assign({}, editorProps, filterValues));
                // proponClose();
                // setSavePending(true);
              }}
            >
              <ModalCloseButton />

              <VariationForm
                // schema={imageToImageSchema("fast-svd")}
                formData={formData}
                handleSubmit={handleSubmit}
                videoId={videoId}
              />

              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  Generate
                </Button>
                <Button variant="ghost" onClick={proponClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </FormProvider> */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
