import React, { useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { backendurl } from "../lib/utils/Misc";
import { useNavigate, useLocation } from "react-router-dom";
import { showAlert } from "../components/lib/Alert";

export const handleShowAlert = (
  type: string,
  title: string,
  message: string
) => {
  showAlert(type, title, message);
};

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Handle() {
  const navigate = useNavigate();
  const location = useLocation();
  const requestSentRef = useRef(false);

  useEffect(() => {
    let isMounted = true;
    const sendRequest = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const provider = params.get('provider');
      if (code && provider && !requestSentRef.current && isMounted) {
        requestSentRef.current = true;
        try {
          const response = await fetch(`${backendurl}/auth/${provider}/handle`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ code }),
            credentials: "include",
          });
          const data = await response.json();
          console.log({data});
          if (data.token) {
            localStorage.setItem("auth", JSON.stringify(data));
            handleShowAlert(
              "success",
              "Success",
              "Logged in successfully. Redirecting to dashboard."
            );
            navigate("/dashboard");
          } else {
            throw new Error("No token received");
          }
        } catch (error) {
          console.error("Error during login:", error);
          handleShowAlert(
            "error",
            "Error",
            "Failed to log in. Redirecting to login page."
          );
          navigate("/login?error=login failed");
        }
      } else if (!code || !provider) {
          navigate("/login?error=no code returned ");
      }
    };
    sendRequest();
    return () => {
      isMounted = false;
    };
  }, [navigate, location]);

  const logOut = () => {
    fetch(`${backendurl}/api/users/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((resp) => {
        localStorage.removeItem("auth");
        if (resp.ok) {
          handleShowAlert(
            "success",
            "Processing",
            "Logged out. Redirecting to login"
          );
        } else {
          handleShowAlert("warning", "Processing", "Issue logging you out.");
          console.log(resp);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        navigate("/login");
      });
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className="relative py-16 overflow-hidden h-screen">
        <img
          className="absolute top-0 left-0 w-full h-screen object-cover"
          src="homepage/bromo-assets/sign-in/background.png"
          alt=""
        />
        <div className="relative container mx-auto px-4">
          <div className="max-w-2xl px-4 mx-auto">
            <div className="relative py-12 px-10 text-center rounded-3xl overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full filter backdrop-blur backdrop-filter bg-black bg-opacity-70" />
              <div className="relative">
                <h2 className="font-heading text-4xl font-medium text-white tracking-tight mb-6">
                  Processing your request...
                </h2>
                <p className="text-xl text-white mb-8">
                  Crackviral is powered by state-of-the-art video, image and audio
                  models to give an edge over other editors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}