import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  useDisclosure,
  chakra,
  Button,
  GridItem,
  useToast,
  CircularProgress,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


import "../Tailwind.css";
import { getSubString } from "../lib/utils/Misc";
import { NewVideoModal } from "../lib/modals/NewVideo";
import { useAuth } from "../components/lib/useAuth";
import { useSnapshot } from "valtio";
import {
  editorState,
  setCurrentCreating,
  setShowLoader,
} from "../stores/EditorStore";
import { HeadContent } from "../lib/components/Headcontent";
import { useWebSocket } from "../lib/components/WebSocketContext";
import DashboardWrapper from "../lib/components/Wrapper";
import FeaturedWorkflows from "../lib/components/Workflows";

const Workflows: React.FC = () => {
  // const wsRef = useRef<WebSocket | null>(null);
  const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
  const [videos, setVideos] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVideos, setFilteredVideos] = useState(videos);
  const navigate = useNavigate();
  const toast = useToast();
  const [auth, error, loading] = useAuth();
  const {
    video,
    newVideo: videoNew,
    creating,
    showLoader,
  } = useSnapshot(editorState);

  useEffect(() => {
    addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);

  useEffect(() => {
    document.title = "Crackviral | Dashboard";
    setFilteredVideos(videos);
  }, [videos]);

  useEffect(() => {
    if (videos.length === 0) {
      loadVideos();
    }
    setShowLoader(false);
  }, []);

  const sidebar = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
 
  // Listen for messages from the WebSocket server
  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status) {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "videos":
        console.log("Updated video:", message.videos);
        setVideos(message.videos);
        break;
      case "video":
        console.log("Updated video:", message.video);
        loadVideos();
        break;
      case "sceneFull":
        console.log("experiment obj:", message.experimentObj);
        navigate(`/storyboard/${message.experimentObj._id}`);
        // setScenes( message.experimentObj.out.content.scenes)
        break;
      case "error":
        console.log("Error video:", message.message, event);
        toast({
          title: `Error video:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };
  const handleSearch = () => {
    if (searchQuery !== null && searchQuery !== undefined) {
      const query = searchQuery.toLowerCase();
      const filtered = videos.filter((vid) => {
        if (vid?.title && vid?.description) {
          return (
            vid.title.toLowerCase().includes(query) ||
            vid.description.toLowerCase().includes(query)
          );
        }
        if (vid?.title) {
          return vid.title.toLowerCase().includes(query);
        }
        if (vid?.description) {
          return vid.description.toLowerCase().includes(query);
        }
        if (!vid?.title && !vid?.description) {
          return;
        }
      });
      setFilteredVideos(filtered);
    }
  };

  function loadVideos() {
    const payload = {
      action: "getVideos",
    };
    send(payload);
  }

  function submitAuth() {
    const payload = {
      action: "auth",
      data: localStorage.getItem("auth"),
    };
    send(payload);
  }

  const handleOpen = () => {
    submitAuth();
    loadVideos();
  };

  return (
    <DashboardWrapper>
      <HeadContent
        sidebar={sidebar}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
      />
      <Box as="main" p="4">
      <FeaturedWorkflows/>

        <Button
          // isLoading
          style={{ position: "fixed", bottom: "10px", right: "10px" }}
          loadingText="Import Scenes"
          // colorScheme="teal"
          // variant="outline"
          spinnerPlacement="start"
          onClick={() => navigate("/video/create")}
        >
          Create Video
        </Button>
      </Box></DashboardWrapper>
  );
};

export default Workflows;