const modes = {
  base: { path: "/", input: "TextToImageInput" },
  image: { path: "/image-to-image", input: "ImageToImageInput" },
};

const title = "Stable Diffusion V3";
const specs = {
  paths: {
    "/image-to-image": {
      post: {
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/SD3Output" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
        requestBody: {
          content: {
            "application/json": {
              schema: { $ref: "#/components/schemas/ImageToImageInput" },
            },
          },
          required: true,
        },
        summary: "Image To Image",
        operationId: "image_to_image_image_to_image_post",
      },
    },
    "/": {
      post: {
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/SD3Output" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
        requestBody: {
          content: {
            "application/json": {
              schema: { $ref: "#/components/schemas/TextToImageInput" },
            },
          },
          required: true,
        },
        summary: "Text To Image",
        operationId: "text_to_image__post",
      },
    },
    "/health": {
      get: {
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
        },
        summary: "Health",
        operationId: "health_health_get",
      },
    },
  },
  info: { version: "0.1.0", title: "FastAPI" },
  components: {
    schemas: {
      TextToImageInput: {
        title: "TextToImageInput",
        type: "object",
        properties: {
          prompt: {
            examples: [
              "Digital art, portrait of an anthropomorphic roaring Tiger warrior with full armor, close up in the middle of a battle, behind him there is a banner with the text \"Open Source\"",
            ],
            title: "Prompt",
            type: "string",
            description: "The prompt to generate an image from.",
          },
          num_images: {
            minimum: 1,
            title: "Num Images",
            type: "integer",
            maximum: 4,
            description: "The number of images to generate.",
            default: 1,
          },
          image_size: {
            anyOf: [
              { $ref: "#/components/schemas/ImageSize" },
              {
                enum: [
                  "square_hd",
                  "square",
                  "portrait_4_3",
                  "portrait_16_9",
                  "landscape_4_3",
                  "landscape_16_9",
                ],
                type: "string",
              },
            ],
            title: "Image Size",
            description: "The size of the generated image.",
            default: "square_hd",
          },
          prompt_expansion: {
            title: "Enhance Prompt",
            type: "boolean",
            description:
              "If set to true, prompt will be upsampled with more details.",
            default: false,
          },
          sync_mode: {
            title: "Sync Mode",
            type: "boolean",
            description:
              "\n            If set to true, the function will wait for the image to be generated and uploaded\n            before returning the response. This will increase the latency of the function but\n            it allows you to get the image directly in the response without going through the CDN.\n        ",
            default: false,
          },
          guidance_scale: {
            minimum: 0,
            title: "Guidance scale (CFG)",
            type: "number",
            maximum: 20,
            description:
              "\n            The CFG (Classifier Free Guidance) scale is a measure of how close you want\n            the model to stick to your prompt when looking for a related image to show you.\n        ",
            default: 5,
          },
          num_inference_steps: {
            minimum: 1,
            title: "Num Inference Steps",
            type: "integer",
            maximum: 50,
            description: "The number of inference steps to perform.",
            default: 28,
          },
          seed: {
            title: "Seed",
            type: "integer",
            description:
              "\n            The same seed and the same prompt given to the same version of Stable Diffusion\n            will output the same image every time.\n        ",
          },
          negative_prompt: {
            title: "Negative Prompt",
            type: "string",
            description: "The negative prompt to generate an image from.",
            default: "",
          },
          enable_safety_checker: {
            title: "Enable Safety Checker",
            type: "boolean",
            description: "If set to true, the safety checker will be enabled.",
            default: true,
          },
        },
        "x-fal-order-properties": [
          "prompt",
          "negative_prompt",
          "prompt_expansion",
          "image_size",
          "num_inference_steps",
          "seed",
          "guidance_scale",
          "sync_mode",
          "num_images",
          "enable_safety_checker",
        ],
        required: ["prompt"],
      },
      SD3Output: {
        title: "SD3Output",
        type: "object",
        properties: {
          prompt: {
            title: "Prompt",
            type: "string",
            description: "The prompt used for generating the image.",
          },
          images: {
            title: "Images",
            type: "array",
            description: "The generated image files info.",
            items: { $ref: "#/components/schemas/Image" },
          },
          num_images: {
            title: "Number of Images",
            type: "integer",
            description: "The number of images generated.",
          },
          timings: {
            title: "Timings",
            type: "object",
            additionalProperties: { type: "number" },
          },
          has_nsfw_concepts: {
            title: "Has Nsfw Concepts",
            type: "array",
            description: "Whether the generated images contain NSFW concepts.",
            items: { type: "boolean" },
          },
          seed: {
            title: "Seed",
            type: "integer",
            description:
              "\n            Seed of the generated Image. It will be the same value of the one passed in the\n            input or the randomly generated that was used in case none was passed.\n        ",
          },
        },
        "x-fal-order-properties": [
          "images",
          "timings",
          "seed",
          "has_nsfw_concepts",
          "prompt",
          "num_images",
        ],
        required: [
          "images",
          "timings",
          "seed",
          "has_nsfw_concepts",
          "prompt",
          "num_images",
        ],
      },
      ImageSize: {
        title: "ImageSize",
        type: "object",
        properties: {
          height: {
            title: "Height",
            type: "integer",
            maximum: 14142,
            description: "The height of the generated image.",
            exclusiveMinimum: 0,
            default: 512,
          },
          width: {
            title: "Width",
            type: "integer",
            maximum: 14142,
            description: "The width of the generated image.",
            exclusiveMinimum: 0,
            default: 512,
          },
        },
        "x-fal-order-properties": ["width", "height"],
      },
      ImageToImageInput: {
        title: "ImageToImageInput",
        type: "object",
        properties: {
          prompt: {
            examples: [
              "cat wizard, gandalf, lord of the rings, detailed, fantasy, cute, adorable, Pixar, Disney, 8k",
            ],
            title: "Prompt",
            type: "string",
            description: "The prompt to generate an image from.",
          },
          num_images: {
            minimum: 1,
            title: "Num Images",
            type: "integer",
            maximum: 4,
            description: "The number of images to generate.",
            default: 1,
          },
          image_size: {
            anyOf: [
              { $ref: "#/components/schemas/ImageSize" },
              {
                enum: [
                  "square_hd",
                  "square",
                  "portrait_4_3",
                  "portrait_16_9",
                  "landscape_4_3",
                  "landscape_16_9",
                ],
                type: "string",
              },
            ],
            title: "Image Size",
            description:
              "The size of the generated image. Defaults to the conditioning image's size.",
            examples: [null],
            nullable: true,
          },
          prompt_expansion: {
            title: "Enhance Prompt",
            type: "boolean",
            description:
              "If set to true, prompt will be upsampled with more details.",
            default: false,
          },
          image_url: {
            examples: [
              "https://fal.media/files/zebra/b52cVi3BhLDJcBrk6x0DL.png",
            ],
            title: "Image URL",
            type: "string",
            description: "The image URL to generate an image from.",
          },
          sync_mode: {
            title: "Sync Mode",
            type: "boolean",
            description:
              "\n            If set to true, the function will wait for the image to be generated and uploaded\n            before returning the response. This will increase the latency of the function but\n            it allows you to get the image directly in the response without going through the CDN.\n        ",
            default: false,
          },
          strength: {
            minimum: 0.01,
            title: "Strength",
            type: "number",
            maximum: 1,
            description: "The strength of the image-to-image transformation.",
            default: 0.9,
          },
          guidance_scale: {
            minimum: 0,
            title: "Guidance scale (CFG)",
            type: "number",
            maximum: 20,
            description:
              "\n            The CFG (Classifier Free Guidance) scale is a measure of how close you want\n            the model to stick to your prompt when looking for a related image to show you.\n        ",
            default: 5,
          },
          num_inference_steps: {
            minimum: 1,
            title: "Num Inference Steps",
            type: "integer",
            maximum: 50,
            description: "The number of inference steps to perform.",
            default: 28,
          },
          seed: {
            title: "Seed",
            type: "integer",
            description:
              "\n            The same seed and the same prompt given to the same version of Stable Diffusion\n            will output the same image every time.\n        ",
          },
          negative_prompt: {
            title: "Negative Prompt",
            type: "string",
            description: "The negative prompt to generate an image from.",
            default: "",
          },
          enable_safety_checker: {
            title: "Enable Safety Checker",
            type: "boolean",
            description: "If set to true, the safety checker will be enabled.",
            default: true,
          },
        },
        "x-fal-order-properties": [
          "image_url",
          "prompt",
          "negative_prompt",
          "prompt_expansion",
          "image_size",
          "num_inference_steps",
          "seed",
          "guidance_scale",
          "sync_mode",
          "num_images",
          "enable_safety_checker",
          "strength",
        ],
        required: ["prompt", "image_url"],
      },
      ValidationError: {
        title: "ValidationError",
        type: "object",
        properties: {
          type: { title: "Error Type", type: "string" },
          msg: { title: "Message", type: "string" },
          loc: {
            title: "Location",
            type: "array",
            items: { anyOf: [{ type: "string" }, { type: "integer" }] },
          },
        },
        "x-fal-order-properties": ["loc", "msg", "type"],
        required: ["loc", "msg", "type"],
      },
      Image: {
        title: "Image",
        type: "object",
        properties: {
          height: { title: "Height", type: "integer" },
          content_type: {
            title: "Content Type",
            type: "string",
            default: "image/jpeg",
          },
          url: { title: "Url", type: "string" },
          width: { title: "Width", type: "integer" },
        },
        "x-fal-order-properties": ["url", "width", "height", "content_type"],
        required: ["url", "width", "height"],
      },
      HTTPValidationError: {
        title: "HTTPValidationError",
        type: "object",
        properties: {
          detail: {
            title: "Detail",
            type: "array",
            items: { $ref: "#/components/schemas/ValidationError" },
          },
        },
        "x-fal-order-properties": ["detail"],
      },
    },
  },
  openapi: "3.0.2",
  "x-fal-order-paths": ["/image-to-image", "/", "/health"],
};
export default { specs, title, modes };
