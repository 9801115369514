import React, { useState } from 'react';
import { ArrowLeft, User, Mic, Music, Maximize2 } from 'lucide-react';
import { BsAspectRatio } from 'react-icons/bs';
const VisualStyleSelector = () => {
  const [selectedStyle, setSelectedStyle] = useState(0);

  const styles = [
    'Green Screen Effect', 'Full Screen', 'Vanilla', 'Quick Transition', 'Avatar Bubble',
    'Top & Bottom', 'Vlog', 'Twitter Frame', 'Side by Side', 'Dramatic', 'Scribble',
    'Product', 'Over Cards', 'Motion Cards'
  ];

  return (
    <div className="bg-gray-900 text-white min-h-screen p-6">
      <h1 className="text-2xl font-bold mb-4">Select visual style</h1>
      
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-4 mb-6">
        {styles.map((style, index) => (
          <div
            key={index}
            className={`relative aspect-[9/16] bg-gray-800 rounded-lg overflow-hidden cursor-pointer ${
              selectedStyle === index ? 'ring-2 ring-blue-500' : ''
            }`}
            onClick={() => setSelectedStyle(index)}
          >
            <div className="absolute inset-0 flex items-center justify-center">
              <img src="/api/placeholder/150/266" alt="Style preview" className="w-full h-full object-cover" />
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-2 text-xs">
              {style}
            </div>
            {selectedStyle === index && (
              <div className="absolute top-2 right-2 w-4 h-4 bg-blue-500 rounded-full flex items-center justify-center">
                <span className="text-white text-xs">✓</span>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-between items-start">
        <div className="w-2/3 pr-4">
          <div className="flex space-x-4 mb-4">
            <button className="px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition">Back</button>
            <button className="px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition">Render directly</button>
            <button className="px-4 py-2 bg-blue-600 rounded-lg hover:bg-blue-500 transition">Further editing in Editor</button>
          </div>
        </div>

        <div className="w-1/3">
          <div className="bg-gray-800 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-2">Preview</h2>
            <div className="aspect-video bg-gray-700 rounded mb-2">
              <img src="/api/placeholder/320/180" alt="Video preview" className="w-full h-full object-cover rounded" />
            </div>
            <div className="flex justify-between items-center text-sm">
              <span>0:00 / 0:33</span>
              <div className="flex space-x-2">
                <button className="p-1 hover:bg-gray-700 rounded"><User size={16} /></button>
                <button className="p-1 hover:bg-gray-700 rounded"><Mic size={16} /></button>
                <button className="p-1 hover:bg-gray-700 rounded"><BsAspectRatio size={16} /></button>
                <button className="p-1 hover:bg-gray-700 rounded"><Music size={16} /></button>
                <button className="p-1 hover:bg-gray-700 rounded"><Maximize2 size={16} /></button>
              </div>
            </div>
          </div>
          <div className="mt-2 p-2 bg-yellow-500 bg-opacity-20 text-yellow-200 rounded-lg text-sm">
            ℹ️ Clicking 'Render' will provide accurate lip-sync and higher quality.
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisualStyleSelector;