// export const ModelDict = [
//   {
//     modelName: "Stable Video Diffusion Turbo",
//     type: "video",
//     status: "supported",
//     imageUrl:
//       "https://storage.googleapis.com/falserverless/gallery/fast-svd-turbo.gif",
//     modelDescription:
//       "Generate short video clips from your images using SVD v1.1 at Lightning Speed",
//     key: "fast-svd",
//     tags: ["image-to-video", "inference"],
//   },
//   {
//     modelName: "MetaVoice",
//     type: "dialogue",
//     status: "supported",
//     imageUrl:
//       "https://storage.googleapis.com/falserverless/gallery/metavoice.jpeg",
//     modelDescription:
//       "MetaVoice-1B is a 1.2B parameter base model trained on 100K hours of speech for TTS (text-to-speech).",
//     key: "fast-svd",
//     tags: ["text-to-speech", "inference", "stylized"],
//   },
//   {
//     modelName: "MusicGen",
//     type: "music",
//     status: "coming soon",
//     imageUrl:
//       "https://storage.googleapis.com/falserverless/gallery/musicgen.jpeg",
//     modelDescription:
//       "Create high-quality music by taking cues from text descriptions or melodies.",
//     tags: ["text-to-audio", "inference", "stylized"],
//     key: "music",
//   },
// ];

export const MotionSequenceUrls = [
  {
    url: "https://github.com/magic-research/magic-animate/raw/main/inputs/applications/driving/densepose/dancing2.mp4",
    view: "https://github.com/magic-research/magic-animate/raw/main/inputs/applications/driving/densepose/dancing2.mp4",
    name: "dancing2",
  },
  {
    url: "https://github.com/magic-research/magic-animate/raw/main/inputs/applications/driving/densepose/demo4.mp4",
    view: "https://github.com/magic-research/magic-animate/raw/main/inputs/applications/driving/densepose/demo4.mp4",
    name: "demo4",
  },
  {
    url: "https://github.com/magic-research/magic-animate/raw/main/inputs/applications/driving/densepose/running.mp4",
    view: "https://github.com/magic-research/magic-animate/raw/main/inputs/applications/driving/densepose/running.mp4",
    name: "running",
  },
  {
    url: "https://github.com/magic-research/magic-animate/raw/main/inputs/applications/driving/densepose/running2.mp4",
    view: "https://github.com/magic-research/magic-animate/raw/main/inputs/applications/driving/densepose/running2.mp4",
    name: "running2",
  },
];

export const styleClips = [
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_surprised_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_surprised_level3_001.mp4",
    name: "W011_front_surprised_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/M030_front_angry_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/M030_front_angry_level3_001.mp4",
    name: "M030_front_angry_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/M030_front_contempt_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/M030_front_contempt_level3_001.mp4",
    name: "M030_front_contempt_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/M030_front_disgusted_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/M030_front_disgusted_level3_001.mp4",
    name: "M030_front_disgusted_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/M030_front_fear_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/M030_front_fear_level3_001.mp4",
    name: "M030_front_fear_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/M030_front_happy_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/M030_front_happy_level3_001.mp4",
    name: "M030_front_happy_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/M030_front_neutral_level1_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/M030_front_neutral_level1_001.mp4",
    name: "M030_front_neutral_level1_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/M030_front_sad_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/M030_front_sad_level3_001.mp4",
    name: "M030_front_sad_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/M030_front_surprised_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/M030_front_surprised_level3_001.mp4",
    name: "M030_front_surprised_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W009_front_angry_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W009_front_angry_level3_001.mp4",
    name: "W009_front_angry_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W009_front_contempt_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W009_front_contempt_level3_001.mp4",
    name: "W009_front_contempt_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W009_front_disgusted_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W009_front_disgusted_level3_001.mp4",
    name: "W009_front_disgusted_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W009_front_fear_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W009_front_fear_level3_001.mp4",
    name: "W009_front_fear_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W009_front_happy_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W009_front_happy_level3_001.mp4",
    name: "W009_front_happy_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W009_front_neutral_level1_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W009_front_neutral_level1_001.mp4",
    name: "W009_front_neutral_level1_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W009_front_sad_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W009_front_sad_level3_001.mp4",
    name: "W009_front_sad_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W009_front_surprised_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W009_front_surprised_level3_001.mp4",
    name: "W009_front_surprised_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_angry_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_angry_level3_001.mp4",
    name: "W011_front_angry_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_contempt_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_contempt_level3_001.mp4",
    name: "W011_front_contempt_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_disgusted_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_disgusted_level3_001.mp4",
    name: "W011_front_disgusted_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_fear_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_fear_level3_001.mp4",
    name: "W011_front_fear_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_happy_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_happy_level3_001.mp4",
    name: "W011_front_happy_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_neutral_level1_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_neutral_level1_001.mp4",
    name: "W011_front_neutral_level1_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_sad_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_sad_level3_001.mp4",
    name: "W011_front_sad_level3_001",
  },
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/3DMM/W011_front_surprised_level3_001.mat",
    view: "https://github.com/ali-vilab/dreamtalk/raw/main/data/style_clip/video/W011_front_surprised_level3_001.mp4",
    name: "W011_front_surprised_level3_001",
  },
];

export const poseUrls = [
  {
    url: "https://github.com/ali-vilab/dreamtalk/raw/main/data/pose/RichardShelby_front_neutral_level1_001.mat",
    view: "https://replicate.delivery/pbxt/KBfUBegFxSEu9miKsAuRLfO1plCDUqVhMeF7yhPbDleEYalk/male_face.png",
    name: "RichardShelby_front_neutral_level1_001",
  },
];


export const ModelDict = [
  {
    id: "stable-diffusion-xl",
    title: "Stable Diffusion XL",
    category: "text-to-image",
    tags: ["inference", "loras", "embeddings"],
    shortDescription: "Run SDXL at the speed of light",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-sdxl.jpeg",
    modelUrl: "https://fal.run/fal-ai/fast-sdxl",
    licenseUrl:
      "https://huggingface.co/stabilityai/stable-diffusion-xl-base-1.0/blob/main/LICENSE.md",
    machineType: "A100",
    group: { key: "stable-diffusion-xl", label: "Text to Image" },
    highlighted: true,
    status: true,
  },
  {
    id: "svd",
    title: "Controlled Stable Video Diffusion",
    category: "image-to-image",
    tags: ["inference", "video"],
    shortDescription: "Generate short video clips from your images.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/svd.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/svd-animated.webp",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/video_models/svd",
    modelUrl: "https://fal.run/fal-ai/svd",
    licenseUrl:
      "https://huggingface.co/stabilityai/stable-video-diffusion-img2vid-xt/blob/main/LICENSE",
    machineType: "A100",
    requiresAuth: true,
    researchOnly: true,
    status: true,
  },
  {
    id: "magic-animate",
    title: "Magic Animate",
    category: "image-to-image",
    tags: ["inference", "animation", "video"],
    shortDescription: "Generate short video clips from motion sequence.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/magic-animate.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/magic-animate-animated.webp",
    githubUrl: "https://github.com/magic-research/magic-animate",
    modelUrl: "https://fal.run/fal-ai/magic-animate",
    licenseUrl:
      "https://github.com/magic-research/magic-animate/blob/main/LICENSE",
    machineType: "A100",
    requiresAuth: true,
    status: true,
  },
  {
    id: "sd-loras",
    title: "Stable Diffusion with LoRAs",
    category: "text-to-image",
    tags: ["inference", "loras", "stylized"],
    shortDescription:
      "Run Any Stable Diffusion model with customizable LoRA weights.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/sd-loras.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/image_models/lora.py",
    modelUrl: "https://fal.run/fal-ai/lora",
    licenseUrl:
      "https://huggingface.co/spaces/CompVis/stable-diffusion-license",
    machineType: "A100",
    group: { key: "sd-loras", label: "Text to Image" },
  },

  {
    id: "fast-svd",
    title: "Stable Video Diffusion",
    category: "image-to-video",
    tags: ["inference", "video"],
    shortDescription:
      "Generate short video clips from your images using SVD v1.1",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-svd.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-svd-animated.webp",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/video_models/svd",
    modelUrl: "https://fal.run/fal-ai/fast-svd",
    licenseUrl:
      "https://huggingface.co/stabilityai/stable-video-diffusion-img2vid-xt-1-1/blob/main/LICENSE",
    machineType: "A100",
    status: true,
    highlighted: true,
    requiresAuth: true,
    researchOnly: false,
  },
  {
    id: "fast-svd-turbo",
    title: "Stable Video Diffusion Turbo",
    category: "image-to-video",
    tags: ["inference", "video"],
    shortDescription:
      "Generate short video clips from your images using SVD v1.1 at Lightning Speed",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-svd-turbo.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-svd-turbo-animated.webp",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/video_models/svd",
    modelUrl: "https://fal.run/fal-ai/fast-svd-lcm",
    licenseUrl:
      "https://huggingface.co/stabilityai/stable-video-diffusion-img2vid-xt-1-1/blob/main/LICENSE",
    machineType: "A100",
    status: true,
    requiresAuth: true,
    researchOnly: false,
    group: { key: "stable-video-diffusion-turbo", label: "Image to Video" },
  },
  {
    id: "stable-cascade",
    title: "Stable Cascade",
    category: "text-to-image",
    tags: ["inference", "lcm", "stylized"],
    shortDescription:
      "Stable Cascade: Image generation on a smaller & cheaper latent space.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/stable-cascade.jpeg",
    githubUrl: "https://github.com/Stability-AI/StableCascade/",
    modelUrl: "https://fal.run/fal-ai/stable-cascade",
    licenseUrl:
      "https://huggingface.co/stabilityai/stable-cascade/blob/main/LICENSE",
    machineType: "A100",
    highlighted: true,
    researchOnly: true,
  },
  {
    id: "animatediff-evolved-v2v",
    title: "AnimateDiff Video-to-Video Evolved",
    category: "video-to-video",
    tags: ["inference", "video", "stylized"],
    shortDescription: "Re-animate your videos with evolved consistency!",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/animatediff-evolved-v2v.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/animatediff-evolved-v2v-animated.webp",
    modelUrl: "https://fal.run/fal-ai/animatediff-v2v",
    machineType: "A100",
    requiresAuth: true,
    status: true,
    group: { key: "animatediff-evolved-v2v", label: "Base" },
  },
  {
    id: "metavoice",
    title: "MetaVoice",
    category: "text-to-speech",
    tags: ["inference", "speech", "voice"],
    shortDescription:
      "MetaVoice-1B is a 1.2B parameter base model trained on 100K hours of speech for TTS (text-to-speech).",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/metavoice.jpeg",
    githubUrl: "https://github.com/metavoiceio/metavoice-src/blob/main/LICENSE",
    modelUrl: "https://fal.run/fal-ai/metavoice-v1",
    machineType: "A6000",
    status: true,
  },
  {
    id: "dreamtalk",
    title: "DreamTalk",
    category: "image-to-video",
    tags: ["inference", "audio", "video"],
    shortDescription: "Animate Faces with Audio Files",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/dreamtalk.png",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/video/dreamtalk/inference.py",
    modelUrl: "https://fal.run/fal-ai/dreamtalk",
    licenseUrl: "https://github.com/ali-vilab/dreamtalk/blob/main/LICENSE",
    requiresAuth: true,
    status: true,
    machineType: "A10G",
  },
  {
    id: "xtts",
    title: "XTTS",
    category: "text-to-audio",
    tags: ["inference", "utility"],
    shortDescription: "",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/xtts.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/audio/xtts/inference.py",
    modelUrl: "https://fal.run/fal-ai/xtts",
    licenseUrl: "https://github.com/coqui-ai/TTS/blob/dev/LICENSE.txt",
    requiresAuth: true,
    status: true,
    machineType: "A10G",
  },
  {
    id: "creative-upscaler",
    title: "Creative Upscaler",
    category: "image-to-image",
    tags: ["inference", "upscaler", "utility"],
    shortDescription: "Create creative upscaled images.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/creative-upscaler.webp?v=3",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/creative-upscaler-animated.webp?v=3",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/creative_upscaler",
    modelUrl: "https://fal.run/fal-ai/creative-upscaler",
    requiresAuth: true,
    highlighted: true,
    machineType: "A100",
    resultComparison: true,
  },
  {
    id: "ccsr",
    title: "CCSR Upscaler",
    category: "image-to-image",
    tags: ["inference", "upscaler", "utility"],
    shortDescription: "SOTA Image Upscaler",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/ccsr.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/ccsr-animated.webp",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/ccsr",
    modelUrl: "https://fal.run/fal-ai/ccsr",
    requiresAuth: true,
    machineType: "A100",
    resultComparison: true,
  },
  {
    id: "fast-turbo-diffusion-turbo",
    title: "Stable Diffusion Turbo (v1.5/XL)",
    category: "text-to-image",
    tags: ["inference", "real-time", "optimized"],
    shortDescription: "Run SDXL at the speed of light",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/sdxl-turbo.jpeg",
    modelUrl: "https://fal.run/fal-ai/fast-turbo-diffusion",
    licenseUrl:
      "https://huggingface.co/stabilityai/sdxl-turbo/blob/main/LICENSE.TXT",
    machineType: "A6000",
    group: { key: "fast-turbo-diffusion", label: "Text to Image" },
  },
  {
    id: "fast-lcm-diffusion-turbo",
    title: "Latent Consistency Models (v1.5/XL)",
    category: "text-to-image",
    tags: ["inference", "real-time", "lcm"],
    shortDescription: "Run SDXL at the speed of light",
    thumbnailUrl:
      "https://fal-cdn.batuhan-941.workers.dev/files/rabbit/P322iQXlz2jOOuRFBWK-q.jpeg",
    modelUrl: "https://fal.run/fal-ai/fast-lcm-diffusion",
    licenseUrl:
      "https://huggingface.co/latent-consistency/lcm-lora-sdxl/blob/main/README.md",
    machineType: "A6000",
    group: { key: "fast-lcm-diffusion", label: "Text to Image" },
  },
  {
    id: "whisper",
    title: "Whisper",
    category: "speech-to-text",
    tags: ["inference", "speech"],
    shortDescription:
      "Whisper is a model for speech transcription and translation.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/whisper.jpg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/audio/whisper.py",
    modelUrl: "https://fal.run/fal-ai/whisper",
    licenseUrl: "https://github.com/openai/whisper/blob/main/LICENSE",
    highlighted: true,
    machineType: "A100",
  },
  {
    id: "wizper",
    title: "Whisper v3 Finetuned",
    category: "speech-to-text",
    tags: ["inference", "speech"],
    shortDescription:
      "[Experimental] Whisper v3 Large -- but optimized by our inference wizards. Same WER, double the performance!",
    thumbnailUrl: "https://fal.media/files/penguin/JoRvuq0z_dzCnR7xB7szX.png",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/audio/whisper.py",
    modelUrl: "https://fal.run/fal-ai/wizper",
    licenseUrl: "https://github.com/openai/whisper/blob/main/LICENSE",
    machineType: "A100",
  },
  {
    id: "stable-diffusion-xl-lightning",
    title: "Stable Diffusion XL Lightning",
    category: "text-to-image",
    tags: ["inference", "real-time", "optimized"],
    shortDescription: "Run SDXL at the speed of light",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/stable-diffusion-xl-lightning.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/stable-diffusion-xl-lightning-animated.webp",
    modelUrl: "https://fal.run/fal-ai/fast-lightning-sdxl",
    licenseUrl:
      "https://huggingface.co/ByteDance/SDXL-Lightning/blob/main/LICENSE.md",
    machineType: "A100",
    group: { key: "stable-diffusion-xl-lightning", label: "Text to Image" },
    highlighted: true,
  },
  {
    id: "playground-v25",
    title: "Playground v2.5",
    category: "text-to-image",
    tags: ["inference", "artistic"],
    shortDescription: "State-of-the-art open-source model in aesthetic quality",
    thumbnailUrl:
      "https://fal-cdn.batuhan-941.workers.dev/files/monkey/8WXjrk5HEam79CPlQlo5T.jpeg",
    modelUrl: "https://fal.run/fal-ai/playground-v25",
    licenseUrl:
      "https://huggingface.co/playgroundai/playground-v2.5-1024px-aesthetic/blob/main/LICENSE.md",
    machineType: "A100",
    group: { key: "playground-v25", label: "Text to Image" },
    highlighted: true,
  },
  {
    id: "japanese-stable-diffusion-xl",
    title: "Japanese Stable Diffusion XL",
    category: "text-to-image",
    tags: ["inference", "localized"],
    shortDescription:
      "Japanese-specific SDXL model that is capable of inputting prompts in Japanese and generating Japanese-style images.",
    thumbnailUrl:
      "https://fal-cdn.batuhan-941.workers.dev/files/kangaroo/hkkzE0OcGZMytFOev2zsC.jpeg",
    modelUrl: "https://fal.run/fal-ai/japanese-sdxl",
    licenseUrl:
      "https://huggingface.co/stabilityai/japanese-stable-diffusion-xl/blob/main/LICENSE",
    machineType: "A100",
    researchOnly: true,
  },
  {
    id: "amt-interpolation",
    title: "AMT Interpolation",
    category: "video-to-video",
    tags: ["inference", "video"],
    shortDescription: "Interpolate between video frames",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/amt-interpolation.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/amt-interpolation-animated.webp",
    modelUrl: "https://fal.run/fal-ai/amt-interpolation",
    licenseUrl: "",
    machineType: "A6000",
    unlisted: false,
  },
  {
    id: "sd15-depth-controlnet",
    title: "SD 1.5 Depth ControlNet",
    category: "image-to-image",
    tags: ["inference", "depth", "controlnet"],
    shortDescription: "SD 1.5 ControlNet",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/tree.jpeg",
    modelUrl: "https://fal.run/fal-ai/sd15-depth-controlnet",
    licenseUrl: "",
    machineType: "A6000",
    unlisted: false,
  },
  {
    id: "controlnet-tile-upscaler",
    title: "ControlNet Tile Upscaler",
    category: "image-to-image",
    tags: ["inference", "controlnet", "upscaler"],
    shortDescription: "ControlNet Tile Upscaler",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/controlnet-tile-upscaler.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/controlnet-tile-upscaler-animated.webp",
    modelUrl: "https://fal.run/fal-ai/controlnet-tile-upscaler",
    licenseUrl: "",
    machineType: "A100",
    unlisted: false,
    resultComparison: true,
  },
  {
    id: "photomaker",
    title: "PhotoMaker",
    category: "image-to-image",
    tags: ["inference", "realistic"],
    shortDescription:
      "Customizing Realistic Human Photos via Stacked ID Embedding",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/photomaker.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/photomaker",
    modelUrl: "https://fal.run/fal-ai/photomaker",
    machineType: "A6000",
    highlighted: true,
    licenseUrl: "https://github.com/TencentARC/PhotoMaker/blob/main/LICENSE",
  },
  {
    id: "latent-consistency",
    title: "Latent Consistency (SDXL & SDv1.5)",
    category: "text-to-image",
    tags: ["inference", "real-time", "lcm"],
    shortDescription:
      "Produce high-quality images with minimal inference steps.",
    thumbnailUrl:
      "https://fal-cdn.batuhan-941.workers.dev/files/penguin/FS1_8TqEc1VEk8fFSes1C.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/image_models/lora.py",
    modelUrl: "https://fal.run/fal-ai/lcm",
    machineType: "A100",
  },
  {
    id: "latent-consistency-sd",
    title: "Optimized Latent Consistency (SDv1.5)",
    category: "image-to-image",
    tags: ["inference", "real-time", "lcm"],
    shortDescription:
      "Produce high-quality images with minimal inference steps. Optimized for 512x512 input image size.",
    thumbnailUrl:
      "https://fal-cdn.batuhan-941.workers.dev/files/kangaroo/4HI4amBAnKjvUi0SYLVwb.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/image_models/lora.py",
    modelUrl: "https://fal.run/fal-ai/lcm-sd15-i2i",
    machineType: "A100",
  },
  {
    id: "fooocus",
    title: "Fooocus",
    category: "text-to-image",
    tags: ["inference", "stylized"],
    shortDescription:
      "Default parameters with automated optimizations and quality improvements.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/fooocus/fal_ai_fooocus_cyberpunk-city.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/image_models/fooocus.py",
    modelUrl: "https://fal.run/fal-ai/fooocus",
    licenseUrl: "https://github.com/lllyasviel/Fooocus/blob/main/LICENSE",
    machineType: "A100",
    requiresAuth: true,
    group: { key: "fooocus", label: "Base" },
  },
  {
    id: "instantid",
    title: "InstantID",
    category: "image-to-image",
    tags: ["inference"],
    shortDescription: "Zero-shot Identity-Preserving Generation in Seconds",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/instantid.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/instantid",
    modelUrl: "https://fal.run/fal-ai/instantid/lcm",
    machineType: "A100",
    group: { key: "instantid", label: "LCM" },
    researchOnly: true,
  },

  {
    id: "fast-animatediff-t2v",
    title: "AnimateDiff",
    category: "text-to-video",
    tags: ["inference", "video", "stylized"],
    shortDescription: "Animate your ideas!",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-animatediff-t2v.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-animatediff-t2v-animated.webp",
    modelUrl: "https://fal.run/fal-ai/fast-animatediff/text-to-video",
    licenseUrl: "https://github.com/guoyww/AnimateDiff/blob/main/LICENSE.txt",
    machineType: "A100",
    requiresAuth: true,
    group: { key: "fast-animatediff", label: "Text to Video" },
  },
  {
    id: "fast-animatediff-t2v-turbo",
    title: "AnimateDiff Turbo",
    category: "text-to-video",
    tags: ["inference", "video", "stylized"],
    shortDescription: "Animate your ideas in lightning speed!",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-animatediff-t2v-turbo.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-animatediff-t2v-turbo-animated.webp",
    modelUrl: "https://fal.run/fal-ai/fast-animatediff/turbo/text-to-video",
    licenseUrl: "https://github.com/guoyww/AnimateDiff/blob/main/LICENSE.txt",
    machineType: "A100",
    requiresAuth: true,
    group: { key: "fast-animatediff-turbo", label: "Text to Video" },
  },

  {
    id: "illusion-diffusion",
    title: "Illusion Diffusion",
    category: "text-to-image",
    tags: ["inference", "stylized"],
    shortDescription: "Create illusions conditioned on image.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/illusion-diffusion.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/image_models/illusion_diffusion.py",
    modelUrl: "https://fal.run/fal-ai/illusion-diffusion",
    licenseUrl:
      "https://huggingface.co/spaces/CompVis/stable-diffusion-license",
    requiresAuth: true,
    machineType: "A10G",
  },
  {
    id: "sam",
    title: "Segment Anything Model",
    category: "image-to-image",
    tags: ["inference", "mask"],
    shortDescription: "SAM.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/sam.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/image_models/fast_sam.py",
    modelUrl: "https://fal.run/fal-ai/imageutils/sam",
    licenseUrl: "https://github.com/CASIA-IVA-Lab/FastSAM/blob/main/LICENSE",
    requiresAuth: true,
    machineType: "A6000",
  },
  {
    id: "tiny-sam",
    title: "TinySAM Distilled Segment Anything Model",
    category: "image-to-image",
    tags: ["inference", "mask"],
    shortDescription: "TinySAM.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/vis.png",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/tiny_sam",
    modelUrl: "https://fal.run/fal-ai/tiny-sam",
    licenseUrl: "https://github.com/xinghaochen/TinySAM/blob/main/LICENSE",
    requiresAuth: true,
    machineType: "A100",
  },
  {
    id: "depth-estimation",
    title: "Midas Depth Estimation",
    category: "image-to-image",
    tags: ["inference", "utility", "depth"],
    shortDescription: "Create depth maps using Midas depth estimation.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/depth-estimation.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/utilities/depth_estimation.py",
    modelUrl: "https://fal.run/fal-ai/imageutils/depth",
    licenseUrl:
      "https://huggingface.co/lllyasviel/Annotators/blob/main/dpt_hybrid-midas-501f0c75.pt",
    requiresAuth: true,
    machineType: "A6000",
  },
  {
    id: "remove-background",
    title: "Remove Background",
    category: "image-to-image",
    tags: ["background", "utility", "inference"],
    shortDescription: "Remove the background from an image.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/remove-background.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/remove-background-animated.webp",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/utilities/remove_background",
    modelUrl: "https://fal.run/fal-ai/imageutils/rembg",
    licenseUrl: "https://github.com/danielgatis/rembg/blob/main/LICENSE.txt",
    requiresAuth: true,
    machineType: "A6000",
  },
  {
    id: "esrgan",
    title: "Upscale Images",
    category: "image-to-image",
    tags: ["inference", "upscaler", "utility"],
    shortDescription: "Upscale images by a given factor.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/esrgan.webp?v=2",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/esrgan-animated.webp?v=2",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/upscale",
    modelUrl: "https://fal.run/fal-ai/esrgan",
    licenseUrl: "https://github.com/xinntao/Real-ESRGAN/blob/master/LICENSE",
    requiresAuth: true,
    machineType: "A6000",
    resultComparison: true,
  },
  {
    id: "fast-controlnet-sdxl",
    title: "ControlNet SDXL",
    category: "text-to-image",
    tags: ["inference", "controlnet"],
    shortDescription: "Generate Images with ControlNet.",
    thumbnailUrl:
      "https://fal-cdn.batuhan-941.workers.dev/files/rabbit/ynzNm1f0ZoDCuOvAE9tKR.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/controlnet",
    modelUrl: "https://fal.run/fal-ai/fast-sdxl-controlnet-canny",
    requiresAuth: true,
    group: { key: "controlnet-sdxl", label: "Text to Image" },
  },
  {
    id: "inpaint",
    title: "Inpainting sdxl and sd",
    category: "image-to-image",
    tags: ["inference", "inpainting"],
    shortDescription: "Inpaint images with SD and SDXL",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/inpaint.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/inpaint",
    modelUrl: "https://fal.run/fal-ai/inpaint",
    requiresAuth: true,
    machineType: "A100",
  },
  {
    id: "animatediff-sparsectrl-lcm",
    title: "Animatediff SparseCtrl LCM",
    category: "text-to-video",
    tags: ["inference", "lcm", "stylized"],
    shortDescription: "Animate Your Drawings with Latent Consistency Models!",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/ad-sparsectrl-lcm.png",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/video_models/animatediff_sparsectrl_lcm",
    modelUrl: "https://fal.run/fal-ai/animatediff-sparsectrl-lcm",
    licenseUrl: "https://github.com/guoyww/AnimateDiff/blob/main/LICENSE.txt",
    machineType: "A100",
  },

  {
    id: "face-swap",
    title: "Swap Face",
    category: "image-to-image",
    tags: ["inference", "utility"],
    shortDescription: "Swap a face between two images.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/face-swap.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/face_swap",
    modelUrl: "https://fal.run/fal-ai/face-swap",
    licenseUrl: "https://github.com/TencentARC/GFPGAN/blob/master/LICENSE",
    machineType: "A6000",
    researchOnly: true,
  },
  {
    id: "ip-adapter-face-id",
    title: "IP Adapter Face ID",
    category: "image-to-image",
    tags: ["inference", "personalization"],
    shortDescription: "High quality zero-shot personalization",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/ip-adapter-face-id.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/ip_adapter_face_id",
    modelUrl: "https://fal.run/fal-ai/ip-adapter-face-id",
    requiresAuth: true,
    machineType: "A10G",
    researchOnly: true,
  },
  {
    id: "marigold-depth-estimation",
    title: "Marigold Depth Estimation",
    category: "image-to-image",
    tags: ["inference", "depth", "utility"],
    shortDescription: "Create depth maps using Marigold depth estimation.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/marigold.png",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/utilities/depth_estimation.py",
    modelUrl: "https://fal.run/fal-ai/imageutils/marigold-depth",
    licenseUrl:
      "https://github.com/prs-eth/Marigold/blob/02cdfa5280d50afad976fdb6512de02011042c1c/LICENSE.txt",
    requiresAuth: true,
    machineType: "A6000",
  },

  {
    id: "diffusion-edge",
    title: "DiffusionEdge",
    category: "text-to-image",
    tags: ["inference"],
    shortDescription: "Diffusion based high quality edge detection",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/diffusion_edge_1.png",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/diffusion_edge/inference.py",
    modelUrl: "https://fal.run/fal-ai/diffusion-edge",
    licenseUrl: "https://github.com/GuHuangAI/DiffusionEdge",
    machineType: "A10G",
  },
  {
    id: "image-to-image",
    title: "Stable Diffusion XL Image to Image with LoRAs",
    category: "image-to-image",
    tags: ["inference", "stylized"],
    shortDescription: "Run Stable Diffusion XL with customizable LoRA weights.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/image-to-image.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/image_to_image",
    modelUrl: "https://fal.run/fal-ai/image-to-image",
    machineType: "A100",
  },
  {
    id: "triposr",
    title: "TripoSR",
    category: "image-to-3d",
    tags: ["inference", "stylized"],
    shortDescription: "State of the art Image to 3D Object generation",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/triposr.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/video/triposr",
    modelUrl: "https://fal.run/fal-ai/triposr",
    machineType: "A6000",
  },
  {
    id: "retoucher",
    title: "Face Retoucher",
    category: "image-to-image",
    tags: ["inference", "utility"],
    shortDescription:
      "Automatically retouches faces to smooth skin and remove blemishes.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/model_tests/retoucher/Screenshot%20from%202024-02-13%2011-40-09.png",
    modelUrl: "https://fal.run/fal-ai/retoucher",
    machineType: "A6000",
    requiresAuth: true,
    researchOnly: false,
    resultComparison: true,
  },
  {
    id: "supir",
    title: "SUPIR Upscaler",
    category: "image-to-image",
    tags: ["inference", "upscaler", "utility"],
    shortDescription: "A Powerful Image Upscaler",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/supir.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/supir-animated.webp",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/supir",
    modelUrl: "https://fal.run/fal-ai/supir",
    requiresAuth: true,
    machineType: "A100",
    resultComparison: true,
  },
  {
    id: "face-to-sticker",
    title: "Face to Sticker",
    category: "image-to-image",
    tags: ["inference", "utility"],
    shortDescription: "Create stickers from faces.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/face-to-sticker.png",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/face-to-sticker",
    modelUrl: "https://fal.run/fal-ai/face-to-sticker",
    machineType: "A100",
    researchOnly: true,
  },
  {
    id: "scribble",
    title: "ControlNet Scribble",
    category: "image-to-image",
    tags: ["inference", "utility"],
    shortDescription: "Generate images from scribbled conditioned images.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/scribble.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/scribble.py",
    modelUrl: "https://fal.run/fal-ai/scribble",
    machineType: "A100",
  },
  {
    id: "dreamshaper-sdxl-lightning",
    title: "Dreamshaper SDXL Lightning",
    category: "text-to-image",
    tags: ["inference"],
    shortDescription:
      "Dreamshaper SDXL Lightning is a Stable Diffusion model that has been fine-tuned on SDXL.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/dreamshaper-sdxl-lightning.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/face-to-sticker",
    modelUrl: "https://fal.run/fal-ai/dreamshaper-sdxl-lightning",
    machineType: "A100",
  },
  {
    id: "birefnet",
    title: "Birefnet Background Removal",
    category: "image-to-image",
    tags: ["background", "utility", "inference"],
    shortDescription:
      "bilateral reference framework (BiRefNet) for high-resolution dichotomous image segmentation (DIS)",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/birefnet.webp",
    thumbnailAnimatedUrl:
      "https://storage.googleapis.com/falserverless/gallery/birefnet-animated.webp",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/image/face-to-sticker",
    modelUrl: "https://fal.run/fal-ai/birefnet",
    machineType: "A6000",
  },
  {
    id: "sadtalker",
    title: "Sad Talker",
    category: "image-to-video",
    tags: ["inference"],
    shortDescription:
      "Learning Realistic 3D Motion Coefficients for Stylized Audio-Driven Single Image Talking Face Animation",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/sadtalker.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/registry/audio/sadtalker",
    modelUrl: "https://fal.run/fal-ai/sadtalker",
    licenseUrl: "https://github.com/OpenTalker/SadTalker/blob/main/LICENSE",
    machineType: "A100",
    group: { key: "sadtalker", label: "Standard" },
  },
  {
    id: "teed",
    title: "TEED Edge Detector",
    category: "image-to-image",
    tags: ["utils"],
    shortDescription: "A fast SOTA edge detector",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/model_tests/workflow_utils/teed_output.png",
    githubUrl: "https://github.com/fal-ai/workflow-utils",
    modelUrl: "https://fal.run/fal-ai/workflow-utils-cpu/teed",
    licenseUrl: "https://github.com/fal-ai/workflow-utils/blob/main/LICENSE",
    machineType: "A10G",
  },
  {
    id: "sd-loras-img-to-img",
    title: "Stable Diffusion with LoRAs",
    category: "image-to-image",
    tags: ["inference", "loras", "stylized"],
    shortDescription:
      "Run Any Stable Diffusion model with customizable LoRA weights.",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/sd-loras.jpeg",
    githubUrl:
      "https://github.com/fal-ai/serverless-registry/blob/main/image_models/lora.py",
    modelUrl: "https://fal.run/fal-ai/lora/image-to-image",
    licenseUrl:
      "https://huggingface.co/spaces/CompVis/stable-diffusion-license",
    machineType: "A100",
    group: { key: "sd-loras", label: "Image to Image" },
    unlisted: false,
  },
  {
    id: "stable-diffusion-xl-img-to-img",
    title: "Stable Diffusion XL",
    category: "image-to-image",
    tags: ["inference", "loras", "embeddings"],
    shortDescription: "Run SDXL at the speed of light",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-sdxl.jpeg",
    modelUrl: "https://fal.run/fal-ai/fast-sdxl/image-to-image",
    licenseUrl:
      "https://huggingface.co/stabilityai/stable-diffusion-xl-base-1.0/blob/main/LICENSE.md",
    machineType: "A100",
    group: { key: "stable-diffusion-xl", label: "Image to Image" },
    unlisted: false,
  },
  {
    id: "stable-diffusion-xl-inpainting",
    title: "Stable Diffusion XL",
    category: "image-to-image",
    tags: ["inference", "inpainting", "loras", "embeddings"],
    shortDescription: "Run SDXL at the speed of light",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/fast-sdxl.jpeg",
    modelUrl: "https://fal.run/fal-ai/fast-sdxl/inpainting",
    licenseUrl:
      "https://huggingface.co/stabilityai/stable-diffusion-xl-base-1.0/blob/main/LICENSE.md",
    machineType: "A100",
    group: { key: "stable-diffusion-xl", label: "Inpainting" },
    unlisted: false,
  },
  {
    id: "pixart-sigma",
    title: "PixArt-Σ",
    category: "text-to-image",
    tags: ["inference", "realistic"],
    shortDescription:
      "Weak-to-Strong Training of Diffusion Transformer for 4K Text-to-Image Generation",
    thumbnailUrl:
      "https://storage.googleapis.com/falserverless/gallery/pixart-sigma.jpeg",
    modelUrl: "https://fal.run/fal-ai/pixart-sigma",
    licenseUrl:
      "https://github.com/PixArt-alpha/PixArt-sigma/blob/master/LICENSE",
    machineType: "A6000",
  },
];
