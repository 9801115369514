import React, { useEffect, useState } from "react";
import {
    Box,
    Input,
    useColorModeValue,
    useDisclosure,
    Button,
    Select,
    useToast,
    Textarea,
    FormLabel,
    Tooltip,
    FormControl,
    Spinner,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Flex,
    FormErrorMessage,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "../../Tailwind.css";
import { useAuth } from "../../components/lib/useAuth";
import { useSnapshot } from "valtio";
import {
    editorState,
    setNewVideo,
} from "../../stores/EditorStore";
import { HeadContent } from "../../lib/components/Headcontent";
import { useWebSocket } from "../../lib/components/WebSocketContext";
import DashboardWrapper from "../../lib/components/Wrapper";

import { cloneDeep } from "lodash";
import { Resolution } from "../../lib/modals/DisplaySwicherModal";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { SendRequest } from "../../utils/main";
import { validateUrl } from "../../utils/misc";

function updateScreenSize(width: number, height: number, video: any, setNewVideo: Function) {
    const videoPayload = {
        meta: { global: {} },
        ...cloneDeep(video),
    };
    videoPayload.meta.global.width = width;
    videoPayload.meta.global.height = height;
    setNewVideo(videoPayload);
}



const AIHooks: React.FC = () => {
    // const wsRef = useRef<WebSocket | null>(null);
    const {
        video,
        newVideo,
        creating,
        showLoader,
    } = useSnapshot(editorState);
    const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
    const [customWidth, setCustomWidth] = useState<string>("");
    const [customHeight, setCustomHeight] = useState<string>("");
    const [brands, setBrands] = useState<any>([]);
    const [selectedBrand, setSelectedBrand] = useState<string>("");
    const [showCreateBrandModal, setShowCreateBrandModal] = useState<boolean>(false);
    const [newBrandName, setNewBrandName] = useState<string>("");
    const [newBrandUrl, setNewBrandUrl] = useState<string>("");
    const [newBrandDescription, setNewBrandDescription] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVertical, setIsVertical] = useState<boolean>(
        newVideo?.meta?.global.height > newVideo?.meta?.global.width
    );
    const [newBrandUrlError, setNewBrandUrlError] = useState<string>("");

    const defaultResolutions: Resolution[] = [
        {
            width: 640,
            height: 360,
            title: "SD",
            description: "360p resolution",
            tags: ["standard", "medium"],
        },
        {
            width: 854,
            height: 480,
            title: "SD",
            description: "480p resolution",
            tags: ["standard", "medium"],
        },
        {
            width: 1280,
            height: 720,
            title: "HD",
            description: "720p resolution",
            tags: ["high", "large"],
        },
        {
            width: 1920,
            height: 1080,
            title: "Full HD",
            description: "1080p resolution",
            tags: ["full", "large"],
        },
        {
            width: 2560,
            height: 1440,
            title: "QHD",
            description: "1440p resolution",
            tags: ["quad", "extra-large"],
        },
        {
            width: 3840,
            height: 2160,
            title: "4K",
            description: "2160p resolution",
            tags: ["ultra", "extra-large"],
        },
    ];

    const handleResolutionChange = (res: Resolution) => {
        // setResolution(res);
        console.log(`Resolution changed to: ${res.width}x${res.height}`);
        if (!isVertical) {
            updateScreenSize(res.width, res.height, newVideo, setNewVideo);
        } else {
            updateScreenSize(res.height, res.width, newVideo, setNewVideo);
        }
    };

    const handleAddResolution = () => {
        const width = parseInt(customWidth, 10);
        const height = parseInt(customHeight, 10);
        if (isVertical) {
            updateScreenSize(width, height, newVideo, setNewVideo);
        } else {
            updateScreenSize(height, width, newVideo, setNewVideo);
        }
    };

    const toggleOrientation = () => {
        // setIsHorizontal(!isHorizontal);
        setIsVertical(!isVertical)
        if (!isVertical) {
            updateScreenSize(newVideo.meta.global.width, newVideo.meta.global.height, newVideo, setNewVideo);
        } else {
            updateScreenSize(newVideo.meta.global.height, newVideo.meta.global.width, newVideo, setNewVideo);
        }
    };

    useEffect(() => {
        addEventListener('open', handleOpen);
        addEventListener('message', handleMessage);

        return () => {
            removeEventListener('open', handleOpen);
            removeEventListener('message', handleMessage);
        };
    }, [addEventListener, removeEventListener]);

    useEffect(() => {
        document.title = "Crackviral | Create New Video";
    }, []);


    const sidebar = useDisclosure();
    const navigate = useNavigate();
    const toast = useToast();
    const [auth, error, loading] = useAuth();
    const color = useColorModeValue("gray.600", "gray.300");

    // Listen for messages from the WebSocket server
    const handleMessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data);
        console.log("message", message, message.true);

        if (!message.status) {
            toast({
                title: `Error video:${message.message}`,
                position: "top-right",
                variant: "top-accent",
                isClosable: true,
            });
            if (message.action === "auth") {
                navigate("/login");
            }
            return;
        }

        switch (message.action) {
            case "brands":
                console.log({ message });

                setBrands(message.data);
                break;
            case "error":
                console.log("Error video:", message.message, event);
                toast({
                    title: `Error video:${message.message}`,
                    position: "top-right",
                    variant: "top-accent",
                    isClosable: true,
                });
                break;
            default:
                break;
        }
    };


    function loadBrands() {
        const payload = {
            action: "loadBrands",
        };
        send(payload);
    }

    function submitAuth() {
        const payload = {
            action: "auth",
            data: localStorage.getItem("auth"),
        };
        send(payload);
    }

    function generateScenes(meta: any) {
        const payload = {
            action: "storyToScenes",
            data: meta,
        };
        send(payload);
    }
    const createVideoWS = (obj: any) => {
        const payload = {
            action: "createVideo",
            video: {
                title: obj.title,
                url: obj.url,
                description: obj.description,
                meta: {
                    ai: { details: obj.details },
                    props: { height: obj.meta.props.height, width: obj.meta.props.width },
                },
            },
        };
        send(payload);
    };
    const handleOpen = () => {
        submitAuth();
        loadBrands();
    };

    const createBrandVideo = async () => {
        setIsSubmitting(true);
        try {
            const response = await SendRequest(
                `/api/v2/brand/video/create`,
                newVideo,
                "POST"
            );
            if (response.data) {

                toast({
                    title: "Video has been created",
                    description: "Your Video has been created.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                navigate(`/video/${response.data.video._id}`);
            } else {
                console.log({ response });

                toast({
                    title: "Brand Video Creation Error",
                    description: `Error saving Brand details:${response.error}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Brand Video Creation Error",
                description: `Network or server error:${error}`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    }

    const handleCreateBrand = async () => {
        if (!newBrandName) {
            toast({
                title: "Brand name is required",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (newBrandUrl && !validateUrl(newBrandUrl)) {
            setNewBrandUrlError("Please enter a valid URL");
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await SendRequest(
                "/api/v2/brand/new",
                {
                    brandName: newBrandName,
                    brandUrl: newBrandUrl,
                    brandDescription: newBrandDescription,
                },
                "POST"
            );
            if (response.data) {
                console.log(response.data);
                setBrands([...brands, response.data.brand])
                setSelectedBrand(response.data.brand._id)
                toast({
                    title: "Brand has been created",
                    description: "Your brand has been created successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setShowCreateBrandModal(false);
                // loadBrands();
            } else {
                toast({
                    title: "Brand Creation Error",
                    description: `Error saving Brand details: ${response.error}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Brand Creation Error",
                description: `Network or server error: ${error}`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <DashboardWrapper><HeadContent
            sidebar={sidebar}
        // searchQuery={searchQuery}
        // setSearchQuery={setSearchQuery}
        // handleSearch={handleSearch}
        />
            <Box as="main" p="4">

                <FormControl p={2}>
                    <FormLabel><b>Video Title</b> <Tooltip label="Don't have the footage for a trend? Crackviral will leave you placeholders with instructions of what to upload" aria-label='A tooltip'>
                        <QuestionOutlineIcon />
                    </Tooltip></FormLabel>
                    <Input
                        placeholder="My Crackviral Video"
                        onChange={(event) => {
                            setNewVideo({
                                ...cloneDeep(newVideo),
                                ...{ title: event.target.value },
                            });
                        }}
                        mt={2}
                    />
                </FormControl>
                <FormControl p={2}>
                    <FormLabel><b>AI Video Description</b></FormLabel>
                    <Textarea
                        placeholder="Launch video"
                        onChange={(event) => {
                            setNewVideo({
                                ...cloneDeep(newVideo),
                                ...{ description: event.target.value },
                            });
                        }}
                        height={"300px"}
                        mt={2}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel><b>Select Brand (Optional)</b></FormLabel>
                    <Flex>
                        <Select
                            value={selectedBrand}
                            placeholder="Select Brand"
                            onClick={() => {
                                if (brands.length === 0) {
                                    loadBrands();
                                }
                            }}
                            onChange={(event) => {
                                const videoPayload = {
                                    ...cloneDeep(newVideo),
                                    brand: event.target.value,
                                };
                                setSelectedBrand(event.target.value)
                                setNewVideo(videoPayload);
                            }}
                            mt={2}
                        >
                            {brands.map((brand: any) => {
                                return <option value={brand._id}>{brand.name}</option>
                            })}
                        </Select>
                        <Button colorScheme="blue" onClick={() => setShowCreateBrandModal(true)} ml={4} mt={2}>
                            Create a New Brand
                        </Button>
                    </Flex>
                </FormControl>

                <Button
                    colorScheme="blue"
                    mr={3}
                    mt={2}
                    onClick={(e) => {
                        createBrandVideo();
                    }}
                    isDisabled={isSubmitting}
                    leftIcon={isSubmitting ? <Spinner size="sm" /> : undefined}
                >
                    {isSubmitting ? 'Submitting...' : 'Create Video'}
                </Button>
            </Box>

            <Modal isOpen={showCreateBrandModal} onClose={() => setShowCreateBrandModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Brand</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>Brand Name</FormLabel>
                            <Input
                                value={newBrandName}
                                onChange={(e) => setNewBrandName(e.target.value)}
                                placeholder="Enter brand name"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Brand URL (Optional)</FormLabel>
                            <Input
                                value={newBrandUrl}
                                onChange={(e) => {
                                    setNewBrandUrl(e.target.value);
                                    setNewBrandUrlError("");
                                }}
                                onBlur={() => {
                                    if (!validateUrl(newBrandUrl)) {
                                        setNewBrandUrlError("Please enter a valid URL");
                                    }
                                }}
                                placeholder="Enter brand URL"
                            />
                            {newBrandUrlError && <div>{newBrandUrlError}</div>}
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Brand Description</FormLabel>
                            <Textarea
                                value={newBrandDescription}
                                onChange={(e) => setNewBrandDescription(e.target.value)}
                                placeholder="Enter brand description"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleCreateBrand} isLoading={isSubmitting}>
                            Create Brand
                        </Button>
                        <Button variant="ghost" onClick={() => setShowCreateBrandModal(false)}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal></DashboardWrapper>
    );
};

export default AIHooks;