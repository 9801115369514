import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Input,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { importAsset } from "../utils/EditorUtil";
import {
  editorState,
  setCurrentImporting,
  setImportProps,
} from "../../stores/EditorStore";
import { SendFormData } from "../../utils/main";
import { handleShowAlert } from "../../pages/Signup";
import { useSnapshot } from "valtio";

export const ImportModal: FC<{
  importIsOpen: boolean;
  importonClose: () => void;
  send: Function;
  videoId: string;
}> = ({ importIsOpen, importonClose, send, videoId }) => {
  const { importing, selectedScreen, importProps } = useSnapshot(editorState);
  const [file, setFile] = useState<any>(null);
  const [fileType, setFileType] = useState<string>("");

  const handleFileChange = (event: any, type: string) => {
    setFile(event.target.files[0]); // Set the file to state
    setFileType(type);
  };

  const uploadFile = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", fileType);
    formData.append("videoId", videoId);
    formData.append("screen", selectedScreen);

    const headers = {
      "x-file-name": file.name, // Sending file name in headers
      "x-file-type": file.type, // Sending file type in headers
    };

    try {
      const response = await SendFormData(
        "/api/v2/add/file",
        formData,
        "POST",
        headers
      );

      if (response.data) {
        handleShowAlert(
          "success",
          "Success",
          "File uploaded successfully. Adding the file now."
        );
        setFile(null);
        importonClose();
        window.location.reload();
      } else {
        handleShowAlert(
          "error",
          "Error",
          `Error uploading file:${response.error}`
        );
      }
    } catch (error) {
      handleShowAlert("error", "Error", `Error uploading file ${error}`);
    }
  };
  return (
    <Modal isOpen={importIsOpen} onClose={importonClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Media</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs
            position="relative"
            variant="unstyled"
            defaultIndex={importing}
            onChange={(index) => setCurrentImporting(index)}
          >
            <TabList>
              <Tab>Image</Tab>
              <Tab>Audio</Tab>
              <Tab>Video</Tab>
              <Tab>Text</Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
            />
            <TabPanels>
              <TabPanel>
                <Input
                  placeholder="Add Image Url"
                  onChange={(event) => {
                    setImportProps({
                      ...importProps,
                      ...{ importURL: event.target.value },
                    });
                  }}
                />
                <br />
                <br />
                or <br />
                <br />
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, "Img")}
                />
              </TabPanel>
              <TabPanel>
                <Input
                  placeholder="Add Audio Url"
                  onChange={(event) => {
                    setImportProps({
                      ...importProps,
                      ...{ importURL: event.target.value },
                    });
                  }}
                />
                <br />
                <br />
                or <br />
                <br />
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, "Audio")}
                />
              </TabPanel>
              <TabPanel>
                <Input
                  placeholder="Add Video Url"
                  onChange={(event) => {
                    setImportProps({
                      ...importProps,
                      ...{ importURL: event.target.value },
                    });
                  }}
                />
                <br />
                <br />
                or <br />
                <br />
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, "Video")}
                />
              </TabPanel>
              <TabPanel>
                <Input
                  placeholder="Add Text"
                  onChange={(event) => {
                    setImportProps({
                      ...importProps,
                      ...{ text: event.target.value },
                    });
                  }}
                  mt={2}
                />
                <Input
                  placeholder="Select Color"
                  type="color"
                  onChange={(event) => {
                    setImportProps({
                      ...importProps,
                      ...{ color: event.target.value },
                    });
                  }}
                  mt={2}
                />
                <Input
                  placeholder="Add font Size"
                  type="number"
                  defaultValue={importProps["fontSize"] ||15}
                  onChange={(event) => {
                    setImportProps({
                      ...importProps,
                      ...{ fontSize: event.target.value },
                    });
                  }}
                  mt={2}
                />
                <Input
                  placeholder="Visible from second"
                  type="number"
                  defaultValue={importProps["from"] || 0}
                  onChange={(event) => {
                    setImportProps({
                      ...importProps,
                      ...{ from: event.target.value },
                    });
                  }}
                  mt={2}
                />
                <Input
                  placeholder="Duration in seconds"
                  type="number"
                  defaultValue={importProps["durationInFrames"] || 10}
                  onChange={(event) => {
                    setImportProps({
                      ...importProps,
                      ...{ durationInFrames: event.target.value },
                    });
                  }}
                  mt={2}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={(e) => {
              if (file) {
                // upload the file
                uploadFile();
              } else {
                importAsset(
                  importing,
                  importProps,
                  send,
                  videoId,
                  selectedScreen,
                  importonClose
                );
              }
            }}
          >
            {file ? "Upload" : "Import"}
          </Button>
          <Button variant="ghost" onClick={importonClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
