const modes = {
  text: { path: "/", input: "TextToImageInput" },
  image: { path: "/image-to-image", input: "ImageToImageInput" },
  inpainting: { path: "/inpainting", input: "InpaintingInput" },
};

const title = "Stable Diffusion XL";
const specs = {
  paths: {
    "/image-to-image": {
      post: {
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/Output" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/HTTPValidationError",
                },
              },
            },
          },
        },
        requestBody: {
          content: {
            "application/json": {
              schema: { $ref: "#/components/schemas/ImageToImageInput" },
            },
          },
          required: true,
        },
        summary: "Image To Image",
        operationId: "image_to_image_image_to_image_post",
      },
    },
    "/inpainting": {
      post: {
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/Output" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/HTTPValidationError",
                },
              },
            },
          },
        },
        requestBody: {
          content: {
            "application/json": {
              schema: { $ref: "#/components/schemas/InpaintingInput" },
            },
          },
          required: true,
        },
        summary: "Inpainting",
        operationId: "inpainting_inpainting_post",
      },
    },
    "/": {
      post: {
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/Output" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/HTTPValidationError",
                },
              },
            },
          },
        },
        requestBody: {
          content: {
            "application/json": {
              schema: { $ref: "#/components/schemas/TextToImageInput" },
            },
          },
          required: true,
        },
        summary: "Text To Image",
        operationId: "text_to_image__post",
      },
    },
  },
  info: { version: "0.1.0", title: "FastAPI" },
  components: {
    schemas: {
      Output: {
        title: "Output",
        type: "object",
        properties: {
          prompt: {
            title: "Prompt",
            type: "string",
            description: "The prompt used for generating the image.",
          },
          images: {
            title: "Images",
            type: "array",
            description: "The generated image files info.",
            items: { $ref: "#/components/schemas/Image" },
          },
          timings: {
            additionalProperties: { type: "number" },
            type: "object",
            title: "Timings",
          },
          has_nsfw_concepts: {
            title: "Has Nsfw Concepts",
            type: "array",
            description: "Whether the generated images contain NSFW concepts.",
            items: { type: "boolean" },
          },
          seed: {
            title: "Seed",
            type: "integer",
            description:
              "\n            Seed of the generated Image. It will be the same value of the one passed in the\n            input or the randomly generated that was used in case none was passed.\n        ",
          },
        },
        "x-fal-order-properties": [
          "images",
          "timings",
          "seed",
          "has_nsfw_concepts",
          "prompt",
        ],
        required: ["images", "timings", "seed", "has_nsfw_concepts", "prompt"],
      },
      TextToImageInput: {
        title: "TextToImageInput",
        type: "object",
        properties: {
          prompt: {
            examples: [
              "photo of a rhino dressed suit and tie sitting at a table in a bar with a bar stools, award winning photography, Elke vogelsang",
              "Photo of a classic red mustang car parked in las vegas strip at night",
            ],
            title: "Prompt",
            type: "string",
            description:
              "The prompt to use for generating the image. Be as descriptive as possible for best results.",
          },
          num_images: {
            minimum: 1,
            maximum: 8,
            type: "integer",
            title: "Num Images",
            description: "The number of images to generate.",
            default: 1,
          },
          image_size: {
            anyOf: [
              { $ref: "#/components/schemas/ImageSize" },
              {
                enum: [
                  "square_hd",
                  "square",
                  "portrait_4_3",
                  "portrait_16_9",
                  "landscape_4_3",
                  "landscape_16_9",
                ],
                type: "string",
              },
            ],
            title: "Image Size",
            description: "The size of the generated image.",
            default: "square_hd",
          },
          format: {
            enum: ["jpeg", "png"],
            title: "Format",
            type: "string",
            description: "The format of the generated image.",
            default: "jpeg",
          },
          embeddings: {
            title: "Embeddings",
            type: "array",
            description: "The list of embeddings to use.",
            items: { $ref: "#/components/schemas/Embedding" },
            default: [],
          },
          expand_prompt: {
            title: "Expand Prompt",
            type: "boolean",
            description:
              "If set to true, the prompt will be expanded with additional prompts.",
            default: false,
          },
          sync_mode: {
            title: "Sync Mode",
            type: "boolean",
            description:
              "\n            If set to true, the function will wait for the image to be generated and uploaded\n            before returning the response. This will increase the latency of the function but\n            it allows you to get the image directly in the response without going through the CDN.\n        ",
            default: false,
          },
          loras: {
            title: "Loras",
            type: "array",
            description: "The list of LoRA weights to use.",
            items: { $ref: "#/components/schemas/LoraWeight" },
            default: [],
          },
          guidance_scale: {
            minimum: 0,
            maximum: 20,
            type: "number",
            title: "Guidance scale (CFG)",
            description:
              "\n            The CFG (Classifier Free Guidance) scale is a measure of how close you want\n            the model to stick to your prompt when looking for a related image to show you.\n        ",
            default: 7.5,
          },
          num_inference_steps: {
            minimum: 1,
            maximum: 50,
            type: "integer",
            title: "Num Inference Steps",
            description: "The number of inference steps to perform.",
            default: 25,
          },
          seed: {
            title: "Seed",
            type: "integer",
            description:
              "\n            The same seed and the same prompt given to the same version of Stable Diffusion\n            will output the same image every time.\n        ",
          },
          negative_prompt: {
            examples: [
              "cartoon, illustration, animation. face. male, female",
              "ugly, deformed",
            ],
            title: "Negative Prompt",
            type: "string",
            description:
              "\n            The negative prompt to use. Use it to address details that you don't want\n            in the image. This could be colors, objects, scenery and even the small details\n            (e.g. moustache, blurry, low resolution).\n        ",
            default: "",
          },
          enable_safety_checker: {
            title: "Enable Safety Checker",
            type: "boolean",
            description: "If set to true, the safety checker will be enabled.",
            default: false,
          },
        },
        "x-fal-order-properties": [
          "prompt",
          "negative_prompt",
          "image_size",
          "num_inference_steps",
          "seed",
          "guidance_scale",
          "sync_mode",
          "num_images",
          "loras",
          "embeddings",
          "enable_safety_checker",
          "expand_prompt",
          "format",
        ],
        required: ["prompt"],
      },
      ImageSize: {
        title: "ImageSize",
        type: "object",
        properties: {
          height: {
            maximum: 14142,
            type: "integer",
            title: "Height",
            description: "The height of the generated image.",
            exclusiveMinimum: 0,
            default: 512,
          },
          width: {
            maximum: 14142,
            type: "integer",
            title: "Width",
            description: "The width of the generated image.",
            exclusiveMinimum: 0,
            default: 512,
          },
        },
        "x-fal-order-properties": ["width", "height"],
      },
      ImageToImageInput: {
        title: "ImageToImageInput",
        type: "object",
        properties: {
          prompt: {
            examples: [
              "an island near sea, with seagulls, moon shining over the sea, light house, boats int he background, fish flying over the sea",
            ],
            title: "Prompt",
            type: "string",
            description:
              "The prompt to use for generating the image. Be as descriptive as possible for best results.",
          },
          image_size: {
            anyOf: [
              { $ref: "#/components/schemas/ImageSize" },
              {
                enum: [
                  "square_hd",
                  "square",
                  "portrait_4_3",
                  "portrait_16_9",
                  "landscape_4_3",
                  "landscape_16_9",
                ],
                type: "string",
              },
            ],
            title: "Image Size",
            description: "The size of the generated image.",
            default: "square_hd",
          },
          embeddings: {
            title: "Embeddings",
            type: "array",
            description: "The list of embeddings to use.",
            items: { $ref: "#/components/schemas/Embedding" },
            default: [],
          },
          expand_prompt: {
            title: "Expand Prompt",
            type: "boolean",
            description:
              "If set to true, the prompt will be expanded with additional prompts.",
            default: false,
          },
          loras: {
            title: "Loras",
            type: "array",
            description: "The list of LoRA weights to use.",
            items: { $ref: "#/components/schemas/LoraWeight" },
            default: [],
          },
          guidance_scale: {
            minimum: 0,
            maximum: 20,
            type: "number",
            title: "Guidance scale (CFG)",
            description:
              "\n            The CFG (Classifier Free Guidance) scale is a measure of how close you want\n            the model to stick to your prompt when looking for a related image to show you.\n        ",
            default: 7.5,
          },
          enable_safety_checker: {
            title: "Enable Safety Checker",
            type: "boolean",
            description: "If set to true, the safety checker will be enabled.",
            default: false,
          },
          negative_prompt: {
            examples: ["cartoon, illustration, animation. face. male, female"],
            title: "Negative Prompt",
            type: "string",
            description:
              "\n            The negative prompt to use.Use it to address details that you don't want\n            in the image. This could be colors, objects, scenery and even the small details\n            (e.g. moustache, blurry, low resolution).\n        ",
            default: "",
          },
          format: {
            enum: ["jpeg", "png"],
            title: "Format",
            type: "string",
            description: "The format of the generated image.",
            default: "jpeg",
          },
          num_images: {
            minimum: 1,
            maximum: 8,
            type: "integer",
            title: "Num Images",
            description: "The number of images to generate.",
            default: 1,
          },
          image_url: {
            examples: [
              "https://fal-cdn.batuhan-941.workers.dev/files/tiger/IExuP-WICqaIesLZAZPur.jpeg",
            ],
            title: "Image Url",
            type: "string",
            description:
              "The URL of the image to use as a starting point for the generation.",
          },
          strength: {
            minimum: 0.05,
            maximum: 1,
            type: "number",
            title: "Strength",
            description:
              "determines how much the generated image resembles the initial image",
            default: 0.95,
          },
          sync_mode: {
            title: "Sync Mode",
            type: "boolean",
            description:
              "\n            If set to true, the function will wait for the image to be generated and uploaded\n            before returning the response. This will increase the latency of the function but\n            it allows you to get the image directly in the response without going through the CDN.\n        ",
            default: false,
          },
          num_inference_steps: {
            minimum: 1,
            maximum: 65,
            type: "integer",
            title: "Num Inference Steps",
            description: "The number of inference steps to perform.",
            default: 25,
          },
          seed: {
            title: "Seed",
            type: "integer",
            description:
              "\n            The same seed and the same prompt given to the same version of Stable Diffusion\n            will output the same image every time.\n        ",
          },
        },
        "x-fal-order-properties": [
          "image_url",
          "prompt",
          "negative_prompt",
          "image_size",
          "num_inference_steps",
          "guidance_scale",
          "strength",
          "seed",
          "sync_mode",
          "num_images",
          "loras",
          "embeddings",
          "enable_safety_checker",
          "expand_prompt",
          "format",
        ],
        required: ["image_url", "prompt"],
      },
      LoraWeight: {
        title: "LoraWeight",
        type: "object",
        properties: {
          path: {
            examples: [
              "https://civitai.com/api/download/models/135931",
              "https://filebin.net/3chfqasxpqu21y8n/my-custom-lora-v1.safetensors",
            ],
            title: "Path",
            type: "string",
            description:
              "URL or the path to the LoRA weights. Or HF model name.",
          },
          scale: {
            minimum: 0,
            maximum: 1,
            type: "number",
            title: "Scale",
            description:
              "\n            The scale of the LoRA weight. This is used to scale the LoRA weight\n            before merging it with the base model.\n        ",
            default: 1,
          },
        },
        "x-fal-order-properties": ["path", "scale"],
        required: ["path"],
      },
      ValidationError: {
        title: "ValidationError",
        type: "object",
        properties: {
          type: { title: "Error Type", type: "string" },
          msg: { title: "Message", type: "string" },
          loc: {
            title: "Location",
            type: "array",
            items: { anyOf: [{ type: "string" }, { type: "integer" }] },
          },
        },
        "x-fal-order-properties": ["loc", "msg", "type"],
        required: ["loc", "msg", "type"],
      },
      Embedding: {
        title: "Embedding",
        type: "object",
        properties: {
          tokens: {
            title: "Tokens",
            type: "array",
            description: "The list of tokens to use for the embedding.",
            items: { type: "string" },
            default: ["<s0>", "<s1>"],
          },
          path: {
            examples: [
              "https://civitai.com/api/download/models/135931",
              "https://filebin.net/3chfqasxpqu21y8n/my-custom-lora-v1.safetensors",
            ],
            title: "Path",
            type: "string",
            description: "URL or the path to the embedding weights.",
          },
        },
        "x-fal-order-properties": ["path", "tokens"],
        required: ["path"],
      },
      Image: {
        title: "Image",
        type: "object",
        properties: {
          height: { title: "Height", type: "integer" },
          content_type: {
            title: "Content Type",
            type: "string",
            default: "image/jpeg",
          },
          url: { title: "Url", type: "string" },
          width: { title: "Width", type: "integer" },
        },
        "x-fal-order-properties": ["url", "width", "height", "content_type"],
        required: ["url", "width", "height"],
      },
      InpaintingInput: {
        title: "InpaintingInput",
        type: "object",
        properties: {
          prompt: {
            examples: ["a tiger sitting on a park bench"],
            title: "Prompt",
            type: "string",
            description:
              "The prompt to use for generating the image. Be as descriptive as possible for best results.",
          },
          image_size: {
            anyOf: [
              { $ref: "#/components/schemas/ImageSize" },
              {
                enum: [
                  "square_hd",
                  "square",
                  "portrait_4_3",
                  "portrait_16_9",
                  "landscape_4_3",
                  "landscape_16_9",
                ],
                type: "string",
              },
            ],
            title: "Image Size",
            description: "The size of the generated image.",
            default: "square_hd",
          },
          embeddings: {
            title: "Embeddings",
            type: "array",
            description: "The list of embeddings to use.",
            items: { $ref: "#/components/schemas/Embedding" },
            default: [],
          },
          expand_prompt: {
            title: "Expand Prompt",
            type: "boolean",
            description:
              "If set to true, the prompt will be expanded with additional prompts.",
            default: false,
          },
          loras: {
            title: "Loras",
            type: "array",
            description: "The list of LoRA weights to use.",
            items: { $ref: "#/components/schemas/LoraWeight" },
            default: [],
          },
          guidance_scale: {
            minimum: 0,
            maximum: 20,
            type: "number",
            title: "Guidance scale (CFG)",
            description:
              "\n            The CFG (Classifier Free Guidance) scale is a measure of how close you want\n            the model to stick to your prompt when looking for a related image to show you.\n        ",
            default: 7.5,
          },
          enable_safety_checker: {
            title: "Enable Safety Checker",
            type: "boolean",
            description: "If set to true, the safety checker will be enabled.",
            default: false,
          },
          negative_prompt: {
            examples: ["cartoon, illustration, animation. face. male, female"],
            title: "Negative Prompt",
            type: "string",
            description:
              "\n            The negative prompt to use.Use it to address details that you don't want\n            in the image. This could be colors, objects, scenery and even the small details\n            (e.g. moustache, blurry, low resolution).\n        ",
            default: "",
          },
          format: {
            enum: ["jpeg", "png"],
            title: "Format",
            type: "string",
            description: "The format of the generated image.",
            default: "jpeg",
          },
          num_images: {
            minimum: 1,
            maximum: 8,
            type: "integer",
            title: "Num Images",
            description: "The number of images to generate.",
            default: 1,
          },
          image_url: {
            examples: [
              "https://raw.githubusercontent.com/CompVis/latent-diffusion/main/data/inpainting_examples/overture-creations-5sI6fQgYIuo.png",
            ],
            title: "Image Url",
            type: "string",
            description:
              "The URL of the image to use as a starting point for the generation.",
          },
          strength: {
            minimum: 0.01,
            maximum: 1,
            type: "number",
            title: "Strength",
            description:
              "determines how much the generated image resembles the initial image",
            default: 0.95,
          },
          sync_mode: {
            title: "Sync Mode",
            type: "boolean",
            description:
              "\n            If set to true, the function will wait for the image to be generated and uploaded\n            before returning the response. This will increase the latency of the function but\n            it allows you to get the image directly in the response without going through the CDN.\n        ",
            default: false,
          },
          num_inference_steps: {
            minimum: 1,
            maximum: 65,
            type: "integer",
            title: "Num Inference Steps",
            description: "The number of inference steps to perform.",
            default: 25,
          },
          mask_url: {
            examples: [
              "https://raw.githubusercontent.com/CompVis/latent-diffusion/main/data/inpainting_examples/overture-creations-5sI6fQgYIuo_mask.png",
            ],
            title: "Mask Url",
            type: "string",
            description: "The URL of the mask to use for inpainting.",
          },
          seed: {
            title: "Seed",
            type: "integer",
            description:
              "\n            The same seed and the same prompt given to the same version of Stable Diffusion\n            will output the same image every time.\n        ",
          },
        },
        "x-fal-order-properties": [
          "image_url",
          "mask_url",
          "prompt",
          "negative_prompt",
          "image_size",
          "num_inference_steps",
          "guidance_scale",
          "strength",
          "seed",
          "sync_mode",
          "num_images",
          "loras",
          "embeddings",
          "enable_safety_checker",
          "expand_prompt",
          "format",
        ],
        required: ["image_url", "mask_url", "prompt"],
      },
      HTTPValidationError: {
        title: "HTTPValidationError",
        type: "object",
        properties: {
          detail: {
            title: "Detail",
            type: "array",
            items: { $ref: "#/components/schemas/ValidationError" },
          },
        },
        "x-fal-order-properties": ["detail"],
      },
    },
  },
  openapi: "3.1.0",
  "x-fal-order-paths": ["/image-to-image", "/inpainting", "/"],
};

export default { specs, title, modes };
