import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import {
    Box,
    Heading,
    Text,
    Button,
    VStack,
    HStack,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    RadioGroup,
    Radio,
    Input,
} from '@chakra-ui/react';
import { SendRequest } from '../../utils/main';
import { stripeKey } from './Payments';
import { useNavigate } from 'react-router-dom';
import { HeadContent } from '../../lib/components/Headcontent';
import DashboardWrapper from '../../lib/components/Wrapper';
import { setSearchQuery } from '../../stores/StoryStore';

const stripePromise = loadStripe(stripeKey);

interface CreditBalance {
    balance: number;
    lastUpdated: string;
}

const AMOUNT_OPTIONS = [5, 10, 15, 20, 50, 100];

const TopUpForm: React.FC<{ amount: number; onSuccess: () => void; onClose: () => void }> = ({ amount, onSuccess, onClose }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        try {
            const { data } = await SendRequest('/api/v2/payment/topup/intent', { amount });
            const { clientSecret } = data;

            const cardElement = elements.getElement(CardElement);

            if (!cardElement) {
                throw new Error('Card element not found');
            }

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                },
            });

            if (result.error) {
                toast({
                    title: 'Payment failed',
                    description: result.error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Payment successful',
                    description: 'Your credits have been topped up.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onSuccess();
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while processing your payment.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{width:"100%"}}>
                <Box w="100%" p={4} borderWidth="1px" borderRadius="lg">
                    <CardElement options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }} />
                </Box>
                <Button
                    type="submit"
                    colorScheme="blue"
                    isLoading={isLoading}
                    loadingText="Processing"
                    width="100%"
                >
                    Pay ${amount}
                </Button>
        </form>
    );
};

const CreditTopUpPage: React.FC = () => {
    const [creditBalance, setCreditBalance] = useState<CreditBalance | null>(null);
    const [topUpAmount, setTopUpAmount] = useState<number>(AMOUNT_OPTIONS[0]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const sidebar = useDisclosure();
    useEffect(() => {
        fetchCreditBalance();
    }, []);

    const fetchCreditBalance = async () => {
        try {
            const response = await SendRequest('/api/v2/user/credit', null, "GET");
            setCreditBalance(response.data.docs[0]);
        } catch (error) {
            console.error('Error fetching credit balance:', error);
            toast({
                title: 'Error',
                description: 'Failed to fetch credit balance. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleTopUpSuccess = () => {
        // fetchCreditBalance();
        navigate('/profile')
        onClose();
    };

    return (
        <DashboardWrapper>
    <HeadContent
      sidebar={sidebar}
      hideSearch={true}
    />
        <Box p={4}>
            <VStack spacing={4} align="stretch">
                <Heading>Credit Balance</Heading>
                {creditBalance ? (
                    <Text fontSize="xl">
                        Current Balance: {creditBalance.balance} credits
                        <Text fontSize="sm" color="gray.500">
                            Last updated: {new Date(creditBalance.lastUpdated).toLocaleString()}
                        </Text>
                    </Text>
                ) : (
                    <Text>Loading credit balance...</Text>
                )}
                <Button colorScheme="blue" onClick={onOpen}>
                    Top Up Credits
                </Button>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Top Up Credits</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={6}>
                            <Text>Select the amount you want to add to topup:</Text>
                            <RadioGroup onChange={(value) => setTopUpAmount(parseInt(value))} value={topUpAmount.toString()}>
                                <HStack spacing={4} wrap="wrap" justify="center">
                                    {AMOUNT_OPTIONS.map((amount) => (
                                        <Radio key={amount} value={amount.toString()}>
                                            ${amount}
                                        </Radio>
                                    ))}
                                </HStack>
                            </RadioGroup>
                            <Text>or Enter the amount of credits you want to add:</Text>
                            <Input
                                type="number"
                                value={topUpAmount}
                                onChange={(e) => setTopUpAmount(parseInt(e.target.value))}
                                placeholder="Enter amount"
                            />
                            <Text>Credits you will receive: {topUpAmount * 10}</Text>
                            <Elements stripe={stripePromise}>
                                <TopUpForm
                                    amount={topUpAmount}
                                    onSuccess={handleTopUpSuccess}
                                    onClose={onClose}
                                />
                            </Elements>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
        </DashboardWrapper>
    );
};

export default CreditTopUpPage;