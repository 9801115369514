import { backendurl } from "../lib/utils/Misc";
import axios, { AxiosRequestConfig, AxiosProgressEvent } from 'axios';

interface SendFormDataOptions {
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export async function importCompositionWithRetry(videoId: string, version: string, selectedScreen: string, setComposition: Function, maxRetries = 5) {
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const componentModule = await import(
        `../components/${videoId}/${version || 0}/${selectedScreen}/Composition`
      );
      setComposition(() => componentModule.default);
      return; // Success, exit the function
    } catch (error) {
      console.error(`Import failed (attempt ${retries + 1}):`, error);
      retries++;

      if (retries < maxRetries) {
        console.log(`Retrying in 1 second...`);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 1 second
      } else {
        window.location.reload();
        console.error('Max retries reached. Import failed.');
        throw error; // Re-throw the error after max retries
      }
    }
  }
}

export const SendAxiosFormData = async (
  url: string,
  body: any,
  method = "POST",
  headers = {},
  options: SendFormDataOptions = {}
) => {
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");

  const config: AxiosRequestConfig = {
    method,
    url: `${backendurl}${url}`,
    headers: {
      Authorization: `JWT ${auth.token}`,
      ...headers
    },
    data: body,
    onUploadProgress: options.onUploadProgress
  };

  try {
    const response = await axios(config);
    return { data: response.data };
  } catch (error: any) {
    console.error("Error during fetch:", error.message);
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return { error: errorMessage };
    }
    return { error: 'An unexpected error occurred' };
  }
};

export const SendRequestRaw = async (url: string, body: any, method = "POST") => {
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  const payload: any = {
    method,
    headers: {
      Authorization: `JWT ${auth.token}`,
      "Content-Type": "application/json",
    },
  }
  if (body) {
    payload.body = JSON.stringify(body)
  }
  const response = await fetch(`${backendurl}${url}`, payload);
  return response;

};


export const SendRequest = async (url: string, body: any, method = "POST") => {
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  const payload: any = {
    method,
    headers: {
      Authorization: `JWT ${auth.token}`,
      "Content-Type": "application/json",
    },
  }
  if (body) {
    payload.body = JSON.stringify(body)
  }
  try {
    const response = await fetch(`${backendurl}${url}`, payload);

    if (!response.ok) {
      const errorMsg = await response.json();

      const errorMessage = errorMsg.message || errorMsg.errors[0].message || errorMsg.errors[0].msg || `Error: ${response.status}`;
      return { error: errorMessage }
    }

    const data = await response.json();
    return { data };
  } catch (error: any) {
    console.error("Error during fetch:", error.message);
    const errorMessage = error.message || error.errors[0].message;
    return { error: errorMessage };
  }
};

export const SendFormData = async (url: string, body: any, method = "POST", headers = {}) => {
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  try {
    const response = await fetch(`${backendurl}${url}`, {
      method,
      headers: {
        Authorization: `JWT ${auth.token}`,
        ...headers
      },
      body,
    });

    const data = await response.json();

    if (!response.ok) {
      return { error: `HTTP error!: ${data.error || response.status}` }
    }

    return { data };
  } catch (error: any) {
    console.error("Error during fetch:", error.message);
    const errorMessage = error.message || error.errors[0].message;
    return { error: errorMessage };
  }
};
