import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  const isAuthenticated = !!auth.user; // Replace with your auth check
  const location = useLocation();
  console.log({auth, isAuthenticated});
  
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;