import React, { useState, useMemo, useEffect, Suspense } from 'react';
import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    Cell,
    TableNode,
    HeaderCell,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { ChevronLeft, Save, Import, Download, Pencil, EyeOff, Eye } from 'lucide-react';
import AssetLibrary from '../../lib/modals/AssetLibrary';
import { editorState, updateCell } from '../../stores/EditorStore';
import { useSnapshot } from 'valtio';
import { GiClockwiseRotation } from 'react-icons/gi';
import { CloseIcon } from '@chakra-ui/icons';
import { useWebSocket } from '../../lib/components/WebSocketContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Player } from '@remotion/player';
import { importCompositionWithRetry, SendFormData, SendRequest, SendRequestRaw } from '../../utils/main';
import {
    HeaderCellSelect,
    CellSelect,
    SelectClickTypes,
    SelectTypes,
    useRowSelect,
} from "@table-library/react-table-library/select";
import { handleShowAlert } from '../../pages/Signup';
import { MdApi } from 'react-icons/md';
import { FaFileExport } from 'react-icons/fa';

interface Component {
    id: string;
    props: {
        [key: string]: {
            type: string;
            default: any;
        };
    };
}

interface MatrixRow {
    id: string;
    [key: string]: string | number;
}

interface MatrixCell {
    text: string;
    type: string;
    header: string;
    name: string;
}

interface MatrixHeader {
    text: string;
    type: string;
}


const customTheme = {
    Table: `
        --data-table-library_grid-template-columns: 80px repeat(auto-fit, minmax(150px, 1fr));
        width: 100%;
        min-width: max-content;
    `,
    HeaderRow: `
        background-color: #f1f5f9;
        position: sticky;
        top: 0;
        z-index: 1;
    `,
    HeaderCell: `
        padding: 12px 8px;
        font-weight: bold;
        border-bottom: 1px solid #e2e8f0;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    Row: `
        &:nth-of-type(odd) {
            background-color: #ffffff;
        }
        &:nth-of-type(even) {
            background-color: #f8fafc;
        }
    `,
    Cell: `
        padding: 12px 8px;
        border-bottom: 1px solid #e2e8f0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
};

const ImageCell: React.FC<{ value: string; onCellClick: () => void }> = ({ value, onCellClick }) => (
    <div className="flex items-center justify-center h-full cursor-pointer" onClick={onCellClick}>
        <img src={value} alt="Asset" className="max-h-[50px] max-w-full object-contain" />
    </div>
);

const MatrixPromotionUI: React.FC = () => {
    const [isAssetLibraryOpen, setIsAssetLibraryOpen] = useState(false);
    const [isHiddenColumnsModalOpen, setIsHiddenColumnsModalOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState<{ variationId: string; propId: string } | null>(null);
    const { video, matrixRows, matrixHeaders, editorProps, durationInFrames, variationMap } = useSnapshot(editorState);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [selectedVariations, setSelectedVariations] = useState<string[]>([]);
    const [selectedVariation, setSelectedVariation] = useState<string>("");
    const [allVariations, setAllVariations] = useState<string[]>([]);
    const [Composition, setComposition] = useState<any>();
    const { send } = useWebSocket();
    const theme = useTheme(customTheme);
    let { videoId = "" } = useParams();
    const navigate = useNavigate();

    // ... (keep the existing data and columns useMemo hooks)
    const data = useMemo(() => {
        const Data: any = [];

        Object.keys(matrixRows).map((variationId: string, index: number) => {
            const rowData: any = { id: variationId };

            matrixRows[variationId].map((row: MatrixCell, index: number) => {
                rowData[`${row.name} ${row.type}`] = row.text;
            });
            Data.push(rowData)
        });
        if (allVariations.length === 0) {
            setAllVariations(Object.keys(matrixRows))
            setSelectedVariation(Object.keys(matrixRows)[0])
        }
        return { nodes: Data };

    }, [matrixRows, matrixHeaders]);
    useEffect(() => {
        if (selectedVariation) {
            importCompositionWithRetry(selectedVariation, matrixRows[selectedVariation]._v, "Scene 1", setComposition);
        }
    }, [selectedVariation])

    useEffect(()=> {
        if (video.meta.hidden) {
            setHiddenColumns(video.meta.hidden)
        }
    }, [video])

    const select = useRowSelect(data, {
        onChange: onSelectChange,
    });

    function onSelectChange(_action: any, state: any) {        
        setSelectedVariations(state.ids)
    }

    const downloadData = async () => {
        const data = { variations: selectedVariations, columns: hiddenColumns }
        try {
            const response = await SendRequestRaw(
                "/api/v2/variations/excel",
                {
                    details: data,
                },
                "POST"
            );
            if (response.ok) {
                // Get the filename from the Content-Disposition header
                const contentDisposition = response.headers.get('Content-Disposition');
                const filenameMatch = contentDisposition && contentDisposition.match(/filename="?(.+)"?/i);
                const filename = filenameMatch ? filenameMatch[1] : 'variations.xlsx';

                // Get the blob from the response
                const blob = await response.blob();

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a temporary anchor element and trigger the download
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);

                // Show success message
                handleShowAlert("success", "Success", "File downloaded successfully");
            } else {
                const error = await response.json();

                handleShowAlert(
                    "error",
                    "Error",
                    error?.error || `Error Downloading excel`
                );
            }
        } catch (error) {
            handleShowAlert("error", "Error", `Network or server error:${error}`);
        }
    }



    const columns = useMemo(() =>
        matrixHeaders.map((header: MatrixHeader) => ({
            label: header.text,
            header: header,
            renderCell: (item: MatrixRow) => {

                const value = item[header.text];
                if (header.type === 'url') {
                    return (
                        <ImageCell
                            value={value as string}
                            onCellClick={() => handleCellClick(item.id, header.text)}
                        />
                    );
                }
                return <input
                    type="text"
                    style={{
                        width: "100%",
                        border: "none",
                        fontSize: "1rem",
                        padding: 0,
                        margin: 0,
                    }}
                    value={value}
                    onChange={(event) => {
                        const version = variationMap[item.id];
                        const [_type, Id, propType] = header.text.split(" ");
                        importCompositionWithRetry(item.id, version, "Scene 1", setComposition)
                        updateCell(item.id, header.text, event.target.value);
                        send({ action: "editVariations", id: item.id, props: { [`${Id}_${propType}`]: event.target.value } })
                    }
                        // handleCellClick(event.target.value, item.id, header)
                    }
                />
            },
        })),
        [matrixHeaders]);


    const handleCellClick = (variationId: string, propId: string) => {
        const version = variationMap[variationId];
        importCompositionWithRetry(variationId, version, "Scene 1", setComposition)

        setSelectedAsset({ variationId, propId });
        setIsAssetLibraryOpen(true);
    };

    const handleAssetUpdate = (newUrl: string) => {
        if (selectedAsset) {
            const [_type, Id, propType] = selectedAsset.propId.split(" ");
            updateCell(selectedAsset.variationId, selectedAsset.propId, newUrl);
            const key = `${Id}_${propType}`;
            const newPropValues = { [key]: newUrl };
            send({ action: "editVariations", id: selectedAsset.variationId, props: newPropValues })
            // Update your state or dispatch an action here with newPropValues
        }
        setIsAssetLibraryOpen(false);
    };

    const toggleColumnVisibility = (columnLabel: string) => {
        setHiddenColumns(prev =>
            prev.includes(columnLabel)
                ? prev.filter(col => col !== columnLabel)
                : [...prev, columnLabel]
        );
        send({ action: "updateHiddenColumns", hidden: hiddenColumns, video: videoId})
    };



    useEffect(() => {
        if (isHiddenColumnsModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isHiddenColumnsModalOpen]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            setUploading(true);
            const response = await SendFormData("/api/v2//variations/excel/upload", formData);
            if (response.data) {
                handleShowAlert(
                    "success",
                    "Success",
                    "File uploaded successfully. Adding the file now."
                );
                setUploading(false);
                window.location.reload();
            } else {
                handleShowAlert(
                    "error",
                    "Error",
                    `Error uploading file:${response.error}`
                );
            }
        }
    };    

    return (
        <div className=" p-4 bg-gray-100 min-h-screen">
            <div className="mb-4 flex items-center">
                <ChevronLeft className="mr-2" />
                <h1 className="text-xl font-semibold">{video?.title}</h1>
            </div>

            <div className="mb-4 flex space-x-2">
                <button className="bg-blue-500 text-white px-4 py-2 rounded flex items-center" onClick={() => send({ action: "createVariation", id: videoId })}>
                    <Save className="mr-2" /> Add a row
                </button>
                <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                    className="hidden"
                    id="file-upload"
                />
                <label htmlFor="file-upload" className="cursor-pointer">
                    <div className="bg-white border border-gray-300 px-4 py-2 rounded flex items-center">
                        <Import className="mr-2" /> Import Data
                    </div>
                </label>

                <button className="bg-white border border-gray-300 px-4 py-2 rounded flex items-center" onClick={() => downloadData()}>
                    <FaFileExport className="mr-2" /> Export Data
                </button>
                <button
                    className="bg-white border border-gray-300 p-2 rounded"
                    onClick={() => setIsHiddenColumnsModalOpen(true)}
                >
                    {hiddenColumns.length > 0 ? <EyeOff /> : <Eye />}
                </button>
                {selectedVariations.length > 0 && <button className="bg-white border border-gray-300 px-4 py-2 rounded flex items-center" onClick={() => send({ action: "exportVariations", ids: selectedVariations})}>
                    <Download className="mr-2" /> Download Variations
                </button>}
            </div>
            <div className='flex'>
                <div className="flex-grow rounded shadow overflow-x-auto max-w-full pr-2">
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                        <Table data={data} theme={theme} select={select}>
                            {(tableList: TableNode) => (
                                <>
                                    <Header>
                                        <HeaderRow>
                                            <HeaderCellSelect />
                                            {columns.map((column: { label: string }) => (
                                                <HeaderCell key={column.label} title={column.label} hide={hiddenColumns.includes(column.label)}>{column.label}</HeaderCell>
                                            ))}
                                        </HeaderRow>
                                    </Header>
                                    <Body>
                                        {tableList.map((item: MatrixRow) => {
                                            return <Row key={item.id} item={item} style={selectedVariation === item.id ? {"background-color": "#b1b1b15c"}: {}} onClick={()=> setSelectedVariation(item.id)}>
                                                <CellSelect item={item}  />
                                                {columns.map((column: { label: string; renderCell: (item: MatrixRow) => React.ReactNode }) => {
                                                    return <Cell key={column.label} hide={hiddenColumns.includes(column.label)} style={{borderStyle:"solid",borderWidth: "1px 0px 1px 1px"}}>{column.renderCell(item)}</Cell>
                                                })}
                                            </Row>
                                        })}
                                    </Body>
                                </>
                            )}
                        </Table>
                    </div>
                </div>

                <div className="w-full mt-4 flex justify-between items-center flex-col" id="preview">
                    {Composition && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Player
                                //   ref={playerRef}
                                inputProps={editorProps}
                                compositionWidth={video?.meta?.global?.width || 1280}
                                compositionHeight={video?.meta?.global?.height || 720}
                                durationInFrames={durationInFrames || 1}
                                fps={video?.meta?.global?.fps || 30}
                                component={Composition}
                                alwaysShowControls={true}
                                controls={true}
                                style={{
                                    width: ((video?.meta?.global?.width || 1280) / 3),
                                    height: ((video?.meta?.global?.height || 720) / 3),

                                }}
                                clickToPlay={false}
                            // style={{ background: "white" }}
                            />
                        </Suspense>
                    )}
                    <div className="text-sm text-gray-500">End of page 1</div>
                    <div className="flex space-x-4 flex-row">
                        <button className="bg-green-500 text-white px-4 py-2 rounded flex items-center" onClick={() => { navigate(`/video/${videoId}`) }}>
                            <Pencil />
                            Edit Video Template
                        </button>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded flex items-center">
                            <GiClockwiseRotation className="mr-2" />
                            Download Variation
                        </button>
                        <button className="bg-white px-4 py-2 rounded flex items-center" disabled>
                            <MdApi /> API Docs
                        </button>

                    </div>
                </div>
            </div>
            {isHiddenColumnsModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 h-screen overflow-y-auto pt-10">
                    <div className="bg-white rounded-lg p-6 w-96 max-w-full m-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Hide/Unhide Columns</h2>
                            <button
                                onClick={() => setIsHiddenColumnsModalOpen(false)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="mt-4">
                            {matrixHeaders.map((header: MatrixHeader) => (
                                <div key={header.text} className="flex items-center space-x-2 mb-2">
                                    <input
                                        type="checkbox"
                                        id={`column-${header.text}`}
                                        checked={!hiddenColumns.includes(header.text)}
                                        onChange={() => toggleColumnVisibility(header.text)}
                                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <label htmlFor={`column-${header.text}`} className="text-sm text-gray-700">
                                        {header.text}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className="mt-6 flex justify-end">
                            <button
                                onClick={() => setIsHiddenColumnsModalOpen(false)}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <AssetLibrary
                isOpen={isAssetLibraryOpen}
                onClose={() => setIsAssetLibraryOpen(false)}
                assetImporting="Image"
                onUpdate={handleAssetUpdate}
            />
        </div>
    );
}

export default MatrixPromotionUI;