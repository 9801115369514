import React, { useState } from 'react';
import { X, ChevronDown } from 'lucide-react';

const CaptionSettingsModal = ({ isOpen, onClose }: any) => {
  const [applyToAll, setApplyToAll] = useState(false);
  const [showSentence, setShowSentence] = useState(true);
  const [selectedFont, setSelectedFont] = useState('Luckiest Guy');
  const [fontSize, setFontSize] = useState(70);
  const [selectedStyle, setSelectedStyle] = useState('MYSTIQUE');

  const fontStyles = ['MYSTIQUE', 'CAUTION', 'FOCUS', 'MINIMAL', 'VINTAGE', 'WHITE'];

  if (!isOpen) return null;

  return (
    <div className="absolute top-0 right-12 bottom-0 w-72 bg-gray-800 p-4 overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Caption settings</h3>
        <button onClick={onClose} className="text-gray-400 hover:text-white">
          <X size={20} />
        </button>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <span>Apply to all</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" checked={applyToAll} onChange={() => setApplyToAll(!applyToAll)} />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>

        <div>
          <p className="text-sm text-gray-300 mb-2">Want to enjoy your active</p>
          <div className="flex items-center">
            <input type="checkbox" id="showSentence" className="mr-2" checked={showSentence} onChange={() => setShowSentence(!showSentence)} />
            <label htmlFor="showSentence" className="text-sm">Show this sentence</label>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="text-sm">Font</span>
            <div className="relative w-1/2">
              <select
                value={selectedFont}
                onChange={(e) => setSelectedFont(e.target.value)}
                className="appearance-none w-full bg-gray-700 border border-gray-600 text-white py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-600 focus:border-gray-500 text-sm"
              >
                <option>Luckiest Guy</option>
                {/* Add more font options here */}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                <ChevronDown size={16} />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm">Size</span>
            <input
              type="number"
              value={fontSize}
              onChange={(e) => setFontSize(Number(e.target.value))}
              className="w-16 bg-gray-700 border border-gray-600 text-white py-1 px-2 rounded text-sm"
            />
          </div>

          <div>
            <span className="text-sm">Style</span>
            <div className="grid grid-cols-3 gap-2 mt-2">
              {fontStyles.map((style) => (
                <button
                  key={style}
                  onClick={() => setSelectedStyle(style)}
                  className={`py-1 px-2 text-xs rounded ${selectedStyle === style ? 'bg-blue-600' : 'bg-gray-700'}`}
                >
                  {style}
                </button>
              ))}
            </div>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm">Highlight Text Color</span>
            <input type="color" className="w-8 h-8 rounded" />
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm">Background Color</span>
            <input type="color" className="w-8 h-8 rounded" />
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm">Offset</span>
            <div className="flex items-center space-x-2">
              <span className="text-xs">X: 0</span>
              <span className="text-xs">Y: 0.33</span>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm">Position</span>
            <div className="grid grid-cols-3 gap-1">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((pos) => (
                <div key={pos} className="w-6 h-6 bg-gray-700 border border-gray-600 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptionSettingsModal;