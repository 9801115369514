import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  VStack,
  Select,
  Spinner,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import DashboardWrapper from '../../lib/components/Wrapper';
import { useNavigate } from 'react-router-dom';
import { SendRequest } from '../../utils/main';

interface TeamFormData {
  name: string;
  description: string;
  organization: string;
  leader: string;
}

interface Organization {
  id: string;
  name: string;
}

const NewTeamForm: React.FC = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [organization, setOrganization] = useState('');
  const [leader, setLeader] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizations = async () => {
      setIsLoadingOrgs(true);
      try {
        const response = await SendRequest('/api/v2/user/orgs', null, 'GET');
        if (response.data) {
          setOrganizations(response.data.docs);
        } else {
          setLoadError('Failed to load organizations. Please try again.');
        }
      } catch (error) {
        setLoadError(`Error loading organizations: ${error}`);
      } finally {
        setIsLoadingOrgs(false);
      }
    };

    fetchOrganizations();
  }, []);

  const handleSave = async () => {
    const data: TeamFormData = {
      name,
      description,
      organization,
      leader,
    };
    console.log('Saving team:', data);
    if (!name) {
      toast({
        title: "Team name is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await SendRequest(
        `/api/v2/team/new`,
        data,
        "POST"
      );
      if (response.data) {
        toast({
          title: "Team Created",
          description: "Your team has been created.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate('/profile');
      } else {
        toast({
          title: "Onboarding Error",
          description: `Error saving onboarding details: ${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Onboarding Error",
        description: `Network or server error: ${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardWrapper>
      <Box maxW="md" mx="auto" mt="10">
        <Heading as='h3' size='lg' mb="6">
          Create New Team
        </Heading>
        {loadError && (
          <Alert status="error" mb="4">
            <AlertIcon />
            {loadError}
          </Alert>
        )}
        <VStack spacing={4} as="form">
          <FormControl id="name" isRequired>
            <FormLabel>Team Name</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl id="description">
            <FormLabel>Team Description</FormLabel>
            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Text as='i' fontSize='xs'>Briefly describe the team's purpose and responsibilities.</Text>
          </FormControl>
          <FormControl id="organization" isRequired>
            <FormLabel>Organization</FormLabel>
            <Select
              placeholder="Select organization"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
              isDisabled={isLoadingOrgs}
            >
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>{org.name}</option>
              ))}
            </Select>
            {isLoadingOrgs && <Spinner size="sm" ml="2" />}
          </FormControl>
          <Button
            colorScheme="green"
            onClick={handleSave}
            isDisabled={isSubmitting || isLoadingOrgs}
            leftIcon={isSubmitting ? <Spinner size="sm" /> : undefined}
          >
            {isSubmitting ? 'Submitting...' : 'Create Team'}
          </Button>
        </VStack>
      </Box>
    </DashboardWrapper>
  );
};

export default NewTeamForm;