import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { backendurl } from "../lib/utils/Misc";
import { handleShowAlert } from "./Signup";

const meta = {
    title: "",
    meta: [],
    link: [],
    style: [],
    script: [],
};


export default function Verify() {
    let { search } = useLocation();
    const navigate = useNavigate();
    const [msg, setMsg] = useState("Verifying your email");

    useEffect(()=> {
        const query = new URLSearchParams(search);
        const token = query.get('token');
        if (!token) {
            setMsg( `No token found. Kindly contact support in case you are stuck here.`)
            return handleShowAlert("error", "Error", `No token found. Kindly contact support in case you are stuck here.`);
        }
        verifyData(token).then().catch()
    }, [])
    const verifyData = async (token: string) => {
       
        try {
            const response = await fetch(`${backendurl}/api/users/verify${token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                // credentials: 'include',
                // body: JSON.stringify(data),
            });

            const result = await response.json();
            if (response.status === 201 || response.status === 200) {
                handleShowAlert("success", "Success", "Email has been verified.");
                navigate("/login");
            } else {
                handleShowAlert(
                    "error",
                    "Error",
                    `Error Verifying email:${result.message}`
                );
                console.error("Error  verifying email:", result.message);
            }
        } catch (error) {
            handleShowAlert("error", "Error", `Network or server error:${error}`);
            console.error("Network or server error:", error);
        }
    };
    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet {...meta}></Helmet>
            </HelmetProvider>
            <section className="relative py-16 overflow-hidden h-screen">
                <img
                    className="absolute top-0 left-0 w-full h-screen object-cover"
                    src="homepage/bromo-assets/sign-in/background.png"
                    alt=""
                />
                <div className="relative container mx-auto px-4">
                    <div className="max-w-2xl px-4 mx-auto">
                        <div className="relative py-12 px-10 text-center rounded-3xl overflow-hidden">
                            <div className="absolute top-0 left-0 w-full h-full filter backdrop-blur backdrop-filter bg-black bg-opacity-70" />
                            <div className="relative">
                                <h2 className="font-heading text-4xl font-medium text-white tracking-tight mb-6">
                                    {msg}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
