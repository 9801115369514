
export const ShowPreview = (props: any) => {
    if (props.api.logs.final) {
      const {type, url } = props.api.logs.final;
      if (type === "Video") {
        return (
          <video controls width="250">
            <source src={url} type="video/webm" />
          </video>
        );
      }
      if (type === "Audio") {        
        return (
          <audio src={url} controls></audio>
        );
      }
    }
    if (props.api.logs.apis?.audio_file?.url) {
      return <audio src={props.api.logs.apis?.audio_file.url} controls></audio>;
    }
    if (props.api.logs.apis?.audio_url) {
      return <audio src={props.api.logs.apis.audio_url} controls></audio>;
    }
    if (props.api.logs.apis?.video) {
      return (
        <video controls width="250">
          <source src={props.api.logs.apis.video.url} type="video/webm" />
        </video>
      );
    }
    if (props.api.logs.apis?.images) {
      return (
        props.api.logs.apis?.images.map((i: any) => <img
        alt={props.api.logs.apis.prompt}
        width="250"
        src={i.url}
      />)
        
      );
    }
    return <span></span>;
  };
  