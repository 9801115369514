import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { backendurl } from "../lib/utils/Misc";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../components/lib/Alert";

export const handleShowAlert = (
  type: string,
  title: string,
  message: string
) => {
  showAlert(type, title, message);
};

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Logout() {
  const navigate = useNavigate();

  const logOut = () => {
    fetch(`${backendurl}/api/users/logout`, {
      method: "POST",
      headers: {
        //   Authorization: `JWT ${auth.token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((resp) => {
        localStorage.setItem("auth", "{}");
        if (resp.ok) {
          handleShowAlert(
            "success",
            "Processing",
            "Logged out. Redirecting to login"
          );
          navigate("/login");
        } else {
          handleShowAlert("success", "Processing", "Issue logging you out.");
          console.log(resp);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    localStorage.setItem("auth", "{}");
    navigate("/login");
    // const auth = JSON.parse(localStorage.getItem("auth") || '{}');
  }, []);
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className="relative py-16 overflow-hidden h-screen">
        <img
          className="absolute top-0 left-0 w-full h-screen object-cover"
          src="homepage/bromo-assets/sign-in/background.png"
          alt=""
        />
        <div className="relative container mx-auto px-4">
          <div className="max-w-2xl px-4 mx-auto">
            <div className="relative py-12 px-10 text-center rounded-3xl overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full filter backdrop-blur backdrop-filter bg-black bg-opacity-70" />
              <div className="relative">
                <h2 className="font-heading text-4xl font-medium text-white tracking-tight mb-6">
                  Logging you out in a jiffy.
                </h2>
                <p className="text-xl text-white mb-8">
                  Crackviral is powered by state-of-the-art video, image and audio
                  models to give an edge over other editors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
