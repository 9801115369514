import { ChevronRightIcon } from '@chakra-ui/icons';
import React from 'react';
import { BiPlay } from 'react-icons/bi';
import { FaHatCowboy, FaHatWizard } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface CardData { title: string, description: string, tag: string, buttonText?: string, link?: string }
export const FeaturedWorkflows = () => {
  return (
    <div className="bg-gray-500 text-white p-6">
      <h2 className="text-2xl font-bold mb-4">Featured Workflows</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <FeaturedCard
          title="AI Viral Shorts"
          description="Make viral video trending ads with a few clicks"
          buttonText="Create now"
          tag="Hot"
          link="/workflows/aihooks"
        />
        <FeaturedCard
          title="AI StoryBoard"
          description="Take an Idea to the storyboard to a video."
          buttonText="Create Now"
          tag="Hot"
          link='/workflows/aistoryboard'
        />
        <FeaturedCard
          title="URL to video ad"
          description="Create video automatically from a URL"
          buttonText="Create Now"
          tag="Coming Soon"
          link='/workflows/aifromurl'
        />
        <FeaturedCard
          title="Bulk Mode"
          description="Create variations of a video in excel. Works for any video that you make using crackviral."
          // buttonText="Live now"
          tag="beta"
          // link='/workflows/aifromurl'
        />
      </div>

      <h2 className="text-2xl font-bold mb-4 pt-40">Coming Soon</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <PopularToolCard
          title="AI avatar"
          description="Create realistic avatar from script"
          tag=""
        />
        <PopularToolCard
          title="AI editing"
          description="Let AI edit your video"
          tag=""
        />
        <PopularToolCard
          tag=""
          title="Comfy UI Integration"
          description="Use your favourite comfy ui pipeline."
        />
        <PopularToolCard
          tag=""
          title="Product Videos"
          description="AI Edited Product Videos"
        />
      </div>
    </div>
  );
};

const FeaturedCard = ({ title, description, buttonText, tag, link }: CardData) => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-800 rounded-lg p-4 flex flex-col justify-between">
      <div>
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-xl font-semibold">{title}</h3>
          {tag && (
            <span className="bg-yellow-500 text-black text-xs font-bold px-2 py-1 rounded">
              {tag}
            </span>
          )}
        </div>
        <p className="text-gray-400 mb-4">{description}</p>
      </div>
      <div className="flex items-center">
        {(buttonText && link) && <button className="bg-indigo-600 text-white px-4 py-2 rounded-md mr-2" onClick={() => {
          navigate(link)
        }}>
          {buttonText}
        </button>}
        <button className="bg-gray-700 p-2 rounded-full">
          <FaHatWizard size={16} />
        </button>
      </div>
    </div>
  );
};

const PopularToolCard = ({ title, description, tag }: CardData) => {
  return (
    <div className="bg-gray-800 rounded-lg p-4">
      <div className="flex justify-between items-start mb-2">
        <h3 className="text-lg font-semibold">{title}</h3>
        {tag && (
          <span className="bg-yellow-500 text-black text-xs font-bold px-2 py-1 rounded">
            {tag}
          </span>
        )}
      </div>
      <p className="text-gray-400 text-sm mb-4">{description}</p>
      <div className="flex justify-between items-center">
        {/* <button className="text-gray-400 text-sm flex items-center">
          Tutorial
          <FaHatCowboy size={12} className="ml-1" />
        </button> */}
        {/* <button className="text-gray-400 text-sm flex items-center">
          Express Interest
          <ChevronRightIcon height={16} className="text-gray-400" />
        </button> */}
      </div>
    </div>
  );
};

export default FeaturedWorkflows;