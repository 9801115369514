const modes = {
  base: { path: "/", input: "WhisperInput" },
};

const title = "Whisper";
const specs = {
  paths: {
    "/": {
      post: {
        responses: {
          "200": {
            description: "Successful Response",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/WhisperOutput" },
              },
            },
          },
          "422": {
            description: "Validation Error",
            content: {
              "application/json": {
                schema: { $ref: "#/components/schemas/HTTPValidationError" },
              },
            },
          },
        },
        requestBody: {
          content: {
            "application/json": {
              schema: { $ref: "#/components/schemas/WhisperInput" },
            },
          },
          required: true,
        },
        summary: "Generate",
        operationId: "generate__post",
      },
    },
    "/health": {
      get: {
        responses: {
          "200": {
            description: "Successful Response",
            content: { "application/json": { schema: {} } },
          },
        },
        summary: "Health",
        operationId: "health_health_get",
      },
    },
  },
  info: { version: "0.1.0", title: "FastAPI" },
  components: {
    schemas: {
      ValidationError: {
        title: "ValidationError",
        type: "object",
        properties: {
          type: { title: "Error Type", type: "string" },
          msg: { title: "Message", type: "string" },
          loc: {
            title: "Location",
            type: "array",
            items: { anyOf: [{ type: "string" }, { type: "integer" }] },
          },
        },
        "x-fal-order-properties": ["loc", "msg", "type"],
        required: ["loc", "msg", "type"],
      },
      WhisperChunk: {
        title: "WhisperChunk",
        type: "object",
        properties: {
          text: {
            title: "Text",
            type: "string",
            description: "Transcription of the chunk",
          },
          timestamp: {
            maxItems: 2,
            type: "array",
            minItems: 2,
            title: "Timestamp",
            description: "Start and end timestamp of the chunk",
            items: [{ type: "number" }, { type: "number" }],
          },
        },
        "x-fal-order-properties": ["timestamp", "text"],
        required: ["timestamp", "text"],
      },
      WhisperInput: {
        title: "WhisperInput",
        type: "object",
        properties: {
          prompt: {
            title: "Prompt",
            type: "string",
            description:
              "Prompt to use for generation. Defaults to an empty string.",
            default: "",
          },
          batch_size: {
            minimum: 1,
            maximum: 64,
            type: "integer",
            title: "Batch Size",
            examples: [64],
            default: 64,
          },
          language: {
            enum: [
              "af",
              "am",
              "ar",
              "as",
              "az",
              "ba",
              "be",
              "bg",
              "bn",
              "bo",
              "br",
              "bs",
              "ca",
              "cs",
              "cy",
              "da",
              "de",
              "el",
              "en",
              "es",
              "et",
              "eu",
              "fa",
              "fi",
              "fo",
              "fr",
              "gl",
              "gu",
              "ha",
              "haw",
              "he",
              "hi",
              "hr",
              "ht",
              "hu",
              "hy",
              "id",
              "is",
              "it",
              "ja",
              "jw",
              "ka",
              "kk",
              "km",
              "kn",
              "ko",
              "la",
              "lb",
              "ln",
              "lo",
              "lt",
              "lv",
              "mg",
              "mi",
              "mk",
              "ml",
              "mn",
              "mr",
              "ms",
              "mt",
              "my",
              "ne",
              "nl",
              "nn",
              "no",
              "oc",
              "pa",
              "pl",
              "ps",
              "pt",
              "ro",
              "ru",
              "sa",
              "sd",
              "si",
              "sk",
              "sl",
              "sn",
              "so",
              "sq",
              "sr",
              "su",
              "sv",
              "sw",
              "ta",
              "te",
              "tg",
              "th",
              "tk",
              "tl",
              "tr",
              "tt",
              "uk",
              "ur",
              "uz",
              "vi",
              "yi",
              "yo",
              "yue",
              "zh",
            ],
            title: "Language",
            type: "string",
            description:
              "\n        Language of the audio file. If set to null, the language will be\n        automatically detected. Defaults to null.\n\n        If translate is selected as the task, the audio will be translated to\n        English, regardless of the language selected.\n        ",
            nullable: true,
          },
          version: {
            enum: ["3"],
            title: "Version",
            type: "string",
            description:
              "Version of the model to use. All of the models are the Whisper large variant.",
            default: "3",
          },
          task: {
            enum: ["transcribe", "translate"],
            title: "Task",
            type: "string",
            description:
              "Task to perform on the audio file. Either transcribe or translate.",
            default: "transcribe",
          },
          chunk_level: {
            enum: ["segment", "word"],
            title: "Chunk Level",
            type: "string",
            description:
              "Level of the chunks to return. Either segment or word.",
            default: "segment",
          },
          audio_url: {
            examples: [
              "https://storage.googleapis.com/falserverless/model_tests/whisper/dinner_conversation.mp3",
            ],
            title: "Audio Url",
            type: "string",
            description:
              "URL of the audio file to transcribe. Supported formats: mp3, mp4, mpeg, mpga, m4a, wav or webm.",
          },
        },
        "x-fal-order-properties": [
          "audio_url",
          "task",
          "language",
          "chunk_level",
          "version",
          "batch_size",
          "prompt",
        ],
        required: ["audio_url"],
      },
      WhisperOutput: {
        title: "WhisperOutput",
        type: "object",
        properties: {
          text: {
            title: "Text",
            type: "string",
            description: "Transcription of the audio file",
          },
          inferred_languages: {
            title: "Inferred Languages",
            type: "array",
            description:
              "List of languages that the audio file is inferred to be. Defaults to null.",
            items: {
              enum: [
                "af",
                "am",
                "ar",
                "as",
                "az",
                "ba",
                "be",
                "bg",
                "bn",
                "bo",
                "br",
                "bs",
                "ca",
                "cs",
                "cy",
                "da",
                "de",
                "el",
                "en",
                "es",
                "et",
                "eu",
                "fa",
                "fi",
                "fo",
                "fr",
                "gl",
                "gu",
                "ha",
                "haw",
                "he",
                "hi",
                "hr",
                "ht",
                "hu",
                "hy",
                "id",
                "is",
                "it",
                "ja",
                "jw",
                "ka",
                "kk",
                "km",
                "kn",
                "ko",
                "la",
                "lb",
                "ln",
                "lo",
                "lt",
                "lv",
                "mg",
                "mi",
                "mk",
                "ml",
                "mn",
                "mr",
                "ms",
                "mt",
                "my",
                "ne",
                "nl",
                "nn",
                "no",
                "oc",
                "pa",
                "pl",
                "ps",
                "pt",
                "ro",
                "ru",
                "sa",
                "sd",
                "si",
                "sk",
                "sl",
                "sn",
                "so",
                "sq",
                "sr",
                "su",
                "sv",
                "sw",
                "ta",
                "te",
                "tg",
                "th",
                "tk",
                "tl",
                "tr",
                "tt",
                "uk",
                "ur",
                "uz",
                "vi",
                "yi",
                "yo",
                "yue",
                "zh",
              ],
              type: "string",
            },
          },
          chunks: {
            title: "Chunks",
            type: "array",
            description: "Timestamp chunks of the audio file",
            items: { $ref: "#/components/schemas/WhisperChunk" },
          },
        },
        "x-fal-order-properties": ["text", "chunks", "inferred_languages"],
        required: ["text", "inferred_languages"],
      },
      HTTPValidationError: {
        title: "HTTPValidationError",
        type: "object",
        properties: {
          detail: {
            title: "Detail",
            type: "array",
            items: { $ref: "#/components/schemas/ValidationError" },
          },
        },
        "x-fal-order-properties": ["detail"],
      },
    },
  },
  openapi: "3.1.0",
  "x-fal-order-paths": ["/", "/health"],
};
export default { specs, title, modes };
