import { GridItem, chakra, Badge, Flex, Button, Box } from "@chakra-ui/react";
import { setActiveIndex } from "../../stores/StoryStore";

export const GridBox = (props: any) => {
  const { sceneIndex, diagIndex } = props;
  const indexesToCheck = [0, 1, 2].map(
    (key: number) => `${sceneIndex},${diagIndex},${key}`
  );
  let isReady = false;
  const modelSelcted = [];
  for (let index = 0; index < indexesToCheck.length; index++) {
    const indexString = indexesToCheck[index];
    if (
      props.exp.meta?.configs?.hasOwnProperty(indexString) &&
      props.exp.meta?.configs[indexString][0]
    ) {
      modelSelcted.push(props.exp.meta?.configs[indexString][0].model_name);
    }
  }
  if (modelSelcted.length > 0) {
    isReady = true;
  }

  return (
    <GridItem w="100%">
      <Box
        // maxW="xs"
        mx="auto"
        // bg="white"
        _dark={{
          bg: "gray.800",
        }}
        shadow="lg"
        rounded="lg"
        bg={isReady ? "green.100" : "yellow.100"}
      >
        <Box px={4} py={2}>
          {props.diagIndex < 0 && (
            <>
              <chakra.h1
                color="gray.800"
                _dark={{
                  color: "white",
                }}
                fontWeight="bold"
                fontSize="xl"
                textTransform="uppercase"
              >
                No Dialog
              </chakra.h1>
            </>
          )}
          {diagIndex >= 0 && (
            <>
              <chakra.h1
                color="gray.800"
                _dark={{
                  color: "white",
                }}
                fontWeight="bold"
                fontSize="xl"
                textTransform="uppercase"
              >
                Speaker: {props.speaker}
              </chakra.h1>
              <chakra.p
                mt={1}
                fontSize="sm"
                color="gray.600"
                _dark={{
                  color: "gray.400",
                }}
              >
                Line: {props.line}
              </chakra.p>
            </>
          )}

          <chakra.p
            mt={1}
            fontSize="sm"
            color="gray.600"
            _dark={{
              color: "gray.400",
            }}
          >
            {modelSelcted.length > 0 && <>Models Selected:</>}
            {modelSelcted.map((model) => (
              <Badge ml="1" colorScheme="blue">
                {model}
              </Badge>
            ))}
          </chakra.p>

          {isReady &&
            modelSelcted.map((model, index) => (
              <Button
                p={2}
                m={2}
                fontSize="xs"
                colorScheme={isReady ? "green" : "red"}
                fontWeight="bold"
                rounded="lg"
                textTransform="uppercase"
                _hover={{
                  bg: "green.200",
                }}
                _focus={{
                  bg: "green.400",
                }}
                onClick={() => {
                  props.startSync();
                  props.setShowLoader(true);
                  props.runExpPipeline({
                    sceneIndex,
                    diagIndex,
                    modelIndex: [index],
                  });
                }}
              >
                Run {model}
              </Button>
            ))}
        </Box>

        {/* <Image
      h={48}
      w="full"
      fit="cover"
      mt={2}
      src="https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=320&q=80"
      alt="NIKE AIR"
    /> */}

        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={4}
          py={2}
          bg="gray.200"
          roundedBottom="lg"
        >
          <Button
            // isLoading
            loadingText="Loading"
            colorScheme="blue"
            variant="outline"
            spinnerPlacement="start"
            onClick={() => {
              setActiveIndex([props.sceneIndex, props.diagIndex, -1].join(","));
              props.editScenePropsOpen();
            }}
          >
            Edit Pipeline
          </Button>

          <Button
            px={2}
            py={1}
            fontSize="xs"
            colorScheme={isReady ? "green" : "red"}
            fontWeight="bold"
            rounded="lg"
            textTransform="uppercase"
            _hover={{
              bg: "green.200",
            }}
            _focus={{
              bg: "green.400",
            }}
            onClick={() => {
              if (!isReady) {
                return;
              }
              props.startSync();
              props.setShowLoader(true);
              props.runExpPipeline({ sceneIndex, diagIndex });
            }}
          >
            {isReady ? "Run Pipeline" : "Not Ready"}
          </Button>
        </Flex>
      </Box>
    </GridItem>
  );
};
