import React, { useState } from 'react';
import { ArrowLeft, ArrowRight, Clock, User, Globe } from 'lucide-react';
import { BsAspectRatio } from 'react-icons/bs';

const VideoSettingsForm = () => {
  const [aspectRatio, setAspectRatio] = useState('9x16');
  const [videoLength, setVideoLength] = useState('15 seconds');
  const [targetAudience, setTargetAudience] = useState('');
  const [language, setLanguage] = useState('English');

  const aspectRatios = ['9x16', '16x9', '1x1'];
  const videoLengths = ['15 seconds', '30 seconds', '60 seconds'];

  return (
    <div className="bg-gradient-to-b from-gray-800 to-gray-900 min-h-screen flex flex-col items-center justify-center p-4 text-white">
      <div className="w-full max-w-2xl">
        <h1 className="text-3xl font-bold mb-8 text-center">Set the video settings</h1>
        
        <div className="space-y-6">
          <div>
            <label className="flex items-center text-sm font-medium mb-2">
              <BsAspectRatio size={16} className="mr-2" />
              Aspect ratio
            </label>
            <div className="grid grid-cols-3 gap-3">
              {aspectRatios.map((ratio) => (
                <button
                  key={ratio}
                  onClick={() => setAspectRatio(ratio)}
                  className={`py-2 px-4 rounded-lg transition duration-300 ${
                    aspectRatio === ratio
                      ? 'bg-white text-gray-900'
                      : 'bg-gray-700 hover:bg-gray-600'
                  }`}
                >
                  {ratio}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="flex items-center text-sm font-medium mb-2">
              <Clock size={16} className="mr-2" />
              Video length
            </label>
            <div className="grid grid-cols-3 gap-3">
              {videoLengths.map((length) => (
                <button
                  key={length}
                  onClick={() => setVideoLength(length)}
                  className={`py-2 px-4 rounded-lg transition duration-300 ${
                    videoLength === length
                      ? 'bg-white text-gray-900'
                      : 'bg-gray-700 hover:bg-gray-600'
                  }`}
                >
                  {length}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="targetAudience" className="flex items-center text-sm font-medium mb-2">
              <User size={16} className="mr-2" />
              Target audience (Optional)
            </label>
            <input
              id="targetAudience"
              type="text"
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
              placeholder="e.g. outdoor lover"
              className="w-full bg-gray-700 rounded-lg py-2 px-4 text-white placeholder-gray-400"
            />
          </div>

          <div>
            <label htmlFor="language" className="flex items-center text-sm font-medium mb-2">
              <Globe size={16} className="mr-2" />
              Language
            </label>
            <select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="w-full bg-gray-700 rounded-lg py-2 px-4 text-white"
            >
              <option value="English">English</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
              <option value="German">German</option>
              <option value="Chinese">Chinese</option>
            </select>
          </div>
        </div>

        <div className="flex justify-between mt-8">
          <button className="bg-gray-700 hover:bg-gray-600 text-white font-medium py-2 px-6 rounded-lg flex items-center transition duration-300">
            <ArrowLeft size={20} className="mr-2" />
            Back
          </button>
          <button className="bg-blue-600 hover:bg-blue-500 text-white font-medium py-2 px-6 rounded-lg flex items-center transition duration-300">
            Next
            <ArrowRight size={20} className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoSettingsForm;