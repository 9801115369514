import React, { useState } from 'react';
import { Image, Edit, ArrowLeft, ArrowRight } from 'lucide-react';

const ProductDetailsForm = () => {
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');

  return (
    <div className="bg-gradient-to-b from-gray-800 to-gray-900 min-h-screen flex flex-col items-center justify-center p-4 text-white">
      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-bold mb-8 text-center">Add media and product details</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2 space-y-6">
            <div>
              <label htmlFor="productName" className="block text-sm font-medium mb-2">Product Name</label>
              <input
                id="productName"
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                placeholder="Enter product name"
                className="w-full bg-gray-700 rounded-lg py-3 px-4 text-white placeholder-gray-400"
              />
            </div>
            
            <div>
              <label htmlFor="productDescription" className="block text-sm font-medium mb-2">Product Description</label>
              <textarea
                id="productDescription"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                placeholder="Describe your product"
                rows={6}
                className="w-full bg-gray-700 rounded-lg py-3 px-4 text-white placeholder-gray-400 resize-none"
              />
            </div>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-2">Logo</label>
              <button className="bg-gray-700 hover:bg-gray-600 rounded-lg p-3 transition duration-300">
                <Image size={24} />
              </button>
            </div>
            
            <div>
              <label className="block text-sm font-medium mb-2">Media</label>
              <div className="bg-gray-700 rounded-lg p-4">
                <div className="border-2 border-dashed border-gray-500 rounded-lg h-40 flex items-center justify-center">
                  <span className="text-4xl text-gray-500">+</span>
                </div>
              </div>
              <button className="mt-2 bg-gray-700 hover:bg-gray-600 text-white font-medium py-2 px-4 rounded-lg flex items-center justify-center w-full transition duration-300">
                <Edit size={16} className="mr-2" />
                Edit
              </button>
            </div>
          </div>
        </div>
        
        <div className="flex justify-between mt-8">
          <button className="bg-gray-700 hover:bg-gray-600 text-white font-medium py-2 px-6 rounded-lg flex items-center transition duration-300">
            <ArrowLeft size={20} className="mr-2" />
            Back
          </button>
          <button className="bg-blue-600 hover:bg-blue-500 text-white font-medium py-2 px-6 rounded-lg flex items-center transition duration-300">
            Next
            <ArrowRight size={20} className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsForm;