import { wssurl } from "./Misc";

type WebSocketEventMap = {
    open: Event;
    close: CloseEvent;
    message: MessageEvent;
    error: Event;
};

type WebSocketEventType = keyof WebSocketEventMap;

export class WebSocketService {
    private static instance: WebSocketService;
    private socket: WebSocket | null = null;
    private reconnectAttempts = 0;
    private maxReconnectAttempts = 5;
    private reconnectInterval = 3000;
    private messageQueue: any[] = [];
    private listeners: { [K in WebSocketEventType]: Set<(event: WebSocketEventMap[K]) => void> } = {
        open: new Set(),
        close: new Set(),
        message: new Set(),
        error: new Set(),
    };

    private constructor() { }

    public static getInstance(): WebSocketService {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }

    public connect(): void {
        if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
            this.socket = new WebSocket(wssurl);

            this.socket.onopen = (event) => {
                console.log('WebSocket connected');
                this.reconnectAttempts = 0;
                this.listeners.open.forEach(listener => listener(event));
                this.processPendingMessages();
            };

            this.socket.onclose = (event) => {
                console.log('WebSocket disconnected');
                this.listeners.close.forEach(listener => listener(event));
                this.reconnect();
            };

            this.socket.onmessage = (event) => {
                this.listeners.message.forEach(listener => listener(event));
            };

            this.socket.onerror = (event) => {
                console.error('WebSocket error:', event);
                this.listeners.error.forEach(listener => listener(event));
            };
        }
    }

    private reconnect(): void {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            this.reconnectAttempts++;
            console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
            setTimeout(() => this.connect(), this.reconnectInterval);
        } else {
            console.log('Max reconnection attempts reached. Please check your connection and try again later.');
        }
    }

    public disconnect(): void {
        if (this.socket) {
            this.socket.close();
        }
    }

    public send(data: any): void {
        const message = this.prepareMessage(data);
        if (this.isConnected()) {
            this.sendMessage(message);
        } else {
            console.log('WebSocket is not connected. Queueing message for later send.');
            this.messageQueue.push(message);
            this.connect();
        }
    }

    private prepareMessage(data: any): string {
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        return JSON.stringify({ ...data, auth });
    }

    private sendMessage(message: string): void {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
        } else {
            throw new Error('WebSocket is not in OPEN state');
        }
    }

    private processPendingMessages(): void {
        while (this.messageQueue.length > 0) {
            const message = this.messageQueue.shift();
            if (message) {
                try {
                    this.sendMessage(message);
                    console.log('Pending message sent successfully');
                } catch (error) {
                    console.error('Failed to send pending message:', error);
                    this.messageQueue.unshift(message);
                    break;
                }
            }
        }
    }

    public addEventListener<K extends WebSocketEventType>(
        type: K,
        callback: (event: WebSocketEventMap[K]) => void
    ): void {
        this.listeners[type].add(callback as any);
    }

    public removeEventListener<K extends WebSocketEventType>(
        type: K,
        callback: (event: WebSocketEventMap[K]) => void
    ): void {
        this.listeners[type].delete(callback as any);
    }

    public isConnected(): boolean {
        return this.socket !== null && this.socket.readyState === WebSocket.OPEN;
    }
}

export default WebSocketService.getInstance();