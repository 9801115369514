export const ShowLogs = (props: any) => {
  if (props.api.logs.apis?.detail) {
    return (
      <span>
        Error:
        {props.api.logs.apis.detail[0].msg || props.api.logs.apis.detail} <br />
        {props.api.logs.apis.detail[0].loc
          ? `Err Location: ${props.api.logs.apis.detail[0].loc?.join(", ")}, Msg: ${props.api.logs.apis.detail[0].msg}, Type: ${props.api.logs.apis.detail[0].type}`
          : ""}
      </span>
    );
  }
  return <span>No Error</span>;
};
