import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  VStack,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import DashboardWrapper from '../../lib/components/Wrapper';
import { useNavigate } from 'react-router-dom';
import { SendRequest } from '../../utils/main';

interface OrganizationFormData {
  name: string;
  industry: string;
  website: string;
  description: string;
  contactEmail: string;
}

const NewOrganizationForm: React.FC = () => {
  const [name, setName] = useState('');
  const [industry, setIndustry] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  
  const handleSave = async () => {
    const data: OrganizationFormData = {
      name,
      industry,
      website,
      description,
      contactEmail,
    };
    // Handle saving the organization data
    if (!name) {
      toast({
        title: "Team name is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await SendRequest(
        `/api/v2/organization/new`,
        data,
        "POST"
      );
      if (response.data) {
        toast({
          title: "Organization created",
          description: "Your organization have been created.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate('/profile')
      } else {
        toast({
          title: "Organization creation Error",
          description: `Error Organization creation details:${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Organization creation Error",
        description:  `Network or server error:${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardWrapper>
      <Box maxW="md" mx="auto" mt="10">
        <Heading as='h3' size='lg' mb="6">
          Create New Organization
        </Heading>
        <VStack spacing={4} as="form">
          <FormControl id="name" isRequired>
            <FormLabel>Organization Name</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl id="industry">
            <FormLabel>Industry</FormLabel>
            <Input
              type="text"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            />
          </FormControl>
          <FormControl id="website">
            <FormLabel>Website</FormLabel>
            <Input
              type="url"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </FormControl>
          <FormControl id="description">
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Text as='i' fontSize='xs'>Briefly describe your organization's purpose and goals.</Text>
          </FormControl>
          <FormControl id="contactEmail" isRequired>
            <FormLabel>Contact Email</FormLabel>
            <Input
              type="email"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
          </FormControl>
          <Button 
              colorScheme="green" 
              onClick={handleSave} 
              isDisabled={isSubmitting}
              leftIcon={isSubmitting ? <Spinner size="sm" /> : undefined}
            >
              {isSubmitting ? 'Submitting...' : 'Create Organization'}
            </Button>
        </VStack>
      </Box>
    </DashboardWrapper>
  );
};

export default NewOrganizationForm;