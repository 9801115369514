import { Timeline, TimelineModel } from "animation-timeline-js";
// import { useEffect, useRef, useState } from "react";



import React, { useEffect, useMemo, useRef, useState } from 'react';
import { editorState, setCurrentTime, setSelectedComponent } from "../../stores/EditorStore";
import { useSnapshot } from "valtio";
import { cloneDeep } from "lodash";
// import { Timeline, TimelineModel } from 'animation-timeline-js';
// import { useRecoilState, useRecoilValue } from 'recoil';
// import { currentTimeState, timelineRowsState } from '../foundation/state';

import "./Timeline.css"
import { EditIcon, RepeatIcon } from "@chakra-ui/icons";
import { Box, HStack, Tooltip } from "@chakra-ui/react";
import { getSubString } from "../utils/Misc";

type Props = {
  time?: number;
  model: TimelineModel;
};

export function Timeline2(props: any) {
  // const [currentTime, setCurrentTime] = useRecoilState(currentTimeState);
  // const timelineRows = useRecoilValue(timelineRowsState);
  const {
    timelineRows,
    currentTime,
    editorProps,
    video
  } = useSnapshot(editorState);
  // const timelineRowsState = cloneDeep(timelineRows);


  const timelineElRef = useRef(null);
  const outlineHeaderRef = useRef(null);
  const outlineScrollContainerRef = useRef(null);
  const outlineContainerRef = useRef(null);
  const timelineRef = useRef<Timeline>();

  useEffect(() => {
    // react error workaround
    const onwheel = (e: any) => e.preventDefault();
    document.body.addEventListener('onwheel', onwheel, { passive: false });

    if (timelineElRef.current && timelineRows.length) {
      const timeline = new Timeline({ id: timelineElRef.current, stepVal: 1000, stepPx: 100, snapStep: 20, snapAllKeyframesOnMove: true, keyframesDraggable: true, timelineDraggable: true }, {
        rows: timelineRows,
      } as TimelineModel);
      const options = timeline.getOptions();
      timelineRef.current = timeline;

      if (outlineHeaderRef.current) {
        const outlineHeaderEl = outlineHeaderRef.current as HTMLElement;

        outlineHeaderEl.style.maxHeight = `${options.headerHeight}px` || outlineHeaderEl.style.maxHeight;
        outlineHeaderEl.style.minHeight = `${options.headerHeight}px` || outlineHeaderEl.style.minHeight;
      }

      timeline.onScroll((obj) => {
        if (options) {
          if (outlineScrollContainerRef.current) {
            const outlineScrollContainerEl = outlineScrollContainerRef.current as HTMLElement;

            outlineScrollContainerEl.scrollTop = obj.scrollTop || outlineScrollContainerEl.scrollTop;
          }

          if (outlineContainerRef.current) {
            const outlineContainerEl = outlineContainerRef.current as HTMLElement;

            outlineContainerEl.style.minHeight = `${obj.scrollHeight}px` || outlineContainerEl.style.minHeight;
          }
        }
      });

      timeline.onTimeChanged(({ val, source }) => {
        if (source !== 'setTimeMethod') {
          setCurrentTime(val / 1000);
          props.playerRef.current.seekTo(val / 1000 * video?.meta?.global?.fps || 30)
        }
      });
      timeline.onDragFinished((timeline: any) => {
        props.handleTimelineChange(timeline.target.row.title, timeline.target.row.keyframes)
        console.log({ timeline, asd: "d" })
      }
      )
    }

    if (props.playerRef?.current) {
      props.playerRef.current.addEventListener("frameupdate", (e: { detail: { frame: number; }; }) => {
        setCurrentTime(e.detail.frame / (video?.meta?.global?.fps || 30));
      });
    }

    // cleanup
    return () => {
      document.body.removeEventListener('onwheel', onwheel);
    };
  }, [timelineRows, setCurrentTime]);

  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.setTime(currentTime * 1000);
    }
  }, [currentTime]);

  const outlineMouseWheelHandler = (event: any) => {
    // workaround react issue
    // eslint-disable-next-line no-param-reassign
    event.preventDefault = () => { };

    if (timelineRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      timelineRef.current._handleWheelEvent(event);
    }
  };  

  const OutlineRows = useMemo(() => {
    const options = timelineRef.current?.getOptions();

    return timelineRows.map((row: any, index: number) => {
      const style = {
        minHeight: options?.rowsStyle?.height || 24,
        maxHeight: options?.rowsStyle?.height || 24,
        marginBottom: options?.rowsStyle?.marginBottom || 2,
      };
      if (["Img", "Video"].includes(row.type)) {
        return (
          <HStack spacing='0px' key={index}>
            <Tooltip label={`Edit ${row.type} Properties`} aria-label='Edit Properties'>
              <Box w='150px' >
                <div
                  className='outline-node hover:bg-zinc-500 [user-select:none]  text-white text-xs items-center flex'
                  style={style}
                  onClick={() => {
                    setSelectedComponent(row.title, editorProps[`${row.title}_name`], props.videoId);
                    props.propOpen();
                  }}
                ><EditIcon pr={1} />
                  {getSubString(editorProps[`${row.title}_name`] || `Track ${index}`, 20) }
                </div>
              </Box>
            </Tooltip>

            <Tooltip label={`Replace ${row.type}`} aria-label='Replace'>
              <Box w='40px' onClick={() => {
                setSelectedComponent(row.title, editorProps[`${row.title}_name`], props.videoId);
                props.openAsset(row.type);
              }}>
                <div className='outline-node hover:bg-zinc-500 [user-select:none]  text-white text-xs items-center flex' style={style}><RepeatIcon /></div>
              </Box>
            </Tooltip>

          </HStack>

        );
      }

      return (
        <HStack spacing='0px' key={index}>
          <Tooltip label={`Edit ${row.type} Properties`} aria-label='Edit Properties'>
            <Box w='190px' >
              <div
                className='outline-node hover:bg-zinc-500 [user-select:none]  text-white text-xs items-center flex'
                style={style}
                onClick={() => {
                  setSelectedComponent(row.title, editorProps[`${row.title}_name`], props.videoId);
                  props.propOpen();
                }}
              ><EditIcon pr={1} />
                {getSubString(editorProps[`${row.title}_name`] || `Track ${index}`, 20) }
              </div>
            </Box>
          </Tooltip>
        </HStack>
      );
    });
  }, [timelineRows]);

  return (
    <div className='timeline-container flex overflow-hidden bg-black'>
      <div className='outline flex flex-col overflow-hidden min-w-[150px] h-full items-stretch [align-content:stretch]'>
        <div className='outline-header min-h-[30px] bg-[#101011]' ref={outlineHeaderRef} />
        <div
          className='outline-scroll-container overflow-hidden'
          ref={outlineScrollContainerRef}
          onWheel={outlineMouseWheelHandler}
        >
          <div className='timeline outline-container w-full h-full' ref={outlineContainerRef}>
            {OutlineRows}
          </div>
        </div>
      </div>
      <div className='flex-1' style={{ width: "100%", minHeight: 300 }} ref={timelineElRef} />
    </div>
  );
}
