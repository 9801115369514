import {
  FormControl,
  FormLabel,
  Textarea,
  Tooltip,
  NumberInputField,
  NumberInput,
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Select,
  Switch,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { SendRequest } from "../../../utils/main";

export const TextArea = (props: any) => {
  const { id: key, value, handleChange, defaultValue } = props;
  return (
    <FormControl key={key}>
      <Tooltip label={value.description}>
        <FormLabel>{value.title}</FormLabel>
      </Tooltip>
      <Textarea
        placeholder={value.description}
        defaultValue={defaultValue}
        onChange={(e: any) => handleChange(e, key)}
      />
    </FormControl>
  );
};

export const NumberFormInput = (props: any) => {
  const { id: key, value, handleChange, defaultValue } = props;
  return (
    <FormControl key={key}>
      <Tooltip label={value.description}>
        <FormLabel>{value.title}</FormLabel>
      </Tooltip>

      <NumberInput
        defaultValue={defaultValue}
        min={value.minimum}
        max={value.maximum}
      >
        <NumberInputField onChange={(e) => handleChange(e, key)} />
      </NumberInput>
    </FormControl>
  );
};

export const SliderMarkComponent = (props: any) => {
  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  return (
    <Box pt={6} width={"full"}>
      <Tooltip label={props.description}>
        <FormLabel mb="6">{props.title}</FormLabel>
      </Tooltip>
      <Slider
        aria-label={props.description}
        onChange={(val) => props.updateSlider(val)}
      >
        <SliderMark value={25} {...labelStyles}>
          25%
        </SliderMark>
        <SliderMark value={50} {...labelStyles}>
          50%
        </SliderMark>
        <SliderMark value={75} {...labelStyles}>
          75%
        </SliderMark>
        <SliderMark
          value={props.sliderValue}
          textAlign="center"
          bg="blue.500"
          color="white"
          mt="-10"
          ml="-5"
          w="12"
        >
          {props.sliderValue}%
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Box>
  );
};

export const SliderFormInput = (props: any) => {
  const { id: key, value, handleChange, defaultValue } = props;
  return (
    <FormControl key={key}>
      <SliderMarkComponent
        sliderValue={defaultValue}
        updateSlider={handleChange}
        {...value}
      />
    </FormControl>
  );
};

export const EnumFormInput = (props: any) => {
  const { id: key, value, handleChange, defaultValue, enums } = props;
  return (
    <FormControl key={key}>
      <Tooltip label={value.description}>
        <FormLabel>{value.title}</FormLabel>
      </Tooltip>
      <Select defaultValue={defaultValue} onChange={handleChange}>
        {enums.map((en: string) => {
          return (
            <option value={en} key={en}>
              {en}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const Models11Input = (props: any) => {
  const { id: key, value, handleChange, defaultValue, enums } = props;
  return (
    <FormControl key={key}>
      <Tooltip label={value.description}>
        <FormLabel>{value.title}</FormLabel>
      </Tooltip>
      <Select defaultValue={defaultValue} onChange={handleChange}>
        {enums.map((en: any) => {
          return (
            <option value={en.model_id} key={en.model_id}>
              {en.name}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const Voices11Input = (props: any) => {
  const { id: key, value, handleChange, defaultValue, enums } = props;

  const [selectedVoice, setSelectedVoice] = useState<any>(enums[0]);
 

  const handleSelectChange = (e: any) => {
    const voice_id = e.target.value as string;
    const filteredVoice = enums.filter((e: any) => e.voice_id === voice_id);
    setSelectedVoice(filteredVoice[0]);
    handleChange(e, key);
  };
  return (
    <FormControl key={key}>
      <Tooltip label={value.description}>
        <FormLabel>{value.title}</FormLabel>
      </Tooltip>
      <Select defaultValue={defaultValue} onChange={handleSelectChange}>
        {enums.map((en: any) => {
          return (
            <option value={en.voice_id} key={en.voice_id}>
              {en.name} : {Object.keys(en.labels).map((t) => (
                <>{` ${en.labels[t]} `}</>
          ))}
            </option>
          );
        })}
      </Select>
      <Box mt={4}>
        {selectedVoice.high_quality_base_model_ids?.length >0 && <>Works best for {selectedVoice.high_quality_base_model_ids[0]}</>}

        {selectedVoice.preview_url && (
          <audio src={selectedVoice.preview_url} controls></audio>
        )}
        {/* <Image src={selectedImageUrl} alt="Selected" maxH="200px" /> */}
      </Box>
    </FormControl>
  );
};

export const SwitchFormInput = (props: any) => {
  const { id: key, value, handleChange, defaultValue } = props;
  return (
    <FormControl key={key} display="flex" alignItems="center">
      <Tooltip label={value.description}>
        <FormLabel mb="0">{value.title}</FormLabel>
      </Tooltip>
      <Switch
        id={key}
        onChange={(e) => handleChange(e, key)}
        defaultChecked={defaultValue}
      />
    </FormControl>
  );
};

export const ImageSelectorInput = (props: any) => {
  const { id: key, value, handleChange } = props;
  const [images, setImages] = useState<any>([]);
  const [filteredImages, setFilteredImages] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  // const Properties = schemaObj && Object.entries(schemaObj.properties);

  const handleSelectChange = (e: any) => {
    const url = e.target.value as string;
    setSelectedImageUrl(url);
    handleChange({ target: { value: url } }, key);
  };

  const handleSearchChange = (e: any) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    const filtered = images.filter((image: any) =>
      image.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredImages(filtered);
  };
  const handleImageSelect = (url: string) => {
    setSelectedImageUrl(url);
    handleChange({ target: { value: url } }, key);
  };

  const fetchAssets = async (type: string) => {
    return await SendRequest(`/api/v2/assets/me?type=${type}`, null, "GET");
  };

  useEffect(() => {
    // Simulate fetching images from an API or other sources
    fetchAssets("Img")
      .then((data: any) => {
        if (data.error) {
          return;
        }
        setImages(data.data.data.docs);
        setFilteredImages(data.data.data.docs);
      })
      .catch((e) => console.log(e, "ererrr"));
  }, []);
  return (
    <FormControl key={key}>
      <Tooltip label={value.description}>
        <FormLabel>{value.title}</FormLabel>
      </Tooltip>
      <Textarea
        placeholder={value.description}
        defaultValue={""}
        value={selectedImageUrl}
        onChange={(e: any) => {
          setSelectedImageUrl(e.target.value);
          handleChange(e, key);
        }}
      />
      <FormLabel>or Choose an image from your assets</FormLabel>
      {/* <Input
    placeholder="Search images by name"
    value={searchTerm}
    onChange={handleSearchChange}
  /> */}
      <SimpleGrid columns={10} mt={3}>
        {filteredImages.map((image: any, index: number) => (
          <Box
            key={index}
            p={2}
            boxShadow="md"
            borderRadius="md"
            cursor="pointer"
            onClick={() => handleImageSelect(image.url)}
          >
            <Image
              src={image.url}
              alt={image.name}
              boxSize="100%"
              objectFit="cover"
            />
            <Box mt={2} textAlign="center">
              {image.name}
            </Box>
          </Box>
        ))}
      </SimpleGrid>
      {selectedImageUrl && (
        <Box mt={4}>
          <FormLabel>Selected Image Preview:</FormLabel>
          <Image src={selectedImageUrl} alt="Selected" maxH="200px" />
        </Box>
      )}
    </FormControl>
  );
};

export const DropSelectorInput = (props: any) => {
  const { id: key, value, handleChange, options, preview } = props;
  const [selectedUrl, setSelectedUrl] = useState("");
  const [selectedPreview, setSelectedPreview] = useState("");
  // const Properties = schemaObj && Object.entries(schemaObj.properties);

  const handleSelectChange = (e: any) => {
    const url = e.target.value as string;
    setSelectedUrl(url);
    handleChange({ target: { value: url } }, key);
  };

  const handleSelect = (url: string, preview: string) => {
    setSelectedUrl(url);
    setSelectedPreview(preview);
    handleChange({ target: { value: url } }, key);
  };

  return (
    <FormControl key={key}>
      <Tooltip label={value.description}>
        <FormLabel>{value.title}</FormLabel>
      </Tooltip>
      <Textarea
        placeholder={value.description}
        defaultValue={""}
        value={selectedUrl}
        onChange={(e: any) => {
          setSelectedUrl(e.target.value);
          handleChange(e, key);
        }}
      />
      <FormLabel>or Choose one from below</FormLabel>
      {/* <Input
    placeholder="Search images by name"
    value={searchTerm}
    onChange={handleSearchChange}
  /> */}
      <SimpleGrid columns={5} mt={3}>
        {options.map((opt: any, index: number) => (
          <Box
            key={index}
            p={2}
            boxShadow="md"
            borderRadius="md"
            cursor="pointer"
            onClick={() => handleSelect(opt.url, opt.view)}
          >
            {" "}
            {preview === "img" && (
              <Image
                src={opt.view}
                alt={opt.name}
                boxSize="100%"
                objectFit="cover"
              />
            )}
            {preview === "vid" && <video src={opt.view} />}
            <Box mt={2} textAlign="center">
              {opt.name}
            </Box>
          </Box>
        ))}
      </SimpleGrid>
      {selectedUrl && (
        <Box mt={4}>
          <FormLabel>Selected Preview:</FormLabel>
          {preview === "img" && (
            <Image src={selectedPreview} boxSize="100%" objectFit="cover" />
          )}
          {preview === "vid" && <video src={selectedPreview} />}
        </Box>
      )}
    </FormControl>
  );
};
