import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  DrawerFooter,
} from "@chakra-ui/react";
import { useSnapshot } from "valtio";
import {
  storyState,
  handleSceneEdit,
  handleDialogueChange,
  deleteDialogue,
  addDialogue,
} from "../../stores/StoryStore";


const EditScene = (props: any) => {
  const { editingScene, scenes } = useSnapshot(storyState);

  const saveScenes = () => {
    props.saveExpScenes(scenes);
  };
  return (
    <>
      <Drawer
        isOpen={props.editSceneIsOpen}
        placement="right"
        //   initialFocusRef={firstField}
        onClose={props.editSceneOnClose}
        size={"xl"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Edit Scene:{editingScene + 1}
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing="24px">
              <label className="label">
                <span className="label-text">Description</span>
              </label>
              <textarea
                className="textarea textarea-bordered w-full"
                value={scenes[editingScene]?.scene_description}
                onChange={(e) =>
                  handleSceneEdit(e.target.value, "scene_description")
                }
              />

              <label className="label">
                <span className="label-text">Visual Details</span>
              </label>
              <textarea
                className="textarea textarea-bordered w-full"
                value={scenes[editingScene]?.visual_details}
                onChange={(e) =>
                  handleSceneEdit(e.target.value, "visual_details")
                }
              />

              <label className="label">
                <span className="label-text">Audio Details</span>
              </label>
              <textarea
                className="textarea textarea-bordered w-full"
                value={scenes[editingScene]?.audio_details}
                onChange={(e) =>
                  handleSceneEdit(e.target.value, "audio_details")
                }
              />

              <div>
                <h4 className="text-lg my-2">Dialogues</h4>
                {scenes[editingScene]?.dialogues.map(
                  (dialogue: any, index: any) => (
                    <div key={index}>
                      <label className="label">
                        <span className="label-text">Speaker</span>
                      </label>
                      <input
                        className="input input-bordered w-full mb-2"
                        value={dialogue.speaker}
                        onChange={(e) =>
                          handleDialogueChange(index, "speaker", e.target.value)
                        }
                      />

                      <label className="label">
                        <span className="label-text">Line</span>
                      </label>
                      <input
                        className="input input-bordered w-full mb-2"
                        value={dialogue.line}
                        onChange={(e) =>
                          handleDialogueChange(index, "line", e.target.value)
                        }
                      />

                      <button
                        className="btn btn-error btn-xs mb-2"
                        onClick={() => deleteDialogue(index)}
                      >
                        Delete Dialogue
                      </button>
                    </div>
                  )
                )}
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={addDialogue}
                >
                  Add Dialogue
                </button>
              </div>
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={props.editSceneOnClose}>
              Close
            </Button>
            <Button
              colorScheme={"green"}
              // disabled={!sceneReady}
              onClick={saveScenes}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default EditScene;
