import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useColorModeValue,
  useDisclosure,
  Button,
  Grid,
  useToast,
  VStack,
  Container,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import "../Tailwind.css";
import { wssurl } from "../lib/utils/Misc";
import { handleShowAlert } from "./Signup";
import EditScene from "../lib/modals/EditScene";
import EditSceneProps from "../lib/modals/EditSceneProps";
import EditPipeline from "../lib/modals/EditPipeline";
import { SidebarContent } from "../lib/components/Sidebar";
import { useSnapshot } from "valtio";
import {
  addScene,
  deleteScene,
  setEditingScene,
  setShowLoader,
} from "../stores/StoryStore";
import {
  setActiveIndex,
  setExp,
  setScenes,
  storyState,
  updateRuns,
  setModelEditing,
  setAPISelected,
  setSceneSelected,
  setSearchQuery,
  setSceneSettings,
} from "../stores/StoryStore";
import { GridBox } from "../lib/components/Gridbox";
import { ApiTable } from "../lib/components/ApiTable";

import { CiEdit } from "react-icons/ci";
import { ImMoveDown, ImMoveUp } from "react-icons/im";
import { MdDeleteForever } from "react-icons/md";
import { GrDuplicate } from "react-icons/gr";
import { IoIosAddCircleOutline } from "react-icons/io";
import { HeadContent } from "../lib/components/Headcontent";
import { sendMessage } from "../lib/utils/EditorUtil";
import { useWebSocket } from "../lib/components/WebSocketContext";
import DashboardWrapper from "../lib/components/Wrapper";

const pallet = ["#00E3CC", "#32A89C", "#009688", "#44E3D3", "#00635A"];
const getRandomColor = (index: number) => {
  if (index < pallet.length - 1) {
    return pallet[index];
  }
  const shade = pallet[Math.floor(Math.random() * pallet.length)];
  return shade;
};

const Storyboard: React.FC = () => {
  const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
  let { experimentId = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    exp,
    searchQuery,
    sceneSettings,
    sceneSelected,
    APISelected,
    runs,
    apis,
    activeIndexes,
    modelEditing,
    scenes,
  } = useSnapshot(storyState);

  const {
    isOpen: editScenePropsIsOpen,
    onOpen: editScenePropsOpen,
    onClose: editScenePropsOnClose,
  } = useDisclosure();

  const {
    isOpen: editSceneIsOpen,
    onOpen: editSceneOpen,
    onClose: editSceneOnClose,
  } = useDisclosure();

  const {
    isOpen: editPipelineIsOpen,
    onOpen: editPipelineOpen,
    onClose: editPipelineOnClose,
  } = useDisclosure();

  useEffect(() => {
    document.title = "Crackviral | Generate Video Pipeline";
  }, []);

  useEffect(() => {
    addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);

  const sidebar = useDisclosure();
  const integrations = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");

  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef<any>(null);

  // Start the timer
  const startSync = () => {
    if (!isActive && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        SyncUpdates();
      }, 10000);
      setIsActive(true);
    }
  };

  // Stop the timer
  const stopSync = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setIsActive(false);
    }
  };

  useEffect(() => {
    // Clean up the interval on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);
  // useEffect(() => {
  //   // Set up the interval
  //   const intervalId = setInterval(props.syncWithServer, 10000);

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);

  // Listen for messages from the WebSocket server
  const handleMessage = async (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status) {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "experiment":
        console.log("Experiment:", message.data);
        setExp(message.data, message.data.out.content.scenes);
        // setScenes(message.data.out.content.scenes);
        break;
      case "run":
        console.log("Experiment Running:", message.data);
        handleShowAlert(
          "status",
          "Status",
          `Generation Job Started`
        );
        // setExp(message.data);
        break;
      case "sceneFull":
        console.log("experiment obj:", message.experimentObj);
        setScenes(message.experimentObj.out.content.scenes);
        break;
      case "runs":
        updateRuns(message.data.runs, message.data.apis);
        // setApis(message.data.exp);
        console.log(message.data);
        break;
      case "error":
        console.log("Error video:", message.message, event);
        toast({
          title: `Error video:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      case "video":
        if (message.video) {
          return navigate("/video/" + (message.video.id || message.video._id));
        }
        break;
      default:
        break;
    }
  };

  function loadExperiment() {
    const payload = {
      action: "getExperiment",
      id: experimentId,
    };
    send(payload);
  }

  function getRunsByExp() {
    const payload = {
      action: "getRunsByExp",
      id: experimentId,
    };
    send(payload);
  }
  function saveExpPipeline(data: any) {
    const payload = {
      action: "saveExpPipeline",
      id: experimentId,
      data,
    };
    send(payload);
  }

  function runExpPipeline(data: any) {
    const payload = {
      action: "runExpPipeline",
      id: experimentId,
      data,
    };
    send(payload);
  }

  function saveExpScenes(data: any) {
    const payload = {
      action: "saveExpScenes",
      id: experimentId,
      data,
    };
    send(payload);
  }

  function SyncUpdates() {
    loadExperiment();
    getRunsByExp();
  }

  // const SyncComponent = (props: any) => {
  //   const [data, setData] = useState(null);

  //   return <></>;
  // };

  const SceneComponent = (props: any) => {
    return (
      <Box as="main" p="4">
        {props.scene.dialogues.length === 0 && (
          <Grid
            // templateColumns="repeat(2, 1fr)"
            gap={6}
            p={4}
            backgroundColor={color}
          >
            <GridBox
              p={2}
              exp={exp}
              sceneIndex={props.sceneIndex}
              diagIndex={-1}
              editScenePropsIsOpen={editScenePropsIsOpen}
              editScenePropsOpen={editScenePropsOpen}
              editScenePropsOnClose={editScenePropsOnClose}
              editPipelineIsOpen={editPipelineIsOpen}
              editPipelineOpen={editPipelineOpen}
              editPipelineOnClose={editPipelineOnClose}
              runExpPipeline={runExpPipeline}
              setShowLoader={setShowLoader}
              startSync={startSync}
            />
            <ApiTable
              APISelected={APISelected}
              setAPISelected={setAPISelected}
              exp={exp}
              runs={runs}
              apis={apis}
              sceneIndex={props.sceneIndex}
              diagIndex={-1}
              editPipelineIsOpen={editPipelineIsOpen}
              editPipelineOpen={editPipelineOpen}
              editPipelineOnClose={editPipelineOnClose}
              runExpPipeline={runExpPipeline}
              sceneSelected={sceneSelected}
              setSceneSelected={setSceneSelected}
            />
          </Grid>
        )}
        {props.scene.dialogues.length > 0 &&
          props.scene.dialogues.map((diag: any, diagIndex: number) => {
            return (
              <Grid
                // templateColumns="repeat(6, 1fr)"
                gap={6}
                p={4}
                backgroundColor={color}
                key={diagIndex}
              >
                <GridBox
                  rowSpan={2}
                  exp={exp}
                  {...diag}
                  sceneIndex={props.sceneIndex}
                  diagIndex={diagIndex}
                  editScenePropsIsOpen={editScenePropsIsOpen}
                  editScenePropsOpen={editScenePropsOpen}
                  editScenePropsOnClose={editScenePropsOnClose}
                  setActiveIndex={setActiveIndex}
                  editPipelineIsOpen={editPipelineIsOpen}
                  editPipelineOpen={editPipelineOpen}
                  editPipelineOnClose={editPipelineOnClose}
                  runExpPipeline={runExpPipeline}
                  setShowLoader={setShowLoader}
                  startSync={startSync}
                />
                <ApiTable
                  APISelected={APISelected}
                  setAPISelected={setAPISelected}
                  rowSpan={4}
                  exp={exp}
                  runs={runs}
                  apis={apis}
                  sceneIndex={props.sceneIndex}
                  diagIndex={diagIndex}
                  editPipelineIsOpen={editPipelineIsOpen}
                  editPipelineOpen={editPipelineOpen}
                  editPipelineOnClose={editPipelineOnClose}
                  runExpPipeline={runExpPipeline}
                  sceneSelected={sceneSelected}
                  setSceneSelected={setSceneSelected}
                />
              </Grid>
            );
          })}
      </Box>
    );
  };

  const sceneReady = () => {
    let isReady = true;
    console.log(scenes, "scenes");

    scenes.map((scene: any, sceneIndex: number) => {
      const { dialogues } = scene;
      dialogues.map((diag: any, diagIndex: number) => {
        const indexesToCheck = [0, 1, 2].map(
          (key: number) => `${sceneIndex},${diagIndex},${key}`
        );
        for (let index = 0; index < indexesToCheck.length; index++) {
          const indexString = indexesToCheck[index];
          if (!exp.meta.configs[indexString]) {
            isReady = false;
          }
        }
      });
    });
    return isReady;
  };

  function generateScenes() {
    const isReady = sceneReady();
    console.log(isReady, "isReady");

    if (!isReady) {
      return handleShowAlert(
        "error",
        "Error",
        "Need to update all the scenes before generating."
      );
    }

    const payload = {
      action: "generateScenes",
      id: experimentId,
    };
    send(payload);
  }

  function importAPIs() {
    // const isReady = sceneReady();
    // console.log(isReady, "isReady");

    if (APISelected.length === 0) {
      return handleShowAlert(
        "error",
        "Error",
        "Need to select asset to import into the editor."
      );
    }
    //TODO - add ready test

    const payload = {
      action: "importAPIs",
      id: experimentId,
      data: APISelected,
    };
    setAPISelected([]);
    send(payload);
  }

  const handleSearch = () => {
    console.log("Search Query", searchQuery);
  };
  const handleOpen = () => {
    loadExperiment();
    getRunsByExp();
  };


  return (
    <DashboardWrapper>
        <HeadContent
          sidebar={sidebar}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
          storyboard={exp.video}
          hideSearch={true}
        />
        <VStack>
          {exp.out?.content?.scenes?.map((scene: any, sceneIndex: any) => {
            return (
              <Container
                maxW="full"
                bg="gray.200"
                color="#262626"
                key={sceneIndex}
              >
                <div style={{paddingTop: "10px"}}><Button
                  outline={"solid"}
                  p={1}
                  mx={1}
                  onClick={(e) => {
                    e.preventDefault();
                    setEditingScene(sceneIndex);
                    editSceneOpen();
                  }}
                >
                  # {sceneIndex + 1} &nbsp;
                </Button>
                <Button
                  outline={"solid"}
                  p={1}
                  mx={1}
                  onClick={(e) => {
                    e.preventDefault();
                    setEditingScene(sceneIndex);
                    editSceneOpen();
                  }}
                >
                  <CiEdit />
                </Button>

                {/* <Button
                  outline={"solid"}
                  p={1}
                  mx={1}
                  onClick={(e) => {
                    e.preventDefault();
                    addScene(scene, sceneIndex, saveExpScenes);
                  }}
                >
                  <IoIosAddCircleOutline />
                </Button> */}

                <Button
                  outline={"solid"}
                  p={1}
                  mx={1}
                  onClick={(e) => {
                    e.preventDefault();
                    addScene(scene, sceneIndex, saveExpScenes);
                  }}
                >
                  <GrDuplicate />
                </Button>
                {/* <Button
                  outline={"solid"}
                  p={1}
                  mx={1}
                  onClick={(e) => {
                    e.preventDefault();
                    moveSceneUp(scene, saveExpScenes);
                  }}
                >
                  <ImMoveUp></ImMoveUp>
                </Button>
                <Button
                  outline={"solid"}
                  p={1}
                  mx={1}
                  onClick={(e) => {
                    e.preventDefault();
                    moveSceneDown(scene, saveExpScenes);
                  }}
                >
                  <ImMoveDown></ImMoveDown>
                </Button> */}
                <Button
                  outline={"solid"}
                  p={1}
                  mx={1}
                  onClick={(e) => {
                    e.preventDefault();
                    deleteScene(sceneIndex, saveExpScenes);
                  }}
                >
                  <MdDeleteForever></MdDeleteForever>
                </Button></div>

                {/* <Badge ml="1" colorScheme="green">
                  
                </Badge> */}

                <br />
                <Box p="2">
                  <b>Scene Details</b>: {scene.scene_description}
                  <br />
                  <b>Visual Details</b>: {scene.visual_details}
                  <br />
                  <b> Audio Details</b>: {scene.audio_details}
                </Box>
                <SceneComponent scene={scene} sceneIndex={sceneIndex} />
              </Container>
            );
          })}
        </VStack>
      {exp.video && (
        <Button
          // isLoading
          style={{ position: "fixed", bottom: "10px", right: "470px" }}
          loadingText="Generating Scenes"
          colorScheme="orange"
          // variant="outline"
          spinnerPlacement="start"
          onClick={() => {
            navigate(`/video/${exp.video}`);
          }}
        >
          Editor
        </Button>
      )}
      <Button
        // isLoading
        style={{ position: "fixed", bottom: "10px", right: "10px" }}
        loadingText="Import Scenes"
        colorScheme="teal"
        // variant="outline"
        spinnerPlacement="start"
        onClick={() => {
          importAPIs();
        }}
      >
        Import Selected Components
      </Button>
      <Button
        // isLoading
        style={{ position: "fixed", bottom: "10px", right: "270px" }}
        loadingText="Generating Scenes"
        colorScheme="green"
        // variant="outline"
        spinnerPlacement="start"
        onClick={() => {
          if (intervalRef.current) {
            stopSync();
          } else {
            startSync();
          }
          // SyncUpdates();
        }}
      >
        {intervalRef.current && "Stop Automatic Sync"}
        {!intervalRef.current && "Start Automatic Sync"}
      </Button>
      <EditSceneProps
        editScenePropsIsOpen={editScenePropsIsOpen}
        editScenePropsOpen={editScenePropsOpen}
        editScenePropsOnClose={editScenePropsOnClose}
        activeIndexes={activeIndexes}
        setActiveIndex={setActiveIndex}
        editPipelineOpen={editPipelineOpen}
        exp={exp}
        modelEditing={modelEditing}
        setModelEditing={setModelEditing}
        sceneSettings={sceneSettings}
        setSceneSettings={setSceneSettings}
      />
      <EditScene
        editSceneIsOpen={editSceneIsOpen}
        editSceneOpen={editSceneOpen}
        editSceneOnClose={editSceneOnClose}
        saveExpScenes={saveExpScenes}
      />
      <EditPipeline
        size="md"
        editPipelineIsOpen={editPipelineIsOpen}
        editPipelineOpen={editPipelineOpen}
        editPipelineOnClose={editPipelineOnClose}
        activeIndexes={activeIndexes}
        setActiveIndex={setActiveIndex}
        exp={exp}
        saveExpPipeline={saveExpPipeline}
        modelEditing={modelEditing}
        setModelEditing={setModelEditing}
        handleOpen={handleOpen}
      />
    </DashboardWrapper>
  );
};

export default Storyboard;

function indexToModel(index: string) {
  switch (index[2]) {
    case "0":
      return "Video/Image";
    case "1":
      return "Dialogue";
    case "2":
      return "Background Music";
    default:
      break;
  }
}