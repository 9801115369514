import React, { useEffect, useState } from "react";
import {
    Box,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import "../Tailwind.css";
import { useAuth } from "../components/lib/useAuth";
import { useSnapshot } from "valtio";
import {
    editorState,
    setBulkProps,
    setCredit,
    setVideoOnly,
} from "../stores/EditorStore";
import { HeadContent } from "../lib/components/Headcontent";
import { useWebSocket } from "../lib/components/WebSocketContext";
import DashboardWrapper from "../lib/components/Wrapper";

import { cloneDeep } from "lodash";
import { Resolution } from "../lib/modals/DisplaySwicherModal";
import { createVideo, loadVideo } from "../lib/utils/EditorUtil";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { SendRequest } from "../utils/main";
import MatrixUI from "../modules/excel/MatrixUI2";

function updateScreenSize(width: number, height: number, video: any, setNewVideo: Function) {
    const videoPayload = {
        meta: { global: {} },
        ...cloneDeep(video),
    };
    videoPayload.meta.global.width = width;
    videoPayload.meta.global.height = height;
    setNewVideo(videoPayload);
}




const ExcelEditor: React.FC = () => {
    // const wsRef = useRef<WebSocket | null>(null);
    const {
        video,
        newVideo,
        creating,
        showLoader,
    } = useSnapshot(editorState);
    const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
    const [brands, setBrands] = useState<any>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    let { videoId = "" } = useParams();

    let stoastId: any = null;

    useEffect(() => {
        addEventListener('open', handleOpen);
        addEventListener('message', handleMessage);

        return () => {
            removeEventListener('open', handleOpen);
            removeEventListener('message', handleMessage);
        };
    }, [addEventListener, removeEventListener]);

    useEffect(() => {
        document.title = "Crackviral | Bulk Video Editor";
    }, []);


    const sidebar = useDisclosure();
    const navigate = useNavigate();
    const toast = useToast();
    const [auth, error, loading] = useAuth();
    const color = useColorModeValue("gray.600", "gray.300");

    // Listen for messages from the WebSocket server
    const handleMessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data);
        console.log("message", message, message.true);

        if (!message.status) {
            toast({
                title: `Error video:${message.message}`,
                position: "top-right",
                variant: "top-accent",
                isClosable: true,
            });
            if (message.action === "auth") {
                navigate("/login");
            }
            return;
        }

        switch (message.action) {
            case "video":
                setVideoOnly(message.video);
                break;
            case "credits":
                const { balance, validUpto, plan } = message.data;
                setCredit(balance, validUpto, plan);
                break;
            case "error":
                console.log("Error video:", message.message, event);
                break;
            case "variations":
                setBulkProps(message.data, message.video);
                break;
            case "export":
                const results = message.data;
                results.forEach((result: { status: string; message: string; }) => {
                    toast({
                        title: `${result.status}:${result.message}`,
                        position: "top-right",
                        variant: "top-accent",
                        isClosable: true,
                    });
                });
                break;
            case "error":
                console.log("Error video:", message.message, event);
                toast({
                    title: `Error video:${message.message}`,
                    position: "top-right",
                    variant: "top-accent",
                    isClosable: true,
                });
                break;
            default:
                break;
        }
    };


    function loadBrands() {
        const payload = {
            action: "loadBrands",
        };
        send(payload);
    }

    function submitAuth() {
        const payload = {
            action: "auth",
            data: localStorage.getItem("auth"),
        };
        send(payload);
    }

    function generateScenes(meta: any) {
        const payload = {
            action: "storyToScenes",
            data: meta,
        };
        send(payload);
    }
    const createVideoWS = (obj: any) => {
        const payload = {
            action: "createVideo",
            video: {
                title: obj.title,
                url: obj.url,
                description: obj.description,
                meta: {
                    ai: { details: obj.details },
                    props: { height: obj.meta.props.height, width: obj.meta.props.width },
                },
            },
        };
        send(payload);
    };
    const handleOpen = () => {
        if (!stoastId) {
            stoastId = toast({
                title: `Connected`,
                position: "top-right",
                variant: "solid",
                isClosable: true,
                onCloseComplete: () => {
                    stoastId = null;
                },
            });
        }
        send({ action: "getVariations", id: videoId });
        send({ action: "getVideo", id: videoId });
        send({ action: "credits" });
    };


    return (
        <DashboardWrapper><HeadContent
            sidebar={sidebar}
        // searchQuery={searchQuery}
        // setSearchQuery={setSearchQuery}
        // handleSearch={handleSearch}
        />
            <Box as="main" p="4">
                <MatrixUI />
            </Box></DashboardWrapper>
    );
};

export default ExcelEditor;