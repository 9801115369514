import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { editorState } from "../../stores/EditorStore";
import { useSnapshot } from "valtio";

export interface Resolution {
  width: number;
  height: number;
  title: string;
  description: string;
  tags: string[];
}

export const DisplaySwicherModal: FC<{
  shortcutIsOpen: boolean;
  shortcutOnClose: () => void;
  updateScreenSize: (height: number, width: number) => void;
  video: any;
}> = ({ shortcutIsOpen, shortcutOnClose, video, updateScreenSize }) => {
  // const [resolution, setResolution] = useState<Resolution>({
  //   width: video?.meta.global.width,
  //   height: video?.meta.global.height,
  //   title: "Full HD",
  //   description: "1080p resolution",
  //   tags: ["full", "large"],
  // });
  const { resolution, isVertical } = useSnapshot(editorState);

  const [customWidth, setCustomWidth] = useState<string>("");
  const [customHeight, setCustomHeight] = useState<string>("");
  // const [isHorizontal, setIsHorizontal] = useState<boolean>(
  //   video?.meta.global.height < video?.meta.global.width
  // );

  const defaultResolutions: Resolution[] = [
    {
      width: 640,
      height: 360,
      title: "SD",
      description: "360p resolution",
      tags: ["standard", "medium"],
    },
    {
      width: 854,
      height: 480,
      title: "SD",
      description: "480p resolution",
      tags: ["standard", "medium"],
    },
    {
      width: 1280,
      height: 720,
      title: "HD",
      description: "720p resolution",
      tags: ["high", "large"],
    },
    {
      width: 1920,
      height: 1080,
      title: "Full HD",
      description: "1080p resolution",
      tags: ["full", "large"],
    },
    {
      width: 2560,
      height: 1440,
      title: "QHD",
      description: "1440p resolution",
      tags: ["quad", "extra-large"],
    },
    {
      width: 3840,
      height: 2160,
      title: "4K",
      description: "2160p resolution",
      tags: ["ultra", "extra-large"],
    },
  ];

  const handleResolutionChange = (res: Resolution) => {
    // setResolution(res);
    console.log(`Resolution changed to: ${res.width}x${res.height}`);
    if (isVertical) {
      updateScreenSize(res.width, res.height);
    } else {
      updateScreenSize(res.height, res.width);
    }
  };

  const handleAddResolution = () => {
    const width = parseInt(customWidth, 10);
    const height = parseInt(customHeight, 10);
    if (isVertical) {
      updateScreenSize(width, height);
    } else {
      updateScreenSize(height, width);
    }
  };

  const toggleOrientation = () => {
    // setIsHorizontal(!isHorizontal);
    if (!isVertical) {
      updateScreenSize(resolution.width, resolution.height);
    } else {
      updateScreenSize(resolution.height, resolution.width);
    }
  };
  if (!resolution) {
    return <></>;
  }

  return (
    <Modal isOpen={shortcutIsOpen} onClose={shortcutOnClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Video Resolution</ModalHeader>
        <ModalCloseButton />
        <ModalBody></ModalBody>
        <ModalFooter>
          <div className="artboard ">
            <div className="p-4">
              <h2 className="text-xl font-bold mb-4">
                Current Video Resolution :{" "}
                {resolution.height}H x {resolution.width}W
              </h2>
              <div className="flex flex-wrap gap-4 mb-4">
                {defaultResolutions.map((res) => {
                  let selected = false;
                  if (isVertical) {
                    selected =
                      resolution.width === res.height &&
                      resolution.height === res.width;
                  } else {
                    selected =
                      resolution.width === res.width &&
                      resolution.height === res.height;
                  }
                  return (
                    <div
                      key={`${res.width}x${res.height}`}
                      onClick={() => handleResolutionChange(res)}
                      className={`border rounded-md p-4 cursor-pointer shadow-md ${
                        selected
                          ? "bg-blue-500 text-white"
                          : "bg-white text-gray-800 hover:bg-gray-100"
                      }`}
                    >
                      <h3 className="text-lg font-bold mb-2">{res.title}</h3>
                      <p className="mb-2">{res.description}</p>
                      <p className="mb-2">
                        {isVertical
                          ? `${res.width}x${res.height}`
                          : `${res.height}x${res.width}`}
                      </p>

                      <div className="flex flex-wrap gap-1 text-black">
                        {res.tags.map((tag, index) => (
                          <span
                            key={index}
                            className="px-2 py-1 bg-gray-200 rounded-md text-xs"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mb-4">
                <input
                  type="number"
                  value={customWidth}
                  onChange={(e) => setCustomWidth(e.target.value)}
                  placeholder="Width"
                  className="px-4 py-2 border rounded-md mr-2"
                />
                <input
                  type="number"
                  value={customHeight}
                  onChange={(e) => setCustomHeight(e.target.value)}
                  placeholder="Height"
                  className="px-4 py-2 border rounded-md mr-2"
                />
                <button
                  onClick={handleAddResolution}
                  className="px-4 py-2 bg-green-500 text-white rounded-md"
                >
                  Update
                </button>
              </div>
              <div>
                <button
                  onClick={toggleOrientation}
                  className="px-4 py-2 bg-purple-500 text-white rounded-md"
                >
                  Switch to {!isVertical ? "Vertical" : "Horizontal"}{" "}
                </button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
