import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  useColorModeValue,
  useDisclosure,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import "../Tailwind.css";
import { wssurl } from "../lib/utils/Misc";
import { NewVideoModal } from "../lib/modals/NewVideo";
import { useAuth } from "../components/lib/useAuth";
import { useSnapshot } from "valtio";
import { editorState, setCurrentCreating } from "../stores/EditorStore";
import { HeadContent } from "../lib/components/Headcontent";
import { sendMessage } from "../lib/utils/EditorUtil";
import DashboardWrapper from "../lib/components/Wrapper";
import { useWebSocket } from "../lib/components/WebSocketContext";

const Assets: React.FC = () => {
  const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
  const [assets, setAssets] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAssets, setFilteredAssets] = useState(assets);
  const navigate = useNavigate();
  const toast = useToast();
  const [auth, error, loading] = useAuth();
  const {
    video,
    newVideo: videoNew,
    creating,
    showLoader,
  } = useSnapshot(editorState);

  useEffect(() => {
    document.title = "Crackviral | Assets";

  }, []);

  useEffect(() => {
    addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);


  const sidebar = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
  const {
    isOpen: videoIsOpen,
    onOpen: videoOpen,
    onClose: videoOnClose,
  } = useDisclosure();

  const {
    isOpen: generateIsOpen,
    onOpen: generateOpen,
    onClose: generateOnClose,
  } = useDisclosure();

  // Listen for messages from the WebSocket server
  const handleMessage = async (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status) {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "assets":
        console.log("Updated video:", message.data);
        setAssets(message.data);
        break;
      case "error":
        console.log("Error video:", message.message, event);
        toast({
          title: `Error video:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };
  const handleSearch = () => {
    if (searchQuery !== null && searchQuery !== undefined) {
      const query = searchQuery.toLowerCase();
      const filtered = assets.filter((asset) => {
        if (asset?.title && asset?.description) {
          return (
            asset.title.toLowerCase().includes(query) ||
            asset.description.toLowerCase().includes(query)
          );
        }
        if (asset?.title) {
          return asset.title.toLowerCase().includes(query);
        }
        if (asset?.description) {
          return asset.description.toLowerCase().includes(query);
        }
        if (!asset?.title && !asset?.description) {
          return;
        }
      });
      setFilteredAssets(filtered);
    }
  };

  function loadAssets() {
    const payload = {
      action: "getAssets",
    };
    send(payload);
  }

  function submitAuth() {
    const payload = {
      action: "auth",
      data: localStorage.getItem("auth"),
    };
    send(payload);
  }

  function generateScenes(meta: any) {
    const payload = {
      action: "storyToScenes",
      data: meta,
    };
    send(payload);
  }
  const createVideo = (obj: any) => {
    const payload = {
      action: "createVideo",
      video: {
        title: obj.title,
        url: obj.url,
        description: obj.description,
        meta: {
          ai: { details: obj.details },
          props: { height: obj.meta.props.height, width: obj.meta.props.width },
        },
      },
    };
    send(payload);
  };
  const handleOpen = () => {
    submitAuth();
    loadAssets();
  };
  function newVideo() {
    switch (creating) {
      case 0:
        console.log("VIDEO :", video);
        createVideo(video);
        break;

      default:
        break;
    }
    videoOnClose();
  }

  return (
    <DashboardWrapper>
        <HeadContent
          sidebar={sidebar}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
        />
        <Box as="main" p="4">
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" />
                    </label>
                  </th>
                  <th>Preview</th>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredAssets?.map((asset, index) => {
                  return (
                    <tr key={index}>
                      <th>
                        <label>
                          <input type="checkbox" className="checkbox" />
                        </label>
                      </th>
                      <th>
                        {asset.type === "Img" && (
                          <div className="mask mask-squircle w-20 h-20">
                            <img src={asset.url} alt={asset.title} />
                          </div>
                        )}
                        {asset.type === "Video" && (
                          <div className="mask mask-squircle w-20 h-20">
                            <video src={asset.url} />
                          </div>
                        )}
                      </th>
                      <td>
                        <div className="text-sm ">{asset.title}</div>
                      </td>
                      <td>
                        Not available
                        <br />
                        {/* <span className="badge badge-ghost badge-sm">Desktop Support Technician</span> */}
                      </td>
                      <td>{asset.type}</td>
                      
                    </tr>
                  );
                })}
              </tbody>
              {/* foot */}
            </table>
          </div>
          <NewVideoModal
            videoIsOpen={videoIsOpen}
            videoOnClose={videoOnClose}
            setCurrentCreating={setCurrentCreating}
            creating={creating}
            newVideo={videoNew}
            auth={auth}
            generateScenes={generateScenes}
            generateOpen={generateOpen}
          />

          {showLoader && (
            <CircularProgress
              isIndeterminate
              color="green.300"
              size={"xl"}
              style={{ left: "30%", position: "absolute", top: "30%" }}
            />
          )}
        </Box>
        </DashboardWrapper>
  );
};

export default Assets;
