import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box, Button, Input, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { HeadContent } from "../../lib/components/Headcontent";
import DashboardWrapper from "../../lib/components/Wrapper";
import { setSearchQuery } from "../../stores/StoryStore";
import { SendRequest } from "../../utils/main";
import { useNavigate } from "react-router-dom";

export const stripeKey = process.env.STRIPE_PUB_KEY || "pk_live_51PBKXaP2ZyCSITkKyQx1u7Dx690sL3DdVHGHbvzy57t3ff4tB18lbo2ybH2h0vRie3bTo9jfv1tyyde2tf8lyqZL00IATUziUw"; 


function CheckoutForm() {
  
  // collect data from the user
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [priceId, setPriceId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  // stripe items
  const stripe = useStripe();
  const elements = useElements();
  const sidebar = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  // main function
  const createSubscription = async () => {
    try {
      
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement)!,
        billing_details: {
          name,
          email,
        },
      });
      if (paymentMethod?.error) {
        toast({
          title: `Payment Error: ${paymentMethod?.error.code}`,
          description: paymentMethod?.error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      console.log({paymentMethod});
      

      // call the backend to create subscription
      const response = await SendRequest("/api/v2/payment/subscription/create", {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name,
        email,
        // priceId
      },"POST");
      console.log({response, inte: response.data.invoice, sec:response.data.invoice.payment_intent});
      
      const confirmPayment = await stripe?.confirmCardPayment(
        response.data.invoice.payment_intent.client_secret
      );

      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {
        alert("Success! Check your email for the invoice.");
        navigate('/profile')
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardWrapper>
    <HeadContent
      sidebar={sidebar}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      hideSearch={true}
    />
    <Box as="main" p="4">
    <div className="grid gap-4 m-auto p-20">
      {/* <input  // this should not be a text field. maybe a radio button ro something
        placeholder="Price Id"
        type="text"
        value={name}
        onChange={(e) => setPriceId(e.target.value)}
      /> */}
      <input
        placeholder="Name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <input
        placeholder="Email"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <CardElement />
      <Button onClick={createSubscription} disabled={!stripe} variant="outline">
        Subscribe to 10$/month
      </Button>
    </div>
    </Box>
    </DashboardWrapper>
  );
}


function StripeWrapper() {
  const stripePromise = loadStripe(stripeKey);

  return (
    <div>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}


export default StripeWrapper;