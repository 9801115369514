import styled from 'styled-components';
import { filterList } from './css/filterList';

interface FilterOptions {
  saturation?: number;
  brightness?: number;
  contrast?: number;
  sepia?: number;
  grayscale?: number;
  hueRotate?: string;
  filter: keyof typeof filterList;
  backgroundBefore?: string;
  backgroundAfter?: string;
}

interface ImageContainerProps {
  options: FilterOptions;
}

export const ImageContainer = styled.figure<ImageContainerProps>`
  width: 100%;
  height: 100%;
  margin: 0;

  ${(props) => {
    const {
      saturation,
      brightness,
      contrast,
      sepia,
      grayscale,
      hueRotate,
      filter,
      backgroundBefore,
      backgroundAfter,
    } = props.options;
    

    return filterList[filter]({
      saturation,
      brightness,
      contrast,
      sepia,
      grayscale,
      hueRotate,
      backgroundBefore,
      backgroundAfter,
    });
  }}
`;

ImageContainer.defaultProps = {
  options: {
    saturation: 1.6,
    brightness: 1.1,
    contrast: undefined,
    sepia: 0.3,
    grayscale: undefined,
    hueRotate: '-10deg',
    filter: 'walden',
    backgroundBefore: undefined,
    backgroundAfter: undefined,
  },
};