import React, { useEffect, useRef } from "react";
import {
    Box,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    useColorModeValue,
    useDisclosure,

} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import "../../Tailwind.css";
import { useSnapshot } from "valtio";
import { editorState, setCredit } from "../../stores/EditorStore";
import { SidebarContent } from "./Sidebar";
import { useWebSocket } from "./WebSocketContext";
import MobileSidebar from "./MobileSidebar";

interface DashboardWrapperProps {
    children: React.ReactNode;
}
const DashboardWrapper: React.FC<DashboardWrapperProps> = ({
    children,
}) => {    
    const { send, addEventListener, removeEventListener } = useWebSocket();
    const navigate = useNavigate();
    const {
        video,
        creating,
    } = useSnapshot(editorState);

    const handleMessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data);
        if (message.action === "auth" && message.status === false) {
            localStorage.removeItem('auth');
            navigate("/login");
        }
        if (message.action === "credits" && message.status === true) {
            const { balance, validUpto, plan } = message.data;
            setCredit(balance, validUpto, plan);
        }
    }
    function handleOpen() {
        const payload = {
            action: "auth",
            data: localStorage.getItem("auth"),
        };
        send(payload);
        send({ action: "credits" });
    }

    useEffect(() => {
        addEventListener("open", handleOpen);
        addEventListener('message', handleMessage);
        const auth = JSON.parse(localStorage.getItem("auth") || "{}");
        if (!auth.user) {
            navigate("/login");
        }        
        if (!auth.user.team) {
            return navigate("/onboarding/new");
        } return () => {
            removeEventListener("open", handleOpen);
            removeEventListener("message", handleMessage);
        };
    }, []);

    const sidebar = useDisclosure();
    const integrations = useDisclosure();
    const color = useColorModeValue("gray.600", "gray.300");
    const {
        isOpen: videoIsOpen,
        onOpen: videoOpen,
        onClose: videoOnClose,
    } = useDisclosure();

    const createVideo = (obj: any) => {
        const payload = {
            action: "createVideo",
            video: {
                title: obj.title,
                url: obj.url,
                description: obj.description,
                meta: {
                    ai: { details: obj.details },
                    props: { height: obj.meta.props.height, width: obj.meta.props.width },
                },
            },
        };
        send(payload);
    };

    function newVideo() {
        switch (creating) {
            case 0:
                console.log("VIDEO :", video);
                createVideo(video);
                break;

            default:
                break;
        }
        videoOnClose();
    }

    return (
        <Box
            as="section"
            bg="gray.50"
            _dark={{
                bg: "gray.700",
            }}
            minH="100vh"
        >
            <SidebarContent
                display={{
                    base: "none",
                    md: "unset",
                }}
                navigation={navigate}
                integrations={integrations}
                color={color}
            />
            <MobileSidebar
                navigation={navigate}
                integrations={integrations}
                color={color}
            />
            <Drawer
                isOpen={sidebar.isOpen}
                onClose={sidebar.onClose}
                placement="left"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <SidebarContent
                        w="full"
                        borderRight="none"
                        navigation={navigate}
                        integrations={integrations}
                        color={color}
                    />
                </DrawerContent>
            </Drawer>
            <Box
                ml={{
                    base: 0,
                    md: 60,
                }}
                transition=".3s ease"
            >
                {children}
            </Box>
        </Box>
    );
};

export default DashboardWrapper;

