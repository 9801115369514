import React from 'react';
import { Box, VStack, Text, Flex, IconButton, useColorModeValue, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiSolidVideos } from 'react-icons/bi';
import { HiOutlineTemplate } from 'react-icons/hi';
import { MdIntegrationInstructions, MdOutlineFeaturedPlayList, MdKeyboardArrowRight, MdOutlineBrandingWatermark, MdVideogameAsset } from 'react-icons/md';
import { IconType } from 'react-icons';
import { LuWorkflow } from "react-icons/lu";

interface NavItemProps {
    icon: IconType;
    children: React.ReactNode;
    onClick: () => void;
    selected: boolean;
    color: string;
    pl?: string;
    py?: string;
}

const NavItem: React.FC<NavItemProps> = ({ icon, children, onClick, selected, color, pl = "4", py = "3" }) => {
    const bgColor = useColorModeValue('gray.100', 'gray.700');
    return (
        <Flex
            align="center"
            px="4"
            pl={pl}
            py={py}
            cursor="pointer"
            role="group"
            fontWeight="semibold"
            transition=".15s ease"
            bg={selected ? bgColor : 'transparent'}
            onClick={onClick}
            color={color}
            _hover={{
                bg: bgColor,
            }}
        >
            {icon && (
                <Box mr="4">
                    {React.createElement(icon, {
                        size: 20,
                    })}
                </Box>
            )}
            {children}
        </Flex>
    );
};

interface MobileSidebarProps {
    navigation: (path: string) => void;
    integrations: {
        isOpen: boolean;
        onToggle: () => void;
    };
    color: string;
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({ navigation, integrations, color }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();

    const sidebarContent = (
        <VStack spacing={0} align="stretch">
            <Flex px="4" py="5" align="center">
            <img src="/logo.svg" />
                <Text fontSize="2xl" fontWeight="semibold" pl={2}>
                    Crackviral Studio
                </Text>
            </Flex>
            <NavItem
                icon={LuWorkflow}
                onClick={() => navigation("/dashboard")}
                color={color}
                selected={location.pathname === "/dashboard"}
            >
                Workflows
            </NavItem>
            <NavItem
                icon={BiSolidVideos}
                onClick={() => navigation("/videos/me")}
                color={color}
                selected={location.pathname === "/videos/me"}
            >
                My Videos
            </NavItem>
            <NavItem
                icon={HiOutlineTemplate}
                onClick={() => { navigation('/templates'); onClose(); }}
                selected={location.pathname === '/templates'}
                color={color}
            >
                Templates
            </NavItem>
            <NavItem
                icon={MdIntegrationInstructions}
                onClick={integrations.onToggle}
                selected={false}
                color={color}
            >
                Creative Corner
                <MdKeyboardArrowRight
                    style={{
                        marginLeft: 'auto',
                        transform: integrations.isOpen ? 'rotate(90deg)' : undefined,
                    }}
                />
            </NavItem>
            {integrations.isOpen && (
                <>
                    <NavItem
                        icon={MdOutlineBrandingWatermark}
                        onClick={() => { navigation('/brands'); onClose(); }}
                        selected={location.pathname === '/brands'}
                        color={color}
                        pl="12"
                        py="2"
                    >
                        Brands
                    </NavItem>
                </>
            )}
            <NavItem
                icon={MdOutlineFeaturedPlayList}
                onClick={() => { navigation('/models'); onClose(); }}
                selected={location.pathname === '/models'}
                color={color}
            >
                Models
            </NavItem>
        </VStack>
    );

    return (
        <>
            <IconButton
                aria-label="Open menu"
                icon={<HamburgerIcon />}
                onClick={onOpen}
                display={{ base: 'flex', md: 'none' }}
                position="fixed"
                top="4"
                left="4"
                zIndex="overlay"
            />
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    {sidebarContent}
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default MobileSidebar;