import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  FormLabel,
  Select,
  DrawerFooter,
  Box,
  Badge,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Models } from "specs";

const ReadyBadge = (props: any) => {
  const [isReady, setIsReady] = useState(false);
  const [sceneIndex, diagIndex] = props.activeIndexes.split(",");
  const indexString = [sceneIndex, diagIndex, props.index].join(",");
  useEffect(() => {
    setIsReady(props.exp.meta?.config?.hasOwnProperty(indexString));
  }, [indexString, props.exp]);

  return (
    <Badge ml="1" colorScheme={isReady ? "green" : "red"}>
      {isReady ? "Ready" : "Not Ready"}
    </Badge>
  );
};

const ModelInput = (props: any) => {
  const type = props.type.toLowerCase();
  const defaultValue = props.getDefaultValue(props.index);
  const [sceneIndex, diagIndex] = props.activeIndexes.split(",");
  const indexString = [sceneIndex, diagIndex, props.index].join(",");
  // console.log(indexString, "indexString");

  const handlClick = (e: any) => {
    if (e.target.value === "") {
      return;
    }
    props.setModelEditing(e.target.value);

    // if (!props.modelEditing) {
    //   props.setModelEditing(props.defaultValue);
    // }

    // props.setModelEditing("musicgen");
    props.setActiveIndex(indexString);
    props.editPipelineOpen();
  };
  return (
    <Box>
      <FormLabel htmlFor={type}>
        Select {props.type} Model
        {/* <ReadyBadge {...props} /> */}
      </FormLabel>
      <Select
        id={`${type}-model`}
        defaultValue={defaultValue}
        onChange={handlClick}
      >
        <option value="">Select Model</option>
        {props.models.map((m: any) => {
          return (
            <option value={m} key={m}>
              {m}
            </option>
          );
        })}
      </Select>
      {/* <br /> */}
      {/* <Button
          colorScheme="blue"
          variant="outline"
          size={"sm"}
          onClick={handlClick}
        >
          Edit Background Pipeline
        </Button> */}
    </Box>
  );
};

const EditSceneProps = (props: any) => {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  // const firstField = React.useRef()
  const [sceneReady, setSceneReady] = useState(false);
  const [sceneIndex, diagIndex, modelIndex] = props.activeIndexes;

  useEffect(() => {
    // const { activeIndexes } = props;

    const indexesToCheck = [0, 1, 2].map((index) =>
      [sceneIndex, diagIndex, index].join(",")
    );
    let isready = true;
    indexesToCheck.map((index) => {
      if (!props.exp.meta?.config?.hasOwnProperty(index)) {
        isready = false;
      }
    });
    if (isready === true) {
      setSceneReady(true);
    }
  }, [props.activeIndexes]);

  const propIndex = `${sceneIndex},${diagIndex}`;

  // const ImageModels = ModelDict.filter(
  //   (m) => m.type === "video" || m.type === "image"
  // ).reduce((acc: any, curr) => {
  //   return acc.concat(curr.modes);
  // }, []);
  const ImageModels = [...Models.video, ...Models.image, ...Models.avatar];
  const DialogueModels = Models.dialog;
  // const DialogueModels = ModelDict.filter((m) => m.type === "dialogue").reduce(
  //   (acc: any, curr) => {
  //     return acc.concat(curr.modes);
  //   },
  //   []
  // );
  // const MusicModels = ModelDict.filter((m) => m.type === "music").reduce(
  //   (acc: any, curr) => {
  //     return acc.concat(curr.modes);
  //   },
  //   []
  // );

  const getDefaultValue = (finalIndex: string) => {
    if (props.exp.meta?.config) {
      return props.exp.meta?.config[`${propIndex},${finalIndex}`]?.model_name;
    }
    return "any";
  };
  return (
    <>
      <Drawer
        isOpen={props.editScenePropsIsOpen}
        placement="right"
        //   initialFocusRef={firstField}
        onClose={props.editScenePropsOnClose}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Edit Pipeline Settings for Scene: {Number(sceneIndex) + 1}{" "}
            {Number(diagIndex) > -1
              ? ` Dialogue: ${Number(diagIndex) + 1}`
              : ""}
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              <ModelInput
                getDefaultValue={getDefaultValue}
                models={ImageModels}
                type="Video/Image"
                index={0}
                {...props}
              />
              <ModelInput
                getDefaultValue={getDefaultValue}
                models={DialogueModels}
                type="Audio"
                index={1}
                {...props}
              />
              {/* <ModelInput
                  getDefaultValue={getDefaultValue}
                  models={MusicModels}
                  type="Music"
                  index={2}
                  {...props}
                /> */}
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              variant="outline"
              mr={3}
              onClick={props.editScenePropsOnClose}
            >
              Close
            </Button>
            {/* <Button
                colorScheme={sceneReady ? "green" : "gray"}
                disabled={!sceneReady}
                onClick={props.editSceneOnClose}
              >
                {sceneReady ? "Scene Ready" : "Scene Settings Incomplete"}
              </Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default EditSceneProps;
