import React, { useState } from "react";
import {
  InputGroup,
  InputLeftAddon,
  Input,
  Switch,
  FormControl,
  FormLabel,
  Box,
  Button,
  HStack,
  Select,
} from "@chakra-ui/react";

interface KeyFrameType {
  type: string;
  over: string;
  frames: number[];
  val: number[];
  easingParams: number[];
  easing: string;
  extrapolateLeft: string;
  extrapolateRight: string;
}

const NumberInput = ({
  register,
  indexInside,
  editorProps,
  selectedComponent,
  prop,
  getValue,
  setValue,
  defaults,
  valOpts={}
}: any) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [easingType, setEasingType] = useState("bezier");
  const [easingParams, setEasingParams] = useState([0.8, 0.22, 0.96, 0.65]);
  const [defaultVal, setDefaultVal] = useState(() => {
    const val = getValue(selectedComponent, prop, editorProps);
    setValue(`${selectedComponent}_${prop}`, val);
    return val;
  });
  const [keyframes, setKeyframes] = useState<KeyFrameType>(() => {
    if (
      editorProps[`${selectedComponent}_${prop}`] &&
      editorProps[`${selectedComponent}_${prop}`][0] === "{"
    ) {
      setIsAnimated(true);
      const animatedVal = JSON.parse(
        editorProps[`${selectedComponent}_${prop}`]
      );
      setDefaultVal(animatedVal.val[0]);
      return animatedVal;
    }
    return {
      type: "interpolate",
      over: "time",
      frames: [0, editorProps[`${selectedComponent}_durationInFrames`]],
      val: defaults || [0, 50],
      easing: "bezier",
      easingParams: [0.8, 0.22, 0.96, 0.65],
      extrapolateLeft: "clamp",
      extrapolateRight: "clamp",
    };
  });

  const handleEasingTypeChange = (type: string) => {
    setEasingType(type);
    let params: any[] = [];
    switch (type) {
      case "linear":
      case "exp":
      case "sin":
      case "bounce":
      case "circle":
      case "ease":
      case "quad":
      case "cubic":
        params = [];
        break;
      case "bezier":
        params = [0.8, 0.22, 0.96, 0.65];
        break;
      case "elastic":
      case "back":
      case "step0":
      case "step1":
      case "poly":
        params = [0.3];
        break;
      case "in":
      case "out":
      case "in/out":
        params = ["Easing.ease"];
        break;
      default:
        params = [];
    }
    setEasingParams(params);
    setKeyframes((prev) => ({
      ...prev,
      easing: type,
      easingParams: params,
    }));
    setValue(
      `${selectedComponent}_${prop}`,
      JSON.stringify({ ...keyframes, easing: type, easingParams: params })
    );
  };

  const handleEasingParamChange = (index: number, value: string) => {
    const newParams = [...easingParams];
    newParams[index] = parseFloat(value);
    setEasingParams(newParams);
    setKeyframes((prev) => ({
      ...prev,
      easing: easingType,
    }));
    setValue(
      `${selectedComponent}_${prop}`,
      JSON.stringify({ ...keyframes, easing: easingType })
    );
  };

  const handleKeyframeChange = (index: number, value: string) => {
    const newFrames = [...keyframes.frames];
    const newValue = parseInt(value, 10);

    if (
      (index === 0 || newValue > newFrames[index - 1]) &&
      (index === newFrames.length - 1 || newValue < newFrames[index + 1])
    ) {
      newFrames[index] = newValue;
      setKeyframes((prev) => ({
        ...prev,
        frames: newFrames,
      }));
      setValue(
        `${selectedComponent}_${prop}`,
        JSON.stringify({ ...keyframes, frames: newFrames })
      );
    } else {
      alert("Frames must be strictly monotonically increasing.");
    }
  };

  const handleValuesChange = (index: number, value: string) => {
    const newValues = [...keyframes.val];
    newValues[index] = parseFloat(value);
    setKeyframes((prev) => ({
      ...prev,
      val: newValues,
    }));
    setValue(
      `${selectedComponent}_${prop}`,
      JSON.stringify({ ...keyframes, val: newValues })
    );
  };

  const addKeyframePair = () => {
    const duration = editorProps[`${selectedComponent}_durationInFrames`];
    const lastFrame = keyframes.frames[keyframes.frames.length - 1];
    if (lastFrame >= duration) {
      alert("Cannot add more keyframes beyond the duration.");
      return;
    }

    const newStartFrame = lastFrame;
    const newEndFrame = duration;

    setKeyframes((prev) => ({
      ...prev,
      frames: [...prev.frames, newStartFrame, newEndFrame],
      val: [
        ...prev.val,
        prev.val[prev.val.length - 1],
        prev.val[prev.val.length - 1],
      ],
    }));
  };

  const removeKeyframePair = (index: number) => {
    setKeyframes((prev) => {
      const newFrames = prev.frames.filter(
        (_, i) => i !== index && i !== index + 1
      );
      const newValues = prev.val.filter(
        (_, i) => i !== index && i !== index + 1
      );
      return {
        ...prev,
        frames: newFrames,
        val: newValues,
      };
    });
  };

  const handleConfigChange = (field: string, value: any) => {
    setKeyframes((prev) => ({
      ...prev,
      [field]: value,
    }));
    setValue(
      `${selectedComponent}_${prop}`,
      JSON.stringify({ ...keyframes, [field]: value })
    );
  };

  return (
    <Box key={`${prop}${indexInside}`} border="1px solid black" p={2}>
      <FormControl
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        mt={2}
      >
        <FormLabel htmlFor="animated-switch" mb="0" alignSelf={"end"}>
          Animate {prop}
        </FormLabel>
        <Switch
          id="animated-switch"
          isChecked={isAnimated}
          onChange={() => {
            if (!isAnimated) {
              setValue(
                `${selectedComponent}_${prop}`,
                JSON.stringify(keyframes)
              );
            } else {
              setValue(`${selectedComponent}_${prop}`, keyframes.val[0]);
            }
            setIsAnimated(!isAnimated);
          }}
        />
      </FormControl>
      {!isAnimated ? (
        <InputGroup mt={2}>
          <InputLeftAddon children={prop} />
          <Input
            type="number"
            name={`${selectedComponent}_${prop}`}
            {...register(`${selectedComponent}_${prop}`)}
            defaultValue={defaultVal}
          />
        </InputGroup>
      ) : (
        <Box mt={2}>
          {keyframes.frames.map((frame, index) => (
            <Box key={index} border="1px solid black" p={2}>
              {index % 2 === 0 && (
                <HStack mt={2}>
                  <InputGroup>
                    <InputLeftAddon children={`Start Time`} />
                    <Input
                      type="number"
                      step={0.01}
                      value={keyframes.frames[index]}
                      onChange={(e) =>
                        handleKeyframeChange(index, e.target.value)
                      }
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftAddon children={`End Time`} />
                    <Input
                      type="number"
                      step={0.01}
                      value={keyframes.frames[index + 1]}
                      onChange={(e) =>
                        handleKeyframeChange(index + 1, e.target.value)
                      }
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftAddon children={`Start Value`} />
                    <Input
                      type="number"
                      {...valOpts}
                      value={keyframes.val[index]}
                      onChange={(e) =>
                        handleValuesChange(index, e.target.value)
                      }
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftAddon children={`End Value`} />
                    <Input
                      type="number"
                      {...valOpts}
                      value={keyframes.val[index + 1]}
                      onChange={(e) =>
                        handleValuesChange(index + 1, e.target.value)
                      }
                    />
                  </InputGroup>
                  {index > 0 && (
                    <Button onClick={() => removeKeyframePair(index)} colorScheme='red' mr={2} size='xs' variant='ghost'>
                      Remove
                    </Button>
                  )}
                </HStack>
              )}
            </Box>
          ))}
          <Button mt={2} onClick={addKeyframePair} colorScheme='blue'>
            Add Keyframe Pair
          </Button>
          <Box mt={4}>
            <FormControl>
              <FormLabel>Easing Type</FormLabel>
              <Select
                value={easingType}
                onChange={(e) => handleEasingTypeChange(e.target.value)}
              >
                <option value="back">
                  Back - Use with Animated.parallel() to create a basic effect
                  where the object animates back slightly as the animation
                  starts.
                </option>
                <option value="bounce">
                  Bounce - Provides a basic bouncing effect.
                </option>
                <option value="ease">
                  Ease - A basic inertial interaction, similar to an object
                  slowly accelerating to speed.
                </option>
                <option value="in">
                  Ease in - Runs an easing function forwards.
                </option>
                <option value="out">
                  Ease out - Runs an easing function backwards.
                </option>
                <option value="inOut">
                  In/out - Makes any easing function symmetrical. The easing
                  function will run forwards for half of the duration, then
                  backwards for the rest of the duration.
                </option>
                <option value="linear">
                  Linear - A linear function, f(t) = t. Position correlates to
                  elapsed time one to one.
                </option>
                <option value="quad">
                  Quad - A quadratic function, f(t) = t * t. Position equals the
                  square of elapsed time.
                </option>
                <option value="cubic">
                  Cubic - A cubic function, f(t) = t * t * t. Position equals
                  the cube of elapsed time.
                </option>
                <option value="poly">
                  Poly - A power function. Position is equal to the Nth power of
                  elapsed time
                </option>
                <option value="circle">Circle - A circular function.</option>
                <option value="sin">Sin - A sinusoidal function.</option>
                <option value="exp">Exp - An exponential function.</option>
                <option value="bezier">
                  Bezier - Provides a cubic bezier curve, equivalent to CSS
                  Transitions' transition-timing-function.
                </option>
                <option value="elastic">
                  Elastic - A basic elastic interaction, similar to a spring
                  oscillating back and forth.Default bounciness is 1, which
                  overshoots a little bit once. 0 bounciness doesn't overshoot
                  at all, and bounciness of N &gt; 1 will overshoot about N
                  times.
                </option>
                <option value="step1">
                  Step1 - A stepping function, returns 1 if n is greater than or
                  equal to 1.
                </option>
                <option value="step0">
                  Step0 - A stepping function, returns 1 for any positive value
                  of n.
                </option>
              </Select>
            </FormControl>
            {easingType === "bezier" && (
              <Box>
                <FormControl mt={2}>
                  <FormLabel>X1</FormLabel>
                  <Input
                    type="number"
                    step="0.01"
                    value={easingParams[0]}
                    onChange={(e) => handleEasingParamChange(0, e.target.value)}
                  />
                </FormControl>
                <FormControl mt={2}>
                  <FormLabel>Y1</FormLabel>
                  <Input
                    type="number"
                    step="0.01"
                    value={easingParams[1]}
                    onChange={(e) => handleEasingParamChange(1, e.target.value)}
                  />
                </FormControl>
                <FormControl mt={2}>
                  <FormLabel>X2</FormLabel>
                  <Input
                    type="number"
                    step="0.01"
                    value={easingParams[2]}
                    onChange={(e) => handleEasingParamChange(2, e.target.value)}
                  />
                </FormControl>
                <FormControl mt={2}>
                  <FormLabel>Y2</FormLabel>
                  <Input
                    type="number"
                    step="0.01"
                    value={easingParams[3]}
                    onChange={(e) => handleEasingParamChange(3, e.target.value)}
                  />
                </FormControl>
              </Box>
            )}
            {easingType === "elastic" && (
              <Box>
                <FormControl mt={2}>
                  <FormLabel>Bounciness</FormLabel>
                  <Input
                    type="number"
                    step="0.01"
                    min={0}
                    value={easingParams[0]}
                    onChange={(e) => handleEasingParamChange(0, e.target.value)}
                  />
                </FormControl>
              </Box>
            )}
            {["back", "step0", "step1", "poly"].includes(easingType) && (
              <Box>
                <FormControl mt={2}>
                  <FormLabel>Parameter</FormLabel>
                  <Input
                    type="number"
                    min={0}
                    step="0.01"
                    value={easingParams[0]}
                    onChange={(e) => handleEasingParamChange(0, e.target.value)}
                  />
                </FormControl>
              </Box>
            )}
            <FormControl mt={2}>
              <FormLabel>Extrapolate Left</FormLabel>
              <Select
                value={keyframes.extrapolateLeft}
                onChange={(e) =>
                  handleConfigChange("extrapolateLeft", e.target.value)
                }
              >
                <option value="extend">Extend</option>
                <option value="clamp">Clamp</option>
                <option value="wrap">Wrap</option>
                <option value="identity">Identity</option>
              </Select>
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>Extrapolate Right</FormLabel>
              <Select
                value={keyframes.extrapolateRight}
                onChange={(e) =>
                  handleConfigChange("extrapolateRight", e.target.value)
                }
              >
                <option value="extend">Extend</option>
                <option value="clamp">Clamp</option>
                <option value="wrap">Wrap</option>
                <option value="identity">Identity</option>
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NumberInput;
