import React, { useState } from 'react';
import { X, ChevronDown, ChevronLeft } from 'lucide-react';
import { RenderScreens } from '../../../lib/utils/EditorUtil';
import { setCurrentImporting } from '../../../stores/EditorStore';
import { setGeneration } from '../../../stores/GenerationStore';

const Menu = ({ selectedTool, updateComponent, sendData, setSelectedTool, importOpen, genOpen }: { selectedTool: string, updateComponent: Function, sendData: Function, setSelectedTool: Function, importOpen: Function, genOpen: Function }) => {

    if (selectedTool === "") {
        return <></>
    }
    if (selectedTool === "Scenes") {
        return <div className="w-64 bg-gray-800 p-4 flex flex-col">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">{selectedTool}</h2>
                <button onClick={() => setSelectedTool("")} className="p-1 rounded-full hover:bg-gray-700">
                    <ChevronLeft size={20} />
                </button>
            </div>
            <RenderScreens
                updateComponent={updateComponent}
                send={sendData}
            />
        </div>
    }
    if (selectedTool === "Text") {
        return <div className="w-64 bg-gray-800 p-4 flex flex-col">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">{selectedTool}</h2>
                <button onClick={() => setSelectedTool("")} className="p-1 rounded-full hover:bg-gray-700">

                    <ChevronLeft size={20} />
                </button>
            </div>
            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={() => {
                setCurrentImporting(3);
                importOpen();
            }}>Add a new Text</button>
            <div>

            </div>
        </div>
    }
    if (selectedTool === "Image") {
        return <div className="w-64 bg-gray-800 p-4 flex flex-col">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">{selectedTool}</h2>
                <button onClick={() => setSelectedTool("")} className="p-1 rounded-full hover:bg-gray-700">

                    <ChevronLeft size={20} />
                </button>
            </div>
            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={() => {
                setCurrentImporting(0);
                importOpen();
            }}>Add {selectedTool}</button>

            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={() => {
                setGeneration(10);
                genOpen();
            }}>AI {selectedTool} Generation</button>
            <div>


            </div>
        </div>
    }
    if (selectedTool === "Audio") {
        return <div className="w-64 bg-gray-800 p-4 flex flex-col">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">{selectedTool}</h2>
                <button onClick={() => setSelectedTool("")} className="p-1 rounded-full hover:bg-gray-700">
                    <ChevronLeft size={20} />
                </button>
            </div>
            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={() => {
                setCurrentImporting(1);
                importOpen();
            }}>Add {selectedTool}</button>

            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={() => {
                setGeneration(11);
                genOpen();
            }}>AI {selectedTool} Generation</button>
            <div>
            </div>
        </div>
    }
    if (selectedTool === "Video") {
        return <div className="w-64 bg-gray-800 p-4 flex flex-col">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">{selectedTool}</h2>
                <button onClick={() => setSelectedTool("")} className="p-1 rounded-full hover:bg-gray-700">

                    <ChevronLeft size={20} />
                </button>
            </div>
            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={() => {
                setCurrentImporting(2);
                importOpen();
            }}>Add {selectedTool}</button>

            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={() => {
                setGeneration(12);
                genOpen();
            }}>AI {selectedTool} Generation</button>
            <div>

            </div>
        </div>
    }
    if (selectedTool === "Settings") {
        return <div className="w-64 bg-gray-800 p-4 flex flex-col">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">{selectedTool}</h2>
                <button onClick={() => setSelectedTool("")} className="p-1 rounded-full hover:bg-gray-700">

                    <ChevronLeft size={20} />
                </button>
            </div>
            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={() => {

            }}>Shortcuts</button>

            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={(e) => { window.location.href = 'mailto:support@crackviral.com'; }}>Support</button>
            <button className="bg-gray-700 hover:bg-gray-600 p-2 rounded mb-2" onClick={(e) => { window.location.href = 'mailto:feedback@crackviral.com'; }}>Share Feedback</button>

            <div>

            </div>
        </div>
    }
    return <></>
};

export default Menu;