import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  TabPanels,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Text,
  Select,
  useToast,
  CircularProgress,
  useDisclosure
} from '@chakra-ui/react';
import DashboardWrapper from '../../lib/components/Wrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { useWebSocket } from '../../lib/components/WebSocketContext';
import { ColorPicker } from "@lanyue/react-color-picker";
import "@lanyue/react-color-picker/dist/style.css";
import { Voices11Input } from '../../lib/components/inputs/common';
import Specs from "specs"
import { HeadContent } from '../../lib/components/Headcontent';
import { SendRequest } from '../../utils/main';
const presets = [
  "#f44337",
  "#e91e63",
  "#9c27b0",
  "#683ab8",
  "#3f52b5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "rgba(0, 0, 0, 0)",
  "rgba(255, 255, 255, 0)",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#9E9E9E",
];


interface BrandFormData {
  brandName: string;
  brandUrl: string;
  brandDescription: string;
  videoGoal: string;
  instagramUsername: string;
}

const BrandForm: React.FC = () => {
  const [brandName, setBrandName] = useState('');
  const [brandUrl, setBrandUrl] = useState('');
  const [brandDescription, setBrandDescription] = useState('');
  const [videoGoal, setVideoGoal] = useState('');
  const [instagramUsername, setInstagramUsername] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [textColor, setTextColor] = useState('#000000');
  const [font, setFont] = useState('charter');
  const [voice, setVoice] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const { send, addEventListener, removeEventListener } = useWebSocket();
  const [isUpdating, setIsUpdating] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const sidebar = useDisclosure();
  let { brandId = "" } = useParams();

  useEffect(() => {
    addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);

  const handleMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    if (!message.status) {
      toast({
        title: `Error video:${message.message}`,
        position: "top-right",
        variant: "top-accent",
        isClosable: true,
      });
      if (message.action === "auth") {
        navigate("/login");
      }
      return;
    }

    switch (message.action) {
      case "brand":
        setShowLoader(false);
        console.log("current brands:", message.brands);
        const brand = message.data;
        setBrandName(brand.name);
        setBrandUrl(brand.meta.brandUrl);
        setBrandDescription(brand.meta.brandDescription);
        setVideoGoal(brand.meta.videoGoal)
        setInstagramUsername(brand.meta.instagramUsername);
        setBackgroundColor(brand.meta.backgroundColor);
        setTextColor(brand.meta.fontColor);
        setFont(brand.meta.fonts);
        setVoice(brand.meta.voice);
        break;
      case "error":
        console.log("Error video:", message.message, event);
        toast({
          title: `Error video:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };

  function loadBrand() {
    setShowLoader(true);
    const payload = {
      action: "loadBrand",
      id: brandId
    };
    send(payload);
  }
  const handleOpen = () => {
    loadBrand();
  };
  useEffect(() => {
    if (!brandName) {
      loadBrand();
    }
  }, []);

  const handleSave = async () => {
    setIsUpdating(true);
    const data = {
      brandName,
      brandUrl,
      brandDescription,
      videoGoal,
      instagramUsername,
      backgroundColor,
      textColor,
      font,
      voice,
    };

    try {


      const result = await SendRequest(`/api/v2/brand/${brandId}`, data);
      if (result.data) {
        toast({
          title: 'Brand updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Brand Updating Error",
          description: `Error saving Brand Updating details: ${result.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

    } catch (error: any) {
      console.error('Error updating brand:', error);
      toast({
        title: 'Failed to update brand',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <DashboardWrapper>
      <HeadContent
        sidebar={sidebar}
        hideSearch={false}
      />
      <Box maxW="md" mx="auto" mt="10">
        <Heading as='h3' size='lg'>
          Customize your brand
        </Heading>
        <br />
        <Tabs variant='soft-rounded' colorScheme='green'>
          <center><TabList><Tab>Description</Tab><Tab>Style</Tab></TabList></center>
          <TabPanels>
            <TabPanel>
              <form>
                <FormControl id="brandName" mb="4">
                  <FormLabel>Brand Name</FormLabel>
                  <Input
                    type="text"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                  />
                </FormControl>
                <FormControl id="brandUrl" mb="4">
                  <FormLabel>Brand URL (Optional)</FormLabel>
                  <Input
                    type="text"
                    value={brandUrl}
                    onChange={(e) => setBrandUrl(e.target.value)}
                  />
                  <Text as='i' fontSize='xs'>Crackviral will automatically fetch brand colors, fonts, and logos from this URL</Text>
                </FormControl>
                <FormControl id="brandDescription" mb="4">
                  <FormLabel>Brand Description</FormLabel>
                  <Input
                    type="text"
                    value={brandDescription}
                    onChange={(e) => setBrandDescription(e.target.value)}
                  />
                  <Text as='i' fontSize='xs'>Summarize your brand's function and style briefly.</Text>
                </FormControl>
                <FormControl id="videoGoal" mb="4">
                  <FormLabel>Video Goal</FormLabel>
                  <Input
                    type="text"
                    value={videoGoal}
                    onChange={(e) => setVideoGoal(e.target.value)}
                  />
                  <Text as='i' fontSize='xs'>Define your marketing objective</Text>
                </FormControl>
                <FormControl id="instagramUsername" mb="4">
                  <FormLabel>Instagram Username</FormLabel>
                  <Input
                    type="text"
                    value={instagramUsername}
                    onChange={(e) => setInstagramUsername(e.target.value)}
                  />
                  <Text as='i' fontSize='xs'>Crackviral automatically imports your Instagram content for use in your brand media.</Text>
                </FormControl>
                <Button
                  colorScheme="blue"
                  onClick={handleSave}
                  isLoading={isUpdating}
                  loadingText="Saving..."
                >
                  Save Changes
                </Button>
              </form>
            </TabPanel>
            <TabPanel>
              <Box maxW="md" mx="auto">
                <Text mb="4" as='i'>
                  (Changing your brand appearance will only affect future video generations. Existing videos will not be modified. *)
                </Text>
                <form>
                  <FormControl id="backgroundColor" mb="4">
                    <FormLabel>Background Color</FormLabel>
                    <ColorPicker
                      value={
                        backgroundColor
                      }
                      presets={presets}
                      onChange={(value) => {
                        setBackgroundColor(value)
                      }}
                    />

                    <Text as='i' fontSize='xs'>The default background color of text frames in your videos</Text>
                  </FormControl>
                  <FormControl id="textColor" mb="4">
                    <FormLabel>Text Color</FormLabel>
                    <ColorPicker
                      value={
                        textColor
                      }
                      presets={presets}
                      onChange={(value) => { setTextColor(value) }}
                    />

                    <Text as='i' fontSize='xs'>The default color of text in your videos</Text>
                  </FormControl>
                  <FormControl id="font" mb="4">
                    <FormLabel>Font</FormLabel>
                    <Select value={font} onChange={(e) => setFont(e.target.value)}>
                      <option value="charter">Charter</option>
                      <option value="arial">Arial</option>
                      <option value="times">Times New Roman</option>
                      {/* Add more font options as needed */}
                    </Select>
                  </FormControl>
                  <FormControl id="voice" mb="4">
                    <FormLabel>Voice</FormLabel>
                    <Voices11Input
                      id="voice"
                      defaultValue={voice}
                      value={{ title: "", description: "" }}
                      handleChange={(e: any) => setVoice(e.target.value)}
                      enums={Specs["eleven-labs"].voices}
                    />
                  </FormControl>
                  <Button
                    colorScheme="blue"
                    onClick={handleSave}
                    isLoading={isUpdating}
                    loadingText="Saving..."
                  >
                    Save Changes
                  </Button>
                </form>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      {showLoader && (
        <CircularProgress
          isIndeterminate
          color="green.300"
          size={"lg"}
          style={{ left: "30%", position: "absolute", top: "30%" }}
        />
      )}
    </DashboardWrapper>
  );
};

export default BrandForm;