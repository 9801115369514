export const ShowLogType = (props: any) => {
    const type = props.index.split(',')[2];
  
    if (type === "0") {
      return "Video/Image";
    }
  
    if (type === "1") {
      return "Dialog";
    }
  
    if (type === "2") {
      return "Background";
    }
  
    return <span></span>;
  };
  