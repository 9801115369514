// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outline-container {
    display: flex;
    flex-direction: column;
}

.outline-node {
    display: block;
    width: 100%;
    min-height: 24px;
    max-height: 24px;
    margin-bottom: 2px;
    padding-left: 20px;
    color: white;
    background-color: #2a2a2a; /* Adjust the background color if needed */
    line-height: 24px; /* Center text vertically if needed */
}

.outline-node:hover {
    background-color: #505050; /* Adjust the hover color if needed */
}


/* this allows timeline to have visible scrollbars */

::-webkit-scrollbar {
    background: #161616;
    color: gray;
  }
  
  ::-webkit-scrollbar-thumb {
    background: gray;
  }
  
  ::-webkit-scrollbar-corner {
    background: #161616;
  }
`, "",{"version":3,"sources":["webpack://./src/lib/components/Timeline.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB,EAAE,0CAA0C;IACrE,iBAAiB,EAAE,qCAAqC;AAC5D;;AAEA;IACI,yBAAyB,EAAE,qCAAqC;AACpE;;;AAGA,oDAAoD;;AAEpD;IACI,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".outline-container {\n    display: flex;\n    flex-direction: column;\n}\n\n.outline-node {\n    display: block;\n    width: 100%;\n    min-height: 24px;\n    max-height: 24px;\n    margin-bottom: 2px;\n    padding-left: 20px;\n    color: white;\n    background-color: #2a2a2a; /* Adjust the background color if needed */\n    line-height: 24px; /* Center text vertically if needed */\n}\n\n.outline-node:hover {\n    background-color: #505050; /* Adjust the hover color if needed */\n}\n\n\n/* this allows timeline to have visible scrollbars */\n\n::-webkit-scrollbar {\n    background: #161616;\n    color: gray;\n  }\n  \n  ::-webkit-scrollbar-thumb {\n    background: gray;\n  }\n  \n  ::-webkit-scrollbar-corner {\n    background: #161616;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
