import { Flex , Box, Text, Collapse, Icon} from "@chakra-ui/react";
import { MdOutlineFeaturedPlayList, MdIntegrationInstructions, MdKeyboardArrowRight } from "react-icons/md";
import { HiOutlineTemplate } from "react-icons/hi";
import { BiSolidVideos } from "react-icons/bi";
import { LuWorkflow } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import { NavItem } from "./NavItem";

export const SidebarContent = (props: any) => {
    const location = useLocation();
    return (
      <Box
        as="nav"
        pos="fixed"
        top="0"
        left="0"
        zIndex="sticky"
        h="full"
        pb="10"
        overflowX="hidden"
        overflowY="auto"
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        border
        color="inherit"
        borderRightWidth="1px"
        w="60"
        {...props}
      >
        <Flex px="4" py="5" align="center">
          <img src="/logo.svg" />
          <Text
            fontSize="2xl"
            ml="2"
            color="brand.500"
            _dark={{
              color: "white",
            }}
            fontWeight="semibold"
          >
            CrackViral Studio
          </Text>
        </Flex>
        <Flex
          direction="column"
          as="nav"
          fontSize="sm"
          color="gray.600"
          aria-label="Main Navigation"
        >
          <NavItem
            icon={LuWorkflow}
            onClick={() => props.navigation("/dashboard")}
            color={props.color}
            selected={location.pathname === "/dashboard"}
          >
            Workflows
          </NavItem>
          <NavItem
            icon={BiSolidVideos}
            onClick={() => props.navigation("/videos/me")}
            color={props.color}
            selected={location.pathname === "/videos/me"}
          >
            My Videos
          </NavItem>
          <NavItem
            icon={HiOutlineTemplate}
            onClick={() => props.navigation("/templates")}
            color={props.color}
            selected={location.pathname === "/templates"}
          >
            Templates
          </NavItem>
          
          {/* <NavItem icon={MdHome}>Checklists</NavItem> */}
          <NavItem
          icon={MdIntegrationInstructions}
          onClick={props.integrations.onToggle}
          color={props.color}
        >
          Creative Corner
          <Icon
            as={MdKeyboardArrowRight}
            ml="auto"
            transform={props.integrations.isOpen ? "rotate(90deg)" : undefined}
          />
        </NavItem>
        <Collapse in={props.integrations.isOpen}>
        <NavItem
            pl="12"
            py="2"
            onClick={() => props.navigation("/brands")}
            color={props.color}
            selected={location.pathname === "/brands"}
          >
            Brands
          </NavItem>
        </Collapse>
          <NavItem
            icon={MdOutlineFeaturedPlayList}
            color={props.color}
            onClick={() => props.navigation("/models")}
            selected={location.pathname === "/models"}
          >
            Models
          </NavItem>
        </Flex>
      </Box>
    );
  };