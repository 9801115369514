// WebSocketContext.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import webSocketService from '../utils/websocketService';


type WebSocketEventMap = {
    open: Event;
    close: CloseEvent;
    message: MessageEvent;
    error: Event;
};

type WebSocketEventType = keyof WebSocketEventMap;

interface WebSocketContextValue {
    isConnected: boolean;
    send: (data: any) => void;
    addEventListener: <K extends WebSocketEventType>(
        type: K,
        callback: (event: WebSocketEventMap[K]) => void
    ) => void;
    removeEventListener: <K extends WebSocketEventType>(
        type: K,
        callback: (event: WebSocketEventMap[K]) => void
    ) => void;
}
const WebSocketContext = createContext<WebSocketContextValue | null>(null);

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};

interface WebSocketProviderProps {
    children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const handleOpen = () => setIsConnected(true);
        const handleClose = () => setIsConnected(false);

        webSocketService.addEventListener('open', handleOpen);
        webSocketService.addEventListener('close', handleClose);

        webSocketService.connect();

        return () => {
            webSocketService.removeEventListener('open', handleOpen);
            webSocketService.removeEventListener('close', handleClose);
            webSocketService.disconnect();
        };
    }, []);

    const contextValue: WebSocketContextValue = {
        isConnected,
        send: webSocketService.send.bind(webSocketService),
        addEventListener: webSocketService.addEventListener.bind(webSocketService),
        removeEventListener: webSocketService.removeEventListener.bind(webSocketService),
    };

    return (
        <WebSocketContext.Provider value={contextValue}>
            {children}
        </WebSocketContext.Provider>
    );
};