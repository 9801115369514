import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  Tag,
  Spinner,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
  ModalFooter,
} from '@chakra-ui/react';
import { SendRequest } from '../../utils/main';
import { HeadContent } from '../../lib/components/Headcontent';
import DashboardWrapper from '../../lib/components/Wrapper';
import { useWebSocket } from '../../lib/components/WebSocketContext';

interface TemplateData {
  _id: string;
  title: string;
  url: string;
  platforms: string[];
  hashtags: string[];
  metrics: {
    url: string;
    likes: number;
    shares: number;
    comments: number;
    plays: number;
  }[];
  custom: string;
  description: string;
  created_at: string;
  preview: {
    demo: string;
    thumb: string;
    sthumb: string;
  };
  demo: string[];
}

const SingleTemplate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [template, setTemplate] = useState<TemplateData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [videoPayload, setVideoPayload] = useState<any>();
  const { send, isConnected, addEventListener, removeEventListener } = useWebSocket();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const toast = useToast();
  const sidebar = useDisclosure();
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const {
    isOpen: templateIsOpen,
    onOpen: templateOpen,
    onClose: templateOnClose,
  } = useDisclosure();
  useEffect(() => {
    const checkLoginStatus = () => {
      const authData = localStorage.getItem('auth');
      setIsLoggedIn(!!authData);
    };

    checkLoginStatus();
    fetchTemplate();
  }, [id]);

  useEffect(() => {
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);

  const handleMessage = async (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    switch (message.action) {
      case "auth":
        navigate("/login");
        // setFilteredTemplates(message.templates);
        break;
      case "video":
        setIsSubmitting(false);
        toast({
          title: `Video Created. Redirecting in few seconds.`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        setTimeout(() => {
          navigate(`/video/${message.video._id}`);
        }, 2000);
        break;
      case "error":
        console.log("Error template:", message.message, event);
        toast({
          title: `Error template:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };

  const fetchTemplate = async () => {
    try {
      const response = await SendRequest(`/api/v2/template/${id}`, null, "GET");
      if (response.error) {
        throw new Error('Failed to fetch template data');
      }
      setTemplate(response.data.template[0]);
    } catch (err) {
      setError('Error fetching template data');
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await SendRequest('/api/users/login', { email, password }, 'POST');
      if (response.error) {
        throw new Error(response.error);
      }
      localStorage.setItem('auth', JSON.stringify(response.data));
      setIsLoggedIn(true);
      onClose();
      toast({
        title: 'Login Successful',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: 'Login Failed',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const createVideoFromTemplate = () => {
    if (!template || !videoPayload.title) {
      return toast({
        title: 'Template Validation Error',
        description: "Title Cannot be empty",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    if (!isConnected) {
      return toast({
        title: 'Server Connection Error',
        description: "Connection to server is not established. Please try again.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    const payload = {
      action: "createVideoFromTemplate",
      video: {
        title: videoPayload.title,
        template: { id: template._id, name: template.title },
        description: videoPayload.description
      },
    };
    send(payload);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }


  if (error || !template) {
    return (
      <Box textAlign="center" p={5}>
        <Heading as="h1" size="xl" mb={4}>
          Error
        </Heading>
        <Text>{error || 'Template not found'}</Text>
      </Box>
    );
  }

  if (!isLoggedIn) {

    return (
      <Box bg={bgColor} minHeight="100vh" p={10}>
        <Helmet>
          <title>{template.title}</title>
          <meta name="description" content={template.description} />
          <meta property="og:title" content={template.title} />
          <meta property="og:description" content={template.description} />
          <meta property="og:image" content={template.preview.thumb} />
          <meta property="og:url" content={`https://app.crackviral.com/template/${template.url}`} />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={template.title} />
          <meta name="twitter:description" content={template.description} />
          <meta name="twitter:image" content={template.preview.thumb} />
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="canonical" href={`https://crackviral.com/template/${template.url}`} />
        </Helmet>
        <VStack spacing={6} align="stretch" maxWidth={{ base: "800px", xl: "1000px", "2xl": "1200px" }} margin="auto">
          <Heading as="h1" size="2xl" color={textColor}>
            {template.title}
          </Heading>

          <Image src={template.preview.thumb} alt={template.title} borderRadius="md" />

          <Text fontSize="lg" color={textColor}>
            {template.description}
          </Text>

          <HStack wrap="wrap" spacing={2}>
            {template.platforms && template.platforms.map((platform) => (
              <Tag key={platform} colorScheme="blue">
                {platform}
              </Tag>
            ))}
          </HStack>

          <HStack wrap="wrap" spacing={2}>
            {template.hashtags && template.hashtags.map((hashtag) => (
              <Tag key={hashtag} colorScheme="green">
                #{hashtag}
              </Tag>
            ))}
          </HStack>
          <Box>
            <Text color={textColor} mb={4}>Please log in to view more details & use this template.</Text>
            <Button colorScheme="blue" onClick={onOpen}>Log In</Button>
          </Box>
        </VStack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Log In</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={handleLogin}>
                <FormControl id="email" isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <FormControl id="password" isRequired mt={4}>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4} w="full">Log In</Button>
                <Button colorScheme="blue" mt={4} w="full" onClick={() => navigate('/signup')}>Signup</Button>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    );
  }
  return <DashboardWrapper>
    <HeadContent
      sidebar={sidebar}
      hideSearch={true}
    />
    <Box bg={bgColor} minHeight="100vh" p={10}>
      <VStack spacing={6} align="stretch" maxWidth={{ base: "800px", xl: "1000px", "2xl": "1200px" }} margin="auto">
        <Heading as="h1" size="2xl" color={textColor}>
          {template.title}
        </Heading>

        <Image src={template.preview.thumb} alt={template.title} borderRadius="md" />

        <Text fontSize="lg" color={textColor}>
          {template.description}
        </Text>

        <HStack wrap="wrap" spacing={2}>
          {template.platforms && template.platforms.map((platform) => (
            <Tag key={platform} colorScheme="blue">
              {platform}
            </Tag>
          ))}
        </HStack>

        <HStack wrap="wrap" spacing={2}>
          {template.hashtags && template.hashtags.map((hashtag) => (
            <Tag key={hashtag} colorScheme="green">
              #{hashtag}
            </Tag>
          ))}
        </HStack>

        <Box>
          <Heading as="h2" size="lg" mb={2} color={textColor}>
            Metrics
          </Heading>
          {template.metrics && template.metrics.map((metric, index) => (
            <VStack key={index} align="start" spacing={1} p={2} borderRadius="md">
              <Text fontWeight="bold">Inspiration: {metric.url}</Text>
              {metric.likes > 0 && <Text>Likes: {metric.likes}</Text>}
              {metric.shares > 0 && <Text>Shares: {metric.shares}</Text>}
              {metric.comments > 0 && <Text>Comments: {metric.comments}</Text>}
              {metric.plays > 0 && <Text>Plays: {metric.plays}</Text>}
            </VStack>
          ))}
        </Box>

        <Button onClick={() => templateOpen()}> Use this Template</Button>

        <Text color={textColor}>
          Created at: {new Date(template.created_at).toLocaleString()}
        </Text>

        <Box>
          <Heading as="h2" size="lg" mb={2} color={textColor}>
            Demo
          </Heading>
          {template.demo.map((demoUrl, index) => (
            <Box key={index} mb={2}>
              <video width="100%" controls>
                <source src={demoUrl} />
                Your browser does not support the video tag.
              </video>
            </Box>
          ))}
        </Box>
      </VStack>
    </Box>
    <Modal isOpen={templateIsOpen} onClose={templateOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Video</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Add Video Title"
            onChange={(event) => {
              setVideoPayload({
                ...videoPayload,
                ...{ title: event.target.value },
              });
            }}
            mt={2}
          />
          <Input
            placeholder="Add Video Description"
            onChange={(event) => {
              setVideoPayload({
                ...videoPayload,
                ...{ description: event.target.value },
              });
            }}
            mt={2}
          />
        </ModalBody>
        <ModalFooter>

          <Button
            colorScheme="blue"
            mr={3}
            onClick={(e) => {
              setIsSubmitting(true);
              createVideoFromTemplate();
            }}
            isDisabled={isSubmitting}
            leftIcon={isSubmitting ? <Spinner size="sm" /> : undefined}
          >
            {isSubmitting ? 'Creating...' : 'Create Video'}
          </Button>
          <Button variant="ghost" onClick={templateOnClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </DashboardWrapper>
};

export default SingleTemplate;