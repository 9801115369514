import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { GenerationForm } from "./GenerationForm";
import { genState, setSelectedModel } from "../../stores/GenerationStore";
import { useSnapshot } from "valtio";
import { cloneDeep } from "lodash";
import { editorState } from "../../stores/EditorStore";

const ModelInput = (props: any) => {
  // const [modelEditing, setModelEditing] = useState("");
  const type = props.type.toLowerCase();
  const { models, selectedModel } = useSnapshot(genState);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.handleSubmit({ model: models });
  };

  const handleChange = (e: any) => {
    if (e.target.value === "") {
      return setSelectedModel("");
    }
    setSelectedModel(e.target.value);
  };

  return (
    <Box>
      <FormLabel htmlFor={type}>
        Select {props.type} Model
        {/* <ReadyBadge {...props} /> */}
      </FormLabel>
      <Select
        id={`${type}-model`}
        defaultValue={selectedModel}
        onChange={handleChange}
      >
        <option value="">Select Model</option>
        {props.models.map((m: any) => {
          return (
            <option value={m} key={m}>
              {m}
            </option>
          );
        })}
      </Select>

      <GenerationForm handleSubmit={handleSubmit} />
    </Box>
  );
};

export const GenerateModal: FC<{
  generateIsOpen: boolean;
  generateOnClose: () => void;
  runOpen: () => void;
  sendData: Function;
}> = ({ generateIsOpen, generateOnClose, sendData, runOpen }) => {
  const { generating, genVal, models, formData, mode, selectedModel } =
    useSnapshot(genState);
  const { video, selectedScreen } = useSnapshot(editorState);

  const handleSubmit = async (data: any) => {
    // e.preventDefault();
    try {
      sendData("saveGenPipeline", {
        formData: cloneDeep(formData),
        mode,
        model: selectedModel,
        selectedScreen,
        video: video._id,
      });
      runOpen();
      generateOnClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., display an error message)
    }
  };

  return (
    <Modal isOpen={generateIsOpen} onClose={generateOnClose} size={"xxl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generate {genVal}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ModelInput
            type={genVal}
            models={models}
            handleSubmit={handleSubmit}
            generating={generating}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Generate
          </Button>
          <Button variant="ghost" onClick={generateOnClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
