import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';

const ProductLinkInput = () => {
  const [url, setUrl] = useState('https://www.amazon.com/Move-Free-Advanced-Plus-tablets/dp/B001W2MBSE');

  const supportedPlatforms = [
    { name: 'Amazon', icon: '🅰️' },
    { name: 'Apple App Store', icon: '🍎' },
    { name: 'ProductHunt', icon: '🏹' },
    { name: 'Google Play', icon: '▶️' },
    { name: 'Walmart', icon: '🛒' },
    { name: 'WordPress', icon: '🌐' },
    { name: 'Wix', icon: 'W' },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-800 to-gray-900 min-h-screen flex flex-col items-center justify-center p-4 text-white">
      <h1 className="text-3xl font-bold mb-2">Share your product link to generate a video</h1>
      
      <div className="flex items-center mb-4">
        <span className="mr-2">Creatify supports:</span>
        {supportedPlatforms.map((platform, index) => (
          <span key={index} className="mr-1" title={platform.name}>
            {platform.icon}
          </span>
        ))}
        <span>...</span>
      </div>

      <div className="w-full max-w-2xl mb-4">
        <div className="relative">
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="w-full bg-gray-700 rounded-lg py-3 px-4 pr-24 text-white placeholder-gray-400"
            placeholder="Enter product URL"
          />
          <button className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-blue-400 hover:underline">
            Try some links?
          </button>
        </div>
      </div>

      <div className="flex space-x-4">
        <button className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300">
          Upload manually
        </button>
        <button className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded-lg flex items-center transition duration-300">
          Analyze URL
          <ArrowRight className="ml-2" size={20} />
        </button>
      </div>
    </div>
  );
};

export default ProductLinkInput;