import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useColorModeValue,
  useDisclosure,
  chakra,
  Button,
  Grid,
  GridItem,
  useToast,
  VStack,
  Container,
  Badge,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";


import "../Tailwind.css";
import { wssurl } from "../lib/utils/Misc";
import { handleShowAlert } from "../pages/Signup";
import EditScene from "../lib/modals/EditScene";
import EditPipeline from "../lib/modals/EditPipeline";
import { SidebarContent } from "../lib/components/Sidebar";
import { HeadContent } from "../lib/components/Headcontent";
import { sendMessage } from "../lib/utils/EditorUtil";
import { useWebSocket } from "../lib/components/WebSocketContext";
import DashboardWrapper from "../lib/components/Wrapper";

export const GridBox = (props: any) => {
  const { sceneIndex, diagIndex } = props;
  const indexesToCheck = [0, 1, 2].map(
    (key: number) => `${sceneIndex},${diagIndex},${key}`
  );
  let isReady = true;
  for (let index = 0; index < indexesToCheck.length; index++) {
    const indexString = indexesToCheck[index];
    if (!props.exp.meta.config[indexString]) {
      isReady = false;
    }
  }
  return (
    <GridItem w="100%" bg="blue.100">
      <Box
        maxW="xs"
        mx="auto"
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        shadow="lg"
        rounded="lg"
      >
        <Box px={4} py={2}>
          <chakra.h1
            color="gray.800"
            _dark={{
              color: "white",
            }}
            fontWeight="bold"
            fontSize="xl"
            textTransform="uppercase"
          >
            {props.speaker}
          </chakra.h1>
          <chakra.p
            mt={1}
            fontSize="sm"
            color="gray.600"
            _dark={{
              color: "gray.400",
            }}
          >
            {props.line}
          </chakra.p>
          <chakra.p
            mt={1}
            fontSize="sm"
            color="gray.600"
            _dark={{
              color: "gray.400",
            }}
          >
            Model Selected: SVD:XL
          </chakra.p>
        </Box>

        {/* <Image
    h={48}
    w="full"
    fit="cover"
    mt={2}
    src="https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=320&q=80"
    alt="NIKE AIR"
  /> */}

        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={4}
          py={2}
          bg="gray.200"
          roundedBottom="lg"
        >
          <Button
            // isLoading
            loadingText="Loading"
            colorScheme="teal"
            variant="outline"
            spinnerPlacement="start"
            onClick={() => {
              props.setActiveIndex(
                [props.sceneIndex, props.diagIndex, -1].join(",")
              );
              props.editSceneOpen();
            }}
          >
            Edit Pipeline
          </Button>

          <Button
            px={2}
            py={1}
            bg="white"
            fontSize="xs"
            colorScheme={isReady ? "green" : "red"}
            fontWeight="bold"
            rounded="lg"
            textTransform="uppercase"
            _hover={{
              bg: "green.200",
            }}
            _focus={{
              bg: "green.400",
            }}
            onClick={() => props.navigate("/video/")}
          >
            {isReady ? "Run Pipeline" : "Not Ready"}
          </Button>
        </Flex>
      </Box>
    </GridItem>
  );
};

const Runs: React.FC = () => {
  const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
  const [exp, setExp] = useState<any>({});
  const [creating, setCurrentCreating] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sceneSettings, setSceneSettings] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  //   const [auth, error, loading] = useAuth();
  let { experimentId = "" } = useParams();
  const [activeIndexes, setActiveIndex] = useState("-1, -1, -1");

  const {
    isOpen: editSceneIsOpen,
    onOpen: editSceneOpen,
    onClose: editSceneOnClose,
  } = useDisclosure();

  const {
    isOpen: editPipelineIsOpen,
    onOpen: editPipelineOpen,
    onClose: editPipelineOnClose,
  } = useDisclosure();


  useEffect(() => {
    addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);

  const sidebar = useDisclosure();
  const integrations = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
  const {
    isOpen: videoIsOpen,
    onOpen: videoOpen,
    onClose: videoOnClose,
  } = useDisclosure();

  const [scenes, setScenes] = useState([]);
  const [modelEditing, setModelEditing] = useState("");

  // Listen for messages from the WebSocket server
  const handleMessage = async (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    switch (message.status) {
      case "experiment":
        console.log("Experiment:", message.data);
        setExp(message.data);
        break;
      case "run":
        console.log("Experiment Running:", message.data);
        handleShowAlert(
          "status",
          "Status",
          `Experiment current Status: ${message.data.status}`
        );
        // setExp(message.data);
        break;
      case "sceneFull":
        console.log("experiment obj:", message.experimentObj);
        setScenes(message.experimentObj.out.content.scenes);
        break;
      case "error":
        console.log("Error video:", message.message, event);
        toast({
          title: `Error video:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };

  function loadRuns() {
    const payload = {
      action: "getRuns",
      id: experimentId,
    };
    send(payload);
  }
  function saveExpPipeline(data: any) {
    const payload = {
      action: "saveExpPipeline",
      id: experimentId,
      data,
    };
    send(payload);
  }

  function generateScenes() {
    const payload = {
      action: "generateScenes",
      id: experimentId,
    };
    send(payload);
  }

  const handleSearch = () => {
    console.log("Search Query", searchQuery);
  };
  const handleOpen = () => {
    loadRuns();
  };
  return (
    <DashboardWrapper>
      <HeadContent
        sidebar={sidebar}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
      />
      <VStack>
        {exp.out?.content?.scenes?.map((scene: any, sceneIndex: Number) => {
          // const isReady = exp.meta.config;
          return (
            <Container maxW="full" bg="gray.200" color="#262626">
              <Badge ml="1" colorScheme="green">
                {scene.scene_number}
              </Badge>
              <Badge ml="1" colorScheme="blue">
                <AddIcon /> Add a Shot
              </Badge>
              <br />
              <Box p="2">
                <b>Scene Details</b>: {scene.scene_description}
                <br />
                <b>Visual Details</b>: {scene.visual_details}
                <br />
                <b> Audio Details</b>: {scene.audio_details}
              </Box>
              <Box as="main" p="4">
                <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                  {scene.dialogues.length === 0 && (
                    <GridBox
                      exp={exp}
                      sceneIndex={sceneIndex}
                      diagIndex={-1}
                      editSceneIsOpen={editSceneIsOpen}
                      editSceneOpen={editSceneOpen}
                      editSceneOnClose={editSceneOnClose}
                      setActiveIndex={setActiveIndex}
                      editPipelineIsOpen={editPipelineIsOpen}
                      editPipelineOpen={editPipelineOpen}
                      editPipelineOnClose={editPipelineOnClose}
                    />
                  )}
                  {scene.dialogues.map((diag: any, diagIndex: number) => {
                    return (
                      <GridBox
                        exp={exp}
                        {...diag}
                        sceneIndex={sceneIndex}
                        diagIndex={diagIndex}
                        editSceneIsOpen={editSceneIsOpen}
                        editSceneOpen={editSceneOpen}
                        editSceneOnClose={editSceneOnClose}
                        setActiveIndex={setActiveIndex}
                        editPipelineIsOpen={editPipelineIsOpen}
                        editPipelineOpen={editPipelineOpen}
                        editPipelineOnClose={editPipelineOnClose}
                      />
                    );
                  })}
                  ;
                </Grid>
              </Box>
            </Container>
          );
        })}
      </VStack>
      <Button
        // isLoading
        style={{ position: "fixed", bottom: "10px", right: "10px" }}
        loadingText="Generating Scenes"
        // colorScheme="teal"
        variant="outline"
        spinnerPlacement="start"
        onClick={() => {
          generateScenes();
        }}
      >
        Generate Scenes
      </Button>
      <EditScene
        editSceneIsOpen={editSceneIsOpen}
        editSceneOpen={editSceneOpen}
        editSceneOnClose={editSceneOnClose}
        activeIndexes={activeIndexes}
        setActiveIndex={setActiveIndex}
        editPipelineOpen={editPipelineOpen}
        exp={exp}
        modelEditing={modelEditing}
        setModelEditing={setModelEditing}
        sceneSettings={sceneSettings}
        setSceneSettings={setSceneSettings}
      />
      <EditPipeline
        size="md"
        editPipelineIsOpen={editPipelineIsOpen}
        editPipelineOpen={editPipelineOpen}
        editPipelineOnClose={editPipelineOnClose}
        activeIndexes={activeIndexes}
        setActiveIndex={setActiveIndex}
        exp={exp}
        saveExpPipeline={saveExpPipeline}
        modelEditing={modelEditing}
        setModelEditing={setModelEditing}
      />
    </DashboardWrapper>
  );
};

export default Runs;
