import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { ShowLogType } from "../components/ShowLogType";
import { ShowPreview } from "../components/ShowPreview";
import { ShowLogs } from "../components/Showlogs";


export const VariationTable = (props: any) => {
  const { videoId, selectedScreen, selectedComponent } =
    props;
  // const indexesToCheck = [0, 1, 2].map(
  //   (key: number) => `${sceneIndex},${diagIndex},${key}`
  // );
  // let isRunning = true;
//   console.log({ props }, "props");

  return (
    <TableContainer>
      <Table variant="simple">
        <TableCaption>Pipelines</TableCaption>
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Preview</Th>
            <Th>Status</Th>
            <Th>Logs</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.apis &&
            props.apis.map((api: any, i: number) => {
              const selected = false;
              // const selected = props.APISelected.includes(api._id);
              return (
                <Tr>
                  <Td>
                    <ShowLogType index={api.index} />
                  </Td>
                  <Td>
                    <ShowPreview api={api} />
                  </Td>
                  <Td>{api.status}</Td>
                  <Td>
                    <ShowLogs api={api} />
                  </Td>
                  <Td>
                    <Button
                      variant={selected ? "outline" : "solid"}
                      loadingText="Generating Scenes"
                      spinnerPlacement="start"
                      onClick={() => {
                        props.replaceComponent({
                          videoId,
                          selectedScreen,
                          selectedComponent,
                          apiID: api._id,
                        });
                      }}
                    >
                      {selected ? "Selected" : "Select"}
                    </Button>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export const VariationModal: FC<{
  propIsOpen: boolean;
  proponClose: () => void;
  variation: string;
  selectedComponent: string;
  selectedScreen: string;
  videoId: string;
  selectedComponentName: string;
  runInstance: any;
  syncRun: any;
  apis: any;
  replaceComponent: Function;
}> = ({
  propIsOpen,
  proponClose,
  variation,
  selectedComponent,
  selectedScreen,
  videoId,
  selectedComponentName,
  runInstance,
  syncRun,
  apis,
  replaceComponent,
}) => {
  // const { register, getValues } = methods;

  return (
    <Drawer
      placement={"right"}
      onClose={proponClose}
      isOpen={propIsOpen}
      size={"xl"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          Variations for <span>{selectedComponentName}</span>
        </DrawerHeader>
        <DrawerBody>
          <VariationTable
            runInstance={runInstance}
            apis={apis}
            selectedComponent={selectedComponent}
            selectedScreen={selectedScreen}
            videoId={videoId}
            replaceComponent={replaceComponent}
          />
          <Button
            // isLoading
            style={{ position: "fixed", bottom: "10px", right: "180px" }}
            loadingText="Generating Scenes"
            // colorScheme="teal"
            // variant="outline"
            spinnerPlacement="start"
            onClick={() => {
              syncRun(variation);
            }}
          >
            Sync APIs
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
