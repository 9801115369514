import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Spinner,
  useToast,
  Badge,
  useDisclosure,
} from '@chakra-ui/react';
import DashboardWrapper from '../../lib/components/Wrapper';
import { SendRequest } from '../../utils/main';
import { HeadContent } from '../../lib/components/Headcontent';

interface CreditLog {
  id: string;
  amount: number;
  balance: number;
  type: 'credit' | 'debit';
  description: string;
  timestamp: string;
}

const CreditLogsPage: React.FC = () => {
  const [logs, setLogs] = useState<CreditLog[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const sidebar = useDisclosure();
  
  useEffect(() => {
    fetchCreditLogs();
  }, []);

  const fetchCreditLogs = async () => {
    try {
      setIsLoading(true);
      const response = await SendRequest('/api/v2/user/ledger', null, "GET");
      setLogs(response.data.docs);
    } catch (error) {
      console.error('Error fetching credit logs:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch credit logs. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardWrapper>
      <HeadContent
        sidebar={sidebar}
        hideSearch={true}
      />
    <Box p={4}>
      <Heading mb={6}>Credit Logs</Heading>
      {isLoading ? (
        <Spinner size="xl" />
      ) : logs.length === 0 ? (
        <Text>No credit logs found.</Text>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Type</Th>
              <Th isNumeric>Amount</Th>
              <Th isNumeric>Balance</Th>
              <Th>Description</Th>
            </Tr>
          </Thead>
          <Tbody>
            {logs.map((log) => (
              <Tr key={log.id}>
                <Td>{new Date(log.timestamp).toLocaleString()}</Td>
                <Td>
                  <Badge colorScheme={log.type === 'credit' ? 'green' : 'red'}>
                    {log.type}
                  </Badge>
                </Td>
                <Td isNumeric>{log.amount}</Td>
                <Td isNumeric>{log.balance}</Td>
                <Td>{log.description}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
    </DashboardWrapper>
  );
};

export default CreditLogsPage;