import React, { useEffect, useRef, useState } from "react";
import {
    Avatar,
    Box,
    Collapse,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    useColorModeValue,
    useDisclosure,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    chakra,
    Button,
    Select,
    Grid,
    GridItem,
    useToast,
    CircularProgress,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Textarea,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    FormLabel,
    Tooltip,
    FormControl,
    Spinner,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { MdHome } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import "../Tailwind.css";
import { getSubString, wssurl } from "../lib/utils/Misc";
import { handleShowAlert } from "./Signup";
import { useAuth } from "../components/lib/useAuth";
import { useSnapshot } from "valtio";
import {
    editorState,
    setCurrentCreating,
    setNewVideo,
    setShowLoader,
} from "../stores/EditorStore";
import { HeadContent } from "../lib/components/Headcontent";
import { useWebSocket } from "../lib/components/WebSocketContext";
import DashboardWrapper from "../lib/components/Wrapper";

import { cloneDeep } from "lodash";
import { Resolution } from "../lib/modals/DisplaySwicherModal";
import { createVideo } from "../lib/utils/EditorUtil";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { SendRequest } from "../utils/main";

function updateScreenSize(width: number, height: number, video: any, setNewVideo: Function) {
    const videoPayload = {
        meta: { global: {} },
        ...cloneDeep(video),
    };
    videoPayload.meta.global.width = width;
    videoPayload.meta.global.height = height;
    setNewVideo(videoPayload);
}




const CreateVideo: React.FC = () => {
    // const wsRef = useRef<WebSocket | null>(null);
    const {
        video,
        newVideo,
        creating,
        showLoader,
    } = useSnapshot(editorState);
    const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();
    const [customWidth, setCustomWidth] = useState<string>("");
    const [customHeight, setCustomHeight] = useState<string>("");
    const [brands, setBrands] = useState<any>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVertical, setIsVertical] = useState<boolean>(
        newVideo?.meta?.global.height > newVideo?.meta?.global.width
    );

    const defaultResolutions: Resolution[] = [
        {
            width: 640,
            height: 360,
            title: "SD",
            description: "360p resolution",
            tags: ["standard", "medium"],
        },
        {
            width: 854,
            height: 480,
            title: "SD",
            description: "480p resolution",
            tags: ["standard", "medium"],
        },
        {
            width: 1280,
            height: 720,
            title: "HD",
            description: "720p resolution",
            tags: ["high", "large"],
        },
        {
            width: 1920,
            height: 1080,
            title: "Full HD",
            description: "1080p resolution",
            tags: ["full", "large"],
        },
        {
            width: 2560,
            height: 1440,
            title: "QHD",
            description: "1440p resolution",
            tags: ["quad", "extra-large"],
        },
        {
            width: 3840,
            height: 2160,
            title: "4K",
            description: "2160p resolution",
            tags: ["ultra", "extra-large"],
        },
    ];

    const handleResolutionChange = (res: Resolution) => {
        // setResolution(res);
        console.log(`Resolution changed to: ${res.width}x${res.height}`);
        if (!isVertical) {
            updateScreenSize(res.width, res.height, newVideo, setNewVideo);
        } else {
            updateScreenSize(res.height, res.width, newVideo, setNewVideo);
        }
    };

    const handleAddResolution = () => {
        const width = parseInt(customWidth, 10);
        const height = parseInt(customHeight, 10);
        if (isVertical) {
            updateScreenSize(width, height, newVideo, setNewVideo);
        } else {
            updateScreenSize(height, width, newVideo, setNewVideo);
        }
    };

    const toggleOrientation = () => {
        // setIsHorizontal(!isHorizontal);
        setIsVertical(!isVertical)
        if (!isVertical) {
            updateScreenSize(newVideo.meta.global.width, newVideo.meta.global.height, newVideo, setNewVideo);
        } else {
            updateScreenSize(newVideo.meta.global.height, newVideo.meta.global.width, newVideo, setNewVideo);
        }
    };

    useEffect(() => {
        addEventListener('open', handleOpen);
        addEventListener('message', handleMessage);

        return () => {
            removeEventListener('open', handleOpen);
            removeEventListener('message', handleMessage);
        };
    }, [addEventListener, removeEventListener]);

    useEffect(() => {
        document.title = "Crackviral | Create New Video";
    }, []);


    const sidebar = useDisclosure();
    const navigate = useNavigate();
    const toast = useToast();
    const [auth, error, loading] = useAuth();
    const color = useColorModeValue("gray.600", "gray.300");

    // Listen for messages from the WebSocket server
    const handleMessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data);
        console.log("message", message, message.true);

        if (!message.status) {
            toast({
                title: `Error video:${message.message}`,
                position: "top-right",
                variant: "top-accent",
                isClosable: true,
            });
            if (message.action === "auth") {
                navigate("/login");
            }
            return;
        }

        switch (message.action) {
            case "brands":
                console.log({ message });

                setBrands(message.data);
                break;
            case "error":
                console.log("Error video:", message.message, event);
                toast({
                    title: `Error video:${message.message}`,
                    position: "top-right",
                    variant: "top-accent",
                    isClosable: true,
                });
                break;
            default:
                break;
        }
    };


    function loadBrands() {
        const payload = {
            action: "loadBrands",
        };
        send(payload);
    }

    function submitAuth() {
        const payload = {
            action: "auth",
            data: localStorage.getItem("auth"),
        };
        send(payload);
    }

    function generateScenes(meta: any) {
        const payload = {
            action: "storyToScenes",
            data: meta,
        };
        send(payload);
    }
    const createVideoWS = (obj: any) => {
        const payload = {
            action: "createVideo",
            video: {
                title: obj.title,
                url: obj.url,
                description: obj.description,
                meta: {
                    ai: { details: obj.details },
                    props: { height: obj.meta.props.height, width: obj.meta.props.width },
                },
            },
        };
        send(payload);
    };
    const handleOpen = () => {
        submitAuth();
        loadBrands();
    };

    const createBrandVideo= async () => {
        setIsSubmitting(true);
        try {
          const response = await SendRequest(
            `/api/v2/brand/video/create`,
                newVideo,
            "POST"
          );
          if (response.data) {
            console.log(response);
            
            toast({
              title: "Video has been created",
              description: "Your Video has been created.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            navigate(`/video/${response.data.video._id}`);
          } else {
            console.log({response});
            
            toast({
              title: "Brand Video Creation Error",
              description: `Error saving Brand details:${response.error}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: "Brand Video Creation Error",
            description: `Network or server error:${error}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsSubmitting(false);
        }
      }
      
    return (
        <DashboardWrapper><HeadContent
            sidebar={sidebar}
        // searchQuery={searchQuery}
        // setSearchQuery={setSearchQuery}
        // handleSearch={handleSearch}
        />
            <Box as="main" p="4">
                <Tabs
                    position="relative"
                    variant="unstyled"
                    defaultIndex={creating}
                    onChange={(index) => setCurrentCreating(index)}
                >
                    <TabList>
                        <Tab>Trending AI</Tab>
                        <Tab>From URL</Tab>
                        <Tab>AI Storyboard</Tab>
                        <Tab>Blank Video</Tab>
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg="blue.500"
                        borderRadius="1px"
                    />
                    <TabPanels>
                        <TabPanel>
                            <FormControl p={2}>
                                <FormLabel><b>Video Title</b> <Tooltip label="Don't have the footage for a trend? Crackviral will leave you placeholders with instructions of what to upload" aria-label='A tooltip'>
                                    <QuestionOutlineIcon />
                                </Tooltip></FormLabel>
                                <Input
                                    placeholder="My Crackviral Video"
                                    onChange={(event) => {
                                        setNewVideo({
                                            ...cloneDeep(newVideo),
                                            ...{ title: event.target.value },
                                        });
                                    }}
                                    mt={2}
                                />
                            </FormControl>
                            <FormControl p={2}>
                                <FormLabel><b>AI Video Description</b></FormLabel>
                                <Textarea
                                    placeholder="Launch video"
                                    onChange={(event) => {
                                        setNewVideo({
                                            ...cloneDeep(newVideo),
                                            ...{ description: event.target.value },
                                        });
                                    }}
                                    height={"300px"}
                                    mt={2}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel><b>Select Brand (Optional)</b></FormLabel>
                                <Select
                                    placeholder="Select Brand"
                                    onClick={() => {
                                        if (brands.length === 0) {
                                            loadBrands();
                                        }
                                        
                                    }}
                                    onChange={(event) => {
                                        console.log({event});
                                        const videoPayload = {
                                            ...cloneDeep(newVideo),
                                            brand:  event.target.value  ,
                                        };
                                        setNewVideo(videoPayload);
                                    }}
                                    mt={2}
                                >
                                    {brands.map((brand: any) => {
                                        return <option value={brand._id}>{brand.name}</option>
                                    })}
                                </Select>
                            </FormControl>
                            <Button
                                colorScheme="blue"
                                mr={3}
                                onClick={(e) => {
                                    createBrandVideo();
                                }}
                                isDisabled={isSubmitting}
                                leftIcon={isSubmitting ? <Spinner size="sm" /> : undefined}
                              >
                                {isSubmitting ? 'Submitting...' : 'Create Video'}
                            </Button>
                        </TabPanel>
                        <TabPanel>
                            <FormControl p={2}>
                                <FormLabel><b>Video Title</b> <Tooltip label="Don't have the footage for a trend? Crackviral will leave you placeholders with instructions of what to upload" aria-label='Video Title '>
                                    <QuestionOutlineIcon />
                                </Tooltip></FormLabel>
                                <Input
                                    placeholder="My Crackviral Video"
                                    onChange={(event) => {
                                        setNewVideo({
                                            ...cloneDeep(newVideo),
                                            ...{ title: event.target.value },
                                        });
                                    }}
                                    mt={2}
                                />
                            </FormControl>
                            <FormControl p={2}>
                                <FormLabel><b>Idea URL</b> <Tooltip label="We will scrape the page to generate video ideas for you." aria-label='Idea URL'>
                                    <QuestionOutlineIcon />
                                </Tooltip></FormLabel>
                                <Input
                                    placeholder="Your Company Blog or Product Page."
                                    onChange={(event) => {
                                        setNewVideo({
                                            ...cloneDeep(newVideo),
                                            ...{ title: event.target.value },
                                        });
                                    }}
                                    mt={2}
                                />
                            </FormControl>
                            <FormControl p={2}>
                                <FormLabel><b>AI Video Description</b></FormLabel>
                                <Textarea
                                    placeholder="Launch video"
                                    onChange={(event) => {
                                        setNewVideo({
                                            ...cloneDeep(newVideo),
                                            ...{ description: event.target.value },
                                        });
                                    }}
                                    height={"300px"}
                                    mt={2}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel><b>Select Brand (Optional)</b></FormLabel>
                                <Select
                                    placeholder="Select Brand"
                                    onChange={(event) => {
                                        console.log({event});
                                        
                                        const videoPayload = {
                                            ...cloneDeep(newVideo),
                                            brand:  event.target.value  ,
                                        };
                                        setNewVideo(videoPayload);
                                    }}
                                    mt={2}
                                >
                                    {brands.map((brand: any) => {
                                        return <option value={brand._id}>{brand.name}</option>
                                    })}
                                </Select>
                            </FormControl>

                            <Accordion>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex="1" textAlign="left">
                                                Advanced Options
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <h2 className="text-xl font-bold mb-4">
                                            Current Video Resolution :{" "}
                                            {`${(newVideo?.meta?.global?.height || 1080)}x${(newVideo?.meta?.global?.width || 1920)}`}
                                        </h2>
                                        <div className="flex flex-wrap gap-4 mb-4">
                                            {defaultResolutions.map((res) => {
                                                let selected = false;
                                                if (isVertical) {
                                                    selected =
                                                        newVideo?.meta?.global.width === res.height &&
                                                        newVideo?.meta?.global.height === res.width;
                                                } else {
                                                    selected =
                                                        newVideo?.meta?.global.width === res.width &&
                                                        newVideo?.meta?.global.height === res.height;
                                                }
                                                return (
                                                    <div
                                                        key={`${res.width}x${res.height}`}
                                                        onClick={() => handleResolutionChange(res)}
                                                        className={`border rounded-md p-4 cursor-pointer shadow-md ${selected
                                                            ? "bg-blue-500 text-white"
                                                            : "bg-white text-gray-800 hover:bg-gray-100"
                                                            }`}
                                                    >
                                                        <h3 className="text-lg font-bold mb-2">{res.title}</h3>
                                                        <p className="mb-2">{res.description}</p>
                                                        <p className="mb-2">
                                                            {isVertical
                                                                ? `${res.width}x${res.height}`
                                                                : `${res.height}x${res.width}`}
                                                        </p>

                                                        <div className="flex flex-wrap gap-1 text-black">
                                                            {res.tags.map((tag, index) => (
                                                                <span
                                                                    key={index}
                                                                    className="px-2 py-1 bg-gray-200 rounded-md text-xs"
                                                                >
                                                                    {tag}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="number"
                                                value={customWidth}
                                                onChange={(e) => setCustomWidth(e.target.value)}
                                                placeholder="Width"
                                                className="px-4 py-2 border rounded-md mr-2"
                                            />
                                            <input
                                                type="number"
                                                value={customHeight}
                                                onChange={(e) => setCustomHeight(e.target.value)}
                                                placeholder="Height"
                                                className="px-4 py-2 border rounded-md mr-2"
                                            />
                                            <button
                                                onClick={handleAddResolution}
                                                className="px-4 py-2 bg-green-500 text-white rounded-md"
                                            >
                                                Update
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={toggleOrientation}
                                                className="px-4 py-2 bg-purple-500 text-white rounded-md"
                                            >
                                                Switch to {!isVertical ? "Vertical" : "Horizontal"}{" "}
                                            </button>
                                        </div>
                                        <Select
                                            placeholder="Select FPS"
                                            onChange={(e) => {
                                                const videoPayload = {
                                                    meta: { global: {} },
                                                    ...cloneDeep(newVideo),
                                                };
                                                videoPayload.meta.global.fps = Number(e.target.value);
                                                setNewVideo(videoPayload);
                                                // setNewVideo({
                                                //   ...video,
                                                //   ...{
                                                //     meta: {
                                                //       props: {
                                                //         fps: Number(e.target.value),
                                                //       },
                                                //     },
                                                //   },
                                                // });
                                            }}
                                            mt={2}
                                        >
                                            <option value="24">24 FPS</option>
                                            <option value="30">30 FPS (Default)</option>
                                            <option value="60">60 FPS </option>
                                            <option value="12">12 FPS</option>
                                        </Select>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            <Button
                                colorScheme="gray"
                                mr={3}
                                disabled
                            // onClick={(e) => {
                            //     createVideo(

                            //         newVideo,
                            //         auth,
                            //         setShowLoader,
                            //     );
                            // }}
                            >
                                Coming soon
                            </Button>
                        </TabPanel>

                        <TabPanel>
                            <Input
                                placeholder="Add Video Title"
                                onChange={(event) => {
                                    setNewVideo({
                                        ...cloneDeep(newVideo),
                                        ...{ title: event.target.value },
                                    });
                                }}
                                mt={2}
                            />
                            <Textarea
                                placeholder="Add Video Description"
                                onChange={(event) => {
                                    setNewVideo({
                                        ...cloneDeep(newVideo),
                                        ...{ description: event.target.value },
                                    });
                                }}
                                height={"300px"}
                                mt={2}
                            />
                            <Textarea
                                placeholder="Add Additional details about the story- like genre, tone etc"
                                onChange={(event) => {
                                    setNewVideo({
                                        ...cloneDeep(newVideo),
                                        ...{ details: event.target.value },
                                    });
                                }}
                                mt={2}
                            />
                            <Accordion>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex="1" textAlign="left">
                                                Advanced Options
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <h2 className="text-xl font-bold mb-4">
                                            Current Video Resolution :{" "}
                                            {`${(newVideo?.meta?.global?.height || 1080)}x${(newVideo?.meta?.global?.width || 1920)}`}
                                        </h2>
                                        <div className="flex flex-wrap gap-4 mb-4">
                                            {defaultResolutions.map((res) => {
                                                let selected = false;
                                                if (isVertical) {
                                                    selected =
                                                        newVideo?.meta?.global.width === res.height &&
                                                        newVideo?.meta?.global.height === res.width;
                                                } else {
                                                    selected =
                                                        newVideo?.meta?.global.width === res.width &&
                                                        newVideo?.meta?.global.height === res.height;
                                                }
                                                return (
                                                    <div
                                                        key={`${res.width}x${res.height}`}
                                                        onClick={() => handleResolutionChange(res)}
                                                        className={`border rounded-md p-4 cursor-pointer shadow-md ${selected
                                                            ? "bg-blue-500 text-white"
                                                            : "bg-white text-gray-800 hover:bg-gray-100"
                                                            }`}
                                                    >
                                                        <h3 className="text-lg font-bold mb-2">{res.title}</h3>
                                                        <p className="mb-2">{res.description}</p>
                                                        <p className="mb-2">
                                                            {isVertical
                                                                ? `${res.width}x${res.height}`
                                                                : `${res.height}x${res.width}`}
                                                        </p>

                                                        <div className="flex flex-wrap gap-1 text-black">
                                                            {res.tags.map((tag, index) => (
                                                                <span
                                                                    key={index}
                                                                    className="px-2 py-1 bg-gray-200 rounded-md text-xs"
                                                                >
                                                                    {tag}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="number"
                                                value={customWidth}
                                                onChange={(e) => setCustomWidth(e.target.value)}
                                                placeholder="Width"
                                                className="px-4 py-2 border rounded-md mr-2"
                                            />
                                            <input
                                                type="number"
                                                value={customHeight}
                                                onChange={(e) => setCustomHeight(e.target.value)}
                                                placeholder="Height"
                                                className="px-4 py-2 border rounded-md mr-2"
                                            />
                                            <button
                                                onClick={handleAddResolution}
                                                className="px-4 py-2 bg-green-500 text-white rounded-md"
                                            >
                                                Update
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={toggleOrientation}
                                                className="px-4 py-2 bg-purple-500 text-white rounded-md"
                                            >
                                                Switch to {!isVertical ? "Vertical" : "Horizontal"}{" "}
                                            </button>
                                        </div>
                                        <Select
                                            placeholder="Select FPS"
                                            onChange={(e) => {
                                                const videoPayload = {
                                                    meta: { global: {} },
                                                    ...cloneDeep(newVideo),
                                                };
                                                videoPayload.meta.global.fps = Number(e.target.value);
                                                setNewVideo(videoPayload);
                                            }}
                                            mt={2}
                                        >
                                            <option value="24">24 FPS</option>
                                            <option value="30">30 FPS (Default)</option>
                                            <option value="60">60 FPS </option>
                                            <option value="12">12 FPS</option>
                                        </Select>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            <Button
                                colorScheme="blue"
                                mr={3}
                                onClick={(e) => {
                                    createVideo(
                                        newVideo,
                                        auth,
                                        setShowLoader,
                                    );
                                }}
                            >
                                Create
                            </Button>
                        </TabPanel>
                        <TabPanel>
                            <Input
                                placeholder="Add Video Title"
                                onChange={(event) => {
                                    setNewVideo({
                                        ...cloneDeep(newVideo),
                                        ...{ title: event.target.value },
                                    });
                                }}
                                mt={2}
                            />
                            <Textarea
                                placeholder="Add Video Description"
                                onChange={(event) => {
                                    setNewVideo({
                                        ...cloneDeep(newVideo),
                                        ...{ description: event.target.value },
                                    });
                                }}
                                height={"300px"}
                                mt={2}
                            />
                            <Accordion>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box as="span" flex="1" textAlign="left">
                                                Advanced Options
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <h2 className="text-xl font-bold mb-4">
                                            Current Video Resolution :{" "}
                                            {`${(newVideo?.meta?.global?.height || 1080)}x${(newVideo?.meta?.global?.width || 1920)}`}
                                        </h2>
                                        <div className="flex flex-wrap gap-4 mb-4">
                                            {defaultResolutions.map((res) => {
                                                let selected = false;
                                                if (isVertical) {
                                                    selected =
                                                        newVideo?.meta?.global.width === res.height &&
                                                        newVideo?.meta?.global.height === res.width;
                                                } else {
                                                    selected =
                                                        newVideo?.meta?.global.width === res.width &&
                                                        newVideo?.meta?.global.height === res.height;
                                                }
                                                return (
                                                    <div
                                                        key={`${res.width}x${res.height}`}
                                                        onClick={() => handleResolutionChange(res)}
                                                        className={`border rounded-md p-4 cursor-pointer shadow-md ${selected
                                                            ? "bg-blue-500 text-white"
                                                            : "bg-white text-gray-800 hover:bg-gray-100"
                                                            }`}
                                                    >
                                                        <h3 className="text-lg font-bold mb-2">{res.title}</h3>
                                                        <p className="mb-2">{res.description}</p>
                                                        <p className="mb-2">
                                                            {isVertical
                                                                ? `${res.width}x${res.height}`
                                                                : `${res.height}x${res.width}`}
                                                        </p>

                                                        <div className="flex flex-wrap gap-1 text-black">
                                                            {res.tags.map((tag, index) => (
                                                                <span
                                                                    key={index}
                                                                    className="px-2 py-1 bg-gray-200 rounded-md text-xs"
                                                                >
                                                                    {tag}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="number"
                                                value={customWidth}
                                                onChange={(e) => setCustomWidth(e.target.value)}
                                                placeholder="Width"
                                                className="px-4 py-2 border rounded-md mr-2"
                                            />
                                            <input
                                                type="number"
                                                value={customHeight}
                                                onChange={(e) => setCustomHeight(e.target.value)}
                                                placeholder="Height"
                                                className="px-4 py-2 border rounded-md mr-2"
                                            />
                                            <button
                                                onClick={handleAddResolution}
                                                className="px-4 py-2 bg-green-500 text-white rounded-md"
                                            >
                                                Update
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={toggleOrientation}
                                                className="px-4 py-2 bg-purple-500 text-white rounded-md"
                                            >
                                                Switch to {!isVertical ? "Vertical" : "Horizontal"}{" "}
                                            </button>
                                        </div>
                                        <Select
                                            placeholder="Select FPS"
                                            onChange={(e) => {
                                                const videoPayload = {
                                                    meta: { global: {} },
                                                    ...cloneDeep(newVideo),
                                                };
                                                videoPayload.meta.global.fps = Number(e.target.value);
                                                setNewVideo(videoPayload);
                                                // setNewVideo({
                                                //   ...video,
                                                //   ...{
                                                //     meta: {
                                                //       props: {
                                                //         fps: Number(e.target.value),
                                                //       },
                                                //     },
                                                //   },
                                                // });
                                            }}
                                            mt={2}
                                        >
                                            <option value="24">24 FPS</option>
                                            <option value="30">30 FPS (Default)</option>
                                            <option value="60">60 FPS </option>
                                            <option value="12">12 FPS</option>
                                        </Select>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            <Button
                                colorScheme="blue"
                                mr={3}
                                onClick={(e) => {
                                    createVideo(

                                        newVideo,
                                        auth,
                                        setShowLoader,
                                    );
                                }}
                            >
                                Create
                            </Button>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box></DashboardWrapper>
    );
};

export default CreateVideo;