import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Heading,
    useToast,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure
} from '@chakra-ui/react';
import DashboardWrapper from '../../lib/components/Wrapper';
import { useWebSocket } from '../../lib/components/WebSocketContext';
import { Link, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { HeadContent } from '../../lib/components/Headcontent';

interface BrandFormData {
    brandName: string;
    brandUrl: string;
    brandDescription: string;
    videoGoal: string;
    instagramUsername: string;
}

const Brandlist: React.FC = () => {
    const { send, addEventListener, removeEventListener } = useWebSocket();
    const toast = useToast();
    const navigate = useNavigate();
    const [brands, setBrands] = useState<any[]>([]);
    const sidebar = useDisclosure();
    useEffect(() => {
        addEventListener('open', handleOpen);
        addEventListener('message', handleMessage);

        return () => {
            removeEventListener('open', handleOpen);
            removeEventListener('message', handleMessage);
        };
    }, [addEventListener, removeEventListener]);

    const handleMessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data);
        console.log("message", message, message.true);

        if (!message.status) {
            toast({
                title: `Error video:${message.message}`,
                position: "top-right",
                variant: "top-accent",
                isClosable: true,
            });
            if (message.action === "auth") {
                navigate("/login");
            }
            return;
        }

        switch (message.action) {
            case "brands":
                console.log("current brands:", message.brands);
                setBrands(message.data);
                break;
            case "error":
                console.log("Error video:", message.message, event);
                toast({
                    title: `Error video:${message.message}`,
                    position: "top-right",
                    variant: "top-accent",
                    isClosable: true,
                });
                break;
            default:
                break;
        }
    };
    function loadBrands() {
        const payload = {
            action: "loadBrands",
        };
        send(payload);
    }
    const handleOpen = () => {
        loadBrands();
    };

    useEffect(() => {
        loadBrands();
    }, [])

    return (
        <DashboardWrapper>
             <HeadContent
        sidebar={sidebar}
        hideSearch={false}
      />
            <Box mx="auto" mt="10">
                <Heading as="h1" size="xl" mb="6">
                    Your Brands
                </Heading>
                <Button
                    as={RouterLink}
                    to="/brands/new"
                    colorScheme="blue"
                    mb="6"
                >
                    Create New Brand
                </Button>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Brand Name</Th>
                            <Th>URL</Th>
                            <Th>Status</Th>
                            <Th>Edit</Th>
                            <Th>View Assets</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {brands && brands.map((brand) => (
                            <Tr key={brand._id}>
                                <Td>{brand.name}</Td>
                                <Td>
                                    <Link to={brand.meta?.brandUrl}>
                                        {brand.meta?.brandUrl}
                                    </Link>
                                </Td>
                                <Td>{brand.status}</Td>
                                <Td>
                                        <Button
                                        as={RouterLink}
                                        to={`/brands/${brand._id}`}
                                        size="sm"
                                        colorScheme="teal"
                                    >
                                        Edit
                                    </Button></Td>
                                    <Td>
                                    <Button
                                        as={RouterLink}
                                        to={`/brand/${brand._id}/assets`}
                                        size="sm"
                                        colorScheme="teal"
                                    >
                                        Assets
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>

        </DashboardWrapper>
    );
};

export default Brandlist;
