export type ShortKeys = 's' | 'b' | 'c' | 'se' | 'g' | 'h' | 'f' | 'bb' | 'ba';
export type LongKeys = 'saturation' | 'brightness' | 'contrast' | 'sepia' | 'grayscale' | 'hueRotate' | 'filter' | 'backgroundBefore' | 'backgroundAfter';

export const keyMap: Record<ShortKeys, LongKeys> = {
  s: 'saturation',
  b: 'brightness',
  c: 'contrast',
  se: 'sepia',
  g: 'grayscale',
  h: 'hueRotate',
  f: 'filter',
  bb: 'backgroundBefore',
  ba: 'backgroundAfter'
};

export const reverseKeyMap: Record<LongKeys, ShortKeys> = Object.fromEntries(
  Object.entries(keyMap).map(([k, v]) => [v, k as ShortKeys])
) as Record<LongKeys, ShortKeys>;

export function filterConvert(obj: string) {
  const result: any = {};
  try {
    const FilterObject = JSON.parse(obj);
    Object.keys(FilterObject).forEach((key) => {
      const value = FilterObject[key];
      const fullKey = keyMap[key as ShortKeys]
      result[fullKey] = value;
    })
  } catch (error) {
    console.log("error in filterConvert", error); 
  }
  return result
}