import { Flex, IconButton, Icon, InputGroup, InputLeftElement, Input, Menu, MenuButton, Avatar, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { MdHome } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { editorState } from "../../stores/EditorStore";
import { useSnapshot } from "valtio";

export const HeadContent = (props: any) => {
    const navigate = useNavigate();
    const { credits } = useSnapshot(editorState);
    const userImg =
        localStorage.getItem("gravatar") ||
        "c9225b0ffc156fd89922954fc3874430f7a3331b2ab6828d71afe98399cae1fb";
    // const hash =
    return (
        <Flex
            as="header"
            align="center"
            justify="space-between"
            w="full"
            px="4"
            bg="white"
            _dark={{
                bg: "gray.800",
            }}
            borderBottomWidth="1px"
            color="inherit"
            h="14"
        >
            <IconButton
                aria-label="Menu"
                display={{
                    base: "inline-flex",
                    md: "none",
                }}
                onClick={props.sidebar.onOpen}
                icon={<Icon color="gray.500" as={MdHome} cursor="pointer" />}
                size="sm"
            />
            {props.hideSearch && <InputGroup w="96"></InputGroup>}
            {!props.hideSearch && (
                <InputGroup
                    w="96"
                    display={{
                        base: "none",
                        md: "flex",
                    }}
                >
                    <InputLeftElement color="gray.500">
                        <FiSearch />
                    </InputLeftElement>
                    <Input
                        placeholder="Search for your videos..."
                        value={props.searchQuery}
                        onChange={(e) => props.setSearchQuery(e.target.value)}
                        onKeyUp={(e) => {
                            props.handleSearch();
                        }}
                    />
                </InputGroup>
            )}

            <Flex align="center">
                {credits.balance >= 50 && <Button colorScheme="green" onClick={()=> navigate("/user/ledger")}>Balance: {credits.balance}</Button>}
                {credits.balance < 50 && <Button colorScheme="red" onClick={()=> navigate("/user/ledger")}>Balance: {credits.balance}</Button>}
                {credits.plan == "free" && <Button colorScheme="red"onClick={()=> navigate("/payments")} ml="4"> Upgrade Now</Button>}
                <Menu>
                    <MenuButton
                        ml="4"
                        as={Avatar}
                        size="sm"
                        name="anubra266"
                        src={`https://gravatar.com/avatar/${userImg}`}
                        cursor="pointer"
                    ></MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
                        <MenuItem onClick={() => navigate('/user/ledger')}>Usage History</MenuItem>
                        <MenuItem onClick={() => navigate("/logout")}>Logout</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    );
};
