import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Input,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  chakra,
  Button,
  Image,
  Grid,
  GridItem,
  useToast,
  SimpleGrid,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "../../Tailwind.css";
import { getSubString, wssurl } from "../../lib/utils/Misc";
import { SidebarContent } from "../../lib/components/Sidebar";
import { HeadContent } from "../../lib/components/Headcontent";
import { sendMessage } from "../../lib/utils/EditorUtil";
import DashboardWrapper from "../../lib/components/Wrapper";
import { useWebSocket } from "../../lib/components/WebSocketContext";

const Templates: React.FC = () => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [creating, setCurrentCreating] = useState<number>(0);
  const [template, setTemplate] = useState<any>();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [platformFilter, setPlatformFilter] = useState<string[]>([]);
  const [hashtagFilter, setHashtagFilter] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { send, addEventListener, removeEventListener, isConnected } = useWebSocket();


  useEffect(() => {
    addEventListener('open', handleOpen);
    addEventListener('message', handleMessage);

    return () => {
      removeEventListener('open', handleOpen);
      removeEventListener('message', handleMessage);
    };
  }, [addEventListener, removeEventListener]);

  useEffect(() => {
    handleFilter();
  }, [searchQuery, platformFilter, hashtagFilter, templates]);

  const sidebar = useDisclosure();
  const integrations = useDisclosure();
  const color = useColorModeValue("gray.600", "gray.300");
  const {
    isOpen: templateIsOpen,
    onOpen: templateOpen,
    onClose: templateOnClose,
  } = useDisclosure();
  // Listen for messages from the WebSocket server
  const handleMessage = async (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    console.log("message", message, message.true);

    switch (message.action) {
      case "templates":
        console.log("Updated template:", message.templates);
        setTemplates(message.templates);
        setFilteredTemplates(message.templates);
        break;
      case "auth":
        if (message.status === false) {
          navigate("/login");
        }
        // setFilteredTemplates(message.templates);
        break;
      case "video":
        setIsSubmitting(false);
        toast({
          title: `Video Created. Redirecting in few seconds.`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        setTimeout(() => {
          navigate(`/video/${message.video._id}`);
        }, 2000);
        break;
      case "error":
        console.log("Error template:", message.message, event);
        toast({
          title: `Error template:${message.message}`,
          position: "top-right",
          variant: "top-accent",
          isClosable: true,
        });
        break;
      default:
        break;
    }
  };
  const handleSearch = () => {
    handleFilter();
  };

  const handleFilter = () => {
    const filtered = templates.filter((vid) => {
      // Search filter
      const matchesSearch = !searchQuery || searchQuery === "" || (
        (vid?.title && vid.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (vid?.description && vid.description.toLowerCase().includes(searchQuery.toLowerCase()))
      );

      // Platform filter
      const matchesPlatform = platformFilter.length === 0 || platformFilter.includes("") || (
        vid.platforms && vid.platforms.some((platform: string) => platformFilter.includes(platform))
      );

      // Hashtag filter
      const matchesHashtag = hashtagFilter.length === 0 || hashtagFilter.includes("") || (
        vid.hashtags && vid.hashtags.some((hashtag: string) => hashtagFilter.includes(hashtag))
      );

      return matchesSearch && matchesPlatform && matchesHashtag;
    });

    setFilteredTemplates(filtered);
  };

  function loadTemplates() {
    const payload = {
      action: "getTemplates",
    };
    send(payload);
  }
  const createVideoFromTemplate = (obj: any) => {
    const payload = {
      action: "createVideoFromTemplate",
      video: {
        title: obj.title,
        template: { id: obj.templateid, name: obj.templateName },
        description: obj.description
      },
    };
    send(payload);
  };
  const handleOpen = () => {
    setIsLoading(true);
    loadTemplates();
  };
  const createHandle = (type: number) => {
    setCurrentCreating(type);
    templateOpen();
  };
  function newTemplate() {
    switch (creating) {
      case 0:
        console.log("VIDEO :", template);
        createVideoFromTemplate(template);
        break;

      default:
        break;
    }
    templateOnClose();
  }
  useEffect(() => {
    if (!isLoading) {
      loadTemplates();
      setIsLoading(true);
    } 
  }, [])



  return (
    <DashboardWrapper>
      <HeadContent
        sidebar={sidebar}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
      />
      <Box
        as="section"
        bg="gray.50"
        _dark={{
          bg: "gray.700",
        }}
        minH="100vh"
      >
        <SidebarContent
          display={{
            base: "none",
            md: "unset",
          }}
          navigation={navigate}
          integrations={integrations}
          color={color}
        />
        <Drawer
          isOpen={sidebar.isOpen}
          onClose={sidebar.onClose}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent>
            <SidebarContent
              w="full"
              borderRight="none"
              navigation={navigate}
              integrations={integrations}
              color={color} />
          </DrawerContent>
        </Drawer>
        <Box

          transition=".3s ease"
        >
          {/* <HeadContent sidebar={sidebar} searchQuery={searchQuery} setSearchQuery={setSearchQuery} handleSearch={handleSearch} /> */}
          <Box as="main" p="4">
            <Flex mb={4} alignItems="center">
              <Box flex={1} mr={2}>
                <Select
                  placeholder="Select Platforms"
                  value={platformFilter}
                  onChange={(e) => {
                    const selected = Array.from(e.target.selectedOptions, option => option.value);
                    setPlatformFilter(selected);
                  }}
                >
                  {Array.from(new Set(templates.flatMap(t => t.platforms || []))).map(platform => (
                    <option key={platform} value={platform}>{platform}</option>
                  ))}
                </Select>
              </Box>
              <Box flex={1}>
                <Select
                  placeholder="Select Hashtags"
                  value={hashtagFilter}
                  onChange={(e) => {
                    const selected = Array.from(e.target.selectedOptions, option => option.value);
                    setHashtagFilter(selected);
                  }}
                >
                  {Array.from(new Set(templates.flatMap(t => t.hashtags || []))).map(hashtag => (
                    <option key={hashtag} value={hashtag}>{hashtag}</option>
                  ))}
                </Select>
              </Box>
            </Flex>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} gap={6}>
              {filteredTemplates?.map((vid) => {
                return (
                  <GridItem w="100%" key={vid._id}>

                    <Box
                      maxW="xs"
                      mx="auto"
                      bg="white"
                      _dark={{
                        bg: "gray.800",
                      }}
                      shadow="lg"
                      height="540px"
                      rounded="lg"

                    >
                      <Box px={4} py={2}>
                        <chakra.h1
                          color="gray.800"
                          _dark={{
                            color: "white",
                          }}
                          fontWeight="bold"
                          fontSize="2xl"
                          textTransform="uppercase"
                          noOfLines={1}
                        >
                          {vid.title}
                        </chakra.h1>
                        {vid.preview?.thumb && <Image
                          h="400px"
                          w="full"
                          fit="cover"
                          mt={2}
                          src={vid.preview.thumb}
                          alt="Template preview"
                        />}
                        <chakra.p
                          mt={1}
                          fontSize="sm"
                          color="gray.600"
                          _dark={{
                            color: "gray.400",
                          }}
                          noOfLines={2}
                        >
                          {getSubString(vid.description, 100)}
                        </chakra.p>
                      </Box>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        px={4}
                        py={2}
                        bg="gray.200"
                        roundedBottom="lg"
                      >
                        {/* <chakra.h1 color="white" fontWeight="bold" fontSize="lg">
                                        $129
                                    </chakra.h1> */}
                        <chakra.button
                          px={2}
                          py={1}
                          bg="white"
                          fontSize="xs"
                          color="gray.900"
                          fontWeight="bold"
                          rounded="lg"
                          textTransform="uppercase"
                          _hover={{
                            bg: "gray.200",
                          }}
                          _focus={{
                            bg: "gray.400",
                          }}
                          onClick={() => {
                            setTemplate({ ...template, ...{ templateid: vid._id, templateName: vid.title } })
                            createHandle(0)

                          }}
                        >
                          Use
                        </chakra.button>
                        <chakra.button
                          px={2}
                          py={1}
                          bg="white"
                          fontSize="xs"
                          color="gray.900"
                          fontWeight="bold"
                          rounded="lg"
                          textTransform="uppercase"
                          _hover={{
                            bg: "gray.200",
                          }}
                          _focus={{
                            bg: "gr ay.400",
                          }}
                          onClick={() => {
                            navigate(`/template/${vid.url}`)
                          }}
                        >
                          View
                        </chakra.button>
                      </Flex>
                    </Box>
                  </GridItem>
                );
              })}
            </SimpleGrid>
            <Modal isOpen={templateIsOpen} onClose={templateOnClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>New Video</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Tabs
                    position="relative"
                    variant="unstyled"
                    defaultIndex={creating}
                    onChange={(index) => setCurrentCreating(index)}
                  >
                    <TabList>
                      <Tab>Video</Tab>
                    </TabList>
                    <TabIndicator
                      mt="-1.5px"
                      height="2px"
                      bg="blue.500"
                      borderRadius="1px"
                    />
                    <TabPanels>
                      <TabPanel>
                        <Input
                          placeholder="Select Base Template"
                          value={template?.templateName}
                          onChange={(event) => {
                            setTemplate({
                              ...template,
                              ...{ templateid: event.target.value },
                              ...{ templateName: event.target.value },
                            });
                          }}
                          hidden={true}
                          mt={2}
                        />
                        <Input
                          placeholder="Add Video Title"
                          onChange={(event) => {
                            setTemplate({
                              ...template,
                              ...{ title: event.target.value },
                            });
                          }}
                          mt={2}
                        />
                        <Input
                          placeholder="Add Video Description"
                          onChange={(event) => {
                            setTemplate({
                              ...template,
                              ...{ description: event.target.value },
                            });
                          }}
                          mt={2}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </ModalBody>
                <ModalFooter>

                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={(e) => {
                      setIsSubmitting(true);
                      newTemplate();
                    }}
                    isDisabled={isSubmitting}
                    leftIcon={isSubmitting ? <Spinner size="sm" /> : undefined}
                  >
                    {isSubmitting ? 'Creating...' : 'Create Video'}
                  </Button>
                  <Button variant="ghost" onClick={templateOnClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </Box>
      </Box>
    </DashboardWrapper>
  );
};

export default Templates;
