// React hooks necessary for the functionality of this component are imported
import React, { Component, ErrorInfo } from "react";
// The ChakraProvider is used for consistent UI design across the app, and the 'theme' allows for custom theming
import { ChakraProvider, theme } from "@chakra-ui/react";
// Importing routing components from 'react-router-dom' for managing the app's navigation
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// The Editor component is imported, this is likely to be rendered when a specific route is accessed
import Editor from "./pages/Editor";
import Editor2 from "./pages/Editor2";
// The Home component is imported, this is likely to be rendered when a specific route is accessed
import MyVideos from "./pages/MyVideos";
// ErrorPage component is imported to handle any errors related to routing
import ErrorPage from "./error-page";
import Feed from "./components/lib/video/Feed";
import SearchResult from "./components/lib/video/SearchResult";
import VideoDetail from "./components/lib/video/VideoDetail";
import { AppContext } from "./components/lib/context/ContextApi";
// import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Blog from "./pages/Blog";
import Privacy from "./pages/Privacy";
import Templates from "./pages/Template/Templates";
import Storyboard from "./pages/Storyboard";
import Runs from "./pages/Runs";
import Models from "./pages/Models";
import Terms from "./pages/Terms";
import Logout from "./pages/Logout";
import ForgotPassword from "./pages/ForgotPassword";
import Verify from "./pages/Verify";
import ResetPassword from "./pages/ResetPassword";
import Onboarding from "./pages/Onboarding/Onboarding";
import Payment from "./pages/Payments/Payments";
import Topup from "./pages/Payments/Topup";
import WaitListed from "./pages/Waitlisted";
import Assets from "./pages/Assets";
import BrandForm from "./pages/Brand/Brand";
import NewOrganizationForm from "./pages/Organizations/Create";
import NewTeamForm from "./pages/Teams/Create";
import { WebSocketProvider } from "./lib/components/WebSocketContext";
import OnboardingTeamForm from "./pages/Onboarding/New";
import Brandlist from "./pages/Brand/List";
import BrandCreate from "./pages/Brand/Create";
import CreateVideo from "./pages/Create";
import BrandAsset from "./pages/Brand/Assets";
import ProtectedRoute from "./utils/ProtectedRoute";
import Profile from "./pages/User/Profile";
import Ledger from "./pages/User/Ledger";
import SingleTemplate from "./pages/Template/Single";
import Handle from "./pages/Handle";
import Workflows from "./pages/Workflows";
import AIHooks from "./pages/Workflows/AIHooks";
import AIStoryboard from "./pages/Workflows/AIStoryboard";
import VideoFromURL from "./pages/Workflows/VideoFromURL";
import ProductLinkInput from "./pages/Workflows/VideoFromURL/Steps/ProductLinkInput"
import ProductDetailsForm from "./pages/Workflows/VideoFromURL/Steps/ProductDetails";
import VideoSettingsForm from "./pages/Workflows/VideoFromURL/Steps/VideoSettings";
import ScriptSelectionComponent from "./pages/Workflows/VideoFromURL/Steps/SelectScript";
import VisualStyleSelector from "./pages/Workflows/VideoFromURL/Steps/VisualStyleSelector";
import ExcelEditor from "./pages/ExcelEditor";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error: ", error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

/**
 * 'App' is a functional component that structures and navigates the application.
 * It uses react-router-dom for routing and wraps the entire application with ChakraProvider for consistent UI design.
 * The 'createBrowserRouter' function is used to create a browser router with the application's specific route configuration.
 * It accepts an array of objects where each object represents a route configuration.
 * Each route object has three properties: 'path', 'element', and 'errorElement'. These properties define the URL path, the component to render for that URL path, and the component to render when an error occurs respectively.
 * The routes defined include '/' which renders the Home component, and '/video/:videoId' which renders the Editor component.
 * The entire application is wrapped in a RouterProvider, which is further wrapped by ChakraProvider for UI uniformity.
 **/
const App: React.FC = () => {
  // A browser router is created using 'createBrowserRouter', with the specific route configuration for this application
  const router = createBrowserRouter([
    {
      // Home route configuration
      path: "/",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/handle",
      element: <Handle />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/videos/me",
      element: <ProtectedRoute>
        <MyVideos />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/dashboard",
      element: <ProtectedRoute>
        <Workflows />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/workflows/aihooks",
      element: <ProtectedRoute>
        <AIHooks />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/workflows/aistoryboard",
      element: <ProtectedRoute>
        <AIStoryboard />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/workflows/aifromurl",
      element: <ProtectedRoute>
        <VideoFromURL />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/workflows/videofromurl/start",
      element: <ProtectedRoute>
        <ProductLinkInput />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/workflows/videofromurl/edit-product/:id",
      element: <ProtectedRoute>
        <ProductDetailsForm />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/workflows/videofromurl/video-settings/:id",
      element: <ProtectedRoute>
        <VideoSettingsForm />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/workflows/videofromurl/select-script/:id",
      element: <ProtectedRoute>
        <ScriptSelectionComponent />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/workflows/videofromurl/select-style/:id",
      element: <ProtectedRoute>
        <VisualStyleSelector />
      </ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/assets",
      element: <ProtectedRoute><Assets /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/templates",
      element: <ProtectedRoute><Templates /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/login",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/signup",
      element: <Signup />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/blog",
      element: <Blog />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/privacy",
      element: <Privacy />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/terms",
      element: <Terms />,
      errorElement: <ErrorPage />,
    },
    {
      path: "template/:id",
      element: <SingleTemplate />,
      errorElement: <ErrorPage />,
    },
    {
      // Editor route configuration, with 'videoId' as a route parameter
      path: "editor/:videoId",
      element: <ProtectedRoute><Editor /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Editor route configuration, with 'videoId' as a route parameter
      path: "bulk/:videoId",
      element: <ProtectedRoute><ExcelEditor /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      // Editor route configuration, with 'videoId' as a route parameter
      path: "video/:videoId",
      element: <ProtectedRoute><Editor2 /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "feed",
      element: <Feed />,
      errorElement: <ErrorPage />,
    },
    {
      path: "models",
      element: <ProtectedRoute> <Models /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "brands/new",
      element: <ProtectedRoute><BrandCreate /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "brands/:brandId",
      element: <ProtectedRoute><BrandForm /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "brand/:brandId/assets",
      element: <ProtectedRoute><BrandAsset /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "video/create",
      element: <ProtectedRoute> <CreateVideo /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "brands",
      element: <ProtectedRoute><Brandlist /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "organization/create",
      element: <ProtectedRoute> <NewOrganizationForm /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "team/create",
      element: <ProtectedRoute><NewTeamForm /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "onboarding/new",
      element: <ProtectedRoute><OnboardingTeamForm /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "storyboard/:experimentId",
      element: <ProtectedRoute><Storyboard /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "generate/runs/:runId",
      element: <ProtectedRoute><Runs /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "feed/searchResult/:searchQuery",
      element: <SearchResult />,
      errorElement: <ErrorPage />,
    },
    {
      path: "feed/video/:id",
      element: <VideoDetail />,
      errorElement: <ErrorPage />,
    },
    {
      path: "logout",
      element: <Logout />,
      errorElement: <ErrorPage />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
      errorElement: <ErrorPage />,
    },
    {
      path: "verify",
      element: <Verify />,
      errorElement: <ErrorPage />,
    },
    {
      path: "onboarding",
      element: <ProtectedRoute><Onboarding /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "waitlisted",
      element: <ProtectedRoute><WaitListed /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "payments",
      element: <ProtectedRoute><Payment /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "payments/topup",
      element: <ProtectedRoute><Topup /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    {
      path: "profile",
      element: <ProtectedRoute><Profile /></ProtectedRoute>,
      errorElement: <ErrorPage />,
    },
    { path: "user/ledger", element: <ProtectedRoute><Ledger /></ProtectedRoute>, errorElement: <ErrorPage />, },
    {
      path: "reset-password",
      element: <ResetPassword />,
      errorElement: <ErrorPage />,
    },
  ]);

  /**
   * The return statement for the 'App' component.
   * It returns a JSX element which has the ChakraProvider wrapping a RouterProvider.
   * The RouterProvider is passed the 'router' object as a prop which contains the configured routes.
   * All the application components are children of these providers.
   **/
  return (
    // The ChakraProvider is used to provide a uniform UI design across the application
    <ErrorBoundary>
      <AppContext>
        <WebSocketProvider>
          <ChakraProvider theme={theme}>
            <RouterProvider router={router} />
          </ChakraProvider>
        </WebSocketProvider>
      </AppContext>
    </ErrorBoundary>
  );
};

// The App functional component is exported as the default for use in other parts of the application
export default App;
