"use strict";

// import { Logger } from "sitka";
import lora from "./models/sd-loras";
import xtts from "./models/xtts";
import metavoiceV1 from "./models/metavoice-v1";
import sdLoras from "./models/sd-loras";
import fastSdxl from "./models/fast-sdxl";
import fastSvd from "./models/fast-svd";
import fastSvdLcm from "./models/fast-svd-lcm";
import animatediffV2v from "./models/animatediff-v2v";
import dreamtalk from "./models/dreamtalk";
import magicAnimate from "./models/magic-animate";
import whisper from "./models/whisper";
import elevenLabs from "./models/eleven-labs";
import t2vTurbo from "./models/t2v-turbo";
import stableDiffusionV3Medium from "./models/stable-diffusion-v3-medium";

interface Map {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const ModelLists: Map = {
  lora: lora,
  xtts,
  "metavoice-v1": metavoiceV1,
  "sd-loras": sdLoras,
  "fast-sdxl": fastSdxl,
  "fast-svd": fastSvd,
  "fast-svd-lcm": fastSvdLcm,
  "animatediff-v2v": animatediffV2v,
  dreamtalk,
  "magic-animate": magicAnimate,
  "eleven-labs": elevenLabs,
  whisper,
  "t2v-turbo": t2vTurbo,
  "stable-diffusion-v3-medium": stableDiffusionV3Medium,
};

export const Models = {
  image: ["stable-diffusion-v3-medium", "fast-sdxl"],
  avatar: [],
  video: ["t2v-turbo", "fast-svd", "fast-svd-lcm", "animatediff-v2v"],
  dialog: ["eleven-labs", "metavoice-v1", "xtts"],
  //   audio: ["musicgen"],
};

export default ModelLists;
