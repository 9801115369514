import { proxy } from "valtio";
import { Models } from "specs";
import { GetInputsFromOPId, GetModesFromOPId } from "../apis/sd/video";

const initState: {
  generating: number;
  genVal: string;
  models: string[];
  modes: string[];
  mode: string;
  selectedModel: string;
  formData: any;
  props: any;
} = {
  generating: 0,
  genVal: "",
  models: [],
  modes: [],
  mode: "",
  formData: {},
  selectedModel: "",
  props: [],
};

export const genState = proxy(initState);

export const setGeneration = (generating: number) => {
  genState.generating = generating;
  switch (generating) {
    case 10:
      genState.models = Models.image;
      genState.genVal = "Image";
      break;
    case 11:
      genState.models = Models.dialog;
      genState.genVal = "Audio";
      break;
    case 12:
      genState.models = Models.video;
      genState.genVal = "Video";
      break;
    default:
      break;
  }
  genState.props = [];
  genState.modes = [];
  genState.selectedModel = "";
  genState.mode = "";
};

export const setSelectedModel = (model: string) => {
  genState.selectedModel = model;
  const modes = GetModesFromOPId(model);
  genState.modes = modes;
  genState.mode = modes[0];

  const props = Object.entries(
    GetInputsFromOPId(model, modes[0])?.properties || {}
  );
  genState.props = props;
  const initValues: any = {};
  props.map(([key, value]: any, index: number) => {
    initValues[key] = value.default;
    // if (key === "prompt" && activeIndexes && activeIndexes[2] === "0") {
    //   initValues["prompt"] = PromptValue();
    // }
    // if (key === "text" && activeIndexes && activeIndexes[2] === "1") {
    //   initValues["text"] = DialogueValue();
    // }
    if (!initValues[key] && value.examples) {
      initValues[key] = value.examples[0];
    }
    if (!initValues[key] && value.default) {
      initValues[key] = value.default;
    }
    if (!initValues[key] && value.v) {
      initValues[key] = value.v;
    }
    if (!initValues[key]) {
      console.log({ v: initValues[key], key, value }, "not set");
    }
  });
  genState.formData = initValues;
};

export const setMode = (mode: string) => {
  genState.mode = mode;
  const props = Object.entries(
    GetInputsFromOPId(genState.selectedModel, mode)?.properties || {}
  );
  genState.props = props;
  const initValues: any = {};
  props.map(([key, value]: any, index: number) => {
    initValues[key] = value.default;
    // if (key === "prompt" && activeIndexes && activeIndexes[2] === "0") {
    //   initValues["prompt"] = PromptValue();
    // }
    // if (key === "text" && activeIndexes && activeIndexes[2] === "1") {
    //   initValues["text"] = DialogueValue();
    // }
    if (!initValues[key] && value.examples) {
      initValues[key] = value.examples[0];
    }
    if (!initValues[key] && value.default) {
      initValues[key] = value.default;
    }
    if (!initValues[key] && value.v) {
      initValues[key] = value.v;
    }
    if (!initValues[key]) {
      console.log({ v: initValues[key], key, value }, "not set");
    }
  });
  genState.formData = initValues;
};

export const setFormData = (formData: any) => {
  genState.formData = formData;
};
