import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Slider } from '../../shadn/ui/slider';
import { Label } from '../../shadn/ui/label';
import { Input } from '../../shadn/ui/input';
import { ChromePicker } from 'react-color';
import { filterList } from '../../react-effect/css/filterList';
import { ImageContainer } from "../../react-effect"
import { filterNameProps } from '../../react-effect/customizeFunctions/customize';
import { LongKeys, reverseKeyMap } from '../../react-effect/filter';


interface FilterData {
    [key: string]: number | string;
}

interface ImageFilterEditorProps {
    indexInside: number;
    editorProps: any;
    selectedComponent: string;
    prop: string;
    type: string;
    getValue: (component: string, prop: string, editorProps: any) => any;
    setValue: (key: string, value: any) => void;
    defaults: any;
    valOpts?: any;
}

export const ImageFilterEditor: React.FC<ImageFilterEditorProps> = ({
    indexInside,
    editorProps,
    selectedComponent,
    prop,
    getValue,
    setValue,
    type,
    defaults,
    valOpts = {}
}) => {
    const [filters, setFilters] = useState<any>({});
    const [selectedFilter, setSelectedFilter] = useState<any>({});
    // const [selectedFilterFull, setSelectedFilterFull] = useState<any>({});
    const [imageUrl, setImageUrl] = useState<string>('');
    const [showColorPicker, setShowColorPicker] = useState({
        backgroundBefore: false,
        backgroundAfter: false,
    });

    useEffect(() => {
        const initialImageUrl = getValue(selectedComponent, "url", editorProps);

        const initialFilterValues = JSON.parse(getValue(selectedComponent, "filter", editorProps) || "{}");
        setSelectedFilter(initialFilterValues);
        setImageUrl(initialImageUrl);

        const initialFilters = {
            saturation: initialFilterValues.s ?? 1.6,
            brightness: initialFilterValues.b ?? 1.1,
            contrast: initialFilterValues.c ?? 1,
            sepia: initialFilterValues.se ?? 0.3,
            grayscale: initialFilterValues.g ?? 0,
            hueRotate: initialFilterValues.h ?? -10,
            filter: initialFilterValues.f ?? '',
            backgroundBefore: initialFilterValues.bb ?? 'rgba(0,0,0,0)',
            backgroundAfter: initialFilterValues.ba ?? 'rgba(0,0,0,0)',
        };

        setFilters(initialFilters);
    }, [selectedComponent, editorProps, getValue]);

    const handleSliderChange = useCallback((name: string, value: number[]) => {
        setFilters((prev: any) => {
            const newValue = { ...prev, [name]: value[0] };
            return newValue;
        });
        setSelectedFilter((prev: any) => {
            const shortKey = reverseKeyMap[name as LongKeys];
            const newValue = { ...prev, [shortKey]: value[0] };
            return newValue;
        });
    }, []);

    const handleFilterChange = useCallback((value: string) => {
        setFilters((prev: any) => {
            const newValue = { filter: value };
            return newValue;
        });
        setSelectedFilter((prev: any) => {
            const newValue = { f: value };
            return newValue;
        });
    }, [selectedComponent, setValue]);

    const resetFilter = () => {
        setFilters({});
        setSelectedFilter({});
    };


    const handleColorChange = useCallback((name: string, color: { hex: string }) => {
        setFilters((prev: any) => {
            const newValue = { ...prev, [name]: color.hex };
            return newValue;
        });
        setSelectedFilter((prev: any) => {
            const shortKey = reverseKeyMap[name as LongKeys];
            const newValue = { ...prev, [shortKey]: color.hex };
            return newValue;
        });
    }, [selectedComponent, setValue]);

    const toggleColorPicker = (name: string) => {
        setShowColorPicker(prev => ({ ...prev, [name]: !prev[name as keyof typeof prev] }));
    };

    const getFilterStyle = (customFilter?: string) => {
        const { saturation, brightness, contrast, sepia, grayscale, hueRotate, backgroundBefore, backgroundAfter } = filters;
        return {
            filter: `saturate(${saturation}) brightness(${brightness}) contrast(${contrast}) sepia(${sepia}) grayscale(${grayscale}) hue-rotate(${hueRotate}deg)${customFilter ? ` ${customFilter}` : ''}`,
            backgroundImage: `linear-gradient(${backgroundBefore}, ${backgroundAfter})`,
        };
    };
    useEffect(() => {
        setValue(`${selectedComponent}_filter`, JSON.stringify(selectedFilter));
    }, [selectedFilter])



    const renderedSliders = useMemo(() => {
        const propsFilter = filterNameProps[filters.filter] || [];

        return Object.entries(filters).map(([key, value]) => {
            if (!propsFilter.includes(key)) return null;
            if (['filter'].includes(key)) return null;
            if (['backgroundBefore', 'backgroundAfter'].includes(key)) {
                return <div key={key} className="space-y-2">
                    <Label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</Label>
                    <div
                        className="w-full h-30 rounded cursor-pointer"
                        style={{ backgroundColor: filters[key] as string }}
                        onClick={() => toggleColorPicker(key)}
                    >

                        <div className=" z-10">
                            <ChromePicker
                                color={filters[key] as string}
                                onChange={(color) => handleColorChange(key, color)}
                            />
                        </div>
                    </div>

                </div>
            };

            return (
                <div key={key} className="space-y-2">
                    <Label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</Label>
                    <Slider
                        id={key}
                        min={key === 'hueRotate' ? -180 : 0}
                        max={key === 'hueRotate' ? 180 : (key === 'saturation' || key === 'brightness' ? 2 : 1)}
                        step={0.01}
                        value={[value as number]}
                        onValueChange={(val) => handleSliderChange(key, val)}
                    />
                    <div className="text-sm text-gray-500">{typeof value === 'number' ? value.toFixed(2) : value as string}</div>
                </div>
            );
        });
    }, [filters, handleSliderChange]);


    return (
        <div key={indexInside} className="p-4 space-y-4">
            <div className="mb-4">
                <h2>Select Filter</h2>
                <div className="grid grid-cols-3 gap-4 mt-2">
                    <div onClick={() => resetFilter()} className={`relative w-full pt-[100%] cursor-pointer border-4 ${!filters.filter ? 'border-blue-500' : 'border-gray-300'}`} >
                        <img
                            src={imageUrl}
                            alt={"No Filter"}
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                        <div className=" text-center " style={{ "top": "-4px", position: "relative" }}>None</div>

                    </div>
                    {Object.keys(filterList).map((filter) => (
                        <ImageContainer
                            key={filter}
                            className={`cursor-pointer border-4 ${filters.filter === filter ? 'border-blue-500' : 'border-gray-300'}`}
                            onClick={() => handleFilterChange(filter)}
                            options={{
                                filter: filter as any,
                            }}
                        >
                            <div className="relative w-full pt-[100%]">
                                {type === "Image" && <img
                                    src={imageUrl}
                                    alt={filter}
                                    className="absolute inset-0 w-full h-full object-cover"
                                />}
                                {type === "Video" && <video src={imageUrl as string} controls={true} className="absolute inset-0 w-full h-full object-cover"/>}

                                <div className=" text-center " style={{ "top": "-4px", position: "relative" , background: "white" }}>{filter}</div>

                            </div>
                        </ImageContainer>
                    ))}
                </div>
            </div>

            {renderedSliders}

            <div className="mt-4">

                {selectedFilter.f && <>
                    <h3 className="text-lg font-semibold mb-2">Preview</h3>
                    <ImageContainer
                        className={`cursor-pointer border-2 w-full h-full'}`}
                        options={{ ...filters }}
                    >
                        <div className="w-full h-full bg-gray-200 overflow-hidden" >
                            {type === "Image" && <img
                                src={imageUrl}
                                alt={"Preview image"}
                                className="absolute inset-0 w-full h-full object-cover"
                            />}
                            {type === "Video" && <video src={imageUrl as string} controls={true}  />}
                        </div>
                    </ImageContainer>
                </>}

            </div>
        </div>
    );
};

export default ImageFilterEditor;