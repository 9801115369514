import {
  MotionSequenceUrls,
  poseUrls,
  styleClips,
} from "../../../apis/sd/spec";
import {
  ImageSelectorInput,
  TextArea,
  EnumFormInput,
  NumberFormInput,
  SliderFormInput,
  SwitchFormInput,
  DropSelectorInput,
  Models11Input,
  Voices11Input,
} from "./common";

export const DynamicForm = (props: any) => {
  const { handleChange, formData, properties, setFormData } = props;
  const textAreaInputs = ["Audio Url", "Prompt", "Text"];

  if (!properties || !properties.length) return <p>Choose a model</p>;
  return properties.map(([key, value]: any, index: number) => {
    if (key === "motion_sequence_url") {
      return (
        <DropSelectorInput
          key={key}
          id={key}
          value={value}
          handleChange={handleChange}
          options={MotionSequenceUrls}
          preview="vid"
        />
      );
    }

    if (key === "style_clip_url") {
      return (
        <DropSelectorInput
          key={key}
          id={key}
          value={value}
          handleChange={handleChange}
          options={styleClips}
          preview="vid"
        />
      );
    }

    if (key === "pose_url") {
      return (
        <DropSelectorInput
          key={key}
          id={key}
          value={value}
          handleChange={handleChange}
          options={poseUrls}
          preview="img"
        />
      );
    }

    if (key === "image_size" || key === "video_size") {
      if (value.anyOf) {
        const enums = value.anyOf[1].enum;
        const defaultValue = getDefaultRatio();
        return (
          <EnumFormInput
            key={key}
            id={key}
            value={value}
            handleChange={(e: any) => handleChange(e, key)}
            defaultValue={defaultValue || enums[0]}
            enums={enums}
          />
        );
      }
    }

    if (key === "image_url") {
      return (
        <ImageSelectorInput
          key={key}
          id={key}
          value={value}
          handleChange={handleChange}
        />
      );
    }
    if (key === "model_id" && value.models) {
      return (
        <Models11Input
          key={key}
          id={key}
          value={value}
          handleChange={handleChange}
          options={poseUrls}
          enums={value.models}
        />
      );
    }
    if (key === "voices" && value.voices) {      
      return (
        <Voices11Input
          key={key}
          id={key}
          value={value}
          handleChange={handleChange}
          enums={value.voices}
        />
      );
    }

    switch (value.type) {
      case "string":
        if (textAreaInputs.includes(value.title)) {
          return (
            <TextArea
              key={key}
              id={key}
              value={value}
              handleChange={handleChange}
              defaultValue={formData[key]}
            />
          );
        }
        if (value.enum) {
          return (
            <EnumFormInput
              key={key}
              id={key}
              value={value}
              handleChange={(e: any) => handleChange(e, key)}
              defaultValue={value.enum[0]}
              enums={value.enum}
            />
          );
        }

        return (
          <TextArea
            key={key}
            id={key}
            value={value}
            handleChange={handleChange}
            defaultValue={value.default}
          />
        );
      case "integer":
        return (
          <NumberFormInput
            key={key}
            id={key}
            value={value}
            handleChange={handleChange}
            defaultValue={value.default}
          />
        );
      case "number":
        return (
          <SliderFormInput
            key={key}
            id={key}
            value={value}
            handleChange={(e: number) => setFormData({ ...formData, [key]: e })}
            defaultValue={formData[key] || 0}
          />
        );
      case "boolean":
        return (
          <SwitchFormInput
            key={key}
            id={key}
            value={value}
            handleChange={handleChange}
            defaultValue={value.default}
          />
        );
      case "array":
        return (
          <TextArea
            key={key}
            id={key}
            value={value}
            handleChange={(e: any) => handleChange(e, key)}
            defaultValue={value.default}
          />
        );
      default:
        if (value.anyOf) {
          const enums = value.anyOf[1].enum;
          return (
            <EnumFormInput
              key={key}
              id={key}
              value={value}
              handleChange={(e: any) => handleChange(e, key)}
              defaultValue={enums[0]}
              enums={enums}
            />
          );
        }
        console.log(value.type, "is unhandled", value);
    }
  });
};
function getDefaultRatio() {
  const storedVal = localStorage.getItem('aexy-storage') || '{}';
  let responseVal;

  try {
    responseVal = JSON.parse(storedVal);
  } catch (e) {
    console.error('Invalid JSON data:', e);
    return;
  }

  const resolution = responseVal.resolution;
  if (!resolution || typeof resolution.height !== 'number' || typeof resolution.width !== 'number') {
    console.warn('Invalid or missing resolution data');
    return;
  }

  const aspectRatio = resolution.width / resolution.height;

  if (aspectRatio < 1) {
    // Portrait
    if (1 / aspectRatio > 1.33) {
      return "portrait_4_3";
    }
    return "portrait_16_9";
  }  else if (aspectRatio === 1) {
    return "square_hd";
  } else {
    // Landscape
    if (aspectRatio > 1.33) {
      return "landscape_4_3";
    }
    return "landscape_16_9";
  }
}

